import React, { useState } from 'react';
import {
  Sidenavbarchumdriver,
  StaffTopnavbar,
  StaffchumNavbar,
  Topnavbarchumdriver,
} from '../../Adminnavbar/Sideandtopnavbar';
import { Routes } from 'react-router-dom';

export const Staffchum = () => {
  const [open, setopen] = useState(window.innerWidth >= 991 ? false : true);
  return (
    <div className="container-fluid position-relative d-flex p-0 ">
      <div
        className={`${open ? 'open' : ''} sidebar pe-4 pb-3`}
        style={{ backgroundColor: 'white' }}
      >
        <StaffchumNavbar />
      </div>
      <div
        className={`${open ? 'open' : ''} content`}
        style={{ backgroundColor: 'rgb(1, 32, 96)' }}
      >
        <StaffTopnavbar setopen={setopen} />
        <div style={{ paddingTop: '70px' }} className="container-fluid px-4 ">
          <Routes></Routes>
        </div>
      </div>
    </div>
  );
};
