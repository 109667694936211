import React, { useContext } from 'react';
import { keyGen } from '../../../../App';
import Addcomponenthook from '../../../Addcomponenthook';
import { BucketContext } from './Vipardub';

export const ViparBucketadding = () => {
  const first = useContext(BucketContext);
  let add = first.hook;
  let setAdd = first.setHook;
  return (
    <>
      <div className="" id="korg">
        <center>
          <img src="/img/dubhookkorg.jpg" height="300px" width="300px" alt="" />
        </center>
        <center>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio1"
              value="a"
              checked={add === 'a'}
              onChange={(e) => setAdd(e.target.value)}
            />
            <label 
              style={{ fontSize: '17px' }}
              className="form-check-label"
              htmlFor="inlineRadio1"
            >
              A
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio2"
              value="b"
              checked={add === 'b'}
              onChange={(e) => setAdd(e.target.value)}
            />
            <label
              style={{ fontSize: '17px' }}
              className="form-check-label"
              htmlFor="inlineRadio2"
            >
              B
            </label>
          </div>
        </center>

        <center>
          <button
            className={`btn btn-outline-dark mt-3 me-1 mb-5 ${
              add ? '' : 'd-none'
            }`}
            id="colourbutton"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
            onClick={() => first.dispatch({ type: 'color' })}
          >
            SELECT YOUR FAVOURITE COLOUR(s)
          </button>
        </center>
      </div>
    </>
  );
};

export const Bucketcolour = () => {
  const first = useContext(BucketContext);
  let optional = first.hook;
  console.log(optional);
  return (
    <>
      <div className="" id="collapseExample">
        <div className="row">
          <button
            className="btn btn-outline-dark mb-5 "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
            onClick={(e) => first.dispatch({ type: 'Bucket' })}
          >
            Select Hook Location Again ?
          </button>
        </div>

        <>
          {first.state.variant.map((variants) => {
            return (
              <Addcomponenthook
                key={keyGen()}
                optional={optional}
                value={variants}
                name={first.state.pname}
              />
            );
          })}
        </>
      </div>
    </>
  );
};
