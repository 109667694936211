import React from 'react'
import "./UserLoading.css"

const Userloading = () => {
  return (
    <>
    <div id='pulse'>
    <div className="pulse">
      <div class="spinner-box">
  <div class="pulse-container">  
    <div class="pulse-bubble pulse-bubble-1"></div>
    <div class="pulse-bubble pulse-bubble-2"></div>
    <div class="pulse-bubble pulse-bubble-3"></div>
  </div>
</div></div>
</div>
    </>
  )
}

export default Userloading
