import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import React, { useEffect, useState } from 'react';
import { keyGen } from '../../App';

export const Reviewadmin = () => {
  const [loading, setLoading] = useState(false);
  const [showcart, setShowcart] = useState(false);
  const [one, setone] = useState(false);

  const [index, setIndex] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [state, setState] = useState([]);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    axios
      .get('/adminlogin/reviews')
      .then((res) => {
        setState(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {};
  }, []);

  const actionbtn = (status, id, index) => {
    axios
      .post('/adminlogin/status', { status, id })
      .then((res) => {
        let temp = [...state];
        temp[index].status = status;
        setState(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cartclick = (Cart_Id) => {
    if (one) return;
    setLoading(true);
    axios
      .get(`/adminlogin/cartreviews?id=${Cart_Id}`)
      .then((res) => {
        setLoading(false);
        setone(true);
        setShowcart(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  return (
    <>
      <div className="page-breadcrumb">
        <div className="row align-items-center">
          <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
            <h4 className="page-title text-white">Reviews</h4>
          </div>
        </div>
      </div>

      <div
        className="container-fluid overflow-scroll mt-3"
        style={{ backgroundColor: 'white' }}
      >
        <div className="row justify-content-center pt-3 ">
          <table className="table ">
            <thead className=" ">
              <tr>
                <th scope="col">#</th>

                <th scope="col">Name</th>
                <th scope="col">Order Id</th>
                <th scope="col">Rating</th>
                <th scope="col">Review</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody className="">
              {state.map((element, index) => {
                console.log(element);
                return (
                  <tr key={keyGen()}>
                    <td>{index + 1}</td>
                    <td>
                      {element?.reviews[0]?.billingAddress?.firstname +
                        ' ' +
                        element?.reviews[0]?.billingAddress?.lastname}
                    </td>
                    <td>{element?.Order_Id}</td>
                    <td>{element?.rating}</td>
                    <td>{element?.comment}</td>
                    <td>
                      <Button
                        variant="primary"
                        onClick={(e) => {
                          setIndex(index);
                          handleShow();
                        }}
                      >
                        View details
                      </Button>
                    </td>
                    <td>
                      {!element.status ? (
                        <button
                          className="btn btn-success"
                          onClick={() => actionbtn(true, element._id, index)}
                        >
                          Approve
                        </button>
                      ) : (
                        <button
                          className="btn btn-danger"
                          onClick={() => actionbtn(false, element._id, index)}
                        >
                          Reject
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        scrollable
        centered
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>View details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {index >= 0 ? (
            <>
              <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ flexShrink: 0 }}>
                    Shipping Address
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="ptag">
                      <p>
                        Name:&nbsp;
                        {state[index]?.reviews[0]?.shippingAddress.firstname +
                          ' ' +
                          state[index]?.reviews[0]?.shippingAddress?.lastname}
                        &nbsp;
                      </p>
                      <p>
                        Address:&nbsp;
                        {state[index]?.reviews[0]?.shippingAddress?.address}
                        &nbsp;
                      </p>
                      <p>
                        Country:&nbsp;
                        {state[index]?.reviews[0]?.shippingAddress?.country}
                        &nbsp;
                      </p>
                      <p>
                        State:&nbsp;
                        {state[index]?.reviews[0]?.shippingAddress?.state}&nbsp;
                      </p>
                      <p>
                        City:&nbsp;
                        {state[index]?.reviews[0]?.shippingAddress?.city}&nbsp;
                      </p>
                      <p>
                        Pincode:&nbsp;
                        {state[index]?.reviews[0]?.shippingAddress?.pincode}
                        &nbsp;
                      </p>
                      <p>
                        Email:&nbsp;
                        {state[index]?.reviews[0]?.shippingAddress?.email}&nbsp;
                      </p>
                      <p>
                        Mobileno:&nbsp;
                        {state[index]?.shippingAddress?.mobileno}
                      </p>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ flexShrink: 0 }}>
                    Billing Address
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="ptag">
                      <p>
                        Name:&nbsp;
                        {state[index]?.reviews[0]?.billingAddress?.firstname +
                          ' ' +
                          state[index]?.reviews[0]?.billingAddress?.lastname}
                        &nbsp;
                      </p>
                      <p>
                        Address:&nbsp;
                        {state[index]?.reviews[0]?.billingAddress?.address}
                        &nbsp;
                      </p>
                      <p>
                        Country:&nbsp;
                        {state[index]?.reviews[0]?.billingAddress?.country}
                        &nbsp;
                      </p>
                      <p>
                        State:&nbsp;
                        {state[index]?.reviews[0]?.billingAddress?.state}&nbsp;
                      </p>
                      <p>
                        City:&nbsp;
                        {state[index]?.reviews[0]?.billingAddress?.city}&nbsp;
                      </p>
                      <p>
                        Pincode:&nbsp;
                        {state[index]?.reviews[0]?.billingAddress?.pincode}
                        &nbsp;
                      </p>
                      <p>
                        Email:&nbsp;
                        {state[index]?.reviews[0]?.billingAddress?.email}&nbsp;
                      </p>
                      <p>
                        Mobileno:&nbsp;
                        {state[index]?.reviews[0]?.billingAddress?.mobileno}
                      </p>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ flexShrink: 0 }}>Gift</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>Occassion :{state[index]?.Gift?.Occassion}</p>
                    <p> Gift Message :{state[index]?.Gift?.GiftcardMessage}</p>
                    <p>Date:{state[index]?.Gift?.Specificdeliverydate}</p>
                    <p>
                      Choclates:
                      {state[index]?.Gift?.choco?.map((element) => {
                        return <span key={keyGen()}>{element}</span>;
                      })}
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel4'}
                onChange={handleChange('panel4')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  onClick={(e) => cartclick(state[index]?.reviews[0]?.Cart_Id)}
                >
                  <Typography sx={{ flexShrink: 0 }}>Cart</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <>
                      {loading ? (
                        <div className="d-flex justify-content-center align-items-center">
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </div>
                      ) : (
                        <>
                          {showcart?.Items?.map((element) => {
                            return (
                              <div className="ptag" key={keyGen()}>
                                <p>Product Name:{element.productId.pname}</p>
                                <p>Quantity:{element.qty}</p>
                                <p>
                                  Varient Name:{element.varientId.varentName}
                                </p>
                                <p>
                                  Varient Code:{element.varientId.varentCode}
                                </p>
                                <hr />
                              </div>
                            );
                          })}
                        </>
                      )}
                    </>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </>
          ) : (
            <></>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
