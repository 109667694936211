import React, { useEffect, useState } from 'react';
const Scrolltopbutton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollPosition = window.pageYOffset;
    const totalHeight =
      document.documentElement.scrollHeight - window.innerHeight;
    const scrollPercentage = (scrollPosition / totalHeight) * 100;

    if (scrollPercentage >= 90) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth',
  //   });
  // };
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,

      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {!isVisible && (
        <button
          className="scrollToBottomBtn btn btn-lg-square"
          onClick={scrollToBottom}
          style={{
            // backgroundColor: 'grey',
            color: '#99d050',
            // borderRadius: '50%',
            border: 'none',
          }}
        >
          <i class="bi bi-chevron-double-down"></i>
        </button>
      )}
    </>
  );
};

export default Scrolltopbutton;
