import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { notify } from '../../../App';
import { addcapode, addTocart } from '../../../redux/viparChic/cartSlice';
import Accessoriescappod from './Accessoriescappod';
import { Accesspod } from './Accesspod';
import './Access.css';
export const Access = ({ value }) => {
  let dispatch = useDispatch();
  const [cap1, setcap1] = useState('');
  const [cap2, setcap2] = useState('');

  const [cap3, setcap3] = useState('');

  const [cap4, setcap4] = useState('');
  const [err, seterr] = useState();

  useEffect(() => {
    console.log(cap1);
    console.log(cap2);

    console.log(cap3);

    console.log(cap4);
  }, [cap1, cap2, cap3, cap4]);

  const validation = (cap1, cap2, cap3, cap4) => {
    if (cap1.length === 0) {
      return seterr('select 1st cap-pod');
    }
    if (cap2.length === 0) {
      return seterr('select 2nd cap-pod');
    }
    if (cap3.length === 0) {
      return seterr('select 3rd cap-pod');
    }
  
    seterr('');
    return true;
  };
  const addtocart = (e) => {
    e.preventDefault();

    if (validation(cap1, cap2, cap3) === true) {
      dispatch(
        addcapode({
          item: {
            selected: [cap1, cap2, cap3],
            productId: '63fc6001f6dc9d130e8e21c7',
            qty: 1,
            varientId: '63fc71eaf6dc9d130e8e21d7',
          },
        })
      );
      e.target.reset();
      setcap1('');
      setcap2('');
      setcap3('');
      notify('Added to Shopping Bag', true);
    }

    return true;
  };

  return (
    <form onSubmit={addtocart}>
      <div className="d-block d-md-none row g-1 d-flex justify-content-center">
        <div className="col-3  ">
          <Top link={'/img/VPFMH100-JETBLACK.jpg'} />
        </div>
        <div className="col-3">
          <Top link={'/img/VPFMH100-OFFWHITE.jpg'} />
        </div>
        <div className="col-3">
          <Top link={'/img/VPFMH100-LIGHTGREY.jpg'} />
        </div>
      </div>
      <div className="d-block d-md-none row pt-1 g-1 d-flex justify-content-center">
        <div className="col-3 ">
          <Top link={'/img/VPFMH100-APPLEGREEN.jpg'} />
        </div>
        <div className="col-3 ">
          <Top link={'/img/VPFMH100-SKYBLUE.jpg'} />
        </div>
        <div className="col-3">
          <Top link={'/img/VPFMH100-LEMMONYELLOW.jpg'} />
        </div>
      </div>
      <div className="d-block d-md-none row pt-1 g-1 d-flex justify-content-center">
        <div className="col-3 ">
          <Top link={'/img/VPFMH100-CHERRYRED.jpg'} />
        </div>
        <div className="col-3 ">
          <Top link={'/img/VPFMH100-BABYPINK.jpg'} />
        </div>
        <div className="col-3 ">
          <Top link={'/img/VPFMH100-LAVENDER.jpg'} />
        </div>
      </div>
      <div className="d-none d-md-block ">
        <div className="row g-3 d-flex justify-content-center">
          <div className="col-3 col-sm-2 col-lg-1 ">
            <Top link={'/img/VPFMH100-JETBLACK.jpg'} />
          </div>
          <div className="col-3 col-sm-2 col-lg-1">
            <Top link={'/img/VPFMH100-OFFWHITE.jpg'} />
          </div>
          <div className="col-3 col-sm-2 col-lg-1">
            <Top link={'/img/VPFMH100-LIGHTGREY.jpg'} />
          </div>
          <div className="col-3 col-sm-2 col-lg-1">
            <Top link={'/img/VPFMH100-APPLEGREEN.jpg'} />
          </div>
          <div className="col-3 col-sm-2 col-lg-1">
            <Top link={'/img/VPFMH100-SKYBLUE.jpg'} />
          </div>
          <div className="col-3 col-sm-2 col-lg-1">
            <Top link={'/img/VPFMH100-LEMMONYELLOW.jpg'} />
          </div>
          <div className="col-3 col-sm-2 col-lg-1">
            <Top link={'/img/VPFMH100-CHERRYRED.jpg'} />
          </div>
          <div className="col-3 col-sm-2 col-lg-1">
            <Top link={'/img/VPFMH100-BABYPINK.jpg'} />
          </div>
          <div className="col-3 col-sm-2 col-lg-1">
            <Top link={'/img/VPFMH100-LAVENDER.jpg'} />
          </div>
        </div>
      </div>
      <p></p>
      <Accesspod
        value={value}
        label="select 1st cap-pod"
        onChange={(e) => {
          setcap1(e);
        }}
      ></Accesspod>
      <Accesspod
        value={value}
        label="select 2nd cap-pod"
        onChange={(e) => {
          setcap2(e);
        }}
      ></Accesspod>
      <Accesspod
        value={value}
        label="select 3rd cap-pod"
        onChange={(e) => {
          setcap3(e);
        }}
      ></Accesspod>
     
      <p className="text-danger">{err}</p>
      <button className="btn btn-outline-dark" type="submit">
        ADD TO SHOPPING BAG
      </button>
    </form>
  );
};
const Top = ({ link }) => {
  const [first, setFirst] = useState(true);
  return (
    <div
      onClick={() => setFirst((e) => !e)}
      className={`${first ? '' : 'vipar-top'}`}
    >
      <div>
        <img className="img-fluid" src={link} alt=""></img>
      </div>
    </div>
  );
};
