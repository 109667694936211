import React, { useEffect, useState } from 'react';
import { NewNavViparc } from './Vipar-chic/NewNavViparc/NewNavViparc';
import { Footer } from './Footer/Footer';
import axios from 'axios';
import { Link } from 'react-router-dom';
export const Taxinvoice = () => {
  const [state1, setstate1] = useState([]);
  const [tax, settax] = useState([]);

  useEffect(() => {
    axios
      .post('/public/finalinvoice')
      .then((res) => {
        console.log(res.data);
        settax(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <NewNavViparc />
      <hr
        className="mt-0"
        style={{
          color: 'rgb(1, 32, 96)',
          opacity: '1',
          borderTop: '3px solid',
        }}
      />
      <div id="fo" className="py-5">
        <table className="table ">
          <tbody className="nm">
            {tax?.map((elem) => {
              console.log(elem);
              console.log(elem.Order_Id);

              return (
                <tr>
                  <td>{elem.Order_date}</td>
                  <td>{elem.Order_Id}</td>
                  <td>
                    <Link
                      className="btn btn-primary"
                      to={`/invoicedrop?order=${elem.Order_Id}`}
                    >
                      view
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {tax.length == 0 && (
          <span className="text-center">
            FINAL TAX INVOICES WILL BE AVAILABLE ONLY AFTER ONE WEEK POST ORDER
            DELIVERY
          </span>
        )}
      </div>
      <Footer />
    </>
  );
};
