import React from 'react';

function Carosuel() {
  return (
    <>
      <div
        className="container-fluid p-0 mb-5 wow fadeIn  d-none d-md-block"
        data-wow-delay="0.1s"
      >
        <div
          id="header-carousel"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active ">
              <img className="w-100" src="/img/1dub.png" alt="" />
              <div className="carousel-caption"></div>
            </div>
            <div className="carousel-item">
              <img className="w-100" src="/img/dubclass321.png" alt="" />
              <div className="carousel-caption"></div>
            </div>
            <div className="carousel-item">
              <img className="w-100" src="/img/fmhnewpic.png" alt="" />
              <div className="carousel-caption"></div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#header-carousel"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
                style={{ backgroundColor: 'grey', border: '10px solid grey' }}
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#header-carousel"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
                style={{ backgroundColor: 'grey', border: '10px solid grey' }}
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
      <div
        id="template-mo-zay-hero-carousel-2"
        className="carousel slide  d-sm-block  d-md-none"
        data-bs-ride="carousel"
      >
        <ol className="carousel-indicators">
          <li
            data-bs-target="#template-mo-zay-hero-carousel-2"
            data-bs-slide-to="0"
            className="active"
          ></li>
          <li
            data-bs-target="#template-mo-zay-hero-carousel-2"
            data-bs-slide-to="1"
          ></li>
          <li
            data-bs-target="#template-mo-zay-hero-carousel-2"
            data-bs-slide-to="2"
          ></li>
        </ol>
        <div className="carousel-inner bg-light">
          <div
            className="carousel-item active bg-light"
            data-bs-interval="3000"
          >
            <div className="container">
              <div className="row p-5">
                <div className="mx-auto col-md-8 col-lg-6 order-lg-last">
                  <img className="img-fluid" src="/img/dubbanner.jpg" alt="" />
                </div>
                <div className="col-lg-6 mb-0 d-flex align-items-center">
                  <div className="text-align-left">
                    <h1 className="fggt mt-3 monot">
                      Vipar&nbsp;&nbsp;
                      <span id="headernameofcarusel">D U B</span>
                    </h1>

                    <p className="p">
                      <font className="p">
                        a must-have in every household !
                      </font>
                    </p>
                    <center className="p">( articles )</center>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item bg-light" data-bs-interval="3000">
            <div className="container">
              <div className="row p-5">
                <div className="mx-auto col-md-8 col-lg-6 order-lg-last">
                  <img
                    className="img-fluid"
                    src="/img/bannerdubc.jpeg"
                    alt=""
                  />
                </div>
                <div className="col-lg-6 mb-0 d-flex align-items-center">
                  <div className="text-align-left">
                    <h1 className="fggt mt-3 monot">
                      Vipar&nbsp;&nbsp;
                      <span id="headernameofcarusel">D U B&nbsp;&nbsp;Classic </span>
                    </h1>

                    <p className="p">
                      <font className="p">
                        a classy D U B ! <br />
                        <span className="p invisible">daa</span>
                      </font>
                    </p>
                    <center className="p">( articles )</center>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item " data-bs-interval="3000">
            <div className="container">
              <div className="row p-5">
                <div className="mx-auto col-md-8 col-lg-6 order-lg-last">
                  <img
                    className="img-fluid w-100"
                    src="/img/banner_img_01.jpeg"
                    alt=""
                  />
                </div>
                <div className="col-lg-6 mb-0 d-flex align-items-center">
                  <div className="text-align-left align-self-center">
                    <h1 className="fggt mt-3 monot ">
                      Vipar&nbsp;&nbsp;
                      <span id="headernameofcarusel">Cap-pod </span>
                    </h1>{' '}
                    <p className="p">
                      <font className="p">
                        capsule-shaped{' '}
                        <span className="p">multipurpose holder !</span>
                      </font>
                    </p>
                    <center className="p">( accessories )</center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          className="carousel-control-prev text-decoration-none w-auto ps-3"
          href="#template-mo-zay-hero-carousel-2"
          role="button"
          data-bs-slide="prev"
        >
          <i className="fas fa-chevron-left"></i>
        </a>
        <a
          className="carousel-control-next text-decoration-none w-auto pe-3"
          href="#template-mo-zay-hero-carousel-2"
          role="button"
          data-bs-slide="next"
        >
          <i className="fas fa-chevron-right"></i>
        </a>
      </div>
    </>
  );
}

export default Carosuel;
