import React, { useContext, useState, useEffect } from 'react';
import { Bucketcontext } from './Viperduocombo';
import { Cappode, Vparshow } from './Vparshow';
import { useDispatch, useSelector } from 'react-redux';
import { addcombo } from '../../../../redux/viparChic/cartSlice';
import { keyGen } from '../../../../App';

export const Viparduo = () => {
  const val = useContext(Bucketcontext);
  let first = val.hook;
  let setFirst = val.setHook;

  return (
    <>
      <div className="modal-body">
        <div className="" id="korg">
          <center>
            <img
              src="/img/dubhookkorg.jpg"
              height="300px"
              width="300px"
              alt=""
            />
          </center>
          <center>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                value="a"
                onChange={(e) => setFirst(e.target.value)}
              />
              <label className="form-check-label" htmlhtmlFor="inlineRadio1">
                A
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                value="b"
                onChange={(e) => setFirst(e.target.value)}
              />
              <label className="form-check-label" htmlhtmlFor="inlineRadio2">
                B
              </label>
            </div>
          </center>

          <center>
            <button
              className={`btn btn-outline-dark mt-3 me-1 mb-5  ${
                first ? '' : 'd-none'
              }`}
              id="colourbutton"
              type="button"
              aria-expanded="false"
              aria-controls="collapseExample"
              onClick={() => {
                val.dispatch({ type: 'color' });
              }}
            >
              Now select your favourite colours
            </button>
          </center>
        </div>
      </div>
    </>
  );
};

export const Bucketcolor = (optional) => {
  const state = useContext(Bucketcontext);
  console.log(state);
  let cart = useSelector((state) => state.cart.value);
  let dispatch = useDispatch();
  let { productId, _id, varientId } = { ...state };

  useEffect(() => {
    console.log(productId);
    return () => {};
  }, []);

  const [err, seterr] = useState();

  const validation = (item1, item2, item3) => {
    if (item3.length === 0) {
      return seterr('select a DUB');
    }
    if (item1.length === 0) {
      return seterr(' select a cap-pod');
    }
    if (item2.length === 0) {
      return seterr('select a cap-pod');
    }

    seterr('');
    return true;
  };

  const addtocombo = () => {
    if (validation(item1, item2, item3) === true) {
      console.log(varientId);

      dispatch(
        addcombo({
          item: {
            varientId: item3,
            combo: true,
            _id,
            productId: state?.state?.variant[0]?.productId,
            selected: [item1, item2],
            optional: state?.hook,
            qty: 1,
          },
          cart,
        })
      );
    }
  };

  const [item1, setitem1] = useState('');
  const [item2, setitem2] = useState('');
  const [item3, setitem3] = useState('');

  console.log(state);
  useEffect(() => {
    console.log(item1);
    console.log(item2);
    console.log(item3);
  }, [item1, item2, item3]);

  return (
    <>
      <div className="" id="collapseExample">
        <div className="row">
          <button
            className="btn btn-outline-dark mb-5 "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
            onClick={() => {
              state.dispatch({ type: 'bucket' });
            }}
          >
            Select Hook Location Again ?
          </button>
        </div>

        <div>
          {state?.state?.variant?.map((variants) => {
            console.log('hi');
            return (
              <Vparshow
                onChange={(e) => {
                  setitem3(e);
                }}
                value={variants}
                // key={keyGen()}
              ></Vparshow>
            );
          })}
        </div>
        <hr />

        <Cappode
          // key={keyGen()}
          onChange={(e) => {
            setitem1(e);
          }}
          value={state.first.variant}
          label="Select 1st Cap-pod"
        ></Cappode>

        <Cappode
          // key={keyGen()}
          onChange={(e) => {
            setitem2(e);
          }}
          value={state.first.variant}
          label="Select 2nd Cap-pod"
        ></Cappode>
      </div>
      <p className="text-danger">{err}</p>
      <button
        className="btn btn-outline-dark"
        type="button"
        onClick={() => addtocombo()}
      >
        Add To Cart
      </button>
    </>
  );
};
