import React from 'react';
import { Link } from 'react-router-dom';
import ReactCompareImage from 'react-compare-image';

export const Articlesmain = () => {
  return (
    <>
      <hr
        className="mt-0"
        style={{
          color: 'rgb(1, 32, 96)',
          opacity: '1',
          borderTop: '3px solid',
        }}
      />
      <div>
        <div className="container-fluid  p-0">
          <div className="container mt-1 fnt">
            <div className="row d-flex justify-content-between w-100 p-0 m-0">
              <div className=" col-md-6  col-lg-4 mt-5">
                <center>
                  <div>How do you like it....</div>
                  <div className="mb-3">
                    Dirty and Messy or Clean and Tidy ?
                  </div>
                </center>
                <Link
                  to="/vipar-chic/articles/articles_dual_utility_basket"
                  className="linknew"
                  style={{ textDecoration: 'none' }}
                >
                  {' '}
                  <center>
                    <div
                      className="card cardforproduct"
                      style={{ border: 'none' }}
                    >
                      {/* <img
                        src="/img/clean&tidy.jpg"
                        className="card-img-top"
                        alt="..."
                      /> */}
                      <ReactCompareImage
                        sliderPositionPercentage={0.5}
                        leftImageCss={{
                          filter: 'grayscale(75%)',
                        }}
                        leftImage="/img/dirty&messy.jpg"
                        rightImage="/img/clean&tidy.jpg"
                      />

                      <div className="card-body ">
                        <span className="text-dark ">
                          <span style={{ fontWeight: '800' }}>
                            Dual Utility Basket
                          </span>
                          &nbsp;:&nbsp;An Attractive Newspaper, Milk Holder.
                        </span>
                      </div>
                    </div>
                  </center>
                </Link>
              </div>
              <div className=" col-md-6  col-lg-4 mt-5">
                <center>
                  <div>How do you like it....</div>
                  <div className="mb-3">Cluttered or Organised ?</div>
                </center>
                <Link
                  to="/vipar-chic/articles/cappod"
                  className="linknew"
                  style={{ textDecoration: 'none' }}
                >
                  <center>
                    <div
                      className="card cardforproduct "
                      id="eruhuieyrt8eryt7863"
                      style={{ border: 'none' }}
                    >
                      {/* <img
                        src="/img/organisedaccessories.jpg"
                        className="card-img-top"
                        alt="..."
                      /> */}
                      {/* <ReactCompareImage
                        className="divfornotscrolling"
                        vertical={true}
                        sliderPositionPercentage={0.3}
                        leftImageCss={{
                          //   'z-index': '1491',
                          filter: 'grayscale(75%)',
                        }}
                        onSliderPositionChange={(e) => {
                          // document.body.style.overflow = 'hidden';
                          // setTimeout(() => {
                          //   document.body.style.overflow = 'scroll';
                          // }, 2000);
                        }}
                        leftImage="/img/organisedaccessories.jpg"
                        rightImage="/img/organisedaccessories.jpg"
                      />
                      <div className="mt-5"></div> */}
                      <ReactCompareImage
                        className="divfornotscrolling "
                        // vertical={true}
                        sliderPositionPercentage={0.5}
                        leftImageCss={{
                          //   'z-index': '1491',
                          filter: 'grayscale(75%)',
                        }}
                        leftImage="/img/cluttered.jpg"
                        rightImage="/img/organisedaccessories.jpg"
                      />

                      <div className="card-body ">
                        <span className="text-dark ">
                          <span style={{ fontWeight: '800' }}>Cap-pod</span>
                          &nbsp;:&nbsp;A Compact Receptacle for your Essentials.
                        </span>
                      </div>
                    </div>
                  </center>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
