import React from 'react';
// import { Footer } from '../components/Footer/Footer'
// import { NavBar } from '../components/NavBar/NavBar'
import { Viparhome } from '../components/Viparhome/Viparhome';
 
export default function Home() {
  return (
    <>
      {/* <NavBar /> */}
      {/* <div className='d-flex justify-content-center align-items-center' style={{height:'100vh'}} >
                <h1>Welcome</h1>
            </div> */}
      <Viparhome />
   
    </>
  );
}
