import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { notify } from '../../App';
// let item = {
//     productId :'',
//     varientId:'',
//     Qty:'',
// }
export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    value: [],
  },
  reducers: {
    addTocart: (state, action) => {
      try {
        let item = action.payload.item;
        let cart = [...action.payload.cart];
        let index = cart.indexOf(
          cart.find((items, index) => {
            return (
              (items.productId === item.productId &&
                items.varientId === item.varientId) ||
              (items.productId._id === item.productId &&
                items.varientId._id === item.varientId)
            );
          })
        );
        if (index >= 0) {
          cart[index] = item;
          state.value = [...cart];
        } else {
          state.value = [...state.value, item];
        }
        let save = axios
          .post('/public/addToCart', { data: state.value })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
        if (save) {
        }
      } catch (error) {}
    },

    initialFromDB: (state, action) => {
      let items = action.payload.value;
      state.value = items;
    },

    removeformcart: (state, action) => {
      console.log(action.payload);
      const { _id, cart } = { ...action.payload };
      let cartTemp = [...cart];

      let ind = cartTemp.indexOf(
        cartTemp.find((elem) => {
          console.log(elem._id, _id);
          return elem._id == _id;
        })
      );
      console.log(ind);
      if (ind >= 0) {
        cartTemp.splice(ind, 1);
        console.log(cartTemp);
        state.value = [...cartTemp];
        let save = axios
          .post('/public/addToCart', { data: state.value })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
        if (save) {
        }
        notify('Removed from Shopping Bag', true);
      } else {
      }
    },

    cartproductincrement: (state, action) => {
      var { _id, cart } = { ...action.payload };
      console.log(_id);
      console.log(cart);
      let cartTemp = [...cart];
      let cartitem = cartTemp.find((elem) => {
        return elem._id === _id;
      });
      let index = cart.indexOf(cartitem);
      let Temp = { ...cartitem };
      Temp.qty += 1;

      cartTemp[index] = Temp;
      state.value = cartTemp;
      let save = axios
        .post('/public/addToCart', { data: state.value })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
      if (save) {
      }
    },

    cartgift: (state, action) => {
      var { _id, cart, gift } = { ...action.payload };
      console.log(_id);
      console.log(cart);
      let cartTemp = [...cart];
      let cartitem = cartTemp.find((elem) => {
        return elem._id === _id;
      });
      let index = cart.indexOf(cartitem);
      let Temp = { ...cartitem };
      Temp.gift = gift;

      cartTemp[index] = Temp;
      state.value = cartTemp;
      let save = axios
        .post('/public/addToCart', { data: state.value })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
      if (save) {
      }
    },

    cartproductdecrement: (state, action) => {
      var { _id, cart } = { ...action.payload };
      let cartTemp = [...cart];
      let cartitem = cartTemp.find((elem) => {
        return elem._id === _id;
      });
      let index = cart.indexOf(cartitem);
      let Temp = { ...cartitem };
      Temp.qty -= 1;
      if (Temp.qty >= 1) {
        cartTemp[index] = Temp;
        state.value = cartTemp;
      }
      let save = axios
        .post('/public/addToCart', { data: state.value })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
      if (save) {
      }
    },

    hookselector: (state, action) => {
      var { _id, cart, value } = { ...action.payload };
      let cartTemp = [...cart];
      let cartitem = cartTemp.find((elem) => {
        return elem._id === _id;
      });
      let index = cart.indexOf(cartitem);
      let Temp = { ...cartitem };
      Temp.optional = value;

      cartTemp[index] = Temp;
      state.value = cartTemp;
      let save = axios
        .post('/public/addToCart', { data: state.value })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
      if (save) {
      }
    },

    addcombo: (state, action) => {
      try {
        let item = action.payload.item;
        console.log(item);
        state.value = [...state.value, item];
        console.log(state.value);

        let save = axios
          .post('/public/addToCart', { data: state.value })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
        if (save) {
        }
      } catch (err) {
        console.log(err);
      }
    },

    addcapode: (state, action) => {
      let item = action.payload.item;
      console.log(item);
      state.value = [...state.value, item];
      console.log(state.value);

      let save = axios
        .post('/public/addToCart', { data: state.value })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
      if (save) {
      }
    },
  },
});

export const {
  addTocart,
  addcapode,
  addcombo,
  hookselector,
  addTocartOption,
  initialFromDB,
  removeformcart,
  cartproductincrement,
  cartproductdecrement,
  cartgift,
} = cartSlice.actions;
export default cartSlice.reducer;
