import React from 'react';
import './Pagenotfound.css';

function Pagenotfound() {
  return (
    <>
      <section
        className="d-flex justify-content-center align-items-center"
        style={{ backgroundColor: '#1a202c', height: '100vh' }}
      >
        <div className="px-4 text-lg  border-r border-gray-500 text-gray-500  tracking-wider">
          404{' '}
        </div>
        <div className=" pe-4 text-gray-500 ">|</div>
        <div className="ml-4 text-lg  border-gray-500 text-gray-500   text-uppercase ">
          Not Found{' '}
        </div>
      </section>
    </>
  );
}

export default Pagenotfound;
