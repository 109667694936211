import React from 'react';
import { Navviparhome } from './Navviparhome';
import { Footer } from '../Footer/Footer';

export const Brand = () => {
  return (
    <>
      <Navviparhome />
      <div id="foooo">
        <section className="bgvipar text-white py-3">
          <div className="container w-100">
            <div className="d-flex justify-content-between align-items-center pt-3 ps-1">
              <span style={{ fontSize: '15px' }}>THE NAME</span>
              <span style={{ fontSize: '15px' }} className="">
                THE TAGLINE
              </span>
              <span style={{ fontSize: '15px' }} className="">
                THE LOGO
              </span>
            </div>
            <div className="d-flex justify-content-between align-items-center  ">
              <span
                className="vipar"
                style={{
                  fontSize: '22px',
                  color: '#99d050',
                  paddingLeft: '12px',
                }}
              >
                {' '}
                Vipar
              </span>
              <div className=" ">
                <span style={{ fontSize: '15px' }} className=" ml">
                  <span className="vipar ml" style={{ fontSize: '15px' }}>
                    Vi
                  </span>
                  <span
                    className="mono text-light ml"
                    style={{ fontSize: '15px' }}
                  >
                    sionary
                  </span>{' '}
                  &nbsp;
                  <span className="vipar ml" style={{ fontSize: '15px' }}>
                    &nbsp;par&nbsp;
                  </span>
                  <span
                    className="mono  text-light ml"
                    style={{ fontSize: '15px' }}
                  >
                    extremity
                  </span>
                </span>
              </div>
              <span
                class="navbar-brand text-success logo"
                style={{ paddingRight: '10px' }}
              >
                <img
                  className=""
                  src="/img/viparman.png"
                  alt=""
                  height="50px"
                  width="50px"
                />
              </span>
            </div>
          </div>
        </section>
        <section className="pb-5" style={{ backgroundColor: '#e9eef5' }}>
          <div className="container ps-3">
            <div className=" pt-5">
              <span className=" zz"> THE NAME</span>
              <br />
            </div>

            <div className="pt-4">
              <span className="">
                Our Brand Name "
                <span
                  className="vipar"
                  style={{ fontSize: '22px', color: '#99d050' }}
                >
                  Vipar&nbsp;
                </span>
                " &nbsp;is a combination of words in the tagline.
              </span>
              <br />
            </div>
            <div className="pt-3">
              <span className="">The Name is pronounced as "y-per".</span>
              <br />
            </div>

            <div className="pt-5">
              <span className="zz">THE LOGO</span>
              <br />
            </div>
            <div className="pt-4">
              <span className="">
                A Logo is the Identity of a Brand and our Mascot "
                <span
                  className="vipar"
                  style={{ fontSize: '22px', color: '#99d050' }}
                >
                  Vipar&nbsp;
                </span>
                -man" is our Logo.
              </span>
              <br />
            </div>
            <div className="">
              {/* <span className="zz">OFFICE ADDRESS</span> */}
            </div>
            <div className="">
              {/* <span className="">
                Vipar
                <br />
                # 103, 2nd Floor
                <br />
                2023/A, Jeevanbheema Nagar
                <br />
                14th Main, HAL 2nd Stage
                <br />
                Indiranagar, Bangalore
                <br />
                Pin code : 560038
              </span> */}

              <div>
                {/* Contact Number : 080-41700022 */}
                <br />
                <div className="">
                  Organization's Official E-mail ID : vipar.official@gmail.com
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};
