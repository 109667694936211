import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Logocenter } from '../Logocenter';
import { Footer } from '../Footer/Footer';

export const Humane = () => {
  let location = useLocation();

  return (
    <>
      <nav
        className="navbar navbar-expand navbar-light shadow"
        id="viparcare"
        style={{ fontFamily: 'FootlightMTLight', display: 'block' }}
      >
        <div
          className="  px-4  w-100"
          style={{
            display: 'block',
            height: '122px',
            backgroundColor: 'rgb(1,32,96,1)',
          }}
        >
          <div className="">
            <div className="d-flex justify-content-between">
              <div style={{ marginTop: '20px' }}>
                <Link
                  class="navbar-brand text-success logo align-self-center"
                  to="/vipar-care"
                >
                  {/* <img
                    className=""
                    src="/img/carecirclee.png"
                    alt=""
                    height="80px"
                    width="80px"
                  /> */}
                  <div style={{ width: '80px', height: '80px' }}>
                    <Logocenter link={'/img/carecirclee.png'} />
                  </div>
                </Link>
              </div>

              <div className="xn">
                {/* <span className="invisible">
                  Innovative&nbsp;Utilitarian&nbsp;Products
                </span> */}
                <div className="pt-2">
                  <div className="mt-2  ">
                    <span
                      className="vipar transform"
                      style={{
                        fontSize: '22px',
                        color: '#99d050',
                        paddingLeft: '39px',
                      }}
                    >
                      Vipar
                    </span>
                    <span
                      className="ps-1 text-light transform"
                      style={{
                        fontSize: '20px',
                        fontFamily: 'maiandra',
                      }}
                    >
                      &nbsp;CARE
                    </span>
                  </div>
                  <div className=" " style={{ height: '13px' }}></div>
                  <div className="mt-3">
                    {' '}
                    <span
                      className="  text-light   btnsss maiandra "
                      style={{
                        fontSize: '16px',
                        textTransform: 'none',
                      }}
                    >
                      Compassionate. Empathetic.
                    </span>
                  </div>
                </div>
              </div>
              <div className="invisible">
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container-fluid mt-2 w-100"
          style={{ display: 'block' }}
        >
          <div className=" d-flex justify-content-center d-md-block ">
            <div className="r" id="templatemo_main_nav">
              <div className="flex-fill">
                <div className="d-block d-md-none">
                  <ul
                    className=" navbar-nav d-flex   justify-content-center mx-auto ms-0"
                    style={{ border: 'none' }}
                  >
                    <li className="nav-item pr-0">
                      {/* <Link
                        to="/vipar-care"
                        className={`viparc nav-link cardtextt  aa ${
                          location.pathname === '/vipar-care'
                            ? ' active-link-vipar'
                            : ''
                        }`}
                      >
                        What&nbsp;is&nbsp;
                        <span style={{ fontFamily: 'monotype-Corsiva' }}>
                          Vipar
                        </span>
                        &nbsp;&nbsp;CARE&nbsp;?
                      </Link> */}
                    </li>
                    {/* {just hidden needed fo future} */}
                    {/* <li className="nav-item pr-0">
                      <Link
                        to="/vipar-care/connect"
                        className={`ssviparc nav-link cardtextt  aa ${
                          location.pathname === '/vipar-care/connect'
                            ? ' active-link-vipar'
                            : ''
                        }`}
                      >
                        GET&nbsp;IN&nbsp;TOUCH
                      </Link>
                    </li> */}
                    {/* {just hidden needed fo future} */}
                  </ul>
                  <ul
                    className=" navbar-nav d-flex  justify-content-center mx-auto ms-0 asdddd"
                    style={{ border: 'none' }}
                  >
                    <li className="nav-item ">
                      <Link
                        to="/vipar-care/philosophy"
                        className={`nav-link cardtextt aa asdddd ${
                          location.pathname === '/vipar-care/philosophy'
                            ? ' active-link-vipar'
                            : ''
                        }`}
                      >
                        PHILOSOPHY
                      </Link>
                    </li>

                    <li className="nav-item ">
                      <Link
                        to="/vipar-care/humane"
                        className={`nav-link cardtextt aa asdddd ${
                          location.pathname === '/vipar-care/humane'
                            ? ' active-link-vipar'
                            : ''
                        }`}
                      >
                        HUMANE INITIATIVES
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="d-none d-md-block ">
                  <div id="nsknfkjdsnfksjn">
                    <ul
                      className="  d-flex ps-0 pt-2  justify-content-center mx-auto ms-0"
                      style={{ border: 'none' }}
                    >
                      <li className="nav-item ">
                        {/* <Link
                          to="/vipar-care"
                          className={`nav-link cardtextt cdd 
                            ${
                              location.pathname === '/vipar-care'
                                ? ' active-link-vipar'
                                : ''
                            }`}
                        >
                          What&nbsp;is&nbsp;
                          <span style={{ fontFamily: 'monotype-Corsiva' }}>
                            Vipar
                          </span>
                          &nbsp;&nbsp;CARE&nbsp;?
                        </Link> */}
                      </li>
                      {/* {just hidden needed fo future} */}
                      {/* <li className="nav-item ">
                        <Link
                          to="/vipar-care/connect"
                          className={`nav-link cardtextt cdd 
                            ${
                              location.pathname === '/vipar-care/connect'
                                ? ' active-link-vipar'
                                : ''
                            }`}
                        >
                          GET&nbsp;IN&nbsp;TOUCH
                        </Link>
                      </li> */}
                      {/* {just hidden needed fo future} */}
                      <li className="nav-item ">
                        <Link
                          to="/vipar-care/philosophy"
                          className={`nav-link cardtextt aa asdddd ${
                            location.pathname === '/vipar-care/philosophy'
                              ? ' active-link-vipar'
                              : ''
                          }`}
                        >
                          PHILOSOPHY
                        </Link>
                      </li>

                      <li className="nav-item ">
                        <Link
                          to="/vipar-care/humane"
                          className={`nav-link cardtextt aa asdddd ${
                            location.pathname === '/vipar-care/humane'
                              ? ' active-link-vipar'
                              : ''
                          }`}
                        >
                          HUMANE INITIATIVES
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div className=" px-4 py-5 bgvipar  ">
        <div className="text-white" style={{ fontWeight: '900' }}>
          <span style={{ fontSize: '20px' }}>VEDEHI</span>
        </div>

        <div className="text-white pb-2">
          A concept of Sahastitva - Swanirbhar : Co-existence and Self-
          dependency
        </div>
        <hr className=" " style={{ color: 'white', border: '2px solid' }} />

        {/* <div className="pt-3 text-white" style={{ fontWeight: '900' }}>
          <span style={{ fontSize: '20px' }}>MAANAVTA</span>
        </div> */}
        <br />
        <div className="text-white">ARANYANI</div>

        <div className="text-white">
          Animal Rescue and Shelter. Feeding Stray Dogs.
        </div>
        <br />
        <div className="text-white">BHOJAN</div>
        <div className="text-white">Food for the poor, children.</div>
        <br />
        <div className="text-white">DILAASA</div>
        <div className="text-white">
          Supporting Medical Treatment. Supporting Victims of a Natural
          Disaster.
          <span className="text-white">
            &nbsp;Support during Natural Disasters / Calamities.
          </span>
        </div>

        <br />
        <div className="text-white">KRISHI DAAN</div>
        <div className="text-white">Support Farmers.</div>
        <br />
        <div className="text-white" style={{ fontWeight: '900' }}>
          MUKTI
        </div>
        <div className="text-white">
          Fight against Child Labour and Child Marriage.
        </div>
        <br />
        <div className="text-white" style={{ fontWeight: '900' }}>
          NAYA PATTA
        </div>
        <div className="text-white">
          Limb Replacement, Hearing and Visibility, Disability Aid Equipments.
        </div>
        <br />
        <div className="text-white" style={{ fontWeight: '900' }}>
          NYAAY
        </div>
        <div className="text-white">Legal Help for the poor.</div>
        <br />
        <div className="text-white" style={{ fontWeight: '900' }}>
          PANAAH
        </div>
        <div className="text-white">
          Shelter for abandoned humans. Shelter and Rehabilitation for Rape and
          Acid-attack Survivors.
        </div>
        <br />
        <div className="text-white" style={{ fontWeight: '900' }}>
          PEHCHAAN
        </div>
        <div className="text-white">
          Education for Girls, Women Empowerment.
        </div>
        <br />
        <div className="text-white" style={{ fontWeight: '900' }}>
          PRAKRITI
        </div>
        <div className="text-white">Planting Trees.</div>
        <br />
        <div className="text-white" style={{ fontWeight: '900' }}>
          SVAABHIMAAN
        </div>
        <div className="text-white">
          Building Toilets and Bathrooms in Rural Villages.
        </div>
        <br />
        <div className="text-white" style={{ fontWeight: '900' }}>
          UMMEED
        </div>
        <div className="text-white">
          24 x 7 Accident Rescue Unit, Mobile Immediate Care service for animals
          and humans.
        </div>
        <br />
        <div className="text-white" style={{ fontWeight: '900' }}>
          VASTRA DAAN
        </div>
        <div className="text-white">Clothing for the poor.</div>
      </div>
      <div className="">
        <Footer />
      </div>
    </>
  );
};
