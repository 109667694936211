import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Tableverticals } from './Tableverticals';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { notify } from '../../App';
import { TestEditor } from '../../pages/TestEditor';
export const Jobsectionselectbox = [
  {
    category: 'Marketing',
    subcategory: ['Marketing Executive', 'Marketing Head'],
  },
  {
    category: 'Software Engineer',
    subcategory: [
      'Project Manager',
      'System Engineer',
      'Software Developer',
      'Software Tester',
    ],
  },
];
export const Jobopportunities = () => {
  const [opperins, setopperins] = useState({
    JobSection: '',
    JobSubSection: ' ',
    JobTitle: '',
    JobDescription: '',
    Qualfication: '',
    Skills: '',
    Experience: '',
    WorkJoblocation: '',
    Vacancies: '',
    verticals: '',
  });

  const submit = (e) => {
    console.log('hii');
    e.preventDefault();
    handleClose();

    axios
      .post('/adminlogin/customeroppor', { opperins })
      .then((res) => {
        console.log();
        notify('Vacancy Added Successfully', true);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        notify('Something went wrong', false);

        console.log(err);
      });
  };

  const [Submit2, setSubmit2] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="page-breadcrumb ">
        <div className="row align-items-center text-white">
          <div className="col-6">
            <h4 className="page-title text-white">Opportunites</h4>
          </div>
          <div className="col-6 d-flex justify-content-end">
            <Button variant="outline-primary" onClick={handleShow}>
              Add Vacancy
            </Button>
          </div>
        </div>
      </div>

      <Tableverticals />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <form
          onSubmit={(e) => submit(e)}
          novalidate
          className={Submit2 ? 'was-validated' : ''}
        >
          <Modal.Body>
            {' '}
            <div>
              <label htmlhtmlFor="inputsubject">Verticles *</label>
              <select
                required
                class="form-select"
                aria-label="Default select example"
                onChange={(e) => {
                  setopperins({ ...opperins, verticals: e.target.value });
                }}
              >
                <option></option>
                <option value="CONNECT">CONNECT</option>
                <option value="CASA">CASA</option>
                <option value="CHUM">CHUM</option>
                <option value="CHIC">CHIC</option>
                {/* <option value="COMFORT">COMFORT</option>
                <option value="CLEAN">CLEAN</option>
                <option value="CRUISE">CRUISE</option>
                <option value="CHEF">CHEF</option>
                <option value="CINÉ">CINÉ</option> */}
                <option value="CARE">CARE</option>
              </select>
              <div class="invalid-feedback">PLEASE SELECT Verticles</div>
            </div>
            <div className="pt-4">
              <label htmlhtmlFor="inputsubject">Job Section *</label>
              <select
                required
                class="form-select"
                aria-label="Default select example"
                onChange={(e) => {
                  setopperins({ ...opperins, JobSection: e.target.value });
                }}
              >
                <option></option>
                {Jobsectionselectbox.map((element) => {
                  return <option>{element.category}</option>;
                })}
              </select>
              <div class="invalid-feedback">PLEASE SELECT Job Section</div>
            </div>
            <div className="pt-4">
              <label htmlhtmlFor="inputsubject">Job Sub Section *</label>
              <select
                required
                class="form-select"
                aria-label="Default select example"
                onChange={(e) => {
                  setopperins({ ...opperins, JobSubSection: e.target.value });
                }}
              >
                <option></option>
                {Jobsectionselectbox.map((element) => {
                  if (element.category === opperins?.JobSection) {
                    return element.subcategory.map((elem) => {
                      return <option>{elem}</option>;
                    });
                  }
                })}
              </select>
              <div class="invalid-feedback">PLEASE SELECT Job Sub Section</div>
            </div>
            <div className="pt-4">
              <label htmlhtmlFor="inputname">Vacancies *</label>
              <input
                required
                type="text"
                className="form-control mt-1"
                id="name"
                name="name"
                onChange={(e) => {
                  setopperins({ ...opperins, Vacancies: e.target.value });
                }}
              />
              <div class="invalid-feedback">PLEASE ENTER Vacancies</div>
            </div>
            <div className="pt-4">
              <label htmlhtmlFor="inputname">Job Title *</label>
              <input
                required
                type="text"
                className="form-control mt-1"
                id="name"
                name="name"
                onChange={(e) => {
                  setopperins({ ...opperins, JobTitle: e.target.value });
                }}
              />
              <div class="invalid-feedback">PLEASE ENTER Job Title</div>
            </div>{' '}
            <div className="pt-4">
              <label className="pb-2" htmlhtmlFor="inputname">
                Job Description *
              </label>
              <TestEditor
                onChange={(e) => {
                  setopperins({ ...opperins, JobDescription: e });
                }}
              />

              <div class="invalid-feedback">PLEASE ENTER Job Description</div>
            </div>
            <div className="pt-4">
              <label htmlhtmlFor="inputname">Qualification *</label>
              <input
                required
                type="text"
                className="form-control mt-1"
                id="name"
                name="name"
                onChange={(e) => {
                  setopperins({ ...opperins, Qualfication: e.target.value });
                }}
              />
              <div class="invalid-feedback">PLEASE ENTER Qualification</div>
            </div>
            <div className="pt-4">
              <label htmlhtmlFor="inputname">Skills *</label>
              <textarea
                rows={3}
                required
                className="form-control mt-1"
                id="name"
                name="name"
                onChange={(e) => {
                  setopperins({ ...opperins, Skills: e.target.value });
                }}
              />
              <div class="invalid-feedback">PLEASE ENTER Skills</div>
            </div>
            <div className="pt-4">
              <label htmlhtmlFor="inputname">Experience *</label>
              <select
                required
                class="form-select"
                aria-label="Default select example"
                onChange={(e) => {
                  setopperins({ ...opperins, Experience: e.target.value });
                }}
              >
                <option></option>
                <option value="Fresher">Fresher</option>
                <option value="0-1 year">0-1 year</option>
                <option value="1-2 years">1-2 years</option>
                <option value="2-3 years">2-3 years</option>
                <option value="3-4 years">3-4 years</option>
                <option value="4-5 years">4-5 years</option>
                <option value="5+ years">5+ years</option>
              </select>
              <div class="invalid-feedback">PLEASE ENTER Experience</div>
            </div>
            <div className="pt-4">
              <label htmlhtmlFor="inputname">Work / Job Location *</label>
              <input
                required
                type="text"
                className="form-control mt-1"
                id="name"
                name="name"
                onChange={(e) => {
                  setopperins({ ...opperins, WorkJoblocation: e.target.value });
                }}
              />
              <div class="invalid-feedback">
                PLEASE ENTER Work / Job Location
              </div>
            </div>
            <div className="pt-4">
              <label htmlhtmlFor="inputname">Joining Date *</label>
              <input
                required
                type="date"
                className="form-control mt-1"
                id="name"
                name="name"
                onChange={(e) => {
                  setopperins({ ...opperins, JoiningDate: e.target.value });
                }}
              />
              <div class="invalid-feedback">PLEASE SELECT Joining Date</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="primary"
              onClick={(e) => {
                setSubmit2(true);
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
