import React, { useEffect } from 'react';
import ReactCompareImage from 'react-compare-image';
import { Link } from 'react-router-dom';
import { Footer } from '../../Footer/Footer';

const Accessories = () => {
  useEffect(() => {
    let el = document.getElementById('eruhuieyrt8eryt7863');
    el.addEventListener('dragstart', () => {
      console.log('first');
    });
    return () => {};
  }, []);

  return (
    <>
      <hr
        className="mt-0"
        style={{
          color: 'rgb(1, 32, 96)',
          opacity: '1',
          borderTop: '3px solid',
        }}
      />
      <div>
        <div className="container-fluid  p-0">
          <div className="container mt-1 fnt">
            <div className="row  w-100 p-0 m-0">
              <div className="col-12 col-md-6  col-lg-4 mt-5">
                <center>
                  <div>How do you like it....</div>
                  <div className="mb-3">Cluttered or Organised ?</div>
                </center>
                <Link
                  to="/vipar-chic/accessories/cappod"
                  className="linknew"
                  style={{ textDecoration: 'none' }}
                >
                  <center>
                    <div
                      className="card cardforproduct "
                      id="eruhuieyrt8eryt7863"
                      style={{ border: 'none' }}
                    >
                      <ReactCompareImage
                        className="divfornotscrolling "
                        sliderPositionPercentage={0.5}
                        leftImageCss={{
                          filter: 'grayscale(75%)',
                        }}
                        leftImage="/img/cluttered.jpg"
                        rightImage="/img/organisedaccessories.jpg"
                      />

                      <div className="card-body ">
                        <span className="text-dark ">
                          <span style={{ fontWeight: '800' }}>Cap-pod</span>
                          &nbsp;:&nbsp;A Compact Receptacle for your Essentials.
                        </span>
                      </div>
                    </div>
                  </center>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accessories;
