import React, { useEffect, useState } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
// import { Link } from 'react-router-dom';
import { GrClose } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { notify } from '../../../App';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-bootstrap/Modal';
import { cartgift } from '../../../redux/viparChic/cartSlice';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';

import ListItemText from '@mui/material/ListItemText';

import {
  removeformcart,
  cartproductincrement,
  cartproductdecrement,
} from '../../../redux/viparChic/cartSlice';
import { Editmodal } from './Editmodal';
import { RiErrorWarningLine } from 'react-icons/ri';

export const Cartaddinglist = ({ _id, index }) => {
  let dispatch = useDispatch();
  let cart = useSelector((state) => state.cart.value);
   console.log(cart[index])
  console.log(cart[index].productId.pname);
  const [urls, setUrls] = useState('/');
  useEffect(() => {
    console.log(cart);
    try {
      if (cart[index].productId.pname === 'Cap-pod') {
        setUrls('/vipar-chic/articles/cappod?modal=true');
      } else if (cart[index].productId.pname === 'D U B') {
        setUrls('/vipar-chic/articles/DUB?modal=true');
      } else if (cart[index].productId.pname === 'D U B  Classic') {
        setUrls('/vipar-chic/articles/DUBclassic?modal=true');
      } else if (
        cart[index].productId.pname === 'D U B  Classic Add-on Accessory'
      ) {
        setUrls('/vipar-chic/articles/DUBclassicAoA?modal=true');
      } else if (cart[index].productId.pname === 'DUO COMBO') {
        setUrls('/vipar-chic/articles/duo_combo?modal=true');
      } else if (cart[index].productId.pname === 'CLASSIC COMBO') {
        setUrls('/vipar-chic/articles/classic_combo?modal=true');
      }
    } catch (err) {
      console.log(cart[index]);
      console.log(err);
    }
  }, [cart[index]]);

  const [errgift, setErrgift] = useState();
  const [submit, setSubmit] = useState(false);
  const [valstatus, setValstatus] = useState(false);
  const [err, setErr] = useState();
  let giftinital = {
    status: false,
    Occassion: '',
    GiftcardMessage: '',
    Specificdeliverydate: '',
    // choclates: [],
    rName: '',
    rNumber: '',
  };
  const [gift, setGift] = useState({
    status: false,
    Occassion: '',
    GiftcardMessage: '',
    Specificdeliverydate: '',
    // choclates: [],
    rName: '',
    rNumber: '',
  });
  const [giftshow, giftsetShow] = useState(false);
  const gifthandleClose = () => giftsetShow(false);
  const gifthandleShow = () => giftsetShow(true);

  const validategift = (data) => {
    console.log(data);
    if (data.GiftcardMessage.length === 0) {
      return setErrgift('Please Enter the Gift Card Message ');
    }
    if (data.Specificdeliverydate.length === 0) {
      return setErrgift('Please Select the Date');
    }
    if (data.rName.length === 0) {
      return setErrgift("Please Enter the Recipient's Name");
    }
    if (data.rNumber.length === 0) {
      return setErrgift("Please Enter the Recipient's Number");
    }
    if (data.rNumber.length < 10 || data.rNumber.length > 10) {
      return setErrgift("Please Enter a valid Recipient's Number");
    }
  };
  useEffect(() => {
    setGift({ ...cart[index]?.gift });
  }, []);
  let choclates = [
    'FerreroRocher',
    'CadburyDairyMilk',
    'CadburyCelebrations',
    'CadburyTemptations',
    'CadburyBournville',
    'Snickers',
    'Mars',
    'Bounty',
    'Galaxy',
    'Munch',
    'Perk',
    '5star',
  ];
  return (
    <>
      <div className="row mb-4 d-flex justify-content-between align-items-center vipar-cart-item">
        {/* <div className="col-12 col-md-2 col-lg-2 col-xl-2">
          <img
            src={`/img/${cart[index]?.varientId?.varentCode}.jpg`}
            className="img-fluid rounded-3"
            alt="VIPAR"
          />
        </div> */}
        <div className="col-12 col-md-3 col-xl-2">
          <img
            src={`/img/${cart[index]?.varientId?.varentCode}.jpg`}
            className="img-fluid rounded-3"
            alt="VIPAR"
          />
        </div>
        <div className="col-12 col-md-9 col-xl-10">
          <div className="row">
            <div className="col-12">
              <h4
                className="  m-0 py-1"
                style={{ fontFamily: 'FootlightMTLight' }}
              >
                {/* {{$item->pname}} */}
                {/* VIPAR Cap-pod */}
                <span style={{ fontFamily: 'Monotype', color: 'green' }}>
                  Vipar
                </span>{' '}
                {cart[index].productId.pname}
              </h4>
            </div>
            <div className="col-6  ">
              <h6 className="text-black mb-0 py-1">
                <span>{cart[index]?.varientId?.varentName}</span>
                <br />
                <span className="text-muted">
                  {cart[index]?.varientId?.varentName === 'Pack of 3' ? (
                    cart[index]?.selected?.map((elm, ind) => {
                      if (ind + 1 == cart[index]?.selected?.length) {
                        return elm?.varentName + ')';
                      } else if (ind == 0) {
                        return '(' + elm?.varentName + ', ';
                      } else {
                        return elm?.varentName + ', ';
                      }
                    })
                  ) : (
                    <></>
                  )}
                </span>
              </h6>
            </div>
            <div className="col-6  d-flex justify-content-end">
              <span
                className="mb-0 py-1 "
                style={{ fontSize: '17px' }}
                // value={cart[index].productId.price}
              >
                ₹&nbsp;{cart[index].productId.price * cart[index].qty}&nbsp;/-
              </span>
            </div>
            <div className="col-6  d-flex py-1 pt-3">
              <button
                className="btn btn-outline-dark px-2 "
                onClick={(e) => dispatch(cartproductdecrement({ _id, cart }))}
              >
                <FaMinus />
              </button>

              <div
                disabled
                min="0"
                name="quantity"
                type="number"
                className="d-flex justify-content-center align-items-center"
                style={{ width: '50px' }}
              >
                {cart[index].qty}
              </div>
              <button
                className="btn btn-outline-dark px-2 "
                onClick={(e) => {
                  if (cart[index].varientId.stock > cart[index].qty) {
                    dispatch(cartproductincrement({ _id, cart }));
                  } else {
                    notify('not enough stock', false);
                  }
                }}
              >
                <FaPlus />
              </button>
            </div>
            <div className="col-6 pt-2  d-flex justify-content-end">
              {' '}
              <span
                id="special"
                //   onClick="removeFromCart('{{$item->varentid}}')"
                className="text-danger"
                onClick={(e) => dispatch(removeformcart({ _id, cart }))}
              >
                Remove
              </span>
            </div>
            <div className="col-12 mt-3 ">
              <Link to={urls}>PICK&nbsp;MORE</Link>
            </div>
            <div className="col-12">
              <div className="pt-3">
                {' '}
                <input
                  type="checkbox"
                  // defaultChecked={gift?.Occassion?.length > 0}
                  checked={gift.status}
                  className=""
                  onChange={(e) => {
                    if (e.target.checked) {
                      setGift({ ...gift, status: true });
                      gifthandleShow();
                    } else {
                      setGift({ ...gift, status: false });
                    }
                  }}
                />{' '}
                <span style={{ fontSize: '16px', fontWeight: '100' }}>
                  This is a Gift. Want this Gift-wrapped.
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-6 d-none col-md-3">
          <h4 className="  m-0 py-1" style={{ fontFamily: 'FootlightMTLight' }}>
            {/* {{$item->pname}} */}
            {/* VIPAR Cap-pod */}
            <span style={{ fontFamily: 'Monotype', color: 'green' }}>
              Vipar
            </span>{' '}
            {cart[index].productId.pname}
          </h4>

          <h6 className="text-black mb-0 py-1">
            <span>{cart[index]?.varientId?.varentName}</span>
            <br />/<Link to={urls}>PICK&nbsp;MORE</Link>
            <div className="pt-2">
              {' '}
              <input
                type="checkbox"
                // defaultChecked={gift?.Occassion?.length > 0}
                checked={gift.status}
                className=""
                onChange={(e) => {
                  if (e.target.checked) {
                    setGift({ ...gift, status: true });
                    gifthandleShow();
                  } else {
                    setGift({ ...gift, status: false });
                  }
                }}
              />{' '}
              <span
                className="mt-3"
                style={{ fontSize: '16px', fontWeight: '100' }}
              >
                This is a Gift. Want this Gift-wrapped.
              </span>
            </div>
            <br />
            <div>{/* <Editmodal value={cart[index]} id={_id} /> */}</div>
          </h6>
          {/* <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="button-tooltip-2">Check out this avatar</Tooltip>}
    >
      {({ ref, ...triggerHandler }) => (
        <Button
          variant="light"
          {...triggerHandler}
          className="d-inline-flex align-items-center"
        >
          
          <RiErrorWarningLine
            ref={ref}
            roundedCircle
            src="holder.js/20x20?text=J&bg=28a745&fg=FFF"
          />
          <span className="ms-1">Hover to see</span>
        </Button>
      )}
    </OverlayTrigger> */}
        </div>
      </div>
      {/* <button
        type="button"
        id="giftbtnn"
        className="d-none"
        data-bs-toggle="modal"
        data-bs-target="#giftt"
      ></button>
      {/* <!--  --> */}
      <div
        className="modal fade"
        id="giftt"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="giftLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                className="btn-close"
                id="closevcandy"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                {/* <span
                  className="modal-title "
                  id="giftLabel"
                  style={{ fontSize: '15px' }}
                >
                  👌We will Gift 🎁 Wrap this and send it on your behalf !!
                  <br />
                  As a token of gratitude for choosing one of our products to
                  gift to your loved ones, you can select any of the sweets
                  mentioned below and we shall add that to your gift absolutely
                  free of cost !
                </span> */}
              </div>
              <div className="mb-3 mt-3">
                <label
                  htmlFor="Occassion"
                  className="form-label "
                  style={{ fontSize: '15px' }}
                >
                  Occassion :
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={gift.Occassion}
                  onChange={(e) =>
                    setGift({
                      ...gift,
                      Occassion: e.target.value,
                    })
                  }
                  name="Occassion"
                  id="Occassion"
                  aria-describedby="emailHelp"
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="Message"
                  className="form-label "
                  style={{ fontSize: '15px' }}
                >
                  Gift Card Message :
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="Message"
                  id="Message"
                  aria-describedby="emailHelp"
                  value={gift.GiftcardMessage}
                  onChange={(e) =>
                    setGift({
                      ...gift,
                      GiftcardMessage: e.target.value,
                    })
                  }
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="delivery"
                  className="form-label "
                  style={{ fontSize: '15px' }}
                >
                  Specific delivery date :
                </label>
                <input
                  type="date"
                  className="form-control"
                  name="delivery"
                  id="delivery"
                  min="{{$d}}"
                  aria-describedby="emailHelp"
                  value={gift.Specificdeliverydate}
                  onChange={(e) =>
                    setGift({
                      ...gift,
                      Specificdeliverydate: e.target.value,
                    })
                  }
                />
              </div>
              <div className="mb-3  row">
                {/* <div className="container">
                  <label
                    htmlFor="delivery"
                    className="form-label "
                    style={{ fontSize: '15px' }}
                  >
                    Chocolates (applicable only on orders worth ₹ 700/- and
                    above) :
                  </label>
                  <p className="" style={{ fontSize: '15px' }}>
                    kindly note that only a maximum of Rs 100/- worth chocolates
                    (among those mentioned below) will be added to your gift.
                  </p>
                </div> */}
              </div>
              <p className="text-danger">{errgift}</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={(e) => validategift(gift)}
                className="btn btn-outline-dark "
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>{' '}
      <Modal
        show={giftshow}
        onHide={gifthandleClose}
        scrollable={true}
        size="md"
        centered
        backdrop="static"
      >
        {/* <Modal.Header></Modal.Header> */}

        <Modal.Body>
          <div>
            <div id="giftLabel" style={{ fontSize: '15px' }}>
              👌We will Gift 🎁 Wrap this and send it on your behalf !!
            </div>
          </div>
          <div className="mb-3 mt-3">
            <label htmlFor="Occassion" className="form-label ">
              Occassion :
            </label>

            <input
              type="text"
              className="form-control"
              value={gift?.Occassion}
              onChange={(e) =>
                setGift({
                  ...gift,
                  Occassion: e.target.value,
                })
              }
              name="Occassion"
              id="Occassion"
              aria-describedby="emailHelp"
            />
          </div>

          <div className="mb-3">
            <label
              htmlFor="Message"
              className="form-label "
              style={{ fontSize: '15px' }}
            >
              Gift Card Message * :
            </label>
            <input
              type="text"
              className="form-control"
              name="Message"
              id="Message"
              aria-describedby="emailHelp"
              value={gift?.GiftcardMessage}
              onChange={(e) =>
                setGift({
                  ...gift,
                  GiftcardMessage: e.target.value,
                })
              }
            />
          </div>

          <div className="mb-3">
            <label
              htmlFor="delivery"
              className="form-label "
              style={{ fontSize: '15px' }}
            >
              Specific Delivery Date * :
            </label>
            <input
              type="date"
              className="form-control"
              name="delivery"
              id="delivery"
              min="{{$d}}"
              aria-describedby="emailHelp"
              value={gift?.Specificdeliverydate}
              onChange={(e) =>
                setGift({
                  ...gift,
                  Specificdeliverydate: e.target.value,
                })
              }
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="delivery"
              className="form-label "
              style={{ fontSize: '15px' }}
            >
              Recipient's Name * :
            </label>
            <input
              type="text"
              className="form-control"
              name="rName"
              id="rName"
              aria-describedby="name"
              value={gift?.rName}
              onChange={(e) =>
                setGift({
                  ...gift,
                  rName: e.target.value,
                })
              }
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="delivery"
              className="form-label "
              style={{ fontSize: '15px' }}
            >
              Recipient's Contact Number * :
            </label>
            <input
              type="text"
              className="form-control"
              name="rNumber"
              id="rNumber"
              aria-describedby="number"
              value={gift?.rNumber}
              onChange={(e) =>
                setGift({
                  ...gift,
                  rNumber: e.target.value,
                })
              }
            />
          </div>
          <p className="text-danger">{errgift}</p>
          {/* <div className="mb-3 d-none  row">
            <div className="container">
              <label
                htmlFor="delivery"
                className="form-label "
                style={{ fontSize: '15px' }}
              >
                Chocolates (applicable only on orders worth ₹ 700/- and above) :
              </label>
              <p className="" style={{ fontSize: '15px' }}>
                kindly note that only a maximum of Rs 100/- worth chocolates
                (among those mentioned below) will be added to your gift.
              </p>
              <label>Select your Chocolate:</label>

              <FormControl className="col-12 form-control">
                <Select
                  id="demo-multiple-checkbox"
                  multiple
                  className="form-select"
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': { display: 'none' },
                    '& .MuiSvgIcon-root': { display: 'none' },
                    '& .MuiSelect-select': { padding: '0' },
                  }}
                  value={gift.choclates}
                  onChange={(e) =>
                    setGift({ ...gift, choclates: e.target.value })
                  }
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(', ')}
                  // MenuProps={MenuProps}
                >
                  {choclates.map((languages) => (
                    <MenuItem key={languages} value={languages}>
                      <Checkbox
                        checked={gift.choclates?.indexOf(languages) > -1}
                      />
                      <ListItemText primary={languages} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <p className="text-danger">{errgift}</p>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={(e) => validategift(gift)}
            className="btn btn-outline-dark "
          >
            Save
          </button>
          <button
            type="button"
            onClick={(e) => {
              gifthandleClose(e);
              setGift({ ...giftinital });
            }}
            className="btn btn-outline-dark "
          >
            Discard
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
