import React, { useRef, useState } from 'react';
import { FiMenu } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import { RiHome2Line } from 'react-icons/ri';
import axios from 'axios';
import { CgPlayButtonR } from 'react-icons/cg';
import { useDispatch, useSelector } from 'react-redux';
const today = new Date();
const year = today.getFullYear();
const month = String(today.getMonth() + 1).padStart(2, '0');
const day = String(today.getDate()).padStart(2, '0');
const currentDate = `${year}-${month}-${day}`;

export const logout = (cb) => {
  axios
    .post('/public/userlogout')
    .then((res) => {
      localStorage.clear();
      localStorage.setItem('namasthe', currentDate);
      cb('/');
      window.location.reload();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const NavbarVipar = () => {
  const [pass, setpass] = useState(true);
  const myFunction = () => {
    document.getElementById('myDropdownvipar').classList.toggle('show');
  };
  window.onclick = function (event) {
    let clist = event.target.classList.value;
    if (event.target.tagName == 'line') {
      return;
    }
    if (clist.indexOf('tommy-2') === -1) {
      var dropdowns = document.getElementsByClassName('dropdown-content');
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
          console.log('remove');
          setpass(!pass);
        }
      }
    }
  };

  let user = useSelector((state) => {
    return state.user.value;
  });
  let navigate = useNavigate();

  return (
    <>
      <nav class="navbar p-0">
        <div class="container-fluid d-flex bd-highlight px-2">
          <div className="me-auto">
            <CgPlayButtonR size={40} />
          </div>
          <div className="dropdown" onClick={myFunction}>
            <div className="px-3 py-2">
              <FiMenu
                type="button"
                // id="dropdownMenu2"
                className="rrt tommy-2"
                style={{ color: '#012060' }}
                size={30}
              />
            </div>
            <div
              id="myDropdownvipar"
              className="dropdown-content tommy-2"
              style={{
                left: '-114px',
                zIndex: '9999999999999999999999',
                overflow: 'initial',
              }}
            >
              {localStorage.getItem('Status') ? (
                <>
                  <div
                    style={{ padding: '12px 16px', cursor: 'pointer' }}
                    className="tommy-2"
                  >
                    {' '}
                    &nbsp;{user.UserName}{' '}
                  </div>
                </>
              ) : (
                <>
                  <div
                    data-bs-target="#loginModal"
                    onClick={myFunction}
                    style={{ padding: '12px 16px', cursor: 'pointer' }}
                    data-bs-toggle="modal"
                    className="tommy-2"
                  >
                    {' '}
                    &nbsp;New Registration{' '}
                  </div>
                  <hr className="m-0 mx-2" />
                  <div
                    data-bs-target="#RegisterModal"
                    style={{ padding: '12px 16px', cursor: 'pointer' }}
                    data-bs-toggle="modal"
                    className="tommy-2"
                  >
                    {' '}
                    &nbsp;Sign In / Log In{' '}
                  </div>
                  <hr className="m-0 mx-2" />

                  <Link to={'/'} className="tommy-2">
                    <span>
                      <RiHome2Line style={{ marginTop: '-4px' }} />
                    </span>
                    <span
                      style={{ color: '#99D050', fontSize: '20px' }}
                      className="monot"
                    >
                      {' '}
                      &nbsp;Vipar{' '}
                    </span>
                  </Link>
                  <hr className="m-0 mx-2" />

                  <Link
                    className="tommy-2"
                    to={'/vipar-connect'}
                    onClick={myFunction}
                  >
                    <span className="verticals">CONNECT</span>
                  </Link>
                  <hr className="m-0 mx-2" />
                  <Link
                    className="tommy-2"
                    onClick={myFunction}
                    to={'/vipar-casa'}
                  >
                    <span className="verticals">CASA</span>
                  </Link>
                  <hr className="m-0 mx-2" />

                 
                </>
              )}
              {!localStorage.getItem('Status') ? (
                <></>
              ) : (
                <>
                  <hr className="m-0 mx-2" />

                  <Link to={'/'} className="tommy-2">
                    <span>
                      <RiHome2Line style={{ marginTop: '-4px' }} />
                    </span>
                    <span
                      style={{ color: '#99D050', fontSize: '20px' }}
                      className="monot"
                    >
                      {' '}
                      &nbsp;Vipar{' '}
                    </span>
                  </Link>
                  <hr className="m-0 mx-2" />
                  {localStorage.getItem('Status') ? (
                    <Link className="tommy-2" to={'/vipar-connect'}>
                      <span className="verticals">CONNECT</span>
                    </Link>
                  ) : (
                    <Link
                      className="tommy-2"
                      to={'/vipar-connect'}
                      onClick={myFunction}
                    >
                      <span className="verticals">CONNECT</span>
                    </Link>
                  )}
                  <hr className="m-0 mx-2" />
                  {localStorage.getItem('Status') ? (
                    <Link to={'/vipar-casa'} className="tommy-2">
                      <span className="verticals">CASA</span>
                    </Link>
                  ) : (
                    <Link className="tommy-2" onClick={myFunction}>
                      <span className="verticals">CASA</span>
                    </Link>
                  )}
                  {/* <hr className="m-0 mx-2" />
                  {localStorage.getItem('Status') ? (
                    <Link className="tommy-2" to={'/vipar-comfort'}>
                      <span className="verticals">COMFORT</span>
                    </Link>
                  ) : (
                    <Link className="tommy-2" onClick={myFunction}>
                      <span className="verticals">COMFORT</span>
                    </Link>
                  )} */}
                  <hr className="m-0 mx-2" />

               
                  {/* <hr className="m-0 mx-2" />
                  {localStorage.getItem('Status') ? (
                    <Link className="tommy-2" to={'/vipar-chum'}>
                      <span className="verticals">CHUM</span>
                    </Link>
                  ) : (
                    <Link className="tommy-2" onClick={myFunction}>
                      <span className="verticals">CHUM</span>
                    </Link>
                  )} */}

                  {/* 
                  <hr className="m-0 mx-2" />
                  {localStorage.getItem('Status') ? (
                    <Link to={'/vipar-clean'} className="tommy-2">
                      <span className="verticals">CLEAN</span>
                    </Link>
                  ) : (
                    <Link className="tommy-2" onClick={myFunction}>
                      <span className="verticals">CLEAN</span>
                    </Link>
                  )} */}
                  {/* 
                  <hr className="m-0 mx-2" />
                  {localStorage.getItem('Status') ? (
                    <Link to={'/vipar-cruise'} className="tommy-2">
                      <span className="verticals">CRUISE</span>
                    </Link>
                  ) : (
                    <Link className="tommy-2" onClick={myFunction}>
                      <span className="verticals">CRUISE</span>
                    </Link>
                  )} */}

                  {/* <hr className="m-0 mx-2" />
                  {localStorage.getItem('Status') ? (
                    <Link className="tommy-2" to={'/vipar-chef'}>
                      <span className="verticals">CHEF</span>
                    </Link>
                  ) : (
                    <Link className="tommy-2" onClick={myFunction}>
                      <span className="verticals">CHEF</span>
                    </Link>
                  )} */}

                  {/* <hr className="m-0 mx-2" />
                  {localStorage.getItem('Status') ? (
                    <Link className="tommy-2" to={'/vipar-cine'}>
                      <span className="verticals">CINÉ</span>
                    </Link>
                  ) : (
                    <Link className="tommy-2" onClick={myFunction}>
                      <span className="verticals">CINÉ</span>
                    </Link>
                  )} */}

                  {/* <hr className="m-0 mx-2" />
                  {localStorage.getItem('Status') ? (
                    <Link className="tommy-2" to={'/vipar-care'}>
                      <>
                        <span className="verticals">CARE</span>
                      </>
                    </Link>
                  ) : (
                    <Link className="tommy-2" onClick={myFunction}>
                      <span className="verticals">CARE</span>
                    </Link>
                  )} */}
                </>
              )}
              <>
              <Link to={'/vipar-chic'} className="tommy-2">
                    <span className="verticals">CHIC</span>
                  </Link>
              </>
              {localStorage.getItem('Status') && (
                <>
                  <hr className="m-0 mx-2" />
                  <div className="dropstart prodrop">
                    <Link
                      className="tommy-2 "
                      data-bs-toggle="dropdown"
                      style={{ padding: '11px 12px', cursor: 'pointer' }}
                      onClick={(e) => {
                        myFunction(e);
                        setpass(!pass);
                      }}
                    >
                      <span
                        className="tommy-2 px-2 cv"
                        style={{ textTransform: 'none', fontSize: '18px' }}
                      >
                        Account&nbsp;Settings
                      </span>
                    </Link>
                    <ul
                      class="dropdown-menu p-0"
                      style={{ backgroundColor: '#f1f1f1' }}
                    >
                      <li>
                        {localStorage.getItem('Status') && (
                          <>
                            <div
                              className="tommy-2"
                              style={{ padding: '4px', cursor: 'pointer' }}
                            >
                              <div>
                                <button
                                  type="button"
                                  className="btn"
                                  style={{ border: 'none' }}
                                  data-bs-target="#ChangePasswordModal"
                                  data-bs-toggle="modal"
                                  onClick={(e) => setpass(!pass)}
                                >
                                  <span
                                    style={{
                                      textTransform: 'none',
                                      fontSize: '17px',
                                    }}
                                  >
                                    Change&nbsp;Password
                                  </span>
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </li>
                      <li>
                        {localStorage.getItem('Status') && (
                          <>
                            <hr hidden={pass} className="m-0 mx-2" />

                            <div
                              className="tommy-2"
                              style={{ padding: '4px', cursor: 'pointer' }}
                            >
                              <div className="">
                                <button
                                  type="button"
                                  className="btn"
                                  style={{ border: 'none' }}
                                  data-bs-target="#accountdeletion"
                                  data-bs-toggle="modal"
                                  onClick={(e) => setpass(!pass)}
                                >
                                  <span
                                    style={{
                                      textTransform: 'none',
                                      fontSize: '17px',
                                    }}
                                  >
                                    Account&nbsp;Deletion
                                  </span>
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </li>
                    </ul>
                  </div>
                </>
              )}

              {localStorage.getItem('Status') && (
                <>
                  <hr className="m-0 mx-2" />
                  <div
                    style={{ padding: '11px 16px', cursor: 'pointer' }}
                    className="tommy-2"
                  >
                    {' '}
                    &nbsp;
                    <button
                      type="button"
                      className="btn p-0"
                      onClick={(e) => logout(navigate)}
                    >
                      <span style={{ textTransform: 'none' }}>Log Out </span>
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
