import React, { useState } from 'react';
import './Spinner.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
function displayRazorpay(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export function Payment(props) {
  let navigate = useNavigate();

  console.log(props);
  const [agree, setAgree] = useState(true);
  const [loading, setloading] = useState(false);
  async function RazorPay() {
    setloading(true);
    const res = await displayRazorpay(
      'https://checkout.razorpay.com/v1/checkout.js'
    );
    if (!res) {
      alert('failed sdk to load');
      setloading(false);
      return;
    }

    axios
      .post('/payment/createpayment', {
        ...props,
      })
      .then((t) => {
        var data = t.data;
        console.log(data);
        var options = {
          key: process.env.KEY_ID, // Enter the Key ID generated from the Dashboard S6PuOik0Pm6t7RcAyX8Ws5i2
          amount: data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: 'INR',
          name: 'Vipar-chic',
          order_id: data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          handler: function (response) {
            success(response);
          },

          theme: {
            color: '#55374a',
          },
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', function (response) {
          setloading(false);
          alert(response.error.code);
          alert(response.error.description);
          alert(response.error.source);
          alert(response.error.step);
          alert(response.error.reason);
          alert(response.error.metadata.order_id);
          alert(response.error.metadata.payment_id);
        });
        rzp1.open();
        // e.preventDefault();
      });

    function success(res) {
      console.log('first');
      console.log(res);
      axios
        .post('/payment/successs', { res })
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data);
            navigate('/vipar-chic/order');
          }
        })
        .catch((err) => {
          setloading(false);
        });
    }
  }

  return (
    <>
      <div className=" container pt-5">
        <div className="row">
          <div className="d-flex justify-content-center">
            <input
              type="checkbox"
              id="finalcheck"
              className={`me-2`}
              value={agree}
              onChange={(e) => setAgree(!e.target.value)}
            />
            <label>
              I agree to all <a href="/about">policies of Vipar-chic</a>{' '}
            </label>
            <br />
          </div>
          <div className=" d-flex justify-content-center">
            <button
              //   onClick="placeOrder()"
              disabled={agree}
              className="btn btn-outline-dark btn-block mt-1"
              data-mdb-ripple-color="dark"
              href="##"
              style={{ textTransform: 'none', width: '200px' }}
              onClick={RazorPay}
              to="/vipar-chic/order"
            >
              To the Payment Counter
            </button>
          </div>
          {/* {loading && (
            <div className="d-flex justify-content-center align-items-center mt-3">
              <span
                className="spinner-border spinner-border-m"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
}
