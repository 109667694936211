import React, { useRef, useState } from 'react';
import { AiOutlineCamera } from 'react-icons/ai';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { RiUpload2Fill } from 'react-icons/ri';
import { Cropper } from 'react-cropper';

export const ImageUpload = ({ onChange, id,ratio = 4/3 }) => {
  console.log(id);
  const fileInputRef = useRef(null);
  const previewRef = useRef(null);

  const [show, setshow] = useState(false);
  const handleClose = () => {
    setshow(false)
  };
  const handleOpen = () => {
    setshow(true);
  };
  const [ImageToCrop, setImageToCrop] = useState();
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = (event) => {
    event.preventDefault();

    if (event.target.files[0].type.indexOf('image') != -1) {
      let file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setImageToCrop(reader.result);
        handleOpen();
      };
      reader.readAsDataURL(file);
      // onChange(event.target.files)
    } else if (event.target.files[0].type.indexOf('video') != -1) {
      onChange(event.target.files[0]);
    }
    event.target.value = null;
  };
  const [cropper, setCropper] = useState();
  const crop = () => {
    if (typeof cropper !== 'undefined') {
      // setCropData(cropper.getCroppedCanvas().toDataURL());
      cropper.getCroppedCanvas().toBlob(
        (blob) => {
          handleClose();
          setCropper();
          setImageToCrop();
          previewRef.current.src = URL.createObjectURL(blob);
          onChange(blob);
        },
        'image/jpeg',
        1
      );
    }
  };
  var url;
  url = `https://s3.ap-south-1.amazonaws.com/prochure.in.profile/AffliateProfile/${id}/avatar.png`;
  // url = `https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/AffliateProfile/${id}/avatar.png`;

  console.log(url);

  return (
    <>
      <div className="container">
        <div>
          <input
            type="file"
            
            hidden
            name="file"
            ref={fileInputRef}
            className="d-none"
            accept="image/*"
            onChange={handleImageUpload}
          ></input>
          <div>
            <img
              ref={previewRef}
              src={url}

              style={{ width: '150px', height: '150px',border:"40px" }}
              onError={(e) => {
                return (e.target.src = '/blank.png');
              }}
            ></img>
          </div>
          <div className="" style={{ paddingLeft: '1px', marginTop: '-55px' }}>
            <button
              type="button"
              style={{
                border: 'none',
                background: '#0e0e0e5c',
                width: '149px',
                height: '53px',
              }}
              onClick={handleButtonClick}
            >
              <AiOutlineCamera
                size={40}
                style={{ zIndex: '999999999999999999999' }}
              />
            </button>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} backdrop={'static'} centered>
        <Modal.Header>
          <Modal.Title>Crop Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {ImageToCrop && (
            <>
              <Cropper
                style={{ height: 400, width: '100%' }}
                zoomTo={false}
                aspectRatio={ratio}
                preview=".img-preview"
                src={ImageToCrop}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={0}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
              />
              <div className="pt-3">
                {' '}
                <button
                  type="button"
                  className="btn pro-spaces-button3 float-end"
                  onClick={crop}
                >
                  OK
                </button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
