import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { keyGen, notify } from '../../App';
import { Link } from 'react-router-dom';
import Moment from 'moment';

export const Admingrivence = () => {
  const [grev, setgrev] = useState();
  useEffect(() => {
    axios
      .post('/adminlogin/admingrivence')
      .then((res) => {
        console.log(res.data);
        setgrev(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [state, setState] = useState([]);

  const [im, setim] = useState(null);
  const [order, setorder] = useState();
  const [ind, setInd] = useState(null);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [confirmm, setconfirmm] = useState(false);

  const confirmmhandleClose = () => setconfirmm(false);
  const confirmmhandleShow = () => setconfirmm(true);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    axios
      .post('/public/productview')
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const confirm = (id, index) => {
    axios
      .post('/public/confirmgrevience', { id })
      .then((res) => {
        console.log(res.data);
        let temp = [...grev];
        temp[index].Status = 'resolved';
        setgrev([...temp]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let no = 1;

  return (
    <>
      <div className="page-breadcrumb ">
        <div
          className="row align-items-center"
          // style={{ backgroundColor: 'black' }}
        >
          <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
            <h4 className="page-title text-white">Grievance</h4>
          </div>
          <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
            <div className="d-md-flex">
              <ol className="breadcrumb ms-auto"></ol>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid overflow-scroll mt-3"
        style={{ backgroundColor: 'white' }}
      >
        <div className="row" style={{ paddingTop: '30px' }}>
          <div className="col-md-12">
            <div className="white-box">
              <table className="table ">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Order_Id / Order_Date</th>
                    <th scope="col">Message</th>
                    <th scope="col">Image</th>
                  </tr>
                </thead>

                <tbody>
                  {grev?.map((elem, index) => {
                    console.log(elem.Order_Id);
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{elem.Order_Id}</td>
                        <td>{elem.Message}</td>

                        <td>
                          <Button
                            variant="primary"
                            onClick={(e) => {
                              setim(elem);
                              handleShow();
                            }}
                          >
                            view
                          </Button>
                          <span className="ps-4">
                            <Button
                              variant="primary"
                              onClick={(e) => {
                                setorder(elem.Order_Id);
                                confirmmhandleShow();
                              }}
                            >
                              Product view
                            </Button>
                          </span>

                          <span className="ps-4">
                            {elem.Status === 'resolved' ? (
                              <></>
                            ) : (
                              <>
                                {' '}
                                <Button
                                  variant="success"
                                  onClick={(e) => confirm(elem._id, index)}
                                >
                                  Confirm
                                </Button>
                              </>
                            )}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                  {state.map((element, index) => {
                    return element?.items.map((elem, ind) => {
                      console.log(elem);
                      // if (elem.Status === 'confirmed') {
                      //   return;
                      // } else if (elem.Status === 'rejected') {
                      //   return;
                      // }
                      //   else if (elem.Status === 'delivered'){
                      //  return;
                      // }
                      //  else if (elem.Cancel_Status) {
                      //   return;
                      // } else {
                      if (elem.Status === 'new') {
                        return (
                          <tr className="" key={keyGen()}>
                            <td>{no++}</td>
                            <td>
                              {element.billingAddress.firstname +
                                ' ' +
                                element.billingAddress.lastname}
                            </td>
                            <td>{elem.name}</td>
                            <td>{element.Order_Id}</td>
                            <td>
                              {Moment(element.Order_date).format('DD-MM-YYYY')}
                            </td>

                            <td>{elem.price}</td>
                            <td>{element.Coupon}</td>

                            <td>{element.Receipt}</td>
                            <td></td>
                            {/* <td>
                            {!element.status ? (
                              <button
                                className="btn btn-success"
                                onClick={() =>
                                  actionbtn(true, element._id, index)
                                }
                              >
                                Confirm
                              </button>
                            ) : (
                              <button
                                className="btn btn-danger"
                                onClick={() =>
                                  actionbtn(false, element._id, index)
                                }
                              >
                                Reject
                              </button>
                            )}
                          </td> */}
                            <td>
                              <button
                                className="btn btn-success"
                                onClick={(e) =>
                                  confirm(
                                    element._id,
                                    index,
                                    'confirmed',
                                    elem.item,
                                    ind
                                  )
                                }
                              >
                                Confirm
                              </button>
                            </td>
                            <td>
                              <button
                                className="btn btn-danger"
                                onClick={(e) =>
                                  confirm(
                                    element._id,
                                    index,
                                    'rejected',
                                    elem.item,
                                    ind
                                  )
                                }
                              >
                                Reject
                              </button>
                            </td>
                          </tr>
                        );
                      }
                    });
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} animation={false} scrollable>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="d-flex align-items-center">
          <div className="row ">
            {im?.Images.map((ele) => {
              return (
                <div className="col-12 col-md-6">
                  <img src={ele.link} style={{ width: '100%' }}></img>
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        scrollable
        centered
        show={confirmm}
        onHide={confirmmhandleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>View details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {state.map((ele) => {
            console.log(ele);
            console.log(ele.Order_Id);
            console.log(order);
            if (ele.Order_Id === order) {
              console.log('Asdsadsascdadadasdcsdasadcsdacsdassacsdd');
              return (
                <>
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center my-1">
                      <Link
                        to={`/vipar-chic/invoice?order=${ele?.Order_Id}`}
                        className="text-success "
                        style={{ textDecoration: 'none' }}
                      >
                        INVOICE
                      </Link>
                    </div>
                  </div>

                  <>
                    <Accordion
                      expanded={expanded === 'panel2'}
                      onChange={handleChange('panel2')}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography sx={{ flexShrink: 0 }}>
                          Shipping Address
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <div className="ptag">
                            <p>
                              Name:&nbsp;
                              {ele?.shippingAddress.firstname +
                                ' ' +
                                ele?.shippingAddress.lastname}
                              &nbsp;
                            </p>
                            <p>
                              Address:&nbsp;
                              {ele?.shippingAddress.address}
                              &nbsp;
                            </p>
                            <p>
                              Country:&nbsp;
                              {ele?.shippingAddress.country}
                              &nbsp;
                            </p>
                            <p>
                              State:&nbsp;
                              {ele?.shippingAddress.state}&nbsp;
                            </p>
                            <p>
                              City:&nbsp;
                              {ele?.shippingAddress.city}&nbsp;
                            </p>
                            <p>
                              Pincode:&nbsp;
                              {ele?.shippingAddress.pincode}
                              &nbsp;
                            </p>
                            <p>
                              Email:&nbsp;
                              {ele?.shippingAddress.email}&nbsp;
                            </p>
                            <p>
                              Mobileno:&nbsp;
                              {ele?.shippingAddress.mobileno}
                            </p>
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === 'panel1'}
                      onChange={handleChange('panel1')}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography sx={{ flexShrink: 0 }}>
                          Billing Address
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <div className="ptag">
                            <p>
                              Name:&nbsp;
                              {ele?.billingAddress.firstname +
                                ' ' +
                                ele?.billingAddress.lastname}
                              &nbsp;
                            </p>
                            <p>
                              Address:&nbsp;
                              {ele?.billingAddress.address}
                              &nbsp;
                            </p>
                            <p>
                              Country:&nbsp;
                              {ele?.billingAddress.country}
                              &nbsp;
                            </p>
                            <p>
                              State:&nbsp;
                              {ele?.billingAddress.state}&nbsp;
                            </p>
                            <p>
                              City:&nbsp;
                              {ele?.billingAddress.city}&nbsp;
                            </p>
                            <p>
                              Pincode:&nbsp;
                              {ele?.billingAddress.pincode}
                              &nbsp;
                            </p>
                            <p>
                              Email:&nbsp;
                              {ele?.billingAddress.email}&nbsp;
                            </p>
                            <p>
                              Mobileno:&nbsp;
                              {ele?.billingAddress.mobileno}
                            </p>
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      expanded={expanded === 'panel4'}
                      onChange={handleChange('panel4')}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography sx={{ flexShrink: 0 }}>Cart</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <>
                            {ele?.Cart_Id?.Items.map((element, indexx) => {
                              console.log(element);

                              console.log('asddadssdasddssd');
                              return (
                                <div className="ptag" key={keyGen()}>
                                  {/* <p>
                                    Status:&nbsp;
                                    <span className="text-uppercase">
                                      {state[index]?.items[ind]?.Status}
                                    </span>
                                  </p> */}
                                  <p>
                                    Status:&nbsp;
                                    {ele?.items[indexx]?.Status === 'new' ? (
                                      <>
                                        <span className="text-danger text-uppercase">
                                          Pending
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <span className="text-uppercase">
                                          {ele?.items[indexx]?.Status}
                                        </span>
                                      </>
                                    )}
                                  </p>
                                  <p>
                                    Product Name:&nbsp;{element.productId.pname}
                                  </p>
                                  <p>Quantity:&nbsp;{element.qty}</p>
                                  <p>
                                    Varient Name:&nbsp;
                                    {element.varientId.varentName}
                                  </p>
                                  <p>
                                    Varient Code:&nbsp;
                                    {element.varientId.varentCode}
                                  </p>
                                  {element?.gift?.status == true && (
                                    <div className="border p-2 mt-2">
                                      <h4 className="text-success ">Gift</h4>
                                      {element?.gift?.Occassion && (
                                        <div>
                                          Occassion:&nbsp;
                                          {element?.gift?.Occassion}
                                        </div>
                                      )}
                                      <div>
                                        Gift Message: &nbsp;
                                        {element?.gift?.GiftcardMessage}
                                      </div>
                                      <div>
                                        Date:&nbsp;
                                        {element?.gift?.Specificdeliverydate}
                                      </div>
                                      <div>
                                        Recipient's Name:&nbsp;
                                        {element?.gift?.rName}
                                      </div>
                                      <div>
                                        Recipient's Contact Number: &nbsp;
                                        {element?.gift?.rNumber}
                                      </div>
                                      {/* <span>Chocolates:</span>
                                    {element?.gift?.choclates?.map((elem) => {
                                      return <span className="px-2">{elem}</span>;
                                    })}{' '} */}
                                    </div>
                                  )}

                                  <hr />
                                </div>
                              );
                            })}
                          </>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </>
                </>
              );
            }
          })}
        </Modal.Body>
      </Modal>
    </>
  );
};
