import React, { useEffect, useState } from 'react';
import { Navviparhome } from './Navviparhome';
import { Footer } from '../Footer/Footer';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { useRef } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import moment from 'moment';
import { Verticalsvaccanies } from './Verticalsvaccanies';
import { useParams, useSearchParams } from 'react-router-dom';

export const Oneworkwage = () => {
  const [expanded, setExpanded] = React.useState(false);
  const [expanded2, setExpanded2] = React.useState(false);
  const [expanded3, setExpanded3] = React.useState(false);
  const [expanded4, setExpanded4] = React.useState(false);
  const [expanded5, setExpanded5] = React.useState(false);

  const [params, setParams] = useSearchParams();
  let link = params.get('remuneration');
  useEffect(() => {
    if (link?.length) {
      linkopen.current?.click();
    }
  });

  const handleChangeaccordion = () => {
    setExpanded(!expanded);
    // setExpanded2(false);
    // setExpanded3(false);
    // setExpanded4(false);
    // setExpanded5(false);
  };

  const handleChangeaccordion2 = () => {
    setExpanded2(!expanded2);
    // setExpanded(false);
    // setExpanded3(false);
    // setExpanded4(false);
    // setExpanded5(false);
  };

  const handleChangeaccordion3 = () => {
    setExpanded3(!expanded3);
    // setExpanded(false);
    // setExpanded2(false);
    // setExpanded4(false);
    // setExpanded5(false);
  };

  const handleChangeaccordion4 = () => {
    setExpanded4(!expanded4);
    // setExpanded(false);
    // setExpanded2(false);
    // setExpanded3(false);
    // setExpanded5(false);
  };

  const handleChangeaccordion5 = () => {
    // setExpanded(false);
    // setExpanded2(false);
    // setExpanded3(false);
    // setExpanded4(false);
    setExpanded5(!expanded4);
  };

  const [Submit2, setSubmit2] = useState(false);

  const [check, setcheck] = useState(true);
  const linkopen = useRef();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [shows, setShows] = useState(false);

  const handleCloses = () => setShows(false);
  const handleShows = () => setShows(true);
  const [drop, setdrop] = useState(true);

  const [plus, setplus] = useState({
    col1: false,
    col2: false,
    col3: false,
    col4: false,
    col5: false,
    col6: false,
    col7: false,
    col15: false,
    col17: false,
    col14: false,
  });

  useEffect(() => {
    const targetDiv = document.getElementById('bd');
    const targetDiv2 = document.getElementById('bdr');

    document.addEventListener('click', function (event) {
      console.log(event.target.classList);
      if (window.innerWidth <= 768) {
        if (!targetDiv.contains(event.target)) {
          setdrop(true);
          return console.log('asdsadadscccccccccccccccccc');
        }
      } else {
        if (!targetDiv2.contains(event.target)) {
          setdrop(true);
          return console.log('asdsadadscccccccccccccccccc');
        }
      }
    });
  }, []);

  const [opper, setopper] = useState([]);

  useEffect(() => {
    console.log(opper);
  }, [opper]);
  useEffect(() => {
    axios
      .post('/vipar/reg')
      .then((res) => {
        console.log(res.data);

        setopper(res.data);
      })
      .catch((err) => {});
  }, []);

  const [verticals, setVerticals] = useState([]);

  useEffect(() => {
    axios
      .post('/vipar/vertview')
      .then((res) => {
        console.log(res.data);

        setVerticals(res.data);
      })
      .catch((err) => {});
  }, []);

  const [img, setimg] = useState([]);

  const submit = (e, elem) => {
    console.log('ASdsasd');
    e.preventDefault();
    handleClose();

    var formData = new FormData();
    console.log(img);
    {
      img?.map((elem) => {
        formData.append('file', elem);
      });
    }

    var dataSend = JSON.stringify({ elem });
    formData.append('data', dataSend);

    axios
      .post('/vipar/regupdate', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        console.log();

        setimg([]);
        setSubmit2(false);
      })
      .catch((err) => {
        setSubmit2(false);
        console.log(err);
      });
  };
  const [jobview, setJobview] = useState();

  return (
    <>
      <Navviparhome />
      <div id="foooo" style={{ backgroundColor: '#e9eef5' }}>
        <section className="bgvipar text-white py-3">
          <div className="container w-100">
            <div className="  d-flex justify-content-between align-items-center py-2 py-md-3 px-2">
              <p
                className="mono"
                style={{
                  fontSize: '15px',
                  paddingTop: '15px',
                  paddingBottom: '10px',
                }}
              >
                <span className="">
                  ‘ ONE WORK. ONE WAGE. ’ is the Employment Ideology of our
                  Organization.
                </span>

                {/* <span className="invisible">Ar. Vinod Venugopal</span> */}
              </p>
            </div>
          </div>
        </section>

        <div
        //  id="coll"
        >
          <section
            className="pb-5 d-md-none d-block"
            style={{ backgroundColor: '#e9eef5' }}
          >
            <div id="bdr">
              <div className="container">
                <div
                  className="card ps-1 pt-2"
                  style={{ backgroundColor: '#e9eef5', border: 'none' }}
                >
                  <div className="card-body pb-0">
                    <div className="row mt-3">
                      <div className="col p-0">
                        <span
                          style={{ fontSize: '15px' }}
                          className=" text-dark"
                        >
                          <div className="nmb">
                            CONCEPT of ‘ ONE WORK. ONE WAGE.&nbsp;’
                          </div>
                          {/* <div className=" bnmm pt-2">“Equal Collar” Jobs</div>{' '} */}
                        </span>
                      </div>
                      <div className="col-2 ">
                        <h5 className="text-center">
                          {!plus.col1 ? (
                            <button
                              style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                              }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse11"
                              aria-expanded="false"
                              aria-controls="collapse11"
                              onClick={(e) =>
                                setplus({
                                  ...plus,
                                  col1: true,
                                })
                              }
                            >
                              <AiOutlinePlusCircle
                                name="add-circle-outline"
                                size="25px"
                                className="black"
                              ></AiOutlinePlusCircle>
                            </button>
                          ) : (
                            <button
                              style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                              }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse11"
                              aria-expanded="false"
                              aria-controls="collapse11"
                              onClick={(e) =>
                                setplus({
                                  ...plus,
                                  col1: false,
                                })
                              }
                            >
                              <AiOutlineMinusCircle
                                name="add-circle-outline"
                                size="25px"
                                className="black"
                              ></AiOutlineMinusCircle>
                            </button>
                          )}
                        </h5>
                      </div>
                    </div>
                    <div
                      className="collapse"
                      data-bs-parent="#coll"
                      id="collapse11"
                    >
                      <div
                        className="card  card-body"
                        style={{ backgroundColor: '#e9eef5', border: 'none' }}
                      >
                        <div className="row">
                          <div className="col ps-0">
                            <span
                              className=" text-dark"
                              style={{ fontSize: '14px' }}
                            >
                              <div className="bnm ">
                                At
                                <span
                                  className="vipar"
                                  style={{
                                    fontSize: '22px',
                                    color: '#99d050',
                                  }}
                                >
                                  {' '}
                                  Vipar
                                </span>
                                , the term “ONE WORK” has only one meaning and
                                that is HARDWORK !
                              </div>

                              <div className="bnm pt-3">
                                Our Policy is to provide, what we call, “Equal
                                Collar” jobs.
                              </div>
                              <div className="bnm pt-3">
                                Remuneration must be based on how much effort
                                and sincerity one puts into work irrespective of
                                gender, age, educational qualification, and
                                profession.
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="px-3 mb-0"></hr>
              </div>
            </div>

            <div id="bdr">
              <div className="container">
                <div
                  className="card ps-1"
                  style={{ backgroundColor: '#e9eef5', border: 'none' }}
                >
                  <div className="card-body py-0">
                    <div className="row mt-3">
                      <div className="col p-0">
                        <span
                          style={{ fontSize: '15px' }}
                          className=" text-dark"
                        >
                          <div className="nmb">EMPLOYMENT BIFURCATION</div>
                          {/* <div className="bnmm pt-2">
                          Only on the basis of Working Hours
                        </div>{' '} */}
                        </span>
                      </div>
                      <div className="col-2 ">
                        <h5 className="text-center">
                          {!plus.col14 ? (
                            <button
                              style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                              }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse14"
                              aria-expanded="false"
                              aria-controls="collapse14"
                              onClick={(e) =>
                                setplus({
                                  ...plus,

                                  col14: true,
                                })
                              }
                            >
                              <AiOutlinePlusCircle
                                name="add-circle-outline"
                                size="25px"
                                className="black"
                              ></AiOutlinePlusCircle>
                            </button>
                          ) : (
                            <button
                              style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                              }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse14"
                              aria-expanded="false"
                              aria-controls="collapse14"
                              onClick={(e) =>
                                setplus({
                                  ...plus,

                                  col14: false,
                                })
                              }
                            >
                              <AiOutlineMinusCircle
                                name="add-circle-outline"
                                size="25px"
                                className="black"
                              ></AiOutlineMinusCircle>
                            </button>
                          )}
                        </h5>
                      </div>
                    </div>
                    <div
                      className="collapse"
                      data-bs-parent="#coll"
                      id="collapse121"
                    >
                      <div
                        className="card  card-body"
                        style={{
                          backgroundColor: '#e9eef5',
                          border: 'none',
                          paddingLeft: '14px',
                        }}
                      >
                        <div className="row">
                          <div className="col ps-0">
                            {/* <div
                              className="bnm text-dark"
                              style={{ fontSize: '17px' }}
                            >
                              <div className="">
                                <span className="bld">
                                  For Inexperienced Freshers :
                                </span>
                                <br />
                              </div>
                              <div className="pt-3">
                                <ul>
                                  <li>
                                    The first week will be an ‘Unpaid Training
                                    Period’ by the end of which{' '}
                                    <span
                                      className="vipar"
                                      style={{
                                        fontSize: '22px',
                                        color: '#99d050',
                                      }}
                                    >
                                      Vipar&nbsp;
                                    </span>{' '}
                                    has the discretion to decide whether or not
                                    to employ the candidate.
                                  </li>
                                  <li className="">
                                    Post the first week, the employee will be on
                                    a probation period of one year.
                                  </li>

                                  <li className="">
                                    The first month’s salary will be paid only
                                    on completion of one year along with the 12
                                    <sup>th</sup> month’s salary.
                                  </li>
                                  <li className="">
                                    Must mandatorily give a Notice period of a
                                    minimum of 3 months to leave the
                                    Organization. If this is not followed, then
                                    neither will the first month’s salary be
                                    paid nor will a work experience certificate
                                    be issued.
                                  </li>
                                  <li className="">
                                    <span
                                      className="vipar"
                                      style={{
                                        fontSize: '22px',
                                        color: '#99d050',
                                      }}
                                    >
                                      Vipar&nbsp;&nbsp;
                                    </span>
                                    will give a minimum of 2 months of Notice
                                    period to the employee to relieve him / her
                                    from the Organization.
                                  </li>
                                  <li>
                                    Salaries for the current month will be
                                    credited on the 15
                                    <sup>th</sup> day of the following month
                                    even if it is a bank / public holiday.
                                  </li>

                                  <li className="">
                                    Salaries for the months during the Notice
                                    period will be settled in full only on the
                                    Last Working day at the Organization.
                                  </li>

                                  <li>
                                    Paid Leave is applicable Only in cases of{' '}
                                    <u>Critical Medical Emergency</u> *
                                    <sup>1</sup> to Self,{' '}
                                    <u>Critical Medical Emergency</u> *
                                    <sup>1</sup> <u>to Immediate Family</u> *
                                    <sup>2</sup>, Death in{' '}
                                    <u>Immediate Family</u> *<sup>2</sup> ,{' '}
                                    <u>Menstrual Leave</u> *<sup>3</sup> (for
                                    women)
                                  </li>

                                  <li>
                                    Work-from-Home is a not concept we believe
                                    in except in case of a pandemic or
                                    nation-wide / state / city shutdown.
                                  </li>
                                  <li>
                                    FREE MEALS will be provided to All the
                                    Employees who opt for it on All Working Days
                                    throughout the year.
                                  </li>
                                  <li>
                                    There will be no organized or sponsored
                                    informal / casual trips for employees from
                                    our end.
                                  </li>
                                </ul>
                                <br />
                              </div>

                              <div className="pt-4">
                                <span className="bld">
                                  For Experienced Employees :
                                </span>
                                <br />
                              </div>
                              <br />

                              <div className="">
                                <span className="">
                                  <ul>
                                    <li>
                                      Must mandatorily give a Notice period of a
                                      minimum of 3 months to leave the
                                      Organization. If this is not followed,
                                      then neither will the immediate previous
                                      first month’s salary be paid nor will a
                                      work experience certificate be issued.
                                    </li>
                                    <li>
                                      <span
                                        className="vipar"
                                        style={{
                                          fontSize: '22px',
                                          color: '#99d050',
                                        }}
                                      >
                                        Vipar&nbsp;&nbsp;
                                      </span>
                                      will give a minimum of 2 months of Notice
                                      period to the employee to relieve him /
                                      her from the Organization.
                                    </li>
                                    <li>
                                      Salaries for the current month will be
                                      credited on the 15
                                      <sup>th</sup> day of the following month
                                      even if it is a bank / public holiday.
                                    </li>

                                    <li>
                                      Paid Leave is applicable Only in cases of{' '}
                                      <u>Critical Medical Emergency</u> *
                                      <sup>1</sup> to Self,{' '}
                                      <u>Critical Medical Emergency</u> *
                                      <sup>1</sup> <u>to Immediate Family</u> *
                                      <sup>2</sup>, Death in{' '}
                                      <u>Immediate Family</u> *<sup>2</sup> ,{' '}
                                      <u>Menstrual Leave</u> *<sup>3</sup> (for
                                      women), <u>Maternity Leave</u> *
                                      <sup>4</sup> (for women),{' '}
                                      <u>Paternity Leave</u> *<sup>5</sup> (for
                                      men).
                                    </li>

                                    <li>
                                      Work-from-Home is a not concept we believe
                                      in except in case of a pandemic
                                      nation-wide / or state / city shutdown.
                                    </li>
                                    <li>
                                      FREE MEALS will be provided to All the
                                      Employees who opt for it on All Working
                                      Days throughout the year.
                                    </li>
                                    <li>
                                      There will be no organized or sponsored
                                      informal / casual trips for employees from
                                      our end.
                                    </li>
                                  </ul>
                                  <br />
                                  <div className="pt-2">
                                    Please Refer LEAVE POLICIES to understand
                                    what it ( * ) means ?
                                  </div>
                                </span>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="px-3 mb-0"></hr>
              </div>
            </div>

            <div id="bdr">
              <div className="container">
                <div
                  className="card ps-1"
                  style={{ backgroundColor: '#e9eef5', border: 'none' }}
                >
                  <div className="card-body py-0">
                    <div className="row mt-3">
                      <div className="col p-0">
                        <span
                          style={{ fontSize: '15px' }}
                          className=" text-dark"
                        >
                          <div className="nmb">EMPLOYMENT POLICIES</div>
                          {/* <div className="bnmm pt-2">
                          Only on the basis of Working Hours
                        </div>{' '} */}
                        </span>
                      </div>
                      <div className="col-2 ">
                        <h5 className="text-center">
                          {!plus.col4 ? (
                            <button
                              style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                              }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse121"
                              aria-expanded="false"
                              aria-controls="collapse121"
                              onClick={(e) =>
                                setplus({
                                  ...plus,

                                  col4: true,
                                })
                              }
                            >
                              <AiOutlinePlusCircle
                                name="add-circle-outline"
                                size="25px"
                                className="black"
                              ></AiOutlinePlusCircle>
                            </button>
                          ) : (
                            <button
                              style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                              }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse121"
                              aria-expanded="false"
                              aria-controls="collapse121"
                              onClick={(e) =>
                                setplus({
                                  ...plus,

                                  col4: false,
                                })
                              }
                            >
                              <AiOutlineMinusCircle
                                name="add-circle-outline"
                                size="25px"
                                className="black"
                              ></AiOutlineMinusCircle>
                            </button>
                          )}
                        </h5>
                      </div>
                    </div>
                    <div
                      className="collapse"
                      data-bs-parent="#coll"
                      id="collapse121"
                    >
                      <div
                        className="card  card-body"
                        style={{
                          backgroundColor: '#e9eef5',
                          border: 'none',
                          paddingLeft: '14px',
                        }}
                      >
                        <div className="row">
                          <div className="col ps-0">
                            <div
                              className="bnm text-dark"
                              style={{ fontSize: '17px' }}
                            >
                              <div className="">
                                <span className="bld">
                                  For Inexperienced Freshers :
                                </span>
                                <br />
                              </div>
                              <div className="pt-3">
                                <ul>
                                  <li>
                                    The first week will be an ‘Unpaid Training
                                    Period’ by the end of which{' '}
                                    <span
                                      className="vipar"
                                      style={{
                                        fontSize: '22px',
                                        color: '#99d050',
                                      }}
                                    >
                                      Vipar&nbsp;
                                    </span>{' '}
                                    has the discretion to decide whether or not
                                    to employ the candidate.
                                  </li>
                                  <li className="">
                                    Post the first week, the employee will be on
                                    a probation period of one year.
                                  </li>

                                  <li className="">
                                    The first month’s salary will be paid only
                                    on completion of one year along with the 12
                                    <sup>th</sup> month’s salary.
                                  </li>
                                  <li className="">
                                    Must mandatorily give a Notice period of a
                                    minimum of 3 months to leave the
                                    Organization. If this is not followed, then
                                    neither will the first month’s salary be
                                    paid nor will a work experience certificate
                                    be issued.
                                  </li>
                                  <li className="">
                                    <span
                                      className="vipar"
                                      style={{
                                        fontSize: '22px',
                                        color: '#99d050',
                                      }}
                                    >
                                      Vipar&nbsp;&nbsp;
                                    </span>
                                    will give a minimum of 2 months of Notice
                                    period to the employee to relieve him / her
                                    from the Organization.
                                  </li>
                                  <li>
                                    Salaries for the current month will be
                                    credited on the 15
                                    <sup>th</sup> day of the following month
                                    even if it is a bank / public holiday.
                                  </li>

                                  <li className="">
                                    Salaries for the months during the Notice
                                    period will be settled in full only on the
                                    Last Working day at the Organization.
                                  </li>

                                  <li>
                                    Paid Leave is applicable Only in cases of{' '}
                                    <u>Critical Medical Emergency</u> *
                                    <sup>1</sup> to Self,{' '}
                                    <u>Critical Medical Emergency</u> *
                                    <sup>1</sup> <u>to Immediate Family</u> *
                                    <sup>2</sup>, Death in{' '}
                                    <u>Immediate Family</u> *<sup>2</sup> ,{' '}
                                    <u>Menstrual Leave</u> *<sup>3</sup> (for
                                    women)
                                  </li>

                                  <li>
                                    Work-from-Home is a not concept we believe
                                    in except in case of a pandemic or
                                    nation-wide / state / city shutdown.
                                  </li>
                                  <li>
                                    FREE MEALS will be provided to All the
                                    Employees who opt for it on All Working Days
                                    throughout the year.
                                  </li>
                                  <li>
                                    There will be no organized or sponsored
                                    informal / casual trips for employees from
                                    our end.
                                  </li>
                                </ul>
                                <br />
                              </div>

                              <div className="pt-4">
                                <span className="bld">
                                  For Experienced Employees :
                                </span>
                                <br />
                              </div>
                              <br />

                              <div className="">
                                <span className="">
                                  <ul>
                                    <li>
                                      Must mandatorily give a Notice period of a
                                      minimum of 3 months to leave the
                                      Organization. If this is not followed,
                                      then neither will the immediate previous
                                      first month’s salary be paid nor will a
                                      work experience certificate be issued.
                                    </li>
                                    <li>
                                      <span
                                        className="vipar"
                                        style={{
                                          fontSize: '22px',
                                          color: '#99d050',
                                        }}
                                      >
                                        Vipar&nbsp;&nbsp;
                                      </span>
                                      will give a minimum of 2 months of Notice
                                      period to the employee to relieve him /
                                      her from the Organization.
                                    </li>
                                    <li>
                                      Salaries for the current month will be
                                      credited on the 15
                                      <sup>th</sup> day of the following month
                                      even if it is a bank / public holiday.
                                    </li>

                                    <li>
                                      Paid Leave is applicable Only in cases of{' '}
                                      <u>Critical Medical Emergency</u> *
                                      <sup>1</sup> to Self,{' '}
                                      <u>Critical Medical Emergency</u> *
                                      <sup>1</sup> <u>to Immediate Family</u> *
                                      <sup>2</sup>, Death in{' '}
                                      <u>Immediate Family</u> *<sup>2</sup> ,{' '}
                                      <u>Menstrual Leave</u> *<sup>3</sup> (for
                                      women), <u>Maternity Leave</u> *
                                      <sup>4</sup> (for women),{' '}
                                      <u>Paternity Leave</u> *<sup>5</sup> (for
                                      men).
                                    </li>

                                    <li>
                                      Work-from-Home is a not concept we believe
                                      in except in case of a pandemic
                                      nation-wide / or state / city shutdown.
                                    </li>
                                    <li>
                                      FREE MEALS will be provided to All the
                                      Employees who opt for it on All Working
                                      Days throughout the year.
                                    </li>
                                    <li>
                                      There will be no organized or sponsored
                                      informal / casual trips for employees from
                                      our end.
                                    </li>
                                  </ul>
                                  <br />
                                  <div className="pt-2">
                                    Please Refer LEAVE POLICIES to understand
                                    what it ( * ) means ?
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="px-3 mb-0"></hr>
              </div>
            </div>

            <div id="bdr">
              <div className="container">
                <div
                  className="card ps-1"
                  style={{ backgroundColor: '#e9eef5', border: 'none' }}
                >
                  <div className="card-body py-0">
                    <div className="row mt-3">
                      <div className="col p-0">
                        <span
                          style={{ fontSize: '15px' }}
                          className=" text-dark"
                        >
                          <div className="nmb">LEAVE POLICIES</div>
                        </span>
                      </div>
                      <div className="col-2 ">
                        <h5 className="text-center">
                          {!plus.col17 ? (
                            <button
                              style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                              }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse117"
                              aria-expanded="false"
                              aria-controls="collapse117"
                              onClick={(e) =>
                                setplus({
                                  ...plus,

                                  col17: true,
                                })
                              }
                            >
                              <AiOutlinePlusCircle
                                name="add-circle-outline"
                                size="25px"
                                className="black"
                              ></AiOutlinePlusCircle>
                            </button>
                          ) : (
                            <button
                              style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                              }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse117"
                              aria-expanded="false"
                              aria-controls="collapse117"
                              onClick={(e) =>
                                setplus({
                                  ...plus,

                                  col17: false,
                                })
                              }
                            >
                              <AiOutlineMinusCircle
                                name="add-circle-outline"
                                size="25px"
                                className="black"
                              ></AiOutlineMinusCircle>
                            </button>
                          )}
                        </h5>
                      </div>
                    </div>
                    <div
                      className="collapse"
                      data-bs-parent="#coll"
                      id="collapse117"
                    >
                      <div
                        className="card  card-body"
                        style={{ backgroundColor: '#e9eef5', border: 'none' }}
                      >
                        <div className="row">
                          <div className="col ps-0">
                            <div className="pb-3">
                              <ul>
                                <li>
                                  There will be NO Paid Leave or Holidays for
                                  Regional and National Festivals like Ayudha
                                  Pooja, Christmas, Diwali, Dussehra, Eid, Holi,
                                  Onam, Pongal, etc.
                                </li>
                                <li>
                                  All Regional and National Festivals are
                                  Restricted Holidays.
                                </li>
                                <li>
                                  Every Paid Leave Application (except in cases
                                  of monthly Menstrual Leaves) will be
                                  entertained and sanctioned Only with Valid
                                  Substantiating Evidence.
                                </li>
                                <li>
                                  Leaves in a month cannot be carried forward to
                                  another month.
                                </li>
                              </ul>
                            </div>
                            <div className="">
                              <span className="">What it ( * ) means ?</span>
                              <br />
                              <br />
                              <span className="">
                                *<sup>1</sup> Critical Medical Emergency :
                              </span>

                              <ul className="pt-2">
                                <li>
                                  Hospitalization / Treatment for a
                                  Life-threatening Illness
                                </li>
                                <li>Critically required Surgeries</li>
                                <li>
                                  Accident related conditions / treatment /
                                  hospitalization
                                </li>
                              </ul>

                              <span className="">
                                *<sup>2</sup> Immediate Family :
                              </span>

                              <ul className="pt-2">
                                <li>Parent</li>
                                <li>Spouse</li>
                                <li>Child</li>
                                <li>Blood Sibling</li>
                                <li>Spouse's Parent</li>
                              </ul>
                              <div className="pt-2">
                                *<sup>3</sup> Menstrual Leave : A maximum of 3
                                days every month for women between the age group
                                of 18 to 50.
                              </div>

                              <div className="pt-4">
                                *<sup>4</sup> Maternity Leave : Paid Maternity
                                Leave will be granted for a period of one year
                                (1 month before delivery and 11 months post
                                delivery) only on the following terms :
                                <ul>
                                  <li>
                                    Paid Maternity Leave will only be sanctioned
                                    after completing a minimum of one year of
                                    employment with{' '}
                                    <span
                                      className="vipar"
                                      style={{
                                        fontSize: '22px',
                                        color: '#99d050',
                                      }}
                                    >
                                      Vipar
                                    </span>
                                    .
                                  </li>
                                  <li>
                                    The Employee must work for a minimum period
                                    of one year after resuming work post
                                    delivery.
                                  </li>
                                  <li>
                                    The Salary for the Maternity Leave period
                                    will only be 50% of the respective
                                    Employee’s average salary of the immediate
                                    previous year.
                                  </li>
                                  <li>
                                    The Salary for the entire Maternity Leave
                                    period will be paid only after completing
                                    one year of work post delivery.
                                  </li>
                                </ul>
                              </div>

                              <div className="pt-4">
                                *<sup>5</sup> Paternity Leave : A maximum of 1
                                month.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="px-3 mb-0"></hr>
              </div>
            </div>

            <div id="bdr">
              <div className="container">
                <div
                  className="card ps-1"
                  style={{ backgroundColor: '#e9eef5', border: 'none' }}
                >
                  <div className="card-body py-0">
                    <div className="row mt-3">
                      <div className="col p-0">
                        <span
                          style={{ fontSize: '15px' }}
                          className=" text-dark"
                        >
                          <div className="nmb">WORKING HOURS and DAYS</div>
                          {/* <div className=" bnmm pt-2">
                          Kindly Read and Understand the Concept, Remuneration
                          and Employment Policies clearly before you apply for a
                          job with us.
                        </div>{' '} */}
                        </span>
                      </div>
                      <div className="col-2 ">
                        <h5 className="text-center">
                          {!plus.col5 ? (
                            <button
                              style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                              }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse15"
                              aria-expanded="false"
                              aria-controls="collapse15"
                              onClick={(e) =>
                                setplus({
                                  ...plus,
                                  col5: true,
                                })
                              }
                            >
                              <AiOutlinePlusCircle
                                name="add-circle-outline"
                                size="25px"
                                className="black"
                              ></AiOutlinePlusCircle>
                            </button>
                          ) : (
                            <button
                              style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                              }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse15"
                              aria-expanded="false"
                              aria-controls="collapse15"
                              onClick={(e) =>
                                setplus({
                                  ...plus,

                                  col5: false,
                                })
                              }
                            >
                              <AiOutlineMinusCircle
                                name="add-circle-outline"
                                size="25px"
                                className="black"
                              ></AiOutlineMinusCircle>
                            </button>
                          )}
                        </h5>
                      </div>
                    </div>
                    <div
                      className="collapse"
                      data-bs-parent="#coll"
                      id="collapse15"
                    >
                      <div
                        className="card  card-body"
                        style={{ backgroundColor: '#e9eef5', border: 'none' }}
                      >
                        <div className="row">
                          <div className="col ps-0">
                            <div
                              className=" text-dark"
                              style={{ fontSize: '18px' }}
                            >
                              <ul>
                                <li>
                                  There is NO FIXED Period of Working Hours in a
                                  day. Choose your own convenient working hours
                                  every day between 0600 (6 am) and 2200 (10
                                  pm). This flexibility is year to suit both the
                                  Early Birds and the Nocturnals.
                                </li>
                                <li>
                                  There are NO FIXED Working Days in a week. You
                                  can even opt to work on a Sunday instead of a
                                  weekday.
                                </li>
                                <li>
                                  We will be closed only on 4 days in a calendar
                                  year ( January 26<sup>th</sup> : Republic Day,
                                  May 1<sup>st</sup> : Labour Day, August 15
                                  <sup>th</sup> : Independence Day, October 2
                                  <sup>nd</sup> : Gandhi Jayanti)
                                </li>
                                <li>
                                  Minimum Working Hours per Day : 6<br />
                                  Maximum Working Hours per Day : 8
                                  <br />
                                  Minimum Working Days per Month : 20
                                  <br />
                                  Maximum Working Days per Month : 25
                                  <br />
                                </li>
                                <li>
                                  Working Hours per Day for All will be
                                  EXCLUDING Loo Breaks, and Breaks for Meals,
                                  Refreshments, and Smoking.
                                </li>
                                <li>
                                  Employees of certain Wings (departments) which
                                  require works / projects to be done in tandem
                                  will have to opt for mutually preferred common
                                  time slots.
                                </li>
                                <li>
                                  Experienced Employees (only) have the option
                                  (on approval and other T&amp;C) to work for 20
                                  consecutive days (including Saturdays and
                                  Sundays) in a month so as to take 10 days off
                                  at a stretch.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="px-3 mb-0"></hr>
                </div>
              </div>
            </div>

            <div id="bdr">
              <div className="container">
                <div
                  className="card ps-1"
                  style={{ backgroundColor: '#e9eef5', border: 'none' }}
                >
                  <div className="card-body py-0">
                    <div className="row mt-3">
                      <div className="col p-0">
                        <span
                          style={{ fontSize: '15px' }}
                          className=" text-dark"
                        >
                          <div className="nmb">REMUNERATION</div>
                          {/* <div className="bnmm pt-2">
                          Only on the basis of Working Hours
                        </div>{' '} */}
                        </span>
                      </div>
                      <div className="col-2 ">
                        <h5 className="text-center">
                          {!plus.col2 ? (
                            <a
                              href="#REMUNERATION"
                              id="list-example1111"
                              style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                              }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse12"
                              aria-expanded="false"
                              aria-controls="collapse12"
                              ref={linkopen}
                              onClick={(e) =>
                                setplus({
                                  ...plus,

                                  col2: true,
                                })
                              }
                            >
                              <AiOutlinePlusCircle
                                name="add-circle-outline"
                                size="25px"
                                className="black"
                              ></AiOutlinePlusCircle>
                            </a>
                          ) : (
                            <a
                              href="#REMUNERATION"
                              style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                              }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse12"
                              aria-expanded="false"
                              aria-controls="collapse12"
                              onClick={(e) =>
                                setplus({
                                  ...plus,

                                  col2: false,
                                })
                              }
                            >
                              <AiOutlineMinusCircle
                                name="add-circle-outline"
                                size="25px"
                                className="black"
                              ></AiOutlineMinusCircle>
                            </a>
                          )}
                        </h5>
                      </div>
                    </div>
                    <div
                      className="collapse"
                      data-bs-parent="#coll"
                      id="collapse12"
                    >
                      <div
                        className="card  card-body"
                        style={{
                          backgroundColor: '#e9eef5',
                          border: 'none',
                          paddingLeft: '14px',
                        }}
                      >
                        <div className="row">
                          <div className="col ps-0">
                            <div
                              className="bnm text-dark"
                              style={{ fontSize: '17px' }}
                            >
                              <div>
                                Remuneration is calculated only on the basis of
                                working hours and working days and not as a
                                fixed monthly salary.
                              </div>
                              <div className="pt-3">
                                <span className="bld">
                                  For Inexperienced Freshers :
                                </span>
                                <br />
                              </div>
                              <div className="pt-2">
                                <span className="">Rs 100/- per hour</span>
                                <div className="pt-2">
                                  <span className="">
                                    Minimum Working Hours per Day : 6<br />
                                    Maximum Working Hours per Day : 8<br />
                                    Minimum Working Days per Month : 20
                                    <br />
                                    Maximum Working Days per Month : 25
                                    <br />
                                    <br />
                                    Can earn anything between Rs 12,000/- and Rs
                                    20,000/- in a month.
                                  </span>
                                  <br />
                                </div>
                              </div>

                              <div className="pt-3">
                                <span className="bld">
                                  For Experienced Employees :
                                </span>
                                <br />
                              </div>
                              <div className="pt-2">
                                <span className="">Rs 200/- per hour</span>
                                <div className="pt-2">
                                  Minimum Working Hours per Day : 6<br />
                                  Maximum Working Hours per Day : 8
                                  <br />
                                  Minimum Working Days per Month : 20
                                  <br />
                                  Maximum Working Days per Month : 25
                                  <br />
                                </div>
                              </div>
                              <div className="pt-3">
                                An <u>INCENTIVE</u> *<sup></sup> of upto a
                                maximum of Rs 25,000/- will be paid every month
                                towards House Rental / EMI, Vehicle EMI, Office
                                Conveyance, Health Insurance, and Medical
                                Expenses. This is not a fixed amount as it would
                                depend on the number of working days in a month.
                                <div className="pt-2">
                                  *<sup></sup> INCENTIVE :
                                </div>
                                <ul className="pt-2">
                                  <li>
                                    NIL for less than 20 days of work in a
                                    month.
                                  </li>
                                  <li>
                                    Rs 12,500/- for 20-23 days of work in a
                                    month.
                                  </li>
                                  <li>
                                    Rs 25,000/- for 24-25 days of work in a
                                    month.
                                  </li>
                                </ul>
                                <div className="pt-2">
                                  Only Experienced Employees are Eligible for
                                  the INCENTIVE.
                                </div>
                                <div className="pt-2">
                                  Can earn anything between Rs 24,000/- and Rs
                                  65,000/- in a month.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="px-3 mb-0"></hr>
              </div>
            </div>

            <div id="bdr">
              <div className="container">
                <div
                  className="card ps-1"
                  style={{ backgroundColor: '#e9eef5', border: 'none' }}
                >
                  <div className="card-body py-0">
                    <div className="row mt-3">
                      <div className="col p-0">
                        <span
                          style={{ fontSize: '15px' }}
                          className=" text-dark"
                        >
                          <div className="nmb">
                            ADDITIONAL SKILL BONUS (ASB)
                          </div>
                          {/* <div className=" bnmm pt-2">
                          Kindly Read and Understand the Concept, Remuneration
                          and Employment Policies clearly before you apply for a
                          job with us.
                        </div>{' '} */}
                        </span>
                      </div>
                      <div className="col-2 ">
                        <h5 className="text-center">
                          {!plus.col6 ? (
                            <button
                              style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                              }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse16"
                              aria-expanded="false"
                              aria-controls="collapse16"
                              onClick={(e) =>
                                setplus({
                                  ...plus,

                                  col6: true,
                                })
                              }
                            >
                              <AiOutlinePlusCircle
                                name="add-circle-outline"
                                size="25px"
                                className="black"
                              ></AiOutlinePlusCircle>
                            </button>
                          ) : (
                            <button
                              style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                              }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse16"
                              aria-expanded="false"
                              aria-controls="collapse16"
                              onClick={(e) =>
                                setplus({
                                  ...plus,

                                  col6: false,
                                })
                              }
                            >
                              <AiOutlineMinusCircle
                                name="add-circle-outline"
                                size="25px"
                                className="black"
                              ></AiOutlineMinusCircle>
                            </button>
                          )}
                        </h5>
                      </div>
                    </div>
                    <div
                      className="collapse"
                      data-bs-parent="#coll"
                      id="collapse16"
                    >
                      <div
                        className="card  card-body"
                        style={{ backgroundColor: '#e9eef5', border: 'none' }}
                      >
                        <div className="row">
                          <div className="col ps-0">
                            <span
                              className=" text-dark"
                              style={{ fontSize: '18px' }}
                            >
                              Employees in certain Job Categories, which
                              involves Technical Skills, will be paid an amount
                              beyond the standard remuneration on the basis of
                              what their Work Experience, Skill and Talent
                              demand.
                            </span>

                            <div className="pt-3">
                              Only Experienced Employees are Eligible for the
                              ASB.
                            </div>
                            <div className="pt-3">
                              However, the ASB is entirely at the sole
                              discretion of{' '}
                              <span
                                className="vipar"
                                style={{
                                  fontSize: '22px',
                                  color: '#99d050',
                                }}
                              >
                                Vipar&nbsp;
                              </span>{' '}
                              and cannot be questioned by employees who are not
                              eligible to avail this.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="px-3 mb-0"></hr>
              </div>
            </div>

            <div id="bdr">
              <div className="container">
                <div
                  className="card ps-1"
                  style={{ backgroundColor: '#e9eef5', border: 'none' }}
                >
                  <div className="card-body py-0">
                    <div className="row mt-3">
                      <div className="col p-0">
                        <span
                          style={{ fontSize: '15px' }}
                          className=" text-dark"
                        >
                          <div className="nmb">GOOD CONDUCT REWARD (GCR)</div>
                        </span>
                      </div>
                      <div className="col-2 ">
                        <h5 className="text-center">
                          {!plus.col7 ? (
                            <button
                              style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                              }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse17"
                              aria-expanded="false"
                              aria-controls="collapse17"
                              onClick={(e) =>
                                setplus({
                                  ...plus,

                                  col7: true,
                                })
                              }
                            >
                              <AiOutlinePlusCircle
                                name="add-circle-outline"
                                size="25px"
                                className="black"
                              ></AiOutlinePlusCircle>
                            </button>
                          ) : (
                            <button
                              style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                              }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse17"
                              aria-expanded="false"
                              aria-controls="collapse17"
                              onClick={(e) =>
                                setplus({
                                  ...plus,

                                  col7: false,
                                })
                              }
                            >
                              <AiOutlineMinusCircle
                                name="add-circle-outline"
                                size="25px"
                                className="black"
                              ></AiOutlineMinusCircle>
                            </button>
                          )}
                        </h5>
                      </div>
                    </div>
                    <div
                      className="collapse"
                      data-bs-parent="#coll"
                      id="collapse17"
                    >
                      <div
                        className="card  card-body"
                        style={{ backgroundColor: '#e9eef5', border: 'none' }}
                      >
                        <div className="row">
                          <div className="col ps-0">
                            <span
                              className=" text-dark"
                              style={{ fontSize: '18px' }}
                            >
                              The manner in which an employee conducts himself /
                              herself, both physically and verbally with their
                              colleagues and seniors and also having
                              professional ethics, sincerity, honesty and
                              gratitude towards the Organization itself is as
                              important as his / her talent, skill and
                              performance at work.
                            </span>
                            <br />
                            <div className="pt-3">
                              This conduct will be thoroughly, discreetly
                              observed by us throughout the job tenure of that
                              employee at{' '}
                              <span
                                className="vipar"
                                style={{
                                  fontSize: '22px',
                                  color: '#99d050',
                                }}
                              >
                                Vipar
                              </span>
                              .
                            </div>
                            <div className="pt-3">
                              Employees with a good conduct record will be duly
                              rewarded either in cash or in-kind at the end of
                              every 12 months of work or at the time of the
                              employee exiting our Organization.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="px-3 mb-0"></hr>
              </div>
            </div>

            <div id="bdr">
              <div className="container">
                <div
                  className="card ps-1"
                  style={{ backgroundColor: '#e9eef5', border: 'none' }}
                >
                  <div className="card-body py-0">
                    <div className="row mt-3">
                      <div className="col p-0">
                        <span
                          style={{ fontSize: '15px' }}
                          className=" text-dark"
                        >
                          <div className="nmb">JOB OPPORTUNITIES</div>
                          {/* <div className=" bnmm pt-2">
                          Kindly Read and Understand the Concept, Remuneration
                          and Employment Policies clearly before you apply for a
                          job with us.
                        </div>{' '} */}
                        </span>
                      </div>
                      <div className="col-2 ">
                        <h5 className="text-center">
                          {!plus.col3 ? (
                            <button
                              style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                              }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse13"
                              aria-expanded="false"
                              aria-controls="collapse13"
                              onClick={(e) =>
                                setplus({
                                  ...plus,

                                  col3: true,
                                })
                              }
                            >
                              <AiOutlinePlusCircle
                                name="add-circle-outline"
                                size="25px"
                                className="black"
                              ></AiOutlinePlusCircle>
                            </button>
                          ) : (
                            <button
                              style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                              }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse13"
                              aria-expanded="false"
                              aria-controls="collapse13"
                              onClick={(e) =>
                                setplus({
                                  ...plus,

                                  col3: false,
                                })
                              }
                            >
                              <AiOutlineMinusCircle
                                name="add-circle-outline"
                                size="25px"
                                className="black"
                              ></AiOutlineMinusCircle>
                            </button>
                          )}
                        </h5>
                      </div>
                    </div>
                    <div
                      className="collapse"
                      data-bs-parent="#coll"
                      id="collapse13"
                    >
                      <div
                        className="card  card-body"
                        style={{ backgroundColor: '#e9eef5', border: 'none' }}
                      >
                        <div className="row">
                          <div className="col ps-0">
                            <span
                              className=" text-dark"
                              style={{ fontSize: '18px' }}
                            >
                              Kindly Read and Understand the Concept,
                              Remuneration and Employment Policies clearly
                              before you Apply for a Job with us.
                            </span>
                            <Verticalsvaccanies />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className="pb-5 d-md-block d-none"
            style={{ backgroundColor: '#e9eef5' }}
          >
            <div id="bdr">
              <div className="container">
                <div
                  className="card ps-1 pt-2"
                  style={{ backgroundColor: '#e9eef5', border: 'none' }}
                >
                  <div className="card-body pb-0">
                    <div className="row mt-3">
                      <div className="col p-0">
                        <span
                          style={{ fontSize: '15px' }}
                          className=" text-dark"
                        >
                          <div className="nmb">
                            CONCEPT of ‘ ONE WORK. ONE WAGE. ’
                          </div>
                          {/* <div className=" bnmm pt-2">“Equal Collar” Jobs</div>{' '} */}
                        </span>
                      </div>
                      <div className="col-2 ">
                        <h5 className="text-center">
                          <button
                            className="btn btn-outline-dark btn-md btnn"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse11"
                            aria-expanded="false"
                            aria-controls="collapse11"
                            onClick={(e) =>
                              setplus({ ...plus, col1: !plus.col1 })
                            }
                          >
                            Read&nbsp;more.....
                          </button>
                        </h5>
                      </div>
                    </div>
                    <div
                      className="collapse"
                      data-bs-parent="#coll"
                      id="collapse11"
                    >
                      <div
                        className="card ps-0 card-body"
                        style={{ backgroundColor: '#e9eef5', border: 'none' }}
                      >
                        <div className="row">
                          <div className="col ps-3">
                            <div className="bnm ">
                              At
                              <span
                                className="vipar"
                                style={{
                                  fontSize: '22px',
                                  color: '#99d050',
                                }}
                              >
                                {' '}
                                Vipar
                              </span>
                              , the term “ONE WORK” has only one meaning and
                              that is HARDWORK !
                            </div>

                            <div className="bnm pt-3">
                              Our Policy is to provide, what we call, “Equal
                              Collar” jobs.
                            </div>
                            <div className="bnm pt-3">
                              Remuneration must be based on how much effort and
                              sincerity one puts into work irrespective of
                              gender, age, educational qualification, and
                              profession.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="bdr">
              <div className="container">
                <div
                  className="card ps-1 pt-2"
                  style={{ backgroundColor: '#e9eef5', border: 'none' }}
                >
                  <div className="card-body py-0">
                    <div className="row mt-3">
                      <div className="col p-0">
                        <span
                          style={{ fontSize: '15px' }}
                          className=" text-dark"
                        >
                          <div className="nmb">EMPLOYMENT BIFURCATION</div>
                          {/* <div className="bnmm pt-2">
                          Only on the basis of Working Hours
                        </div>{' '} */}
                        </span>
                      </div>
                      <div className="col-2 ">
                        <h5 className="text-center">
                          {!plus.col14 ? (
                            <button
                              className="btn btn-outline-dark btn-md btnn"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse14"
                              aria-expanded="false"
                              aria-controls="collapse14"
                              onClick={(e) =>
                                setplus({ ...plus, col14: !plus.col14 })
                              }
                            >
                              Read&nbsp;more.....
                            </button>
                          ) : (
                            <button
                              className="btn btn-outline-dark btn-md btnn"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse14"
                              aria-expanded="false"
                              aria-controls="collapse14"
                              onClick={(e) =>
                                setplus({ ...plus, col14: !plus.col14 })
                              }
                            >
                              Read&nbsp;more.....
                            </button>
                          )}
                        </h5>
                      </div>
                    </div>
                    <div
                      className="collapse"
                      data-bs-parent="#coll"
                      id="collapse14"
                    >
                      <div
                        className="card  card-body"
                        style={{
                          backgroundColor: '#e9eef5',
                          border: 'none',
                          paddingLeft: '14px',
                        }}
                      >
                        <div className="row">
                          <div className="col ps-0">
                            {/* <div
                              className="bnm text-dark"
                              style={{ fontSize: '17px' }}
                            >
                              <div className="">
                                <span className="bld">
                                  For Inexperienced Freshers :
                                </span>
                                <br />
                              </div>
                              <div className="pt-3">
                                <ul>
                                  <li>
                                    The first week will be an ‘Unpaid Training
                                    Period’ by the end of which{' '}
                                    <span
                                      className="vipar"
                                      style={{
                                        fontSize: '22px',
                                        color: '#99d050',
                                      }}
                                    >
                                      Vipar&nbsp;&nbsp;
                                    </span>{' '}
                                    has the discretion to decide whether or not
                                    to employ the candidate.
                                  </li>
                                  <li className="">
                                    Post the first week, the employee will be on
                                    a probation period of one year.
                                  </li>

                                  <li className="">
                                    The first month’s salary will be paid only
                                    on completion of one year along with the 12
                                    <sup>th</sup> month’s salary.
                                  </li>
                                  <li className="">
                                    Must mandatorily give a Notice period of a
                                    minimum of 3 months to leave the
                                    Organization. If this is not followed, then
                                    neither will the first month’s salary be
                                    paid nor will a work experience certificate
                                    be issued.
                                  </li>
                                  <li className="">
                                    <span
                                      className="vipar"
                                      style={{
                                        fontSize: '22px',
                                        color: '#99d050',
                                      }}
                                    >
                                      Vipar&nbsp;&nbsp;
                                    </span>
                                    will give a minimum of 2 months of Notice
                                    period to the employee to relieve him / her
                                    from the Organization.
                                  </li>
                                  <li>
                                    Salaries for the current month will be
                                    credited on the 15
                                    <sup>th</sup> day of the following month
                                    even if it is a bank / public holiday.
                                  </li>
                                  <li className="">
                                    Salaries for the months during the Notice
                                    period will be settled in full only on the
                                    Last Working day at the Organization.
                                  </li>

                                  <li>
                                    Paid Leave is applicable Only in cases of{' '}
                                    <u>Critical Medical Emergency</u> *
                                    <sup>1</sup> to Self,{' '}
                                    <u>Critical Medical Emergency</u> *
                                    <sup>1</sup> <u>to Immediate Family</u> *
                                    <sup>2</sup>, Death in{' '}
                                    <u>Immediate Family</u> *<sup>2</sup> ,{' '}
                                    <u>Menstrual Leave</u> *<sup>3</sup> (for
                                    women)
                                  </li>

                                  <li>
                                    Work-from-Home is a not concept we believe
                                    in except in case of a pandemic or
                                    nation-wide / state / city shutdown.
                                  </li>
                                  <li>
                                    FREE MEALS will be provided to All the
                                    Employees who opt for it on All Working Days
                                    throughout the year.
                                  </li>
                                  <li>
                                    There will be no organized or sponsored
                                    informal / casual trips for employees from
                                    our end.
                                  </li>
                                </ul>
                                <br />
                              </div>

                              <div className="pt-4">
                                <span className="bld">
                                  For Experienced Employees :
                                </span>
                                <br />
                              </div>
                              <br />

                              <div className="">
                                <span className="">
                                  <ul>
                                    <li>
                                      Must mandatorily give a Notice period of a
                                      minimum of 3 months to leave the
                                      Organization. If this is not followed,
                                      then neither will the immediate previous
                                      first month’s salary be paid nor will a
                                      work experience certificate be issued.
                                    </li>
                                    <li>
                                      <span
                                        className="vipar"
                                        style={{
                                          fontSize: '22px',
                                          color: '#99d050',
                                        }}
                                      >
                                        Vipar&nbsp;&nbsp;
                                      </span>
                                      will give a minimum of 2 months of Notice
                                      period to the employee to relieve him /
                                      her from the Organization.
                                    </li>
                                    <li>
                                      Salaries for the current month will be
                                      credited on the 15
                                      <sup>th</sup> day of the following month
                                      even if it is a bank / public holiday.
                                    </li>

                                    <li>
                                      Paid Leave is applicable Only in cases of{' '}
                                      <u>Critical Medical Emergency</u> *
                                      <sup>1</sup> to Self,{' '}
                                      <u>Critical Medical Emergency</u> *
                                      <sup>1</sup> <u>to Immediate Family</u> *
                                      <sup>2</sup>, Death in{' '}
                                      <u>Immediate Family</u> *<sup>2</sup> ,{' '}
                                      <u>Menstrual Leave</u> *<sup>3</sup> (for
                                      women), <u>Maternity Leave</u> *
                                      <sup>4</sup> (for women),{' '}
                                      <u>Paternity Leave</u> *<sup>5</sup> (for
                                      men).
                                    </li>

                                    <li>
                                      Work-from-Home is a not concept we believe
                                      in except in case of a pandemic
                                      nation-wide / or state / city shutdown.
                                    </li>
                                    <li>
                                      FREE MEALS will be provided to All the
                                      Employees who opt for it on All Working
                                      Days throughout the year.
                                    </li>
                                    <li>
                                      There will be no organized or sponsored
                                      informal / casual trips for employees from
                                      our end.
                                    </li>
                                  </ul>
                                  <br />
                                  <div className="pt-2">
                                    Please Refer LEAVE POLICIES to understand
                                    what it ( * ) means ?
                                  </div>
                                </span>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="bdr">
              <div className="container">
                <div
                  className="card ps-1 pt-2"
                  style={{ backgroundColor: '#e9eef5', border: 'none' }}
                >
                  <div className="card-body py-0">
                    <div className="row mt-3">
                      <div className="col p-0">
                        <span
                          style={{ fontSize: '15px' }}
                          className=" text-dark"
                        >
                          <div className="nmb">EMPLOYMENT POLICIES</div>
                          {/* <div className="bnmm pt-2">
                          Only on the basis of Working Hours
                        </div>{' '} */}
                        </span>
                      </div>
                      <div className="col-2 ">
                        <h5 className="text-center">
                          {!plus.col4 ? (
                            <button
                              className="btn btn-outline-dark btn-md btnn"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse121"
                              aria-expanded="false"
                              aria-controls="collapse121"
                              onClick={(e) =>
                                setplus({ ...plus, col4: !plus.col4 })
                              }
                            >
                              Read&nbsp;more.....
                            </button>
                          ) : (
                            <button
                              className="btn btn-outline-dark btn-md btnn"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse121"
                              aria-expanded="false"
                              aria-controls="collapse121"
                              onClick={(e) =>
                                setplus({ ...plus, col4: !plus.col4 })
                              }
                            >
                              Read&nbsp;more.....
                            </button>
                          )}
                        </h5>
                      </div>
                    </div>
                    <div
                      className="collapse"
                      data-bs-parent="#coll"
                      id="collapse121"
                    >
                      <div
                        className="card  card-body"
                        style={{
                          backgroundColor: '#e9eef5',
                          border: 'none',
                          paddingLeft: '14px',
                        }}
                      >
                        <div className="row">
                          <div className="col ps-0">
                            <div
                              className="bnm text-dark"
                              style={{ fontSize: '17px' }}
                            >
                              <div className="pt-3">
                                <span className="bld">
                                  For Inexperienced Freshers :
                                </span>
                                <br />
                              </div>
                              <div className="pt-3">
                                <ul>
                                  <li>
                                    The first week will be an ‘Unpaid Training
                                    Period’ by the end of which{' '}
                                    <span
                                      className="vipar"
                                      style={{
                                        fontSize: '22px',
                                        color: '#99d050',
                                      }}
                                    >
                                      Vipar&nbsp;&nbsp;
                                    </span>{' '}
                                    has the discretion to decide whether or not
                                    to employ the candidate.
                                  </li>
                                  <li className="">
                                    Post the first week, the employee will be on
                                    a probation period of one year.
                                  </li>

                                  <li className="">
                                    The first month’s salary will be paid only
                                    on completion of one year along with the 12
                                    <sup>th</sup> month’s salary.
                                  </li>
                                  <li className="">
                                    Must mandatorily give a Notice period of a
                                    minimum of 3 months to leave the
                                    Organization. If this is not followed, then
                                    neither will the first month’s salary be
                                    paid nor will a work experience certificate
                                    be issued.
                                  </li>
                                  <li className="">
                                    <span
                                      className="vipar"
                                      style={{
                                        fontSize: '22px',
                                        color: '#99d050',
                                      }}
                                    >
                                      Vipar&nbsp;&nbsp;
                                    </span>
                                    will give a minimum of 2 months of Notice
                                    period to the employee to relieve him / her
                                    from the Organization.
                                  </li>
                                  <li>
                                    Salaries for the current month will be
                                    credited on the 15
                                    <sup>th</sup> day of the following month
                                    even if it is a bank / public holiday.
                                  </li>
                                  <li className="">
                                    Salaries for the months during the Notice
                                    period will be settled in full only on the
                                    Last Working day at the Organization.
                                  </li>

                                  <li>
                                    Paid Leave is applicable Only in cases of{' '}
                                    <u>Critical Medical Emergency</u> *
                                    <sup>1</sup> to Self,{' '}
                                    <u>Critical Medical Emergency</u> *
                                    <sup>1</sup> <u>to Immediate Family</u> *
                                    <sup>2</sup>, Death in{' '}
                                    <u>Immediate Family</u> *<sup>2</sup> ,{' '}
                                    <u>Menstrual Leave</u> *<sup>3</sup> (for
                                    women)
                                  </li>

                                  <li>
                                    Work-from-Home is a not concept we believe
                                    in except in case of a pandemic or
                                    nation-wide / state / city shutdown.
                                  </li>
                                  <li>
                                    FREE MEALS will be provided to All the
                                    Employees who opt for it on All Working Days
                                    throughout the year.
                                  </li>
                                  <li>
                                    There will be no organized or sponsored
                                    informal / casual trips for employees from
                                    our end.
                                  </li>
                                </ul>
                                <br />
                              </div>

                              <div className="pt-4">
                                <span className="bld">
                                  For Experienced Employees :
                                </span>
                                <br />
                              </div>
                              <br />

                              <div className="">
                                <span className="">
                                  <ul>
                                    <li>
                                      Must mandatorily give a Notice period of a
                                      minimum of 3 months to leave the
                                      Organization. If this is not followed,
                                      then neither will the immediate previous
                                      first month’s salary be paid nor will a
                                      work experience certificate be issued.
                                    </li>
                                    <li>
                                      <span
                                        className="vipar"
                                        style={{
                                          fontSize: '22px',
                                          color: '#99d050',
                                        }}
                                      >
                                        Vipar&nbsp;&nbsp;
                                      </span>
                                      will give a minimum of 2 months of Notice
                                      period to the employee to relieve him /
                                      her from the Organization.
                                    </li>
                                    <li>
                                      Salaries for the current month will be
                                      credited on the 15
                                      <sup>th</sup> day of the following month
                                      even if it is a bank / public holiday.
                                    </li>

                                    <li>
                                      Paid Leave is applicable Only in cases of{' '}
                                      <u>Critical Medical Emergency</u> *
                                      <sup>1</sup> to Self,{' '}
                                      <u>Critical Medical Emergency</u> *
                                      <sup>1</sup> <u>to Immediate Family</u> *
                                      <sup>2</sup>, Death in{' '}
                                      <u>Immediate Family</u> *<sup>2</sup> ,{' '}
                                      <u>Menstrual Leave</u> *<sup>3</sup> (for
                                      women), <u>Maternity Leave</u> *
                                      <sup>4</sup> (for women),{' '}
                                      <u>Paternity Leave</u> *<sup>5</sup> (for
                                      men).
                                    </li>

                                    <li>
                                      Work-from-Home is a not concept we believe
                                      in except in case of a pandemic
                                      nation-wide / or state / city shutdown.
                                    </li>
                                    <li>
                                      FREE MEALS will be provided to All the
                                      Employees who opt for it on All Working
                                      Days throughout the year.
                                    </li>
                                    <li>
                                      There will be no organized or sponsored
                                      informal / casual trips for employees from
                                      our end.
                                    </li>
                                  </ul>
                                  <br />
                                  <div className="pt-2">
                                    Please Refer LEAVE POLICIES to understand
                                    what it ( * ) means ?
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="bdr">
              <div className="container">
                <div
                  className="card ps-1 pt-2"
                  style={{ backgroundColor: '#e9eef5', border: 'none' }}
                >
                  <div className="card-body">
                    <div className="row mt-3">
                      <div className="col p-0">
                        <span
                          style={{ fontSize: '15px' }}
                          className=" text-dark"
                        >
                          <div className="nmb">LEAVE POLICIES</div>
                          {/* <div className=" bnmm pt-2">
                          Kindly Read and Understand the Concept, Remuneration
                          and Employment Policies clearly before you apply for a
                          job with us.
                        </div>{' '} */}
                        </span>
                      </div>
                      <div className="col-2 ">
                        <h5 className="text-center">
                          <button
                            className="btn btn-outline-dark btn-md btnn"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse115"
                            aria-expanded="false"
                            aria-controls="collapse115"
                            onClick={(e) =>
                              setplus({ ...plus, col15: !plus.col15 })
                            }
                          >
                            Read&nbsp;more.....
                          </button>
                        </h5>
                      </div>
                    </div>
                    <div
                      className="collapse"
                      data-bs-parent="#coll"
                      id="collapse115"
                    >
                      <div
                        className="card card-body"
                        style={{ backgroundColor: '#e9eef5', border: 'none' }}
                      >
                        <div className="row">
                          <div className="col ps-0">
                            <div
                              className=" text-dark"
                              style={{ fontSize: '18px' }}
                            >
                              <div className="pb-3">
                                <ul>
                                  <li>
                                    There will be NO Paid Leave or Holidays for
                                    Regional and National Festivals like Ayudha
                                    Pooja, Christmas, Diwali, Dussehra, Eid,
                                    Holi, Onam, Pongal, etc.
                                  </li>
                                  <li>
                                    All Regional and National Festivals are
                                    Restricted Holidays.
                                  </li>
                                  <li>
                                    Every Paid Leave Application (except in
                                    cases of monthly Menstrual Leaves) will be
                                    entertained and sanctioned Only with Valid
                                    Substantiating Evidence.
                                  </li>
                                  <li>
                                    Leaves in a month cannot be carried forward
                                    to another month.
                                  </li>
                                </ul>
                              </div>
                              <div className="">
                                <span className="">What it ( * ) means ?</span>
                                <br />
                                <br />
                                <span className="">
                                  *<sup>1</sup> Critical Medical Emergency :
                                </span>

                                <ul className="pt-2">
                                  <li>
                                    Hospitalization / Treatment for a
                                    Life-threatening Illness
                                  </li>
                                  <li>Critically required Surgeries</li>
                                  <li>
                                    Accident related conditions / treatment /
                                    hospitalization
                                  </li>
                                </ul>

                                <span className="">
                                  *<sup>2</sup> Immediate Family :
                                </span>

                                <ul className="pt-2">
                                  <li>Parent</li>
                                  <li>Spouse</li>
                                  <li>Child</li>
                                  <li>Blood Sibling</li>
                                  <li>Spouse's Parent</li>
                                </ul>
                                <div className="pt-2">
                                  *<sup>3</sup> Menstrual Leave : A maximum of 3
                                  days every month for women between the age
                                  group of 18 to 50.
                                </div>

                                <div className="pt-4">
                                  *<sup>4</sup> Maternity Leave : Paid Maternity
                                  Leave will be granted for a period of one year
                                  (1 month before delivery and 11 months post
                                  delivery) only on the following terms :
                                  <ul>
                                    <li>
                                      Paid Maternity Leave will only be
                                      sanctioned after completing a minimum of
                                      one year of employment with{' '}
                                      <span
                                        className="vipar"
                                        style={{
                                          fontSize: '22px',
                                          color: '#99d050',
                                        }}
                                      >
                                        Vipar
                                      </span>
                                      .
                                    </li>
                                    <li>
                                      The Employee must work for a minimum
                                      period of one year after resuming work
                                      post delivery.
                                    </li>
                                    <li>
                                      The Salary for the Maternity Leave period
                                      will only be 50% of the respective
                                      Employee’s average salary of the immediate
                                      previous year.
                                    </li>
                                    <li>
                                      The Salary for the entire Maternity Leave
                                      period will be paid only after completing
                                      one year of work post delivery.
                                    </li>
                                  </ul>
                                </div>

                                <div className="pt-4">
                                  *<sup>5</sup> Paternity Leave : A maximum of 1
                                  month.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="bdr">
              <div className="container">
                <div
                  className="card ps-1 pt-2"
                  style={{ backgroundColor: '#e9eef5', border: 'none' }}
                >
                  <div className="card-body">
                    <div className="row mt-3">
                      <div className="col p-0">
                        <span
                          style={{ fontSize: '15px' }}
                          className=" text-dark"
                        >
                          <div className="nmb">WORKING HOURS and DAYS</div>
                          {/* <div className=" bnmm pt-2">
                          Kindly Read and Understand the Concept, Remuneration
                          and Employment Policies clearly before you apply for a
                          job with us.
                        </div>{' '} */}
                        </span>
                      </div>
                      <div className="col-2 ">
                        <h5 className="text-center">
                          <button
                            className="btn btn-outline-dark btn-md btnn"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse15"
                            aria-expanded="false"
                            aria-controls="collapse15"
                            onClick={(e) =>
                              setplus({ ...plus, col5: !plus.col5 })
                            }
                          >
                            Read&nbsp;more.....
                          </button>
                        </h5>
                      </div>
                    </div>
                    <div
                      className="collapse"
                      data-bs-parent="#coll"
                      id="collapse15"
                    >
                      <div
                        className="card card-body"
                        style={{ backgroundColor: '#e9eef5', border: 'none' }}
                      >
                        <div className="row">
                          <div className="col ps-0">
                            <div
                              className=" text-dark"
                              style={{ fontSize: '18px' }}
                            >
                              <ul>
                                <li>
                                  There is NO FIXED Period of Working Hours in a
                                  day. Choose your own convenient working hours
                                  every day between 0600 (6 am) and 2200 (10
                                  pm). This flexibility is year to suit both the
                                  Early Birds and the Nocturnals.
                                </li>
                                <li>
                                  There are NO FIXED Working Days in a week. You
                                  can even opt to work on a Sunday instead of a
                                  weekday.
                                </li>
                                <li>
                                  We will be closed only on 4 days in a calendar
                                  year ( January 26<sup>th</sup> : Republic Day,
                                  May 1<sup>st</sup> : Labour Day, August 15
                                  <sup>th</sup> : Independence Day, October 2
                                  <sup>nd</sup> : Gandhi Jayanti)
                                </li>
                                <li>
                                  Minimum Working Hours per Day : 6<br />
                                  Maximum Working Hours per Day : 8
                                  <br />
                                  Minimum Working Days per Month : 20
                                  <br />
                                  Maximum Working Days per Month : 25
                                  <br />
                                </li>
                                <li>
                                  Working Hours per Day for All will be
                                  EXCLUDING Loo Breaks, and Breaks for Meals,
                                  Refreshments, and Smoking.
                                </li>
                                <li>
                                  Employees of certain Wings (departments) which
                                  require works / projects to be done in tandem
                                  will have to opt for mutually preferred common
                                  time slots.
                                </li>
                                <li>
                                  Experienced Employees (only) have the option
                                  (on approval and other T&amp;C) to work for 20
                                  consecutive days (including Saturdays and
                                  Sundays) in a month so as to take 10 days off
                                  at a stretch.
                                </li>
                              </ul>
                              <hr className="px-3 mb-0"></hr>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="bdr">
              <div className="container">
                <div
                  className="card ps-1 pt-1 "
                  style={{ backgroundColor: '#e9eef5', border: 'none' }}
                >
                  <div className="card-body pb-0">
                    <div className="row mt-3">
                      <div className="col p-0">
                        <span
                          style={{ fontSize: '15px' }}
                          className=" text-dark"
                        >
                          <div className="nmb">REMUNERATION</div>
                          {/* <div className="bnmm pt-2">
                          Only on the basis of Working Hours
                        </div>{' '} */}
                        </span>
                      </div>
                      <div className="col-2 ">
                        <h5 className="text-center">
                          <button
                            className="btn btn-outline-dark btn-md btnn"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse12"
                            aria-expanded="false"
                            aria-controls="collapse12"
                            ref={linkopen}
                            onClick={(e) =>
                              setplus({ ...plus, col2: !plus.col2 })
                            }
                          >
                            Read&nbsp;more.....
                          </button>
                        </h5>
                      </div>
                    </div>
                    <div
                      className="collapse"
                      data-bs-parent="#coll"
                      id="collapse12"
                    >
                      <div
                        className="card ps-0 card-body"
                        style={{ backgroundColor: '#e9eef5', border: 'none' }}
                      >
                        <div className="row">
                          <div className="col ps-3">
                            <span
                              className=" text-dark"
                              style={{ fontSize: '14px' }}
                            >
                              <div>
                                Remuneration is calculated only on the basis of
                                working hours and working days and not as a
                                fixed monthly salary.
                              </div>
                              <div className="pt-3">
                                <span className="bld">
                                  For Inexperienced Freshers :
                                </span>
                                <br />
                              </div>
                              <div className="pt-2">
                                <span className="">Rs 100/- per hour</span>
                                <div className="pt-2">
                                  <span className="">
                                    Minimum Working Hours per Day : 6<br />
                                    Maximum Working Hours per Day : 8<br />
                                    Minimum Working Days per Month : 20
                                    <br />
                                    Maximum Working Days per Month : 25
                                    <br />
                                    <br />
                                    Can earn anything between Rs 12,000/- and Rs
                                    20,000/- in a month.
                                  </span>
                                  <br />
                                </div>
                              </div>

                              <div className="pt-3">
                                <span className="bld">
                                  For Experienced Employees :
                                </span>
                                <br />
                              </div>
                              <div className="pt-2">
                                <span className="">Rs 200/- per hour</span>
                                <div className="pt-2">
                                  Minimum Working Hours per Day : 6<br />
                                  Maximum Working Hours per Day : 8
                                  <br />
                                  Minimum Working Days per Month : 20
                                  <br />
                                  Maximum Working Days per Month : 25
                                  <br />
                                </div>
                              </div>

                              <div className="pt-3">
                                An <u>INCENTIVE</u> *<sup></sup> of upto a
                                maximum of Rs 25,000/- will be paid every month
                                towards House Rental / EMI, Vehicle EMI, Office
                                Conveyance, Health Insurance, and Medical
                                Expenses. This is not a fixed amount as it would
                                depend on the number of working days in a month.
                              </div>
                              <div className="pt-4">
                                *<sup></sup> INCENTIVE :
                              </div>

                              <ul className="pt-2">
                                <li>
                                  NIL for less than 20 days of work in a month.
                                </li>
                                <li>
                                  Rs 12,500/- for 20-23 days of work in a month.
                                </li>
                                <li>
                                  Rs 25,000/- for 24-25 days of work in a month.
                                </li>
                              </ul>
                              <div className="pt-2">
                                Only Experienced Employees are Eligible for the
                                INCENTIVE.
                              </div>
                              <div className="pt-2">
                                Can earn anything between Rs 24,000/- and Rs
                                65,000/- in a month.
                              </div>

                              <br />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="bdr">
              <div className="container">
                <div
                  className="card ps-1 pt-2"
                  style={{ backgroundColor: '#e9eef5', border: 'none' }}
                >
                  <div className="card-body">
                    <div className="row mt-3">
                      <div className="col p-0">
                        <span
                          style={{ fontSize: '15px' }}
                          className=" text-dark"
                        >
                          <div className="nmb">
                            ADDITIONAL SKILL BONUS (ASB)
                          </div>
                          {/* <div className=" bnmm pt-2">
                          Kindly Read and Understand the Concept, Remuneration
                          and Employment Policies clearly before you apply for a
                          job with us.
                        </div>{' '} */}
                        </span>
                      </div>
                      <div className="col-2 ">
                        <h5 className="text-center">
                          <button
                            className="btn btn-outline-dark btn-md btnn"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse136"
                            aria-expanded="false"
                            aria-controls="collapse136"
                            onClick={(e) =>
                              setplus({ ...plus, col6: !plus.col6 })
                            }
                          >
                            Read&nbsp;more.....
                          </button>
                        </h5>
                      </div>
                    </div>
                    <div
                      className="collapse"
                      data-bs-parent="#coll"
                      id="collapse136"
                    >
                      <div
                        className="card card-body"
                        style={{ backgroundColor: '#e9eef5', border: 'none' }}
                      >
                        <div className="row">
                          <div className="col ps-0">
                            <span
                              className=" text-dark"
                              style={{ fontSize: '18px' }}
                            >
                              Employees in certain Job Categories, which
                              involves Technical Skills, will be paid an amount
                              beyond the standard remuneration on the basis of
                              what their Work Experience, Skill and Talent
                              demand.
                            </span>
                            <div className="pt-3">
                              Only Experienced Employees are Eligible for the
                              ASB.
                            </div>
                            <div className="pt-3">
                              However, the ASB is entirely at the sole
                              discretion of{' '}
                              <span
                                className="vipar"
                                style={{
                                  fontSize: '22px',
                                  color: '#99d050',
                                }}
                              >
                                Vipar&nbsp;
                              </span>{' '}
                              and cannot be questioned by employees who are not
                              eligible to avail this.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="bdr">
              <div className="container">
                <div
                  className="card ps-1 pt-2"
                  style={{ backgroundColor: '#e9eef5', border: 'none' }}
                >
                  <div className="card-body">
                    <div className="row mt-3">
                      <div className="col p-0">
                        <span
                          style={{ fontSize: '15px' }}
                          className=" text-dark"
                        >
                          <div className="nmb">GOOD CONDUCT REWARD (GCR)</div>
                        </span>
                      </div>
                      <div className="col-2 ">
                        <h5 className="text-center">
                          <button
                            className="btn btn-outline-dark btn-md btnn"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse137"
                            aria-expanded="false"
                            aria-controls="collapse137"
                            onClick={(e) =>
                              setplus({ ...plus, col7: !plus.col7 })
                            }
                          >
                            Read&nbsp;more.....
                          </button>
                        </h5>
                      </div>
                    </div>
                    <div
                      className="collapse"
                      data-bs-parent="#coll"
                      id="collapse137"
                    >
                      <div
                        className="card card-body"
                        style={{ backgroundColor: '#e9eef5', border: 'none' }}
                      >
                        <div className="row">
                          <div className="col ps-0">
                            <span
                              className=" text-dark"
                              style={{ fontSize: '18px' }}
                            >
                              The manner in which an employee conducts himself /
                              herself, both physically and verbally with their
                              colleagues and seniors and also having
                              professional ethics, sincerity, honesty and
                              gratitude towards the Organization itself is as
                              important as his / her talent, skill and
                              performance at work.
                            </span>
                            <br />
                            <div className="pt-3">
                              This conduct will be thoroughly, discreetly
                              observed by us throughout the job tenure of that
                              employee at{' '}
                              <span
                                className="vipar"
                                style={{
                                  fontSize: '22px',
                                  color: '#99d050',
                                }}
                              >
                                Vipar
                              </span>
                              .
                            </div>
                            <div className="pt-3">
                              Employees with a good conduct record will be duly
                              rewarded either in cash or in-kind at the end of
                              every 12 months of work or at the time of the
                              employee exiting our Organization.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="bdr">
              <div className="container">
                <div
                  className="card ps-1 pt-2"
                  style={{ backgroundColor: '#e9eef5', border: 'none' }}
                >
                  <div className="card-body">
                    <div className="row mt-3">
                      <div className="col p-0">
                        <span
                          style={{ fontSize: '15px' }}
                          className=" text-dark"
                        >
                          <div className="nmb">JOB OPPORTUNITIES</div>
                          {/* <div className=" bnmm pt-2">
                          Kindly Read and Understand the Concept, Remuneration
                          and Employment Policies clearly before you apply for a
                          job with us.
                        </div>{' '} */}
                        </span>
                      </div>
                      <div className="col-2 ">
                        <h5 className="text-center">
                          <button
                            className="btn btn-outline-dark btn-md btnn"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse13"
                            aria-expanded="false"
                            aria-controls="collapse13"
                            onClick={(e) =>
                              setplus({ ...plus, col3: !plus.col3 })
                            }
                          >
                            Read&nbsp;more.....
                          </button>
                        </h5>
                      </div>
                    </div>
                    <div
                      className="collapse"
                      data-bs-parent="#coll"
                      id="collapse13"
                    >
                      <div
                        className="card card-body"
                        style={{ backgroundColor: '#e9eef5', border: 'none' }}
                      >
                        <div className="row">
                          <div className="col ps-0">
                            <span
                              className=" text-dark"
                              style={{ fontSize: '18px' }}
                            >
                              Kindly Read and Understand the Concept,
                              Remuneration and Employment Policies clearly
                              before you Apply for a Job with us.
                            </span>
                            <Verticalsvaccanies />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <Footer />
    </>
  );
};
