import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Footer } from '../Footer/Footer';
import { Navviparhome } from './Navviparhome';
import './Viparhome.css';
import Scrolltopbutton from './Scrolltopbutton';
import { Scrollbottobutton } from './Scrollbottobutton';
import {
  BsFillHandIndexFill,
  BsHandIndex,
  BsHandIndexThumb,
} from 'react-icons/bs';

export const Viparhome = () => {
  const collapase1 = useRef();
  const collapase2 = useRef();
  const collapase3 = useRef();
  const collapase4 = useRef();
  const collapase5 = useRef();
  const collapase6 = useRef();
  const collapase7 = useRef();
  const collapase8 = useRef();
  const collapase9 = useRef();
  const collapase10 = useRef();
  const collapase11 = useRef();
  const anees = useSelector((state) => state.user.value);

  function doit(id) {
    try {
      let element = id.current;
      console.log(element);
      if (element.children[1]) {
        let content = element.children[1];
        console.log(content.classList);
        if (content.classList[content.classList.length - 1] === 'd-none') {
          content.classList.remove('d-none');
          element.children[2].children[0].children[0].classList.add('d-none');
          element.children[2].children[0].children[1].classList.remove(
            'd-none'
          );
        } else {
          content.classList.add('d-none');
          element.children[2].children[0].children[0].classList.remove(
            'd-none'
          );
          element.children[2].children[0].children[1].classList.add('d-none');
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    try {
      document.getElementsByTagName('title')[0].innerHTML = 'Vipar';
    } catch (err) {
      console.log(err);
    }
    return () => {
      document.getElementsByTagName('title')[0].innerHTML = 'Vipar-chic';
    };
  }, []);

  return (
    <>
      <div id="foo">
        <div id="viparHome" className="">
          <Navviparhome />
          <hr
            className="mt-0"
            style={{
              color: 'rgb(1, 32, 96)',
              opacity: '1',
              borderTop: '3px solid',
            }}
          />
          <div className="container mt-3">
            <div className="">
              <span
                className="fntspm  d-flex justify-content-center pb-1"
                style={{ paddingLeft: '15px' }}
              >
                Commercial
              </span>
              <div className="container">
                <div className="row d-flex justify-content-center">
                  <div className="col-4 ">
                    <div className="ftcc mt-2 d-flex justify-content-center">
                      APPS
                    </div>
                    <div className="p-2">
                      <div
                        id="containerft2"
                        ref={collapase10}
                        className="container  m-0 "
                        style={{ maxWidth: '400px', border: 'none' }}
                      >
                        <Link
                          to={'/vipar-connect'}
                          style={{ textDecoration: 'none' }}
                        >
                          <div className="row cardSuper d-flex justify-content-center">
                            <div className="col-auto pe-0 ps-0 ">
                              <img
                                className="img-fluid"
                                src="/img/connectcircle.png"
                                width="120px"
                                height="120px"
                                alt=""
                              />
                            </div>

                            <div className="d-flex justify-content-center">
                              <span
                                className="  transform m-0 p-0"
                                style={{
                                  fontSize: '17px',
                                  fontFamily: 'maiandra',
                                  fontWeight: '800',
                                  color: '#012060',
                                  marginLeft: '6px',
                                }}
                              >
                                CONNECT
                              </span>
                            </div>
                            <div className="col ps-0 pe-0"></div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-8 pe-0 ">
                    <div className="">
                      <div
                        id="containerft2"
                        ref={collapase10}
                        className="   pt-4   m-0  "
                        style={{ display: 'inline-block' }}
                        // style={{ maxWidth: "400px", border: "none" }}
                      >
                        <a
                          // to={anees?.Status ? 'https://www.prochure.in/' : ''}
                          href="https://www.prochure.in"
                          target="_blank"
                          style={{ textDecoration: 'none' }}
                        >
                          <div
                            className="d-flex justify-content-start mt-4  pt-2"
                            // id="blink"
                            // className="nn  transform m-0"

                            // style={{
                            //   marginTop: '25px',
                            //   display: 'inline-block',
                            //   color: '#5d334e',
                            //   border: '1px solid',
                            //   margin: '30px',
                            //   marginBottom: '0',
                            //   marginTop: '0',
                            // }}
                          >
                            <span className="d-flex align-items-center ps-2 blinkkk ">
                              <img
                                src="/img/finger.png"
                                width="30px"
                                height="30px"
                              />
                            </span>
                            &nbsp;&nbsp;
                            <img
                              className="img-fluid szz "
                              src="/img/prochureLogo.png"
                              // width="110px"
                              height="30px"
                              alt=""
                            />
                            {/* <span className="d-flex align-items-center ps-2 blinkkk ">
                              <img
                                src="/img/finger2.png"
                                width="30px"
                                height="30px"
                              />
                            </span> */}
                            {/* <span
                              className="nn  transform m-0 pt-3 blinkkk "
                              style={{
                                fontSize: '15px',
                                // fontFamily: 'calibri',
                                fontWeight: '800',
                                color: 'black',
                                transform: 'rotateY(180deg) rotate(90deg)',
                              }}
                            >
                              <BsHandIndexThumb color="#5d334e" size={23} />
                            </span> */}
                          </div>
                          <div className="">
                            <div className=" pe-0  ">
                              <div className="mmm text-center pt-1 ">
                                &nbsp;&nbsp;an E-Brochure of&nbsp;
                                <br />
                                Professional Services and Products
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <hr
                    className="pt-2 "
                    style={{
                      color: 'darkgray',
                      borderTop: '2px solid',
                    }}
                  />
                  <div className="col-4">
                    <Link to={'/vipar-casa'} style={{ textDecoration: 'none' }}>
                      <div className="">
                        <div className="col-12 ">
                          <div className="ftcc mt-2 d-flex justify-content-center">
                            ARCHITECTURE
                          </div>
                          <div className="p-2">
                            <div
                              id="containerft2"
                              ref={collapase9}
                              className="container m-0 "
                              style={{ maxWidth: '400px', border: 'none' }}
                            >
                              <div className="row cardSuper d-flex justify-content-center">
                                <div className="col-auto pe-0 ps-0 ">
                                  <img
                                    className="img-fluid"
                                    src="/img/casacirclee.png"
                                    width="120px"
                                    height="120px"
                                    alt=""
                                  />
                                </div>
                                <div className="d-flex justify-content-center">
                                  <span
                                    className=" transform m-0 "
                                    style={{
                                      fontSize: '17px',
                                      fontFamily: 'maiandra',
                                      fontWeight: '800',
                                      color: '#012060',
                                    }}
                                  >
                                    CASA
                                  </span>
                                </div>
                                <div className="col ps-0 pe-0">
                                  <div className="row p-0 m-0">
                                    <div
                                      className="col-12 p-0  "
                                      style={{ marginTop: '6.4px' }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  {/* <div className="col-4 ">
                    <div className="ftcc mt-2 d-flex justify-content-center">
                      PETS
                    </div>
                    <div className="p-2">
                      <div
                        id="containerft2"
                        ref={collapase10}
                        className="container    m-0 "
                        style={{ maxWidth: '400px', border: 'none' }}
                      >
                        <Link
                          to={anees?.Status ? '/vipar-chum' : ''}
                          style={{ textDecoration: 'none' }}
                        >
                          <div className="row cardSuper d-flex justify-content-center">
                            <div className="col-auto pe-0 ps-0 ">
                              <img
                                className="img-fluid"
                                src="/img/viparchumh.png"
                                width="120px"
                                height="120px"
                                alt=""
                              />
                            </div>

                            <div className="d-flex justify-content-center">
                              <span
                                className="  transform m-0  p-0"
                                style={{
                                  fontSize: '17px',
                                  fontFamily: 'maiandra',
                                  fontWeight: '800',
                                  color: '#012060',
                                  marginLeft: '6px',
                                }}
                              >
                                CHUM
                              </span>
                            </div>
                            <div className="col ps-0 pe-0"></div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="col-4">
                    <div className="ftcc mt-2 d-flex justify-content-center">
                      ESSENTIAL
                    </div>
                    <div className="p-2">
                      <div
                        id="containerft2"
                        ref={collapase10}
                        className="container    m-0 "
                        style={{ maxWidth: '400px', border: 'none' }}
                      >
                        <Link
                          to={anees?.Status ? '/vipar-comfort' : ''}
                          style={{ textDecoration: 'none' }}
                        >
                          <div className="row cardSuper d-flex justify-content-center">
                            <div className="col-auto pe-0 ps-0 ">
                              <img
                                className="img-fluid "
                                src="/img/viparcomforth.png"
                                width="120px"
                                height="120px"
                                alt=""
                              />
                            </div>

                            <div className="d-flex justify-content-center">
                              <span
                                className="  transform m-0  p-0"
                                style={{
                                  fontSize: '17px',
                                  fontFamily: 'maiandra',
                                  fontWeight: '800',
                                  color: '#012060',
                                  marginLeft: '6px',
                                }}
                              >
                                COMFORT
                              </span>
                            </div>
                            <div className="col ps-0 pe-0"></div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-4">
                    <div className="ftcc mt-2 d-flex justify-content-center">
                      UTILITY
                    </div>
                    <Link to="/vipar-chic" style={{ textDecoration: 'none' }}>
                      <div className="">
                        <div className="col-12 ">
                          <div className="p-2">
                            <div
                              id="containerft2"
                              ref={collapase7}
                              className="container    m-0 "
                              style={{ maxWidth: '400px', border: 'none' }}
                            >
                              <div className="row cardSuper d-flex justify-content-center">
                                <div className="col-auto pe-0 ps-0 ">
                                  <img
                                    className="img-fluid"
                                    src="/img/circleborder.png"
                                    width="120px"
                                    height="120px"
                                    alt=""
                                  />
                                </div>
                                <div className="d-flex justify-content-center">
                                  <span
                                    className="   transform m-0 "
                                    style={{
                                      fontSize: '17px',
                                      fontFamily: 'maiandra',
                                      fontWeight: '800',
                                      color: '#012060',
                                    }}
                                  >
                                    CHIC
                                  </span>
                                </div>
                                <div className="col ps-0 pe-0"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  {/* <hr
                    className="mt-2 "
                    style={{
                      color: 'darkgray',
                      borderTop: '2px solid',
                    }}
                  /> */}

                  {/* <div className="col-4 ">
                    <div className="ftcc ps-5 mt-2 d-flex justify-content-center">
                      &nbsp;&#8239;WASTE&nbsp;MANAGEMENT
                    </div>
                    <div className="p-2">
                      <div
                        id="containerft2"
                        ref={collapase10}
                        className="container    m-0 "
                        style={{ maxWidth: '400px', border: 'none' }}
                      >
                        <Link
                          to={anees?.Status ? '/vipar-clean' : ''}
                          style={{ textDecoration: 'none' }}
                        >
                          <div className="row cardSuper d-flex justify-content-center">
                            <div className="col-auto pe-0 ps-0 ">
                              <img
                                className="img-fluid"
                                src="/img/viparcleanh.png"
                                width="120px"
                                height="120px"
                                alt=""
                              />
                            </div>

                            <div className="d-flex justify-content-center">
                              <span
                                className="  transform m-0  p-0"
                                style={{
                                  fontSize: '17px',
                                  fontFamily: 'maiandra',
                                  fontWeight: '800',
                                  color: '#012060',
                                  marginLeft: '6px',
                                }}
                              >
                                CLEAN
                              </span>
                            </div>
                            <div className="col ps-0 pe-0"></div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="col-4"></div> */}
                  {/* <div className="col-4 ">
                    <div className="ftcc mt-2 d-flex justify-content-center">
                      AUTOMOBILES
                    </div>
                    <div className="p-2">
                      <div
                        id="containerft2"
                        ref={collapase10}
                        className="container    m-0 "
                        style={{ maxWidth: '400px', border: 'none' }}
                      >
                        <Link
                          to={anees?.Status ? '/vipar-cruise' : ''}
                          style={{ textDecoration: 'none' }}
                        >
                          <div className="row cardSuper d-flex justify-content-center">
                            <div className="col-auto pe-0 ps-0 ">
                              <img
                                className="img-fluid"
                                src="/img/viparcart.png"
                                width="120px"
                                height="120px"
                                alt=""
                              />
                            </div>

                            <div className="d-flex justify-content-center">
                              <span
                                className="  transform m-0  p-0"
                                style={{
                                  fontSize: '17px',
                                  fontFamily: 'maiandra',
                                  fontWeight: '800',
                                  color: '#012060',
                                  marginLeft: '6px',
                                }}
                              >
                                CRUISE&nbsp;
                              </span>
                            </div>
                            <div className="col ps-0 pe-0"></div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div> */}

                  {/* <hr
                    className="mt-2 "
                    style={{
                      color: 'darkgray',
                      borderTop: '2px solid',
                    }}
                  /> */}
                  {/* <div className="col-4">
                    <Link
                      to={anees?.Status ? '/vipar-casa' : ''}
                      style={{ textDecoration: 'none' }}
                    >
                      <div className="">
                        <div className="col-12 ">
                          <div className="ftcc mt-2 d-flex justify-content-center">
                            ARCHITECTURE
                          </div>
                          <div className="p-2">
                            <div
                              id="containerft2"
                              ref={collapase9}
                              className="container m-0 "
                              style={{ maxWidth: '400px', border: 'none' }}
                            >
                              <div className="row cardSuper d-flex justify-content-center">
                                <div className="col-auto pe-0 ps-0 ">
                                  <img
                                    className="img-fluid"
                                    src="/img/casacirclee.png"
                                    width="120px"
                                    height="120px"
                                    alt=""
                                  />
                                </div>
                                <div className="d-flex justify-content-center">
                                  <span
                                    className=" transform m-0 "
                                    style={{
                                      fontSize: '17px',
                                      fontFamily: 'maiandra',
                                      fontWeight: '800',
                                      color: '#012060',
                                    }}
                                  >
                                    CASA
                                  </span>
                                </div>
                                <div className="col ps-0 pe-0">
                                  <div className="row p-0 m-0">
                                    <div
                                      className="col-12 p-0  "
                                      style={{ marginTop: '6.4px' }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div> */}

                  {/* <div className="col-4 ">
                    <div className="ftcc mt-2 d-flex justify-content-center">
                      EATERY
                    </div>
                    <div className="p-2">
                      <div
                        id="containerft2"
                        ref={collapase10}
                        className="container    m-0 "
                        style={{ maxWidth: '400px', border: 'none' }}
                      >
                        <Link
                          to={anees?.Status ? '/vipar-chef' : ''}
                          style={{ textDecoration: 'none' }}
                        >
                          <div className="row cardSuper d-flex justify-content-center">
                            <div className="col-auto pe-0 ps-0 ">
                              <img
                                className="img-fluid"
                                src="/img/viparchefh.png"
                                width="120px"
                                height="120px"
                                alt=""
                              />
                            </div>

                            <div className="d-flex justify-content-center">
                              <span
                                className="  transform m-0  p-0"
                                style={{
                                  fontSize: '17px',
                                  fontFamily: 'maiandra',
                                  fontWeight: '800',
                                  color: '#012060',
                                  marginLeft: '6px',
                                }}
                              >
                                CHEF
                              </span>
                            </div>
                            <div className="col ps-0 pe-0"></div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="col-4">
                    <div className="ftcc mt-2 d-flex justify-content-center">
                      SHOWBIZ
                    </div>
                    <div className="p-2">
                      <div
                        id="containerft2"
                        ref={collapase11}
                        className="container m-0 "
                        style={{ maxWidth: '400px', border: 'none' }}
                      >
                        <Link
                          to={anees?.Status === true ? '/vipar-cine' : ''}
                          style={{ textDecoration: 'none' }}
                        >
                          <div className="row cardSuper d-flex justify-content-center">
                            <div className="col-auto pe-0 ps-0 ">
                              <img
                                className="img-fluid "
                                src="/img/viparcineh.png"
                                width="120px"
                                height="120px"
                                alt=""
                              />
                            </div>
                            <div className="d-flex justify-content-center">
                              <span
                                className="  transform m-0 "
                                style={{
                                  fontSize: '17px',
                                  fontFamily: 'maiandra',
                                  fontWeight: '800',
                                  color: '#012060',
                                }}
                              >
                                CINÉ
                              </span>
                            </div>
                            <div className="col ps-0 pe-0"></div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <hr
                className="pt-2"
                style={{
                  color: '#000',
                  borderTop: '2px solid',
                }}
              />

              {/* <div className="container">
                <span
                  className="fntspm  d-flex justify-content-center pb-1"
                  style={{ paddingLeft: '15px' }}
                >
                  Compassion
                </span>
                <div className="row d-flex justify-content-center pt-2">
                  <div className="col-4 me-1">
                    <div className="p-2">
                      <div
                        id="containerft2"
                        ref={collapase11}
                        className="container m-0 "
                        style={{ maxWidth: '400px', border: 'none' }}
                      >
                        <Link
                          to={anees?.Status === true ? '/vipar-care' : ''}
                          style={{ textDecoration: 'none' }}
                        >
                          <div className="row cardSuper d-flex justify-content-center">
                            <div className="col-auto pe-0 ps-0 ">
                              <img
                                className="img-fluid"
                                src="/img/carecircle.png"
                                width="120px"
                                height="120px"
                                alt=""
                              />
                            </div>
                            <div className="d-flex justify-content-center">
                              <span
                                className="  transform m-0  "
                                style={{
                                  fontSize: '17px',
                                  fontFamily: 'maiandra',
                                  fontWeight: '800',
                                  color: '#012060',
                                }}
                              >
                                CARE
                              </span>
                            </div>
                            <div className="col ps-0 pe-0"></div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row w-100 p-0 m-0 d-flex justify-content-end d-none">
                  <div className="col-4 "></div>
                </div>
              </div> */}
              <br />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end me-3 pb-1 mz">
        <Scrolltopbutton />
      </div>
      <div>
        {' '}
        <Footer />
      </div>
    </>
  );
};
