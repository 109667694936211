import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const Subadminlogin = () => {
  let navigate = useNavigate();
  const [login, setlogin] = useState({
    Email: '',
    Password: '',
  });





  const submit = () => {
    axios
      .post('/chum/subadminlogin', login)
      .then((res) => {
        console.log(res.data);
        navigate('/subadmin_chum');
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const [forgot, setforgot] = useState({
    Email : ""
  })


  const forgotpassword = () =>{
    axios.post("/chum/subadminfotgottpassword",forgot).then((res)=>{
      console.log("as")
    }).catch((err=>{
      console.log(err)
    }))
  }



  return (
    <>
      <section className="vh-100" style={{ backgroundColor: '#508bfc' }}>
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div
                className="card shadow-2-strong"
                style={{ borderRadius: '1rem' }}
              >
                <div className="card-body p-5 ">
                  <center>
                    <h3 className="mb-5">Sign in</h3>
                  </center>
                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="typeEmailX-2">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="typeEmailX-2"
                      className="form-control form-control-lg"
                      fdprocessedid="ydop06"
                      onChange={(e) =>
                        setlogin({ ...login, Email: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="typePasswordX-2">
                      Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      id="typePasswordX-2"
                      className="form-control form-control-lg"
                      fdprocessedid="kao81l"
                      onChange={(e) =>
                        setlogin({ ...login, Password: e.target.value })
                      }
                    />
                  </div>
                  <div className="mb-4 ">
                    <a
                      style={{
                        textDecoration: 'none',
                        fontSize: '10px',
                        cursor: 'pointer',
                        color: 'black',
                      }}
                      data-bs-target="#ForgetPasswordModal"
                      data-bs-toggle="modal"
                      data-bs-dismiss="modal"
                    >
                      Forgot password ?
                    </a>
                  </div>

                  {/* <p style={{ color: 'red' }}>{err}</p> */}

                  <button
                    className="btn btn-primary btn-lg btn-block"
                    type="submit"
                    fdprocessedid="i8iyob"
                    onClick={(e) => submit()}
                  >
                    Login
                  </button>

                  <hr className="my-4" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* forget password modal */}
      <div
        className="modal fade"
        id="ForgetPasswordModal"
        aria-hidden="true"
        aria-labelledby="ForgetPasswordModal"
        tabIndex="-1"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-login mt-5"
          role="document"
        >
          <div className="modal-content">
            <div className="card card-signup card-plain">
              <div class="modal-header">
                <h4 class="modal-title">Forgot Password ?</h4>
                <button
                  type="button"
                  id="forgetmodalclosebtn"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                <form className="form" method="" action="">
                  <div className="card-body">
                    <div className="form-group bmd-form-group">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email Id"
                          onChange={(e) => {
                            setforgot({ ...forgot, Email: e.target.value });
                          }}
                        />
                      </div>
                    </div>

                    <div className="form-group bmd-form-group">
                      <div className="input-group d-flex justify-content-center">
                     
                       
                        <button
                          className="btn btn-primary rounded-pill px-4 mt-3"
                          style={{ letterSpacing: '1px' }}
                          type="button"
                          onClick={(e) => forgotpassword()}
                        >
                          CONTINUE
                        </button>
                      
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* forget password modal */}

      {/* forgetpassphase2 btn */}
      <button
        type="button"
        className="btn btn-primary d-none"
        id="otpModalbtn"
        data-bs-toggle="modal"
        data-bs-target="#forgetpassphase2"
      >
        otpmodalbutton
      </button>
      {/* forgetpassphase2 btn */}

      {/* ///forgetpassphase2 */}
      <div
        className="modal fade"
        id="forgetpassphase2"
        aria-hidden="true"
        aria-labelledby="otpModalLabel"
        tabIndex="-1"
      >
        <div
          className="modal-dialog  modal-dialog-centered modal-login mt-5"
          role="document"
        >
          <div className="modal-content">
            <div className="card card-signup card-plain">
              <div className="modal-header">
                <div className="modal-body">
                  <div className="container d-flex justify-content-center align-items-center mt-3">
                    <div className="position-relative">
                      <div className="p-2 text-center">
                        <h6>
                          A Link to Reset your Password has been sent to your
                          <br />
                          Email Id :{/* {forgot.Email} */}
                        </h6>

                        <div className="mt-4">
                          {' '}
                          <button
                            className=" btnouttt px-4 "
                            data-bs-dismiss="modal"
                            type="button"
                          >
                            GOT IT
                          </button>{' '}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ///forgetpassphase2 */}
    </>
  );
};
