import React from 'react';
import { Footer } from '../Footer/Footer';
import { Navviparhome } from './Navviparhome';

export const Viparman = () => {
  return (
    <>
      <Navviparhome />
      <div id="foooo">
        <section className="bgvipar text-white py-3">
          <div className="container w-100">
            <div className=" d-flex justify-content-between  align-items-center py-2 py-md-3 px-2">
              <div
                className="mono "
                style={{
                  fontSize: '15px',
                  //   paddingTop: '15px',
                  //   paddingBottom: '10px',
                }}
              >
                <div className="pt-1">
                  <span className="">
                    Meet our Mascot, Mr.
                    <span
                      className="vipar"
                      style={{
                        fontSize: '22px',
                        color: '#99d050',
                      }}
                    >
                      &nbsp;Vipar&nbsp;&nbsp;
                    </span>
                    man !
                    <img
                      className=""
                      src="/img/round.png"
                      alt=""
                      height="60px"
                      width="60px"
                    />
                  </span>
                  <br />
                </div>
                <div className="pt-1">
                  <span className="">
                    He supports unfortunate and underprivileged living beings
                    via
                    <span
                      className="vipar"
                      style={{
                        fontSize: '22px',
                        color: '#99d050',
                      }}
                    >
                      &nbsp;Vipar&nbsp;&nbsp;
                    </span>
                    <span className="verticals">CARE&nbsp;</span>by virtue of
                    providing a variety of useful Services and Products.
                  </span>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-5 " style={{ backgroundColor: '#e9eef5' }}>
          <div className="container px-3">
            <div className="pt-5">
              Profits from the commercial verticals of
              <span
                className="vipar"
                style={{
                  fontSize: '22px',
                  color: '#99d050',
                }}
              >
                &nbsp;Vipar&nbsp;&nbsp;
              </span>
              is used for the Social Welfare and Philanthropic Initiatives at
              <span
                className="vipar"
                style={{
                  fontSize: '22px',
                  color: '#99d050',
                }}
              >
                &nbsp;Vipar&nbsp;&nbsp;
              </span>
              <span className="verticals">CARE</span>.
            </div>
            <div className="pt-3">
              Each and every Customer / Subscriber of
              <span
                className="vipar"
                style={{
                  fontSize: '22px',
                  color: '#99d050',
                }}
              >
                &nbsp;Vipar&nbsp;&nbsp;
              </span>
              Services and Products is Indirectly contributing towards the
              welfare of those in need of support.
              <br />
            </div>
            <div className="pt-3">
              <span
                className="vipar"
                style={{
                  fontSize: '22px',
                  color: '#99d050',
                }}
              >
                Vipar&nbsp;&nbsp;
              </span>
              man is Only an Intermediary.
            </div>
            <div className="pt-3 invisible">
              <span
                className="vipar"
                style={{
                  fontSize: '22px',
                  color: '#99d050',
                }}
              >
                Vipar&nbsp;&nbsp;
              </span>
              man is Only an Intermediary.
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};
