import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { initialFromDB } from '../../../redux/viparChic/cartSlice';
import './ORder.css';

export const Order = () => {
  let Navigate = useNavigate();
  let dispatch = useDispatch();
  const [cart, setCart] = useState([]);
  useEffect(() => {
    axios
      .get('/public/shopping')
      .then((res) => {
        console.log(res.data);
        setCart(res.data);
        if (res.data.Items) {
          dispatch(
            initialFromDB({
              value: res.data.Items,
            })
          );
        } else {
          dispatch(
            initialFromDB({
              value: [],
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });

    setTimeout(() => {
      Navigate('/vipar-chic/policies');
    }, 5000);
    return () => {};
  }, []);
  return (
    <div className=" d-flex justify-content-center align-items-center h-100">
      <div>
        <h1 style={{ width: '350px' }} className="text-center">
          Your order is placed
        </h1>
        <div id="vandi" class="main">
          <div class="road">
            <ul>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
            <div class="bus">
              <div class="back">
                <div class="back1door"></div>
                <div class="back2door"></div>
                <div class="join"></div>
              </div>
              <div class="front">
                <div class="black"></div>
                <div class="light1"></div>
                <div class="light2"></div>
              </div>
            </div>
            <div class="gift"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
