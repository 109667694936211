import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { keyGen, notify } from '../../App';

export const Adminart = () => {
  const [deleteshow, deletesetShow] = useState(false);
  const deletehandleClose = () => deletesetShow(false);
  const deletehandleShow = (id, index) => {
    setDeletevalue({ id, index });
    deletesetShow(true);
  };

  const [state, setState] = useState([]);
  const [deletevalue, setDeletevalue] = useState({
    id: '',
    index: '',
  });
  const confirm = (e) => {
    e.preventDefault();
    console.log(state);
    axios
      .post('/adminlogin/deleteart', { deletevalue })

      .then((res) => {
        let temp = [...state];
        temp.splice(deletevalue.index, 1);
        setState(temp);
        setDeletevalue({
          id: '',
          index: '',
        });
        notify('value deleted', true);

        deletehandleClose();
      })
      .catch((err) => {
        console.log(err);
        notify('something went wrong', false);
      });
  };
  useEffect(() => {
    axios
      .post('/adminlogin/art')
      .then((res) => {
        setState(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {};
  }, []);

  return (
    <>
      <div className="page-breadcrumb bg-white">
        <div
          className="row align-items-center"
          style={{ backgroundColor: 'black' }}
        >
          <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
            <h4 className="page-title text-white">Art</h4>
          </div>
          <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
            <div className="d-md-flex">
              <ol className="breadcrumb ms-auto"></ol>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid overflow-scroll">
        <div className="row" style={{ paddingTop: '30px' }}>
          <div className="col-md-12">
            <div className="white-box">
              <table className="table text-white">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {state.map((element, index) => {
                    return (
                      <tr key={keyGen()}>
                        <td>{index + 1}</td>
                        <td>{element.Name}</td>
                        <td>{element.Email}</td>
                        <td>{element.Time}</td>

                        <td>
                          <button
                            onClick={() => deletehandleShow(element._id, index)}
                            className="btn btn-danger"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal
        centered
        show={deleteshow}
        onHide={deletehandleClose}
        animation={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={(e) => confirm(e)}>
              <h5>
                Are you sure do you want to delete{' '}
                <span className="text-danger">
                  {state[deletevalue?.index]?.Name}{' '}
                </span>{' '}
                ?
              </h5>
              <center>
                <button className="btn btn-primary">Confirm</button>
              </center>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
