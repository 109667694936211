import React, { useEffect, useRef, useState } from 'react';
import { Grievances } from './Grievances';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import './Gr.css';
import axios from 'axios';
import { Footer } from '../../Footer/Footer';
import { notify } from '../../../App';

export const Newform = () => {
  const [img, setimg] = useState([]);
  const [previ, setprevi] = useState({});
  const [first, setfirst] = useState();
  const [mess, setmess] = useState(false);

  const [msg, setmsg] = useState({
    Order_Id: '',
    Order_date: '',
    Message: '',
  });
  var anees = useRef();
  const [err, seterr] = useState();
  const validation = () => {
    if (msg.Order_Id.length <= 0) {
      return seterr('SELECT Grievance related to');
    }
    if (msg.Message.length <= 0) {
      return seterr('PLEASE ENTER Message');
    }
    seterr('');
    return true;
  };

  useEffect(() => {
    if (mess) {
      validation();
    }
  }, [msg]);

  const submit = () => {
    setmess(true);
    if (validation() === true) {
      var formData = new FormData();
      console.log(img);
      {
        img?.map((elem) => {
          formData.append('file', elem);
        });
      }

      var dataSend = JSON.stringify({ msg });
      formData.append('data', dataSend);
      axios
        .post('/public/grievances', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          console.log('first');
          console.log(res.data);
          setprevi(res.data);
          notify('Sucessfully Added', true);
          setmess(false);
          setmsg({
            Message: '',
            Order_Id: '',
          });
          setimg([]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    axios.get('/public/grev').then((res) => {
      console.log(res.data);
      setfirst(res.data);
    });
  }, []);

  const fun = (index) => {
    let temp = [...img];
    temp.splice(index, 1);
    setimg([...temp]);
  };
  return (
    <>
      <div>
        <Grievances />
      </div>
      <div className="container py-5">
        <div className="container">
          <label for="Grievance related to *" className="">
            Grievance related to&nbsp;*
          </label>

          <select
            class="form-select form-select mt-3"
            aria-label=".form-select example"
            value={msg.Order_Id}
            onChange={(e) => {
              console.log(e.target.selectedOptions[0].innerHTML);
              setmsg({
                ...msg,
                Order_Id: e.target.value,
                Order_date: e.target.selectedOptions[0].innerHTML,
              });
            }}
            // onChange={(e)=>setmsg(...msg,e.target.value)}
          >
            <option></option>
            {first?.map((elem) => {
              console.log(elem);
              return (
                <option value={elem.Order_Id}>
                  {elem.Order_Id} / {elem.Order_date}
                </option>
              );
            })}
          </select>
        </div>

        <div className="container" style={{ paddingTop: '80px' }}>
          <div className="">
            <label htmlhtmlFor="inputmessage">Message&nbsp;*</label>
            <textarea
              className="form-control mt-3"
              id="message"
              name="message"
              rows="8"
              value={msg.Message}
              onChange={(e) => {
                setmsg({ ...msg, Message: e.target.value });
              }}
            ></textarea>
          </div>
        </div>
        <input
          hidden
          ref={anees}
          type="file"
          className="btn btn-primary mt-3 mx-2"
          style={{ backgroundColor: 'rgb(1 32 96)' }}
          accept="image/*"
          onChange={(e) => setimg([...img, ...e.target.files])}
        />
        <div className="ps-3 pt-3">
          <div className="row g-3">
            {img.map((ele, index) => {
              console.log(ele);
              return (
                <div className="col-12 col-md-6 col-lg-4">
                  <IoIosCloseCircleOutline
                    className="d-flex float-end"
                    onClick={(e) => fun(index)}
                  />
                  <img
                    src={URL.createObjectURL(ele)}
                    style={{
                      width: '100%',
                      height: '200px',
                      objectFit: 'cover',
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <button
          id="anees"
          className="btn btn-primary mt-3 mx-2"
          style={{ backgroundColor: 'rgb(1 32 96)' }}
          accept="image/*"
          onClick={(e) => anees.current.click()}
        >
          ADD IMAGE
        </button>
        <div className="text-danger">{err}</div>
        <div className="container pb-3">
          <button
            type="button"
            className="btn btn-outline-dark btn-lg px-3 btnn float-end"
            onClick={(e) => submit()}
          >
            Shoot
          </button>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};
