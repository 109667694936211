import React, { useContext, useEffect, useState } from 'react';
import { IoIosCloseCircleOutline } from 'react-icons/io';

// import './Orderdetails.css';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import StarRatings from 'react-star-ratings';
import axios from 'axios';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { notify } from '../../../App';

export const Refunddetails = () => {
  const [order, setorder] = useState([]);
  useEffect(() => {
    axios
      .post('/public/refunddetails')
      .then((res) => {
        console.log(res.data);
        setorder(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <hr
        className="mt-0"
        style={{
          color: 'rgb(1, 32, 96)',
          opacity: '1',
          borderTop: '3px solid',
        }}
      />
      <>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Product Name</TableCell>
                <TableCell>Order Id</TableCell>
                <TableCell>Refund Processed On</TableCell>
                <TableCell>Refund Id and Date</TableCell>
                <TableCell>Refund Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order.map((row, index) => {
                return row?.ref.map((rows, ind) => {
                  return rows?.items.map((rowss, indd) => {
                    console.log(order);
                    console.log(row);
                    console.log(rows);
                    console.log(rowss);
                    if (rowss.item == row.item_Id) {
                      if (row.Status === 'processed') {
                        console.log();
                        let v = rows.cart[0].Items[indd].varientId;
                        let im = rows.cart[0].variant_col.find((e) => {
                          return e._id == v;
                        });
                        console.log(im);
                        return (
                          <Row
                            key={rows.item + index}
                            row={row}
                            order={order}
                            rows={rows}
                            index={index}
                            rowss={rowss}
                            ind={ind}
                            indd={indd}
                            setorder={setorder}
                            image={im}
                          />
                        );
                      }
                    }
                  });
                });
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </>
  );
};

function Row(props) {
  var { row, index, rows, ind, indd, setorder, order, rowss, image } = {
    ...props,
  };
  console.log(row, index, rows);

  const [open, setOpen] = React.useState(false);
  const submit = () => {
    axios
      .post('/payment/refund')
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {rowss?.name}
        </TableCell>
        <TableCell>{row?.Order_Id}</TableCell>

        <TableCell>{moment(row?.Date).format('MMMM Do YYYY')}</TableCell>
        <TableCell>{row?.RefundId + '' + row?.Date}</TableCell>
        <TableCell>
          {row.Status === 'processed' ? (
            <span className="text-uppercase">Success</span>
          ) : (
            <></>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <div
                className="row"
                style={{ width: '100vw', paddingRight: '18px' }}
              >
                <div className="col-12 col-md-auto mt-2 d-flex justify-content-center align-items-center">
                  <img
                    src={`/img/${image.varentCode}.jpg`}
                    style={{
                      width: '200px',
                      height: '200px',
                      objectFit: 'cover',
                    }}
                    className="img-fluid rounded-3"
                    alt="VIPAR"
                  />
                </div>
                <div className="col-12 col-md-8 mt-2">
                  <h1 style={{ whiteSpace: 'pre' }}>
                    {rowss?.name}&nbsp;&nbsp;&nbsp;
                    {rowss?.vname}
                  </h1>
                  <div>
                    Quantity&nbsp;:&nbsp;{rowss?.qty}
                    <br />
                    Price&nbsp;: ₹ {rowss?.price * rowss.qty}
                  </div>
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
