import React, { useContext, useEffect, useState } from 'react';
// import './Orderdetails.css';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import StarRatings from 'react-star-ratings';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import './Orderdetails.css';

import axios from 'axios';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { notify } from '../../../App';

export const Neworderdetails = () => {
  const [order, setorder] = useState([]);

  useEffect(() => {
    axios
      .post('/public/neworderdetails')
      .then((res) => {
        if (res.data) {
          console.log(res.data);
          setorder(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const check = () => {
    let status = false;
    order.forEach((ee) => {
      console.log(ee);
      if (ee.items.length) {
        ee.items.forEach((er) => {
          if (er.Status == 'delivered' || er.Status == 'dispatched') {
            status = true;
          }
        });
      }
    });
    return status;
  };
  return (
    <>
      <hr
        className="mt-0"
        style={{
          color: 'rgb(1, 32, 96)',
          opacity: '1',
          borderTop: '3px solid',
        }}
      />
      <>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Product Name</TableCell>
                <TableCell>Purchased On</TableCell>
                <TableCell>Delivered On</TableCell>
                <TableCell>Order Id</TableCell>
                <TableCell>Order Status</TableCell>
                {check() && <TableCell>Tracking Id</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {order.map((row, index) => {
                return row?.items.map((rows, ind) => {
                  console.log(row);
                  console.log(rows);
                  let date = new Date(row.Order_date);
                  let currentdate = new Date();

                  const diffInMs = Math.abs(date - currentdate);
                  const diffInDays = Math.ceil(
                    diffInMs / (1000 * 60 * 60 * 24)
                  );
                  return (
                    <Row
                      key={rows.item + index}
                      row={row}
                      order={order}
                      rows={rows}
                      index={ind}
                      indexx={index}
                      setorder={setorder}
                    />
                  );
                });
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </>
  );
};

function Row(props) {
  var { row, index, rows, indexx, setorder, order } = { ...props };
  console.log(row, index, rows);
  const [commentsndbtn, setCommentsndbtn] = useState(false);
  const [ratesndbtn, setRatesndbtn] = useState(false);
  useEffect(() => {
    if (rows.ratings_id?.rating) {
      setRatting({ ...ratting, rating: rows.ratings_id.rating });
      setRatesndbtn(true);
    }
    if (rows.Comment_id?.comment) {
      setCommentt({ ...commentt, comment: rows.Comment_id.comment });
      setCommentsndbtn(true);
    }
  }, [props]);

  const [open, setOpen] = React.useState(false);

  const [ratting, setRatting] = useState({
    rating: 0,
    Order_Id: '',
    varientId: '',
    productId: '',
    item_Id: '',
  });
  const [commentt, setCommentt] = useState({
    comment: '',
    Order_Id: '',
    varientId: '',
    productId: '',
    item_id: '',
  });

  const [cancelreason, setCancelreason] = useState({
    Cancel_reason: '',
    Order_Id: '',
    varientId: '',
    productId: '',
    item_Id: '',
  });

  const [returnreason, setReturnreason] = useState({
    Return_reason: '',
    Return_reasonimg: '',
    Order_Id: '',
    varientId: '',
    productId: '',
    item_Id: '',
  });
  const ratesubmit = (Order_Id, productId, varientId, item_Id) => {
    axios
      .post('/public/rating', {
        ratting,
        Order_Id,
        productId,
        varientId,
        item_Id,
      })
      .then((res) => {
        setRatesndbtn(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const commentsubmit = (Order_Id, productId, varientId, item_Id) => {
    axios
      .post('/public/comment', {
        commentt,
        Order_Id,
        productId,
        varientId,
        item_Id,
      })
      .then((res) => {
        setCommentsndbtn(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [cancelerr, setCancelerr] = useState('');
  const cancelvalidate = (data) => {
    console.log(data);

    if (data.Cancel_reason.length === 0) {
      return setCancelerr('please enter your reason');
    } else {
      setCancelerr('');
    }

    return true;
  };
  const cancelordersubmit = (Order_Id, productId, varientId, item_Id) => {
    console.log(Order_Id, productId, varientId, item_Id);

    if (cancelvalidate(cancelreason) === true) {
      axios
        .post('/public/cancelorder', {
          cancelreason,
          Order_Id,
          productId,
          varientId,
          item_Id,
        })
        .then((res) => {
          let temp = [...order];

          temp[indexx].items[index].Cancel_Status = true;

          setorder([...temp]);
          setCancelreason({ ...cancelreason, Cancel_reason: '' });

          setCancelerr('');
          document
            .getElementById(`awsedefvevnjkkdsnvjsdnvjknsd${index}kk${indexx}`)
            .click();
          notify(' Reason updated', true);
        })
        .catch((err) => {
          notify('Something went wrong', false);
          console.log(err);
        });
    }
  };
  const [reasonerr, setReasonerr] = useState('');
  const reasonvalidate = (data) => {
    console.log(data);

    if (data.Return_reason.length === 0) {
      return setReasonerr('please enter your reason');
    } else {
      setReasonerr('');
      if (img.length === 0) {
        return setReasonerr('please upload an image');
      }
    }

    return true;
  };
  const [img, setimg] = useState([]);

  const returnordersubmit = (Order_Id, productId, varientId, item_Id) => {
    console.log(returnreason);

    var formdata = new FormData();
    console.log(img);
    if (reasonvalidate(returnreason) === true) {
      img?.map((elem) => {
        formdata.append('file', elem);
      });

      var dataSend = JSON.stringify({
        returnreason,
        Order_Id,
        productId,
        varientId,
        item_Id,
      });
      formdata.append('data', dataSend);
      axios
        .post('/public/returnorder', formdata, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((res) => {
          let temp = [...order];
          temp[indexx].items[index].Return_Status = true;

          setorder([...temp]);
          setReturnreason({ ...returnreason, Return_reason: '' });
          setReasonerr('');

          document
            .getElementById(`bwsedefvevnjkkdsnvjsdnvjknsd${index}kk${indexx}`)
            .click();
          notify(' Reason updated', true);
        })
        .catch((err) => {
          notify('Something went wrong', false);
          console.log(err);
        });
    }
  };

  const fun = (index) => {
    var temp = [...img];
    temp.splice(index, 1);
    setimg([...temp]);
  };
  const [diffInDays, setdiffInDays] = useState();
  useEffect(() => {
    let date = new Date(row.Order_date);
    let currentdate = new Date();

    const diffInMs = Math.abs(date - currentdate);
    setdiffInDays(Math.ceil(diffInMs / (1000 * 60 * 60 * 24)));
    console.log(diffInDays);
    console.log(currentdate, date);
  }, []);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {rows?.name}
        </TableCell>
        <TableCell>{moment(row?.Order_date).format('MMMM Do YYYY')}</TableCell>
        <TableCell>
          {/* {moment(row?.PaymentDate).format('MMMM Do YYYY, h:mm:ss a')} */}
          {rows?.Status === 'delivered' ? (
            <>{moment(rows?.DeliveryDate).format('MMMM Do YYYY')}</>
          ) : (
            <>
              <span className="invisible">hlo </span>-
            </>
          )}
        </TableCell>
        <TableCell>{row?.Order_Id}</TableCell>
        <TableCell className="text-uppercase">
          {rows.Status === 'new' && diffInDays > 1 ? (
            <span className="text-danger">pending</span>
          ) : (
            rows?.Status
          )}
        </TableCell>
        <TableCell>
          {rows.Status === 'dispatched' || rows.Status === 'delivered' ? (
            <>{row.Tracking_Id ? <>{row.Tracking_Id}</> : <></>}</>
          ) : (
            <></>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <div
                className="row"
                style={{ width: '100vw', paddingRight: '18px' }}
              >
                <div className="col-12 col-md-auto d-flex justify-content-center align-items-center">
                  <img
                    src={`/img/${row?.Cart_Id.Items[index]?.varientId?.varentCode}.jpg`}
                    style={{
                      width: '200px',
                      height: '200px',
                      objectFit: 'cover',
                    }}
                    className="img-fluid rounded-3"
                    alt="VIPAR"
                  />
                </div>
                <div className="col-12 col-md-8 mt-2">
                  <h1 style={{ whiteSpace: 'pre' }}>
                    {rows?.name}&nbsp;&nbsp;&nbsp;
                    {rows?.vname}
                  </h1>
                  <div>
                    Quantity&nbsp;:&nbsp;{rows?.qty}
                    <br />
                    Price&nbsp;: ₹ {rows?.price * rows.qty}
                  </div>
                  {(rows.Status === 'new' || rows.Status === 'confirmed') &&
                  rows.Cancel_Status === false ? (
                    <>
                      <div className="mt-5">
                        <button
                          className="btn text-white"
                          style={{ backgroundColor: '#012060' }}
                          data-bs-toggle="modal"
                          data-bs-target={
                            '#cancelModalbtn' + row.Order_Id + index
                          }
                        >
                          Cancel Order
                        </button>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="rating mt-3">
                    {rows.Status === 'returned' ||
                    rows.Status === 'cancelled' ? (
                      <></>
                    ) : (
                      rows.Status === 'delivered' && (
                        <>
                          <h6 className="mb-1">Rating</h6>
                          {ratesndbtn ? (
                            <StarRatings
                              rating={ratting.rating}
                              isSelectable={true}
                              starRatedColor="gold"
                              starHoverColor="gold"
                              starDimension="30px"
                              starSpacing="5px"
                              numberOfStars={5}
                              name="rating"
                            />
                          ) : (
                            <StarRatings
                              rating={ratting.rating}
                              isSelectable={true}
                              starRatedColor="gold"
                              starHoverColor="gold"
                              changeRating={(e) => {
                                setRatting({ ...ratting, rating: e });
                              }}
                              starDimension="30px"
                              starSpacing="5px"
                              numberOfStars={5}
                              name="rating"
                            />
                          )}
                          {ratesndbtn ? (
                            <></>
                          ) : (
                            ratting.rating >= 1 && (
                              <button
                                className="btn btn-primary btnoutt  ms-5"
                                onClick={(e) =>
                                  ratesubmit(
                                    row.Order_Id,
                                    row.Cart_Id.Items[0].productId,
                                    row.Cart_Id.Items[0].varientId,
                                    rows.item
                                  )
                                }
                              >
                                SEND
                              </button>
                            )
                          )}
                        </>
                      )
                    )}
                  </div>{' '}
                </div>
                <div className=" col-12 mt-3">
                  {rows.Status === 'returned' || rows.Status === 'cancelled' ? (
                    <></>
                  ) : (
                    rows.Status === 'delivered' && (
                      <>
                        <h6>Review</h6>
                        <div className="">
                          <textarea
                            className="form-control my-1    "
                            id="exampleFormControlTextarea1"
                            rows="3"
                            placeholder="Enter Your Review Here"
                            value={commentt.comment}
                            disabled={commentsndbtn}
                            onChange={(e) =>
                              setCommentt({
                                ...commentt,
                                comment: e.target.value,
                              })
                            }
                          ></textarea>
                        </div>
                        <div>
                          {commentsndbtn ? (
                            <></>
                          ) : (
                            commentt.comment.length >= 1 && (
                              <button
                                className="btn btn-primary mt-2 btnoutt  float-end"
                                onClick={(e) =>
                                  commentsubmit(
                                    row.Order_Id,
                                    row.Cart_Id.Items[0].productId,
                                    row.Cart_Id.Items[0].varientId,
                                    rows.item
                                  )
                                }
                              >
                                SEND
                              </button>
                            )
                          )}
                        </div>
                      </>
                    )
                  )}
                </div>
                <div className="row my-2 ">
                  <div className="col-auto col-md-12 text-center ">
                    {rows.Status === 'delivered' &&
                    rows?.Return_Status === false ? (
                      <>
                        <button
                          className="btn text-white "
                          style={{ backgroundColor: '#012060' }}
                          data-bs-toggle="modal"
                          data-bs-target={
                            '#returnModalbtn' + row.Order_Id + index
                          }
                        >
                          Return Order
                        </button>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {/* <//cancel modal> */}
      <div
        class="modal fade"
        id={'cancelModalbtn' + row.Order_Id + index}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Cancel order
              </h5>
              <button
                id={`awsedefvevnjkkdsnvjsdnvjknsd${index}kk${indexx}`}
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div class="modal-body">
              <label className="">Reason *</label>

              <textarea
                className="form-control mt-2"
                rows={5}
                value={cancelreason.Cancel_reason}
                onChange={(e) =>
                  setCancelreason({
                    ...cancelreason,
                    Cancel_reason: e.target.value,
                  })
                }
              >
                {cancelreason.Cancel_reason}
              </textarea>
            </div>
            <p className="text-danger">&nbsp;&nbsp;{cancelerr}</p>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                onClick={(e) =>
                  cancelordersubmit(
                    row.Order_Id,
                    row.Cart_Id.Items[index].productId,
                    row.Cart_Id.Items[index].varientId,
                    rows.item
                  )
                }
              >
                SHOOT
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <//cancel modal> */}

      {/* <//return modal> */}
      <div
        class="modal fade"
        id={'returnModalbtn' + row.Order_Id + index}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Return Order
              </h5>
              <button
                id={`bwsedefvevnjkkdsnvjsdnvjknsd${index}kk${indexx}`}
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <label className="">Reason *</label>

              <textarea
                className="form-control mt-2"
                onChange={(e) =>
                  setReturnreason({
                    ...returnreason,
                    Return_reason: e.target.value,
                  })
                }
                value={returnreason.Return_reason}
                rows={5}
              ></textarea>
              <div className="ps-3 pt-3">
                <div className="row g-3">
                  {img.map((ele, index) => {
                    console.log(ele);
                    return (
                      <div className="col-12 col-md-6 col-lg-4">
                        <IoIosCloseCircleOutline
                          className="d-flex float-end"
                          onClick={(e) => fun(index)}
                        />
                        <img
                          className="img-fluid"
                          src={URL.createObjectURL(ele)}
                          style={{
                            width: '100%',
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <input
                class="form-control mt-3"
                type="file"
                id="formFileMultiple"
                accept="image/*"
                onChange={(e) => setimg([...img, ...e.target.files])}
                multiple
              />
            </div>
            <p className="text-danger">&nbsp;&nbsp;{reasonerr}</p>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                onClick={(e) => {
                  returnordersubmit(
                    row.Order_Id,
                    row.Cart_Id.Items[index].productId,
                    row.Cart_Id.Items[index].varientId,
                    rows.item
                  );
                }}
              >
                SHOOT
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <//return modal> */}
    </React.Fragment>
  );
}
