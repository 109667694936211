import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Footer } from '../Footer/Footer';
import { NavbarVipar } from '../NavbarVipar/NavbarVipar';
import { Navviparcasa } from './Navviparcasa';
import { notify } from '../../App';
import './Viparcasa.css';
import { Viparcasaarchitects } from './Viparcasaarchitects';
export const Viparcasa = () => {
  const [getin, setgetin] = useState({
    Name: '',
    State: '',
    City: '',
    ContactNumber: '',
    Requirement: '',
  });

  const [submitt, setsubmitt] = useState();

  const [err, seterr] = useState();
  let validation = () => {
    if (getin.Name.length === 0) {
      return seterr('Enter Your Name');
    }
    if (getin.State.length === 0) {
      return seterr('Enter Your State');
    }
    if (getin.City.length === 0) {
      return seterr('Enter Your City');
    }
    if (getin.ContactNumber.length === 0) {
      return seterr('Enter Your Contact Number');
    }
    if (getin.ContactNumber.length > 13 || getin.ContactNumber.length < 10) {
      return seterr('Enter a Valid Contact Number');
    }
    if (getin.Requirement.length === 0) {
      return seterr('Enter Your Requirement');
    }
    seterr('');
    return true;
  };

  useEffect(() => {
    if (submitt) {
      validation();
    }
  }, [getin]);

  const submit = () => {
    setsubmitt(true);
    if (validation() === true) {
      axios
        .post('/viparcasa/viparcasaget', getin)
        .then((res) => {
          notify('Successfully Entered', true);
          setsubmitt(false);

          setgetin({
            Name: '',
            State: '',
            City: '',
            ContactNumber: '',
            Requirement: '',
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      <div id="fot">
        <section className="bgvipar d-none d-md-block">
          <div className="container w-100">
            <div className="   py-2 py-md-3 px-2">
              {/* <p
                style={{ fontSize: '15px', fontFamily: 'calibri' }}
                className="m-0  text-light invisible "
              >
                We are an online retail store for unique, innovative accessories
                and articles, and exquisite art including
                <span
                  className="vipar"
                  style={{ fontSize: '22px', color: '#99d050' }}
                >
                  &nbsp;Vipar
                </span>
                ‘s own creations.
              </p> */}
              <div className="pt-3 fdd text-light">
                The architecture consultancy and housing development wing of
                <span
                  className="vipar"
                  style={{ fontSize: '22px', color: '#99d050' }}
                >
                  &nbsp;Vipar&nbsp;
                </span>{' '}
                <sup>®</sup>.
              </div>

              <div
                className="text-light   py-1"
                style={{
                  fontFamily: 'calibri',
                  // zIndex: 99999,
                  position: '',
                }}
              >
                <div className="fdd">
                  <span className="fdd" style={{ fontSize: '30px' }}>
                    C
                  </span>
                  oncrete-less{' '}
                  <span className="fdd" style={{ fontSize: '30px' }}>
                    A
                  </span>
                  nd{' '}
                  <span className="fdd" style={{ fontSize: '30px' }}>
                    S
                  </span>
                  ustainable{' '}
                  <span className="fdd" style={{ fontSize: '30px' }}>
                    A
                  </span>
                  rchitecture
                </div>
              </div>
              <p
                className="text-light fdd pt-2 pb-3"
                style={{ fontFamily: '' }}
              >
                A Biophilic and Biomorphic approach to blend Architecture with
                Nature.
              </p>

              {/* <p
                className="text-dark "
                style={{ fontFamily: 'calibri' }}
              >
               A Biophilic and Biomorphic approach to blend Architecture with Nature.
              </p> */}
            </div>
          </div>
        </section>

        <section className="pb-5 px-2 d-none d-md-block">
          <div className="container">
            <div className="" style={{ paddingTop: '70px' }}></div>
          </div>
        </section>
        <section className="bgvipar d-block d-md-none">
          <div className="container w-100">
            <div className=" py-2 py-md-3 ">
              {/* <p
                style={{ fontSize: '15px', fontFamily: 'calibri' }}
                className="m-0 text-light invisible"
              >
                We are an online retail store for unique, innovative accessories
                and articles, and exquisite art including
                <span
                  className="vipar"
                  style={{ fontSize: '22px', color: '#99d050' }}
                >
                  &nbsp;Vipar
                </span>
                ‘s own creations.
              </p> */}

              <p
                className="text-light ps-3 mt-4 mb-5 nm"
                style={{
                  fontFamily: 'calibri',
                  // zIndex: 99999,
                }}
              >
                <div className="pt-3 fdd">
                  The architecture consultancy and housing development wing of
                  <span
                    className="vipar"
                    style={{ fontSize: '22px', color: '#99d050' }}
                  >
                    &nbsp;Vipar&nbsp;
                  </span>{' '}
                  <sup>®</sup>.
                </div>{' '}
                <div className="py-1 fdd" style={{}}>
                  <span className="fdd" style={{ fontSize: '30px' }}>
                    C
                  </span>
                  oncrete-less
                  <span className="ps-2 fdd" style={{ fontSize: '30px' }}>
                    A
                  </span>
                  nd
                  <span className="ps-2 fdd" style={{ fontSize: '30px' }}>
                    S
                  </span>
                  ustainable{' '}
                  <span className="ps-2 fdd" style={{ fontSize: '30px' }}>
                    A
                  </span>
                  rchitecture
                </div>
                <p className="text-light fdd mt-2">
                  A Biophilic and Biomorphic approach to blend Architecture with
                  Nature.
                </p>
              </p>

              {/* <p
                className="text-light invisible"
                style={{ fontFamily: "calibri" }}
              >
                {" "}
                Type of Entity : E-commerce{" "}
              </p> */}
            </div>
          </div>
        </section>
        <div className="container">
          <section className="pb-5  d-block d-md-none">
            <div className="container">
              <div className="" style={{ paddingTop: '55px' }}></div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
