import { fontWeight } from '@mui/system';
import React from 'react';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { Footer } from '../../Footer/Footer';

import './Mayihelpyou.css';
export const Mayihelpyou = () => {
  const modal = useSelector((state) => {
    return state.modal.value;
  });
  return (
    <>
      <hr
        className="mt-0"
        style={{
          color: 'rgb(1, 32, 96)',
          opacity: '1',
          borderTop: '3px solid',
        }}
      />
      <div className="footer fo" style={{ fontFamily: "'FootlightMTLight" }}>
        <div className="container ">
          <center className="py-4">
            <h4 className="text-dark">
              <b>HOW MAY WE HELP YOU ?</b>
            </h4>
          </center>

          <div className="" id="coll">
            {' '}
            <div className="card">
              <div className="card-body">
                <div className="row mt-3">
                  <div className="col-auto">
                    <span
                      style={{ fontSize: '18px' }}
                      className="text-center text-dark"
                    >
                      Q.
                    </span>
                  </div>
                  <div className="col">
                    <span style={{ fontSize: '18px' }} className=" text-dark">
                      Who is your
                      <span style={{ fontWeight: '800' }}>
                        {' '}
                        Logistics Partner
                      </span>{' '}
                      ?{' '}
                    </span>
                  </div>
                  <div className="col-2 ">
                    <h5 className="text-center">
                      <button
                        style={{
                          border: 'none',
                          backgroundColor: 'transparent',
                        }}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse11"
                        aria-expanded="false"
                        aria-controls="collapse11"
                      >
                        <AiOutlinePlusCircle
                          name="add-circle-outline"
                          size="25px"
                          className="black"
                        ></AiOutlinePlusCircle>
                      </button>
                    </h5>
                  </div>
                </div>
                <div
                  className="collapse"
                  data-bs-parent="#coll"
                  id="collapse11"
                >
                  <div className="card card-body">
                    <div className="row">
                      <div className="col-auto">
                        <span
                          style={{ fontSize: '18px' }}
                          className="ms-2 ps-1 text-dark"
                        >
                          A.
                        </span>
                      </div>
                      <div className="col ps-0">
                        <span
                          className=" text-dark"
                          style={{ fontSize: '20px' }}
                        >
                          India post
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row mt-3">
                  <div className="col-auto">
                    <span
                      style={{ fontSize: '18px' }}
                      className="text-center text-dark"
                    >
                      Q.
                    </span>
                  </div>
                  <div className="col">
                    <span className=" text-dark" style={{ fontSize: '18px' }}>
                      After placing an order, how do we{' '}
                      <span style={{ fontWeight: '800' }}>Track</span> the{' '}
                      <span style={{ fontWeight: '800' }}>Status</span> of our
                      order ?{' '}
                    </span>
                  </div>
                  <div className="col-2 ">
                    <h5 className="text-center">
                      <button
                        style={{
                          border: 'none',
                          backgroundColor: 'transparent',
                        }}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse1"
                        aria-expanded="false"
                        aria-controls="collapse1"
                      >
                        <AiOutlinePlusCircle
                          name="add-circle-outline"
                          className="black"
                          size="25px"
                        ></AiOutlinePlusCircle>
                      </button>
                    </h5>
                  </div>
                </div>
                <div className="collapse" data-bs-parent="#coll" id="collapse1">
                  <div className="card card-body">
                    <div className="row">
                      <div className="col-auto">
                        <span
                          className="ms-2 ps-1  text-dark"
                          style={{ fontSize: '18px' }}
                        >
                          A.
                        </span>
                      </div>
                      <div
                        className="col ps-0 text-dark"
                        style={{ fontSize: '18px' }}
                      >
                        <span>
                          Products are usually, under normal circumstances,
                          dispatched within 1-3 business days via speed post.
                          <br />
                          As soon as your product is dispatched via our
                          logistics partner India post, you will be sent a
                          dispatch notification with the Article/Consignment no.
                          to the email id you have provided us while placing the
                          order. You can log in to www.indiapost.gov.in and
                          enter your Artcile no. in the relevant fields to track
                          your order.
                        </span>
                        <br />
                        <br />
                        To View the Order Tracking Id, follow these steps :
                        <br /> 1. Log in to your Vipar account. <br />
                        2. Go to 'New Order Status' on your Shopping Bag icon.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row mt-3">
                  <div className="col-auto">
                    <span
                      style={{ fontSize: '18px' }}
                      className="text-center text-dark"
                    >
                      Q.
                    </span>
                  </div>
                  <div className="col">
                    <span className=" text-dark" style={{ fontSize: '18px' }}>
                      Can we <span style={{ fontWeight: '800' }}>Return</span>{' '}
                      our order ?{' '}
                    </span>
                  </div>
                  <div className="col-2 ">
                    <h5 className="text-center">
                      <button
                        style={{
                          border: 'none',
                          backgroundColor: 'transparent',
                        }}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse2"
                        aria-expanded="false"
                        aria-controls="collapse2"
                      >
                        <AiOutlinePlusCircle
                          name="add-circle-outline"
                          size="25px"
                          className="black"
                        ></AiOutlinePlusCircle>
                      </button>
                    </h5>
                  </div>
                </div>
                <div className="collapse" data-bs-parent="#coll" id="collapse2">
                  <div className="card card-body">
                    <div className="row">
                      <div className="col-auto">
                        <span
                          style={{ fontSize: '18px' }}
                          className="ms-2 ps-1 text-dark"
                        >
                          A.
                        </span>
                      </div>
                      <div
                        className="col ps-0 text-dark"
                        style={{ fontSize: '18px' }}
                      >
                        <span>
                          All our products are{' '}
                          <b style={{ fontWeight: '800' }}>non</b>-returnable.
                          However, products with manufacturing defects can be
                          exchanged on the basis of strict terms and conditions.
                          <br />
                          Please read our{' '}
                          <a
                            className="as"
                            href="#"
                            onClick={(e) => modal.epmodal()}
                            data-bs-dismiss="modal"
                          >
                            exchange policies
                          </a>{' '}
                          to know more about this.
                          <br />
                          <br />
                          To Return the Order, follow these steps : <br />
                          1. Log in to your Vipar account.
                          <br /> 2. Go to 'New Order Status' on your Shopping
                          Bag icon.
                          <br /> 3. Click on the 'Return' button.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row mt-3">
                  <div className="col-auto">
                    <span
                      className="text-center text-dark"
                      style={{ fontSize: '18px' }}
                    >
                      Q.
                    </span>
                  </div>
                  <div className="col">
                    <span className=" text-dark" style={{ fontSize: '18px' }}>
                      Can we <span style={{ fontWeight: '800' }}>Cancel</span>{' '}
                      our order ?{' '}
                    </span>
                  </div>
                  <div className="col-2">
                    <h5 className="text-center">
                      <button
                        style={{
                          border: 'none',
                          backgroundColor: 'transparent',
                        }}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse3"
                        aria-expanded="false"
                        aria-controls="collapse3"
                      >
                        <AiOutlinePlusCircle
                          name="add-circle-outline"
                          size="25px"
                          className="black"
                        ></AiOutlinePlusCircle>
                      </button>
                    </h5>
                  </div>
                </div>
                <div className="collapse" data-bs-parent="#coll" id="collapse3">
                  <div className="card card-body">
                    <div className="row">
                      <div className="col-auto">
                        <span
                          style={{ fontSize: '18px' }}
                          className="ms-2 ps-1 text-dark"
                        >
                          A.
                        </span>
                      </div>
                      <div
                        className="col ps-0  text-dark"
                        style={{ fontSize: '18px' }}
                      >
                        <span>
                          Yes. ONLY if you cancel the order before we dispatch
                          it from our warehouse.
                          <br />
                          <br />
                          To Cancel the Order, follow these steps :<br />
                          1. Log in to your Vipar account.
                          <br />
                          2. Go to 'New Order Status' on your Shopping Bag icon.
                          <br />
                          3. Click on the 'Cancel' button.
                          <br />
                          <br />
                          Once the order is booked for shipping with our
                          logistics partner, and then you cancel the order, you
                          will have to bear both the to-and-fro shipping costs.
                          And, the parcel box must be returned STRICTLY without
                          the VIPAR seal tape tampered with or damaged or
                          broken. Refund (after deduction of to-and-fro shipping
                          costs) will be initiated only after we receive the
                          parcel box in the manner we have specified above and
                          we are assured that the product is unused.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row mt-3">
                  <div className="col-auto">
                    <span
                      style={{ fontSize: '18px' }}
                      className="text-center text-dark"
                    >
                      Q.
                    </span>
                  </div>
                  <div className="col">
                    <span className=" text-dark" style={{ fontSize: '18px' }}>
                      Do we get a{' '}
                      <span style={{ fontWeight: '800' }}>Tax Invoice</span> for
                      our purchase ?{' '}
                    </span>
                  </div>
                  <div className="col-2 ">
                    <h5 className="text-center">
                      <button
                        style={{
                          border: 'none',
                          backgroundColor: 'transparent',
                        }}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse5"
                        aria-expanded="false"
                        aria-controls="collapse5"
                      >
                        <AiOutlinePlusCircle
                          name="add-circle-outline"
                          size="25px"
                          className="black"
                        ></AiOutlinePlusCircle>
                      </button>
                    </h5>
                  </div>
                </div>
                <div className="collapse" data-bs-parent="#coll" id="collapse5">
                  <div className="card card-body">
                    <div className="row">
                      <div className="col-auto col-md-1">
                        <span
                          className="ms-2 ps-1  text-dark"
                          style={{ fontSize: '18px' }}
                        >
                          A.
                        </span>
                      </div>
                      <div
                        className="col ps-0  text-dark"
                        style={{ fontSize: '20px' }}
                      >
                        <span>
                          Of course. However, the Invoice will be available only
                          as a soft copy. You can View and Download your tax
                          invoice available on 'Invoices' on your Shopping Bag
                          icon.
                          <br />
                          <br />
                          Please be aware that your Final Bill / Tax Invoice (in
                          case of product cancellation refunds) will be Visible
                          to you only after 1 week post order delivery.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row mt-3">
                  <div className="col-auto">
                    <span
                      className="text-center text-dark"
                      style={{ fontSize: '18px' }}
                    >
                      Q.
                    </span>
                  </div>
                  <div className="col">
                    <span className=" text-dark" style={{ fontSize: '18px' }}>
                      Can orders to two or more different{' '}
                      <span style={{ fontWeight: '800' }}>
                        Shipping Addresses
                      </span>{' '}
                      be&nbsp;
                      <span style={{ fontWeight: '800' }}>Clubbed</span> ?{' '}
                    </span>
                  </div>
                  <div className="col-2">
                    <h5 className="text-center">
                      <button
                        style={{
                          border: 'none',
                          backgroundColor: 'transparent',
                        }}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse12"
                        aria-expanded="false"
                        aria-controls="collapse3"
                      >
                        <AiOutlinePlusCircle
                          name="add-circle-outline"
                          size="25px"
                          className="black"
                        ></AiOutlinePlusCircle>
                      </button>
                    </h5>
                  </div>
                </div>
                <div
                  className="collapse"
                  data-bs-parent="#coll"
                  id="collapse12"
                >
                  <div className="card card-body">
                    <div className="row">
                      <div className="col-auto">
                        <span
                          style={{ fontSize: '18px' }}
                          className="ms-2 ps-1 text-dark"
                        >
                          A.
                        </span>
                      </div>
                      <div
                        className="col ps-0  text-dark"
                        style={{ fontSize: '18px' }}
                      >
                        <span>
                          No. Each Order must be to only one shipping address.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3 ">
            <div className="col"></div>
          </div>
          <div className="row mt-5 "></div>
        </div>
      </div>
    </>
  );
};
