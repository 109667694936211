import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

export const Logocenter = ({ link }) => {
  const [first, setFirst] = useState(true);
  return (
    <div
      onClick={() => setFirst((e) => !e)}
      className={`${first ? '' : 'vipar-top'}`}
    >
      {first === false ? (
        <>
          <AiOutlineClose size={30} className="loko" />
        </>
      ) : (
        <></>
      )}
      <div>
        <img className="img-fluid" src={link} alt=""></img>
      </div>
    </div>
  );
};
