import React from 'react';
import { Footer } from '../Footer/Footer';
import './Viparcare.css';
import { Viparcarenav } from './Viparcarenav';
import { useLocation } from 'react-router-dom';

export const Viparcarehome = () => {
  let location = useLocation();

  return (
    <>
      <div>
        <Viparcarenav />
      </div>
      <div id="fooo">
        <section className="bgvipar d-none d-md-block">
          <div className="container w-100">
            <div className="  d-flex justify-content-center align-items-center py-2 py-md-3 px-2">
              <p
                style={{
                  fontSize: '15px',
                  fontFamily: 'calibri',
                  textAlign: 'left',
                }}
                className="m-0 mt-3 text-light  "
              >
                <div className="fdd">
                  The social welfare and philanthropic wing of{' '}
                  <span
                    className="vipar "
                    style={{
                      fontSize: '22px',
                      color: '#99d050',
                    }}
                  >
                    Vipar&nbsp;
                  </span>{' '}
                  <sup>®</sup>.
                </div>
                <div className="fdd">
                  <span className="fdd" style={{ fontSize: '30px' }}>
                    C
                  </span>
                  ompassion{' '}
                  <span className="fdd" style={{ fontSize: '30px' }}>
                    A
                  </span>
                  nd{' '}
                  <span className="fdd" style={{ fontSize: '30px' }}>
                    R
                  </span>
                  eal{' '}
                  <span className="fdd" style={{ fontSize: '30px' }}>
                    E
                  </span>
                  mpathy
                </div>
                <div className="pt-1 pb-3 fdd">
                  A non-profit, benevolent arm of{' '}
                  <span
                    className="vipar"
                    style={{ fontSize: '22px', color: '#99d050' }}
                  >
                    Vipar
                  </span>
                  &nbsp;which supports stray and abandoned animals, homeless and
                  abandoned humans, destitutes&nbsp;and underprivileged,
                  physically challenged, differently-abled, rape, acid-attack
                  survivors, and a few other social welfare schemes as well.
                </div>
              </p>
              <br />
              {/* <p
                style={{ fontSize: "15px", fontFamily: "calibri" }}
                className="m-0 mt-2   text-light  invisible"
              >
                A non-profit, benevolent arm of{" "}
                <span
                  className="vipar"
                  style={{ fontSize: "22px", color: "#99d050" }}
                >
                  Vipar
                </span>
                &nbsp; which supports stray and abandoned animals, destitutes,
                homeless and abandoned humans, physically-challenged,
                differently-abled, rape and acid-attack survivors.
              </p> */}
            </div>
          </div>
        </section>
        <div className="py-5 d-none d-md-block">
          <div className="container  d-none d-md-block">
            <span
              className="vipar"
              style={{ fontSize: '22px', color: '#99d050' }}
            >
              Vipar
            </span>
            &nbsp;&nbsp;<span className="verticals">CARE&nbsp;</span>is funded
            only by a substantial percentage of Proceeds from the Commercial
            Verticals of
            <span
              className="vipar"
              style={{ fontSize: '22px', color: '#99d050' }}
            >
              &nbsp;Vipar
            </span>
            . We do not accept direct funding from external sources.
            <br />
            <br />
            Each and every Customer / Subscriber of our Services and Products is
            Indirectly contributing towards the welfare of those in need of
            support.
            <br />
            <br />
            We are Only an Intermediary.
            <br />
            <br />
          </div>
        </div>
        <section className="bgvipar d-block d-md-none">
          <div className="container w-100">
            <div className="  d-flex justify-content-center align-items-center py-2 py-md-3 px-2 ps-3">
              <p
                className="text-light mt-4 "
                style={{
                  textAlign: 'left',
                }}
              >
                <div className="fdd pt-1">
                  The social welfare and philanthropic wing of{' '}
                  <span
                    className="vipar "
                    style={{
                      fontSize: '22px',
                      color: '#99d050',
                    }}
                  >
                    Vipar&nbsp;
                  </span>{' '}
                  <sup>®</sup>.
                </div>
                <div className=" py-1 fdd">
                  <span className="fdd" style={{ fontSize: '30px' }}>
                    C
                  </span>
                  ompassion{' '}
                  <span className="fdd" style={{ fontSize: '30px' }}>
                    A
                  </span>
                  nd{' '}
                  <span className="fdd" style={{ fontSize: '30px' }}>
                    R
                  </span>
                  eal{' '}
                  <span className="fdd" style={{ fontSize: '30px' }}>
                    E
                  </span>
                  mpathy
                </div>
                <div className="pt-1 pb-3 fdd">
                  A non-profit, benevolent arm of{' '}
                  <span
                    className="vipar"
                    style={{ fontSize: '22px', color: '#99d050' }}
                  >
                    Vipar
                  </span>
                  &nbsp;which supports stray and abandoned animals, homeless and
                  abandoned humans, destitutes&nbsp;and underprivileged,
                  physically challenged, differently-abled, rape, acid-attack
                  survivors, and a few other social welfare schemes as well.
                </div>
              </p>
            </div>
          </div>
        </section>
        <div className="py-5  d-block d-md-none ps-3">
          <div
            className="container d-block d-md-none"
            // style={{
            //   overflow: " hidden",
            //   width: "100vw",
            // }}
          >
            <span
              className="vipar"
              style={{ fontSize: '22px', color: '#99d050' }}
            >
              Vipar
            </span>
            &nbsp;&nbsp;<span className="verticals">CARE&nbsp;</span>is funded
            only by a substantial percentage of Proceeds from the Commercial
            Verticals of
            <span
              className="vipar"
              style={{ fontSize: '22px', color: '#99d050' }}
            >
              &nbsp;Vipar
            </span>
            . We do not accept direct funding from external sources.
            <br />
            <br />
            Each and every Customer / Subscriber of our Services and Products is
            Indirectly contributing towards the welfare of those in need of
            support.
            <br />
            <br />
            We are Only an Intermediary.
            <br />
            <br />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
