import React from "react";
import { ImageUpload } from "../ImageUpload/ImageUpload";

export const Employeregistration = () => {
  return (
    <section className="" style={{ backgroundColor: "#012060" }}>
      <div className="  h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div
              className="card shadow-2-strong"
              style={{ borderRadius: "1rem" }}
            >
              <div className="card-body p-5 ">
                <center>
                  <h3 className="mb-5">Employee Registration</h3>
                </center>
                <div className="row">
                    <center>
                      <ImageUpload />
                      <label className="labels">Upload your Photo</label>
                    </center>
                  </div>
                    <div className="form-outline mb-4 mt-5">
                      <label className="form-label" htmlFor="typeEmailX-22">
                        Vipar WING
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="typeEmailX-22"
                        className="form-control form-control-lg"
                        fdprocessedid="ydop066"
                      />
                    <div className="form-outline mb-4 mt-3">
                      <label className="form-label" htmlFor="typeEmailX-22">
                        DESIGNATION
                      </label>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected></option>
                        <option value="PROchure FIELD SALES EXECUTIVE">
                          PROchure FIELD SALES EXECUTIVE
                        </option>
                      </select>

                    <div className="form-outline mb-3 mt-3">
                      <label className="form-label" htmlFor="typeEmailX-22">
                        EMPLOYEE ID
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="typeEmailX-22"
                        className="form-control form-control-lg"
                        fdprocessedid="ydop066"
                      />
                    </div>

                    <div className="form-outline mb-3">
                      <label className="form-label" htmlFor="typeEmailX-22">
                      JOINED ON
                      </label>
                      <input
                        type="date"
                        name="name"
                        id="typeEmailX-22"
                        className="form-control form-control-lg"
                        fdprocessedid="ydop066"
                      />
                    </div>

                    <div className="form-outline mb-3">
                      <label className="form-label" htmlFor="typeEmailX-22">
                        FATHER'S NAME 
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="typeEmailX-22"
                        className="form-control form-control-lg"
                        fdprocessedid="ydop066"
                      />
                    </div>


                    <div className="form-outline mb-3">
                      <label className="form-label" htmlFor="typeEmailX-22">
                      AGE 
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="typeEmailX-22"
                        className="form-control form-control-lg"
                        fdprocessedid="ydop066"
                      />
                    </div>

                    <div className="form-outline mb-3">
                      <label className="form-label" htmlFor="typeEmailX-22">
                        BLOOD GROUP
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="typeEmailX-22"
                        className="form-control form-control-lg"
                        fdprocessedid="ydop066"
                      />
                    </div>
                    <div className="form-outline mb-3 ">
                      <label className="form-label" htmlFor="typeEmailX-22">
                        OFFICIAL CONTACT NUMBER 
                      </label>
                      <input
                        type="text"
                        maxLength={10}
                        name="name"
                        id="typeEmailX-22"
                        className="form-control form-control-lg"
                        fdprocessedid="ydop066"
                      />
                    </div>
                    <div className="form-outline mb-4 ">
                      <label className="form-label" htmlFor="typeEmailX-22">
                        REMARKS 
                      </label>
                      <textarea
                        className="form-control"
                        rows={3}
                        
                      ></textarea>
                      
                    </div>
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-primary btn-lg btn-block" 
                      type="submit"
                      fdprocessedid="i8iyob"
                    >
                      Register
                    </button>
                    </div>
                    <hr className="my-4" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
