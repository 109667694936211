import React from 'react';

import About from '../components/Vipar-chic/Vipar-chic_about/About';

function ViparChicAbout() {
  return (
    <>
      <About />
    </>
  );
}

export default ViparChicAbout;
