import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Logocenter } from '../Logocenter';

export const Navviparcasa = () => {
  let location = useLocation();

  return (
    <>
      <nav
        className="navbar navbar-expand navbar-light shadow"
        style={{ fontFamily: 'FootlightMTLight', display: 'block' }}
      >
        <div
          className="  px-4  w-100"
          style={{
            display: 'block',
            height: '122px',
            backgroundColor: 'rgb(1,32,96,1)',
          }}
        >
          <div className="">
            <div className="d-flex justify-content-between">
              <div style={{ marginTop: '20px' }}>
                <Link
                  class="navbar-brand text-success logo align-self-center"
                  to="/vipar-casa"
                >
                  {/* <img
                    className=""
                    src="/img/casacircle.png"
                    alt=""
                    height="80px"
                    width="80px"
                  /> */}
                  <div style={{ width: '80px', height: '80px' }}>
                    <Logocenter link={'/img/casacircle.png'} />
                  </div>
                </Link>
              </div>
              <div className="xn">
                <div className="pt-2">
                  <div className="mt-2  ">
                    <span
                      className="vipar transform  "
                      style={{
                        fontSize: '22px',
                        color: '#99d050',
                        paddingLeft: '11px',
                      }}
                    >
                      Vipar
                    </span>
                    <span
                      className="ps-1 text-light transform"
                      style={{
                        fontSize: '20px',
                        fontFamily: 'maiandra',
                      }}
                    >
                      &nbsp;CASA
                    </span>
                  </div>
                  <div className=" " style={{ height: '13px' }}></div>
                  <div className="mt-3">
                    {' '}
                    <span
                      className="  text-light    btnsss maiandra "
                      style={{
                        fontSize: '16px',
                        textTransform: 'none',
                      }}
                    >
                      Nature Architecture.
                    </span>
                  </div>
                </div>
              </div>
              <div className="invisible">
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container-fluid mt-2  w-100 "
          style={{ display: 'block' }}
        >
          <div className=" d-flex justify-content-center d-md-block  ">
            <div className="r" id="templatemo_main_nav">
              <div className="flex-fill">
                <div className="d-block d-md-none">
                  <ul
                    className=" navbar-nav d-flex  justify-content-center mx-auto ms-0"
                    style={{ border: 'none' }}
                  >
                    {/* <li className="nav-item ">
                      <Link
                        // to="/vipar-casa/as_architects"
                        className={`nav-link cardtextt aa  ${
                          location.pathname === '/vipar-casa/as_architects'
                            ? ' active-link-vipar'
                            : ''
                        }`}
                      >
                        DESIGN&nbsp;PROJECTS
                      </Link>
                    </li>
                    <li className="nav-item ">
                      <Link
                        // to="/vipar-casa/as_developers"
                        className={`nav-link cardtextt aa ${
                          location.pathname === '/vipar-casa/as_developers'
                            ? ' active-link-vipar'
                            : ''
                        }`}
                      >
                        HOUSING&nbsp;PROJECTS
                      </Link>
                    </li> */}
                    <li className="nav-item ">
                      <Link
                        to="/vipar-casa/enquiry"
                        className={`nav-link cardtextt aa ${
                          location.pathname === '/vipar-casa/enquiry'
                            ? ' active-link-vipar'
                            : ''
                        }`}
                      >
                        ENQUIRY
                      </Link>
                    </li>
                  </ul>
                  {/* <ul
                    className=" navbar-nav d-flex  justify-content-center mx-auto ms-0"
                    style={{ border: 'none' }}
                  >
                    <li className="nav-item ">
                      <Link className={`nav-link cardtextt aa `}>
                        LANDMARKS&nbsp;and&nbsp;LANDSCAPES
                      </Link>
                    </li>
                  </ul> */}
                  {/* <div className=" navbar-nav  " style={{ border: 'none' }}>
                    <div className=" nav-item ">
                      <Link to="" className={`nav-link cardtextt aa ss`}>
                        ARCHITECTURE
                      </Link>
                    </div>
                    <div className=" nav-item ">
                      <Link to="" className={`nav-link cardtextt aa ss`}>
                        REAL&nbsp;ESTATE
                      </Link>
                    </div>
                  </div>
                  <div className=" navbar-nav row" style={{ border: 'none' }}>
                    <div className=" nav-item d-flex justify-content-center pd ">
                      <Link to="" className={`nav-link cardtextt aa ss`}>
                        MONUMENTS&nbsp;and&nbsp;PUBLIC&nbsp;SPACES
                      </Link>
                    </div>
                  </div> */}
                </div>

                {/* //lap */}
                <div className="d-none d-md-block ">
                  <div id="nsknfkjdsnfksjn">
                    <ul
                      className="  d-flex ps-0  pt-2 justify-content-center mx-auto ms-0"
                      style={{ border: 'none' }}
                    >
                      {/* <li className="nav-item ">
                        <Link
                          // to="/vipar-casa/as_architects"
                          className={`nav-link cardtextt aa ${
                            location.pathname === '/vipar-casa/as_architects'
                              ? ' active-link-vipar'
                              : ''
                          }`}
                        >
                          DESIGN&nbsp;PROJECTS
                        </Link>
                      </li>

                      <li className="nav-item ">
                        <Link
                          // to="/vipar-casa/as_developers"
                          className={`nav-link cardtextt aa ${
                            location.pathname === '/vipar-casa/as_developers'
                              ? ' active-link-vipar'
                              : ''
                          }`}
                        >
                          HOUSING&nbsp;PROJECTS
                        </Link>
                      </li> */}
                      <li className="nav-item ">
                        <Link
                          to="/vipar-casa/enquiry"
                          className={`nav-link cardtextt aa ${
                            location.pathname === '/vipar-casa/enquiry'
                              ? ' active-link-vipar'
                              : ''
                          }`}
                        >
                          ENQUIRY
                        </Link>
                      </li>

                      <li className="nav-item ">
                        {/* <Link to="" className={`nav-link cardtextt aa `}>
                          LANDMARKS&nbsp;and&nbsp;LANDSCAPES
                        </Link> */}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
