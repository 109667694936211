import { createSlice } from '@reduxjs/toolkit';

let modalSlice = createSlice({
  name: 'modal',
  initialState: {
    value: {
      firstmodal: '',
    },
  },
  reducers: {
    addModal: (state, action) => {
      try {
        state.value.toumodal = action.payload.type.termsofuse;
        state.value.ppmodal = action.payload.type.privacypolicy;
        state.value.dpmodal = action.payload.type.deliverypolicy;
        state.value.epmodal = action.payload.type.exchangepolicy;
      } catch (err) {}
    },
  },
});

export const { addModal } = modalSlice.actions;
export default modalSlice.reducer;
