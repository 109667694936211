import React, { useEffect, useRef, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import moment from 'moment';
import { Button, Modal } from 'react-bootstrap';
import { keyGen, notify } from '../../../App';
import { ExistingData, InitializeCountry } from '../../Utility/Country';
import { useNavigate } from 'react-router-dom';
import { MenuItem, Select } from '@mui/material';
export const Countryview = () => {
  const [index, setIndex] = useState();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (params) => setShow(true);
  const [view, setView] = useState({
    Name: '',
    Country: '',
    City: '',
    State: '',
  });

  // let countryref = useRef();
  // let stateref = useRef();
  // let cityref = useRef();

  const columns = [
    { field: 'id', headerName: 'No', width: 50 },
    {
      field: 'Country',
      headerName: 'Country',
      width: 150,
      editable: true,
    },
    {
      field: 'State',
      headerName: 'State',
      width: 150,
      editable: true,
    },

    {
      field: 'Details',
      headerName: 'Details',
      sortable: false,
      width: 90,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <button
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#detailsModal"
          onClick={(e) => {
            console.log(params.row);
            setView(params.row);
          }}
        >
          Details
        </button>
      ),
    },

    {
      field: 'Edit',
      headerName: 'Edit',
      sortable: false,
      width: 90,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <button
          className="btn btn-success"
          // data-bs-toggle="modal"
          // data-bs-target={"#editmodal" + params.row.ind}
          onClick={(e) => {
            console.log(params.row);
            //   setopperins(params.row);
            // setIndex(ind);
            handleShow();
            setView(params.row);
          }}
        >
          Edit
        </button>
      ),
    },
    {
      field: 'Delete',
      headerName: 'Delete',
      sortable: false,
      width: 90,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <button
          className="btn btn-danger"
          data-bs-toggle="modal"
          data-bs-target={'#exampleModal' + params.row.ind}
          onClick={(e) => {
            console.log(params.row.ind);
          }}
        >
          Delete
        </button>
      ),
    },
  ];
  const [row, setRow] = useState([]);

  const update = () => {
    console.log('as');
    console.log(view);
    axios
      .post('/chum/chumupdate', { view })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .post('/chum/chumfind')
      .then((res) => {
        console.log(res.data);
        const newState = res.data
          .map((e, index) => {
            console.log(e);
            return {
              ...e,
              id: index + 1,
              ind: index,
            };
          })
          .filter((e) => e !== undefined);
        console.log(newState);
        setRow(newState);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    console.log(view);
  }, [view]);

  const tableStyles = {
    backgroundColor: '#f0f0f0',
  };
  const [arr, setArr] = useState([]);
  useEffect(() => {
    var array = row.map((e, index) => {
      return {
        ...e,
        id: e.id,
      };
    });
    setArr(array);
  }, [row]);

  const Deleteee = (id, ind) => {
    axios
      .post('/chum/chumdelete', { id })
      .then((res) => {
        console.log(res.data);
        let temp = [...row];
        console.log(temp);
        temp.splice(ind, 1);
        setRow([...temp]);

        notify('Successfully Deleted', true);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        notify('Something Went Wrong', false);
      });
  };

  const [selectboxchange, setselectboxchange] = useState('selectboxchange');

  const countryselectbox = () => {
    setselectboxchange(...selectboxchange);
    keyGen();
  };

  return (
    <>
      <div className="page-breadcrumb ">
        <div
          className="row align-items-center text-white"
          // style={{ backgroundColor: 'black' }}
        >
          <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
            <h4 className="page-title text-white">Country View</h4>
          </div>
          <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
            <div className="d-md-flex">
              <ol className="breadcrumb ms-auto"></ol>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          height: 400,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <DataGrid
          rows={arr}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          style={tableStyles}
          className="mt-5 pt-3 sswewe"
        />
      </div>

      {/* details modal */}
      <div
        class="modal fade"
        id="detailsModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content ">
            <div class="modal-header">
              <h5 class="modal-title px-3" id="exampleModalLabel">
                Details
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="px-3">
                {view?.Name ? (
                  <div className="">Name : {view?.Name}</div>
                ) : (
                  <></>
                )}

                <div className="">Country : {view?.Country}</div>
                <div className="">State : {view?.State}</div>
                <div className="">City : {view?.City}</div>
                {/* <div className="">
                  Created Date:{' '}
                  {moment(view?.CreatedDate).format('Do MMMM YYYY')}
                </div>
                <div className="">
                  Updated Date :{' '}
                  {moment(view?.UpdatedDate).format('Do MMMM YYYY')}{' '}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* details modal */}
      {/* edit modal */}

      {/* {row?.map((elem, index) => {
        console.log(elem)
        return (
          <>
             <div
        class="modal fade"
        id={"editmodal" + index}

        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content ">
            <div class="modal-header">
              <h5 class="modal-title px-3" id="exampleModalLabel">
                Details
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
            <div>
            <label className="mt-3">Name :</label>
            <input
              type="name"
              className="form-control mt-2"
              id="exampleFormControlInput1"
              placeholder="Name"
              value={elem?.Name}
              onChange={(e) => {
                setView({ ...view, Name: e.target.value });
              }}
            />
            <label className="mt-3">Country :</label>
            <select 
              className="form-select mt-2"
              ref={countryref}
              aria-label="Default select example"
              value={elem?.Country}
              onChange={(e) => {
                setView({ ...view, Country: e.target.value });
              }}
            > 
            </select>
            <label className="mt-3">State :</label>
            <select  
              className="form-select mt-2"
              ref={stateref}
              aria-label="Default select example"
              value={elem.State}
              onChange={(e) => {
                setView({ ...view, State: e.target.value });
              }}
            >
            </select>
            <label className="mt-3">City :</label>
            <select
              className="form-select mt-2"
              ref={cityref}
              aria-label="Default select example"
              value={elem?.City}
              onChange={(e) => {
                setView({ ...view, City: e.target.value });
              }}
            >
            </select>

            <button
              className="btn btn-primary float-end my-3 "
              onClick={(e) => {
                update();
              }}
            >
              SUBMIT
            </button>
          </div>
            </div>
          </div>
        </div>
      </div>
          </>
        )
      })} */}

      <Modal show={show} onShow={countryselectbox} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {' '}
          <Selectbox value={view} />
        </Modal.Body>
      </Modal>

      {/* edit modal */}
      {/* delete modal */}
      {row?.map((elem, index) => {
        return (
          <>
            <div
              class="modal fade"
              id={'exampleModal' + index}
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="btn-close"
                      id={'btnclose'}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body text-danger">
                    Are you sure you want to delete ?
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={(e) => {
                        Deleteee(elem._id, elem.ind);
                        // setopperins(elem.ind);
                        setIndex(elem.ind);
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
      {/* delete modal */}
    </>
  );
};

export const Selectbox = ({ value }) => {
  console.log(value);

  const [view, setView] = useState({
    Name: '',
    Country: '',
    City: '',
    State: '',
  });
  let countryref = useRef();
  let stateref = useRef();
  let cityref = useRef();

  useEffect(() => {
    InitializeCountry(countryref, stateref, cityref);

    console.log(value.Country);
    ExistingData(
      countryref,
      stateref,
      cityref,
      value.Country,
      value.State,
      value.City
    );
  }, []);

  const update = () => {
    console.log('as');
    console.log(view);
    axios
      .post('/chum/chumupdate', { view })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div>
        <label className="mt-3">Name :</label>
        <input
          type="name"
          className="form-control mt-2"
          id="exampleFormControlInput1"
          placeholder="Name"
          value={value?.Name}
          onChange={(e) => {
            setView({ ...view, Name: e.target.value });
          }}
        />
        <label className="mt-3">Country :</label>
        <select
          className="form-select mt-2"
          ref={countryref}
          aria-label="Default select example"
          value={value?.Country}
          onChange={(e) => {
            setView({ ...view, Country: e.target.value });
          }}
        ></select>
        <label className="mt-3">State :</label>
        <select
          className="form-select mt-2"
          ref={stateref}
          aria-label="Default select example"
          value={value?.State}
          onChange={(e) => {
            setView({ ...view, State: e.target.value });
          }}
        ></select>
        <label className="mt-3">City :</label>
        <select
          className="form-select mt-2"
          ref={cityref}
          aria-label="Default select example"
          value={value?.City}
          onChange={(e) => {
            setView({ ...view, City: e.target.value });
          }}
        ></select>

        <button
          className="btn btn-primary float-end my-3 "
          onClick={(e) => {
            update();
          }}
        >
          UPDATE
        </button>
      </div>
    </>
  );
};
