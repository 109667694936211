import React, { useEffect, useState } from 'react';
import { Navviparhome } from './Navviparhome';
import { Footer } from '../Footer/Footer';
import moment from 'moment';

export const Founder = () => {
  const [age, setAge] = useState(0);
  const birthDate = '1978-07-22'; // your date of birth in YYYY-MM-DD format

  useEffect(() => {
    const calculateAge = () => {
      const currentDate = moment(); // Get the current date
      const birthMoment = moment(birthDate, 'YYYY-MM-DD');
      const ag = currentDate.diff(birthMoment, 'years'); // Calculate the age in years
      setAge(ag);
    };

    calculateAge(); // Calculate the age initially when the component mounts

    // To automatically update the age, you can use a setInterval
    const interval = setInterval(calculateAge, 1000 * 60); // Update every minute, change the interval as needed

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  return (
    <>
      <Navviparhome />
      <div id="foooo">
        <section className="bgvipar text-white pt-3">
          <div className="container w-100">
            <div className=" d-flex justify-content-between  align-items-center py-2 py-md-3 px-2">
              <div
                className="mono "
                style={{
                  fontSize: '15px',
                  paddingTop: '15px',
                  paddingBottom: '10px',
                }}
              >
                <span className="">Ar. Vinod Venugopal</span>
                <br></br>
                <span>{age} years</span>
                <br />
                <br />
                <span className="">
                  Ar. Vinod Venugopal is a '96 batch graduate in B.Arch from M S
                  Ramaiah Institute of Technology in Bangalore.
                </span>
                <br />
                <span className="">
                  Council of Architecture Reg.&nbsp;No.&nbsp;: CA/2011/51213
                </span>
                <br />
                <br />
                <p className="" style={{ fontSize: '20px' }}>
                  Founded in 2020,{' '}
                  <span
                    className="vipar"
                    style={{ fontSize: '25px', color: '#99d050' }}
                  >
                    {' '}
                    Vipar
                  </span>
                  {/* <sup className="ms-1 mono" style={{ fontSize: "16px" }}>
                  ®
                </sup>{" "} */}{' '}
                  &nbsp;is an Architect’s brainchild to originate Services and
                  Products which are essentially useful to all, thereby creating
                  employment opportunities to many.
                </p>
                <br />
              </div>
            </div>
          </div>
          <section className="pt-2 pb-5 bgvipar text-black">
            <div className="container px-3">
              <div className=" "></div>
              {/* <div className="pt-4">
              <span className="">
                Originally from Kerala, he spent his entire childhood in Abu
                Dhabi, UAE.
                <br /> After graduating from M S Ramaiah Institute of Technology
                (Bangalore, India), he started his professional practice as an
                Architect in Mangalore, which he pursued passionately and
                intensively for a decade and a half before he decided to take a
                sabbatical from Architecture and constructively utilise his
                entrepreneurial skills to a magnitude which could ultimately be
                beneficial to the society at large.
              </span>
              <br />
            </div> */}
            </div>
          </section>
          <div className="pt-5">
            <Footer />
          </div>
        </section>
      </div>
    </>
  );
};
