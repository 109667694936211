import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Footer } from '../Footer/Footer'
import { Viparcarenav } from './Viparcarenav'
import {notify} from '../../App'
export const Getintouch = () => {
  const [submitt, setSubmitt] = useState(false)
  const [getin, setgetin] = useState({
    Name:"",
    State:"",
    City:"",
    ContactNumber:"",
    Requirement:"",
  })
  const [err, seterr] = useState()


let validation=()=>{
  if(getin.Name.length===0){
   return seterr("Enter Your Name")
  }
  if(getin.State.length===0){
    return seterr("Enter Your State")
  }
  if(getin.City.length===0){
    return seterr("Enter Your City")
  }
  if(getin.ContactNumber.length===0 ){
    return seterr("Enter your Contact Number")
  }
  if(getin.ContactNumber.length>13 || getin.ContactNumber.length<10){
    return seterr("Enter a Valid Contact Number")
  }
  if(getin.Requirement.length===0){
    return seterr("Enter your Requirement")
  }
  seterr("")
  return true
  }

  useEffect(() => {
    if(submitt){
      validation()
    }
 
 }, [getin])




  const submit = ()=>{
    setSubmitt(true)
    if(validation()===true){
      axios.post("/viparcare/viparcareget",getin).then((res)=>{
        notify("Successfully Entered", true);
        setSubmitt(false)
        setgetin({
          Name:"",
          State:"",
          City:"",
          ContactNumber:"",
          Requirement:"",
        })
      
      }).catch((err)=>{
  
      })
    }
   
  }
  return (
    <>
    <div>
      <Viparcarenav/>
    </div>
        <div id='fo'
        className="container-fluid py-2"
        style={{ backgroundColor: '#e9eef5' }}
      >
        <div className="container">
          <div className="  my-2  mx-lg-5 d-flex justify-content-center ">
            <div className="container row ">
              
                <div className="">
                  <div className="row ">
                    <div className="form-group col-md-12 mb-3">
                      <label htmlhtmlFor="inputname">Name *</label>
                      <input
                      onChange={(e)=>{setgetin({...getin,Name:e.target.value})}}
                        type="text"
                        className="form-control mt-1"
                        id="name"
                        name="name"
                        value={getin.Name}
                      />
                    </div>

                    <div className="form-group col-md-12 mb-3">
                      <label htmlhtmlFor="inputemail">State *</label>
                      <input
                      onChange={(e)=>setgetin({...getin,State:e.target.value})}
                        type="text"
                        className="form-control mt-1"
                        id="state"
                        name="state"
                        value={getin.State}

                      />
                    </div>
                    <div className="form-group col-md-12 mb-3">
                      <label htmlhtmlFor="inputemail">City *</label>
                      <input
                      onChange={(e)=>setgetin({...getin,City:e.target.value})}

                        type="text"
                        className="form-control mt-1"
                        id="city"
                        name="city"
                        value={getin.City}

                      />
                    </div>
                    <div className="form-group col-md-12 mb-3">
                      <label htmlhtmlFor="inputemail">Contact Number *</label>
                      <input
                      onChange={(e)=>setgetin({...getin,ContactNumber:e.target.value})}

                        type="number"
                        className="form-control mt-1"
                        id="mob"
                        name="mob"
                        value={getin.ContactNumber}

                      />
                    </div>
                    <div className="mb-3">
                      <label htmlhtmlFor="inputmessage">Requirement *</label>
                      <textarea
                      onChange={(e)=>setgetin({...getin,Requirement:e.target.value})}

                        className="form-control mt-1"
                        id="message"
                        name="message"
                        rows="8"
                        value={getin.Requirement}
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="">
                  <div><p className="text-danger">{err}</p></div>
                  <div className="col  mt-2">
                    <button
                      type="button"
                      className="btn btn-outline-dark btn-lg px-3 btnn"
                      onClick={(e)=>submit()}
                    >
                      SEND
                    </button>
                  </div>
                </div>
            </div>
          </div>

          <div></div>
        </div>
      </div>
      <div><Footer/></div>

    </>
  )
}
