import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';

export const Viparexclusivemodal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <span className="footlightfont">
        ** a{' '}
        <span
          className=""
          style={{ fontSize: '25px', fontFamily: 'Monotype', color: 'green' }}
        >
          Vipar&nbsp;
        </span>{' '}
        exclusive product **&nbsp;&nbsp;
        {/* <Button variant="primary" onClick={handleShow}>
          Launch demo modal
        </Button> */}
        <IoInformationCircleOutline size={23} onClick={handleShow}/>
      </span>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          Conceptualised, designed, manufactured, marketed and sold by{' '}
          <span
            className=""
            style={{ fontSize: '25px', fontFamily: 'Monotype', color: 'green' }}
          >
            Vipar&nbsp;
          </span>{" "}
          and available for purchase only at <Link to="/" style={{textDecoration:"none",color:"black"}}>www.vipar.in.</Link>
        </Modal.Body>
      </Modal>
    </>
  );
};
