import React, { useEffect, useState } from 'react';
import { NewNavViparc } from './Vipar-chic/NewNavViparc/NewNavViparc';
import { Footer } from './Footer/Footer';
import axios from 'axios';
import { Link } from 'react-router-dom';

export const Provinvoice = () => {



    const [state1, setstate1] = useState([]);
    const [tax, settax] = useState([])
    useEffect(() => {
        axios
          .post('/public/invoicenew')
          .then((res) => {
            console.log(res.data);
            setstate1(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }, []);
    

      useEffect(() => {
        axios.post('/public/finalinvoice').then((res)=>{
         console.log(   res.data);
         settax(res.data)
        }).catch((err)=>{
         console.log(err)
        })
       }, [])
  return (
    <>
            <NewNavViparc/>
            {!tax?.length==0?<></>:<>
            <div id="fo">
            <div className='py-5'>
            <table className="table ">
                <tbody className="nm">
                  {state1?.map((elem) => {
                    console.log(elem);
                    console.log(elem.Order_Id);

                    return (
                      <tr>
                        <td>{elem.Order_date}</td>
                        <td>{elem.Order_Id}</td>
                        <td>
                          <Link
                            className="btn btn-primary"
                            to={`/invoicedrop?order=${elem.Order_Id}`}
                          >
                            view
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            </div>
            </>}
         <Footer/>

    </>
  )
}
