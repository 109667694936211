import React, { useState,useEffect } from "react";
import StarRatings from "react-star-ratings";

export const Rating = ({value}) => {
    var sum = 0
    var num = value.review.length

    value.review.forEach(element => {
        sum = sum + element.rating
    });
    var avg = sum/num
    console.log(avg)
    
    

  return (
    <>
      <StarRatings
        rating={avg?avg:0}
        starRatedColor="gold"
        starDimension="20px"
        starSpacing="1px"
        starHoverColor="gold"
        numberOfStars={5}
        name="rating"
        
        
      />  
      
      <span className="" style={{marginBottom:"20px"}}>&nbsp;{avg?avg!=0?`(${avg})`:'':''}</span>
    </>
  );
};
