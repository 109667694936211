import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { keyGen } from '../App';
import { addcombo, } from '../redux/viparChic/cartSlice';
const Addcomponenthook = (props) => {
  let cart = useSelector((state) => state.cart.value);
  let dispatch = useDispatch();
  let { productId, stock, _id, varentCode, varentName } = { ...props.value };
  let name = props.name;
  let optional = props.optional;
  const [count, setCount] = useState(stock >= 1 ? 1 : 0);
  const [status, setstatus] = useState(false);
  useEffect(() => {
    // console.log(count);
    return () => {};
  }, [count]);

  const addtocart = (e) => {
    console.log('hi');
    console.log(count);
    if (count >= 1) {
      dispatch(
        addcombo({
          item: {
            id: keyGen(),
            productId,
            varientId: _id,
            qty: count,
            optional,
            varentCode,
            name,
            varentName,
          },
          cart,
        })
      );
    }
  };

  useEffect(() => {
    if (
      cart?.find((elem) => {
        if (
          elem.productId._id === productId &&
          elem.varientId._id === _id &&
          elem.optional === optional
        ) {
          setCount(elem.qty);
          setstatus(true);
        } else {
          console.log(elem.productId, productId, elem.varientId, _id);
        }
      })
    ) {
    }
    if (
      cart?.find((elem) => {
        if (
          elem.productId === productId &&
          elem.varientId === _id &&
          elem.optional === optional
        ) {
          setCount(elem.qty);
          setstatus(true);
        } else {
          console.log(elem.productId, productId, elem.varientId, _id);
        }
      })
    ) {
    }
    console.log(cart);
    console.log(optional);

    return () => {};
  }, [cart]);
  return (
    <>
      <div className="row">
        <div className="col-md-2 col-sm-6 col-6">
          <img
            src={`/img/${props.value.varentCode}.jpg`}
            alt="s"
            height="80px"
            width="80px"
          />
        </div>
        <div className="col-md-2 col-sm-6 col-6 mt-4">
          {props.value.varentName}
        </div>
        <div className="col-md-5 col-sm-6 col-6 mt-4">
          <ul className="list-inline pb-3">
            <li className="list-inline-item text-right">
              <input
                type="hidden"
                name="product-quanity"
                id="black"
                value="1"
              />
            </li>
            <li className="list-inline-item">
              <button
                className="btn btn-outline-dark"
                type="button"
                disabled={count === 1}
                onClick={(e) =>
                  setCount((rev) => (rev > 1 ? count - 1 : count))
                }
              >
                -
              </button>
            </li>
            <li className="list-inline-item">
              <span
                className={`badge ${status ? 'bg-success' : 'bg-secondary'}  `}
                id="white-value"
              >
                {count}
              </span>
            </li>
            <li className="list-inline-item">
              <button
                type="button"
                disabled={count === stock}
                className="btn btn-outline-dark"
                onClick={(e) =>
                  setCount((rev) => (rev < stock ? count + 1 : count))
                }
              >
                +
              </button>
            </li>
          </ul>
        </div>
        <div className="col-md-3 col-sm-6 col-6 mt-4">
          {' '}
          {parseInt(stock) === 0 ? (
            <span className="text-danger">out of stock</span>
          ) : (
            <button
              type="button"
              className="btn btn-outline-dark"
              onClick={(e) => addtocart(e)}
            >
              ADD
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Addcomponenthook;
