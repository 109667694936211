import React, { useState } from 'react';
import { Footer } from '../../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import './Art.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const Art = () => {
  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const [error, setError] = useState();
  const submit = (e) => {
    e.preventDefault();
    let elements = e.target.elements;
    console.log(e.target.elements);
    let data = {
      Name: elements.name.value,
      Email: elements.email.value,
    };
    if (validate(data) === true) {
      axios
        .post('/public/insert', data)
        .then((res) => {
          e.target.reset();
          notify('Successfull', true);
        })
        .catch((err) => {
          console.log(err);
          notify('Something went wrong !', false);
        });
    }
  };
  const validate = (data) => {
    if (data.Name.length === 0) {
      return setError('Enter your Name');
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.Email)) {
      return setError('Enter a valid email');
    }
    return true;
  };
  return (
    <>
      <div style={{ backgroundColor: '#e9eef5' }}>
        <div className="container ">
          <form onSubmit={(e) => submit(e)}>
            <center className="py-5">
              <span
                style={{ fontWeight: '100', fontSize: '20px' }}
              >
                {' '}
                Our artisans are working on some really exquisite art to be
                launched soon.
                <br />
                <br />
                If you want us to intimate you once this section is open,
                <br /> please fill in your name and email id below.
              </span>
            </center>
            <div className="row mt-5 ">
              <div className="col-12 col-md-6 mt-3 mb-3">
                <input
                  type="text"
                  className="form-control mt-1"
                  id="name"
                  name="name"
                  required
                  placeholder="Name"
                />
              </div>
              <div className="col-12 col-md-6 mt-3 mb-3  ">
                <input
                  type="text"
                  className="form-control mt-1"
                  id="email"
                  name="email"
                  required
                  placeholder="Email"
                />
              </div>
              <div className="col-12 ">
                <p className="text-danger">{error}</p>
                <button
                  className="btn btn-outline-dark float-end"
                  type="submit"
                >
                  Shoot
                </button>
              </div>
            </div>
          </form>
        </div>
        <ToastContainer />
        <div className='pt-5'>


      </div>
      </div>
     
    </>
  );
};

export default Art;
