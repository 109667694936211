import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { InitializeCountry } from '../../Utility/Country';

export const Country = () => {
  const [data, setdata] = useState({
    Name: '',
    Country: '',
    City: '',
    State: '',
  });

  let countryref = useRef();
  let stateref = useRef();
  let cityref = useRef();

  useEffect(() => {
    InitializeCountry(countryref, stateref, cityref);
  }, []);

  const submit = () => {
    axios
      .post('/chum/chuminsert', { data })
      .then((res) => {
        console.log();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="page-breadcrumb ">
        <div
          className="row align-items-center text-white"
          // style={{ backgroundColor: 'black' }}
        >
          <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
            <h4 className="page-title text-white">Country</h4>
          </div>
          <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
            <div className="d-md-flex">
              <ol className="breadcrumb ms-auto"></ol>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid overflow-scroll mt-3"
        style={{ backgroundColor: 'white' }}
      >
        <div>
          <label className="mt-3">Name :</label>
          <input
            type="name"
            className="form-control mt-2"
            id="exampleFormControlInput1"
            placeholder="Name"
            onChange={(e) => {
              setdata({ ...data, Name: e.target.value });
            }}
          />
          <label className="mt-3">Country :</label>
          <select
            className="form-select mt-2"
            ref={countryref}
            aria-label="Default select example"
            onChange={(e) => {
              setdata({ ...data, Country: e.target.value });
            }}
          ></select>
          <label className="mt-3">State :</label>
          <select
            className="form-select mt-2"
            ref={stateref}
            aria-label="Default select example"
            onChange={(e) => {
              setdata({ ...data, State: e.target.value });
            }}
          ></select>
          <label className="mt-3">City :</label>
          <select
            className="form-select mt-2"
            ref={cityref}
            aria-label="Default select example"
            onChange={(e) => {
              setdata({ ...data, City: e.target.value });
            }}
          ></select>

          <button
            className="btn btn-primary float-end my-3 "
            onClick={(e) => {
              submit();
            }}
          >
            SUBMIT
          </button>
        </div>
      </div>
    </>
  );
};
