import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Adminnavbar.css';
import {
  BsMoonStarsFill,
  BsFillSunFill,
  BsGlobe,
  BsClipboardPlus,
  BsPersonPlus,
} from 'react-icons/bs';
import axios from 'axios';
import { Accordion } from 'react-bootstrap';
import {
  AiFillSetting,
  AiOutlineFileAdd,
  AiOutlineFileDone,
  AiOutlineFileExcel,
  AiOutlineFileExclamation,
  AiOutlineProfile,
} from 'react-icons/ai';

import { FaGlobe, FaTachometerAlt, FaUserAlt } from 'react-icons/fa';
import { BiCommentEdit, BiTachometer } from 'react-icons/bi';
import { GrBarChart } from 'react-icons/gr';
import {
  RiCoupon3Line,
  RiCouponLine,
  RiRefund2Fill,
  RiRefundLine,
  RiUserSettingsLine,
} from 'react-icons/ri';

export const Sidenavbar = () => {
  const navopclose = () => {
    document.getElementById('navbar-toggle-btn').click();
  };
  return (
    <>
      <nav className="navbar">
        <div className="d-flex align-items-center ms-4 mb-4">
          <div className="position-relative"></div>
        </div>
        <div
          className="navbar-nav pt-5 w-100"
          // style={{ backgroundColor: 'rgba(0,0,0,1)' }}
        >
          <Link
            className="nav-item nav-link"
            to={'/admin'}
            onClick={navopclose}
          >
            <BiTachometer size={25} className="ms-2" />
            <span className="ps-3 pt-2">Dashboard</span>
          </Link>
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item" style={{ borderBottom: '0' }}>
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button collapsed text-dark"
                  style={{ color: 'white' }}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  CHIC
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body text-dark px-0">
                  <div class="accordion" id="accordionExamplee">
                    <div class="accordion-item" style={{ borderBottom: '0' }}>
                      <h2 class="accordion-header" id="headingOnee">
                        <button
                          // style={{ background: 'black', color: 'white' }}
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOnee"
                          aria-expanded="true"
                          aria-controls="collapseOnee"
                        >
                          COUPON
                        </button>
                      </h2>
                      <div
                        id="collapseOnee"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingOnee"
                        data-bs-parent="#accordionExamplee"
                      >
                        <div
                          class="accordion-body px-0"
                          // style={{ background: 'black', color: 'white' }}
                        >
                          <Link
                            className="nav-item nav-link pe-0"
                            to={'/admin/coupen'}
                            onClick={navopclose}
                          >
                            <RiCouponLine size={25} className="ms-2" />
                            <span className=" ps-3">Generate Coupon</span>
                          </Link>
                          <Link
                            className="nav-item nav-link"
                            to={'/admin/admincoupenview'}
                            onClick={navopclose}
                          >
                            <RiCoupon3Line size={25} className="ms-2" />
                            <span className="ps-3">Coupon View</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item" style={{ borderBottom: '0' }}>
                      <h2 class="accordion-header" id="headingTwo">
                        <button
                          // style={{ background: 'black', color: 'white' }}
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          ORDER
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          class="accordion-body px-0"
                          // style={{ background: 'black', color: 'white' }}
                        >
                          <Link
                            to={'/admin/adminoldorder'}
                            className="nav-item nav-link"
                            onClick={navopclose}
                          >
                            <AiOutlineProfile size={25} className="ms-2" />
                            <span className="ps-2"> Old order</span>
                          </Link>
                          <Link
                            to={'/admin/adminneworder'}
                            className="nav-item nav-link"
                            onClick={navopclose}
                          >
                            <AiOutlineFileAdd size={25} className="ms-2" />
                            <span className="ps-2"> New order</span>
                          </Link>
                          <Link
                            to={'/admin/adminconfirmorder'}
                            className="nav-item nav-link"
                            onClick={navopclose}
                          >
                            <AiOutlineFileDone size={25} className="ms-2" />

                            <span className="ps-2"> Confirm order</span>
                          </Link>
                          <Link
                            to={'/admin/admincancelorder'}
                            className="nav-item nav-link"
                            onClick={navopclose}
                          >
                            <AiOutlineFileExcel size={25} className="ms-2" />

                            <span className="ps-2"> Cancel Request</span>
                          </Link>

                          <Link
                            to={'/admin/adminreturnorder'}
                            className="nav-item nav-link"
                            onClick={navopclose}
                          >
                            <AiOutlineFileExclamation
                              size={25}
                              className="ms-2"
                            />

                            <span className="ps-2"> Return Request</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item" style={{ borderBottom: '0' }}>
                      <h2 class="accordion-header" id="headingThree">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          // style={{ background: 'black', color: 'white' }}
                        >
                          REFUND
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          class="accordion-body px-0"
                          // style={{ background: 'black', color: 'white' }}
                        >
                          <Link
                            to={'/admin/adminrefundrequest'}
                            className="nav-item nav-link"
                            onClick={navopclose}
                          >
                            <RiRefundLine size={25} className="ms-2" />

                            <span className="ps-3">Refund Request</span>
                          </Link>
                          <Link
                            to={'/admin/adminrefundview'}
                            className="nav-item nav-link"
                            onClick={navopclose}
                          >
                            <RiRefund2Fill size={25} className="ms-2" />

                            <span className="ps-3">Refund View</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item" style={{ borderBottom: '0' }}>
                      <h2 class="accordion-header" id="headingfour">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsefour"
                          aria-expanded="false"
                          aria-controls="collapsefour"
                          // style={{ background: 'black', color: 'white' }}
                        >
                          OTHERS
                        </button>
                      </h2>
                      <div
                        id="collapsefour"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingfour"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          class="accordion-body px-0"
                          // style={{ background: 'black', color: 'white' }}
                        >
                          <Link
                            to="/admin/adminreview"
                            className="nav-item nav-link"
                            onClick={navopclose}
                          >
                            <GrBarChart size={25} className="ms-2" />
                            <span className="ps-3">Ratings & Reviews</span>
                          </Link>
                          <Link
                            to="/admin/adminconnect"
                            className="nav-item nav-link"
                            onClick={navopclose}
                          >
                            <BiCommentEdit size={25} className="ms-2" />
                            <span className="ps-3">Get In Touch</span>
                          </Link>
                          <Link
                            to="/admin/grievances"
                            className="nav-item nav-link"
                            onClick={navopclose}
                          >
                            <RiUserSettingsLine size={25} className="ms-2" />

                            <span className="ps-3"> Grievance</span>
                          </Link>
                          {/* <Link
                    to="/admin/adminart"
                    className="nav-item nav-link"
                    onClick={navopclose}
                  >
                    <i className="fa fa-user me-2"></i>
                    <span className="fs"> Art</span>
                  </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingTwo">
                <button
                  class="accordion-button collapsed text-uppercase"
                  // style={{ background: 'black', color: 'white' }}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  Job Opportunities
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
                <div
                  class="accordion-body px-0"
                  // style={{ background: 'black', color: 'white' }}
                >
                  <Link
                    to="/admin/job_opportunities"
                    className="nav-item nav-link"
                    onClick={navopclose}
                  >
                    <BsClipboardPlus size={25} className="ms-2" />

                    <span className="ps-3">Job Opportunities</span>
                  </Link>
                  <Link
                    to="/admin/applicantsview"
                    className="nav-item nav-link"
                    onClick={navopclose}
                  >
                    <BsPersonPlus size={25} className="ms-2" />

                    <span className="ps-3">Applicants&nbsp;View</span>
                  </Link>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingThree">
                <button
                  class="accordion-button collapsed text-uppercase"
                  // style={{ background: 'black', color: 'white' }}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  CHUM
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div
                  class="accordion-body px-0"
                  // style={{ background: 'black', color: 'white' }}
                >
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        COUNTRY
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="">
                        <Link
                          to="/admin/country"
                          className="nav-item nav-link"
                          onClick={navopclose}
                        >
                          <BsClipboardPlus size={25} className="ms-2" />

                          <span className="ps-3">Country Management</span>
                        </Link>
                        <Link
                          to="/admin/countryview"
                          className="nav-item nav-link"
                          onClick={navopclose}
                        >
                          <BsClipboardPlus size={25} className="ms-2" />

                          <span className="ps-3">Country View</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingSubadmin">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#Subadmin"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        SUB ADMIN
                      </button>
                    </h2>
                    <div
                      id="Subadmin"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingSubadmin"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="">
                        <Link
                          to="/admin/subadminregistration"
                          className="nav-item nav-link"
                          onClick={navopclose}
                        >
                          <BsClipboardPlus size={25} className="ms-2" />

                          <span className="ps-3">Sub Admin Registration</span>
                        </Link>
                        <Link
                          to="/admin/subadminlist"
                          className="nav-item nav-link"
                          onClick={navopclose}
                        >
                          <BsClipboardPlus size={25} className="ms-2" />

                          <span className="ps-3">Sub Admin List</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingSTAFF">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#STAFF"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        STAFF
                      </button>
                    </h2>
                    <div
                      id="STAFF"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingSTAFF"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="">
                        <Link
                          to="/admin/staffregistration"
                          className="nav-item nav-link"
                          onClick={navopclose}
                        >
                          <BsClipboardPlus size={25} className="ms-2" />

                          <span className="ps-3">Staff Registration</span>
                        </Link>
                        <Link
                          to="/admin/stafflist"
                          className="nav-item nav-link"
                          onClick={navopclose}
                        >
                          <BsClipboardPlus size={25} className="ms-2" />

                          <span className="ps-3">Staff List</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className='accordin-item'>
            <h2 class="accordion-header" id="flush-headingFour">
                <button
                  class="accordion-button collapsed text-uppercase"
                  // style={{ background: 'black', color: 'white' }}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                  EMPLOYEE
                </button>
              </h2>
              <div
                id="flush-collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingFour"
                data-bs-parent="#accordionFlushExample"
              >
                <div
                  class="accordion-body px-0"
                  // style={{ background: 'black', color: 'white' }}
                >
                
                      <div class="">
                        <Link
                          to="/admin/employee_registration"
                          className="nav-item nav-link"
                          onClick={navopclose}
                        >
                          <BsClipboardPlus size={25} className="ms-2" />

                          <span className="ps-3">Employee Registation</span>
                        </Link>
                        <Link
                          to="/admin/employee_details"
                          className="nav-item nav-link"
                          onClick={navopclose}
                        >
                          <BsClipboardPlus size={25} className="ms-2" />

                          <span className="ps-3">Employee View</span>
                        </Link>
                      </div>
                    </div>
                  
                </div>
             
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

const Topnavbar = ({ setopen }) => {
  let navigate = useNavigate();

  const logout = () => {
    // window.reload()
    axios
      .post('/adminlogin/logout')
      .then((res) => {
        console.log(res);
        navigate('/login');
      })
      .catch((err) => {
        // window.reload();
        navigate('/login');

        console.log(err);
      });
  };

  return (
    <>
      <nav className="navbar navbar-expand bg-seconDdary  sticky-top px-2 py-0 mt-2">
        <div className="btn-group dropstart ms-auto">
          <button
            type="button"
            className="btn  text-light"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ border: 'none' }}
          >
            <AiFillSetting size={30} />
          </button>
          <ul className="dropdown-menu" style={{ backgroundColor: '#000000' }}>
            <li>
              <Link to={'/updatepassword'} className="dropdown-item" href="#">
                Change Password
              </Link>
            </li>
            <li>
              <Link
                className="nav-link text-light viparr text-center"
                to=""
                style={{ textDecoration: 'none', color: 'white' }}
                onClick={logout}
              >
                Log Out
              </Link>
            </li>
          </ul>
        </div>
        <span
          onClick={(e) => setopen((prev) => !prev)}
          className="sidebar-toggler  text-white  "
          id="navbar-toggle-btn"
        >
          <i className="fa fa-bars"></i>
        </span>

        {/* <div className="">
            <Darkmode />
          </div> */}
      </nav>
    </>
  );
};

export default Topnavbar;

// {subadminchum}

export const SubadminNavbar = () => {
  const navopclose = () => {
    document.getElementById('navbar-toggle-btn').click();
  };
  return (
    <>
      <nav className="navbar">
        <div className="d-flex align-items-center ms-4 mb-4">
          <div className="position-relative"></div>
        </div>
        <div
          className="navbar-nav pt-5 w-100"
          // style={{ backgroundColor: 'rgba(0,0,0,1)' }}
        >
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingThree">
                <button
                  class="accordion-button collapsed text-uppercase"
                  // style={{ background: 'black', color: 'white' }}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  CHUM
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div
                  class="accordion-body px-0"
                  // style={{ background: 'black', color: 'white' }}
                >
                  <Link
                    to="/subadminchum/country"
                    className="nav-item nav-link"
                    onClick={navopclose}
                  >
                    <BsClipboardPlus size={25} className="ms-2" />

                    <span className="ps-3">Country Management</span>
                  </Link>
                  <Link
                    to="/subadminchum/countryview"
                    className="nav-item nav-link"
                    onClick={navopclose}
                  >
                    <BsClipboardPlus size={25} className="ms-2" />

                    <span className="ps-3">Country View</span>
                  </Link>

                  <Link
                    to="/subadminchum/driverregistration"
                    className="nav-item nav-link"
                    onClick={navopclose}
                  >
                    <BsClipboardPlus size={25} className="ms-2" />

                    <span className="ps-3">Driver Registration</span>
                  </Link>

                  <Link
                    to="/subadminchum/driverregistrationview"
                    className="nav-item nav-link"
                    onClick={navopclose}
                  >
                    <BsClipboardPlus size={25} className="ms-2" />

                    <span className="ps-3">Driver Registration View</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export const Subadminnavbarchum = ({ setopen }) => {
  let navigate = useNavigate();

  const logout = () => {
    // window.reload()
    axios
      .post('/adminlogin/logout')
      .then((res) => {
        console.log(res);
        navigate('/login');
      })
      .catch((err) => {
        // window.reload();
        navigate('/login');

        console.log(err);
      });
  };

  return (
    <>
      <nav className="navbar navbar-expand bg-seconDdary  sticky-top px-2 py-0 mt-2">
        <div className="btn-group dropstart ms-auto">
          <button
            type="button"
            className="btn  text-light"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ border: 'none' }}
          >
            <AiFillSetting size={30} />
          </button>
          <ul className="dropdown-menu" style={{ backgroundColor: '#000000' }}>
            <li>
              <Link to={'/SubadminchangePassword'} className="dropdown-item" href="#">
                Change Password
              </Link>
            </li>
            <li>
              <Link
                className="nav-link text-light viparr text-center"
                to=""
                style={{ textDecoration: 'none', color: 'white' }}
                onClick={logout}
              >
                Log Out
              </Link>
            </li>
          </ul>
        </div>
        <span
          onClick={(e) => setopen((prev) => !prev)}
          className="sidebar-toggler  text-white  "
          id="navbar-toggle-btn"
        >
          <i className="fa fa-bars"></i>
        </span>

        {/* <div className="">
            <Darkmode />
          </div> */}
      </nav>
    </>
  );
};

// {subadminchum}

// {staffchum}

export const StaffchumNavbar = () => {
  const navopclose = () => {
    document.getElementById('navbar-toggle-btn').click();
  };
  return (
    <>
      <nav className="navbar">
        <div className="d-flex align-items-center ms-4 mb-4">
          <div className="position-relative"></div>
        </div>
        <div
          className="navbar-nav pt-5 w-100"
          // style={{ backgroundColor: 'rgba(0,0,0,1)' }}
        >
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingThree">
                <button
                  class="accordion-button collapsed text-uppercase"
                  // style={{ background: 'black', color: 'white' }}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  CHUM
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div
                  class="accordion-body px-0"
                  // style={{ background: 'black', color: 'white' }}
                >
                  <Link
                    to="/admin/driverregistrationview"
                    className="nav-item nav-link"
                    onClick={navopclose}
                  >
                    <BsClipboardPlus size={25} className="ms-2" />

                    <span className="ps-3">Driver Registration View</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export const StaffTopnavbar = ({ setopen }) => {
  let navigate = useNavigate();

  const logout = () => {
    // window.reload()
    axios
      .post('/adminlogin/logout')
      .then((res) => {
        console.log(res);
        navigate('/login');
      })
      .catch((err) => {
        // window.reload();
        navigate('/login');

        console.log(err);
      });
  };

  return (
    <>
      <nav className="navbar navbar-expand bg-seconDdary  sticky-top px-2 py-0 mt-2">
        <div className="btn-group dropstart ms-auto">
          <button
            type="button"
            className="btn  text-light"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ border: 'none' }}
          >
            <AiFillSetting size={30} />
          </button>
          <ul className="dropdown-menu" style={{ backgroundColor: '#000000' }}>
            <li>
              <Link to={'/StaffChangepassword'} className="dropdown-item" href="#">
                Change Password
              </Link>
            </li>
            <li>
              <Link
                className="nav-link text-light viparr text-center"
                to=""
                style={{ textDecoration: 'none', color: 'white' }}
                onClick={logout}
              >
                Log Out
              </Link>
            </li>
          </ul>
        </div>
        <span
          onClick={(e) => setopen((prev) => !prev)}
          className="sidebar-toggler  text-white  "
          id="navbar-toggle-btn"
        >
          <i className="fa fa-bars"></i>
        </span>

        {/* <div className="">
            <Darkmode />
          </div> */}
      </nav>
    </>
  );
};

// {staffchum}

export const Darkmode = () => {
  const [mode, setMode] = useState(true);
  // true => dark
  // false => light

  useEffect(() => {
    console.log(mode);
    // document.get
    return () => {};
  }, [mode]);

  return (
    <>
      <div className={`switchContainer `}>
        <BsMoonStarsFill
          color="white"
          style={{ marginTop: '2px' }}
        ></BsMoonStarsFill>
        <div
          onClick={(e) => setMode(!mode)}
          className={`switchButton ${mode ? 'addedrtr' : ''}`}
        ></div>
        <BsFillSunFill color="yellow" size="20"></BsFillSunFill>
      </div>
    </>
  );
};
