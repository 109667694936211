import React from 'react';
import { FiMenu } from 'react-icons/fi';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RiHome2Line } from 'react-icons/ri';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { FaShoppingBag } from 'react-icons/fa';
import { Footer } from '../Footer/Footer';
import { Logocenter } from '../Logocenter';

export const Navviparhome = () => {
  let location = useLocation();

  const anees = useSelector((state) => state.user.value);

  return (
    <>
      <nav
        className="navbar navbar-expand navbar-light "
        id="viparcare"
        style={{ fontFamily: 'FootlightMTLight', display: 'block' }}
      >
        <div
          className="  px-3  w-100"
          style={{
            display: 'block',
            height: '122px',
            backgroundColor: 'rgb(1,32,96,1)',
          }}
        >
          <div className="">
            <div className="d-flex justify-content-between">
              <div className="" style={{ marginTop: '11px' }}>
                <Link
                  class="navbar-brand text-success logo align-self-center"
                  to=""
                >
                  <img
                    className=""
                    src="/img/round.png"
                    alt=""
                    height="100px"
                    width="100px"
                  />
                  {/* <div
                    style={{
                      width: '80px',
                      height: '80px',
                      paddingTop: '10px',
                    }}
                  >
                    <Logocenter link={'/img/roundd.png'} />
                  </div> */}
                </Link>
              </div>

              <div className="" style={{ marginTop: '8px' }}>
                <div className="d-flex justify-content-center align-items-start pt-3">
                  <span
                    className="vipar transform "
                    style={{
                      fontSize: '27px',
                      color: '#99d050',
                    }}
                  >
                    Vipar&nbsp;
                    <sup class="text-white" style={{ fontSize: '13px' }}>
                      &reg;
                    </sup>
                  </span>
                </div>

                <div className="text-end ">
                  <span style={{ fontSize: '22px' }} className="pe-2 ml">
                    <span className="vipar ml" style={{ fontSize: '22px' }}>
                      Vi
                    </span>
                    <span
                      className="mono text-light ml"
                      style={{ fontSize: '22px' }}
                    >
                      sionary
                    </span>{' '}
                    &nbsp;
                    <span className="vipar ml" style={{ fontSize: '22px' }}>
                      &nbsp;par&nbsp;
                    </span>
                    <span
                      className="mono  text-light ml"
                      style={{ fontSize: '22px' }}
                    >
                      extremity
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container-fluid mt-3  w-100"
          style={{ display: 'block' }}
        >
          <div className=" d-flex justify-content-center d-md-block ">
            <div className="r" id="templatemo_main_nav">
              <div id="asuhygf" className="flex-fill">
                <div
                  className="d-block d-md-none"
                  style={{ overflow: 'hidden' }}
                >
                  {/* main */}
                  {/* <div className="row">
                    <div className="col-8">
                      <div className="row">
                        <div className="col-6">
                          <ul
                            className=" navbar-nav d-flex  justify-content-center mx-auto ms-0 asdddd"
                            style={{ border: 'none' }}
                          >
                            <li className="nav-item ">
                              <Link
                                to={anees?.Status === true ? '/inception' : ''}
                                className={` nav-link cardtextt  aa ${
                                  location.pathname === '/inception'
                                    ? ' active-link-vipar'
                                    : ''
                                }`}
                              >
                                &nbsp;&nbsp;INCEPTION&nbsp;
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-6">
                          <ul
                            className=" navbar-nav d-flex  justify-content-center mx-auto ms-0 asdddd"
                            style={{ border: 'none' }}
                          >
                            <li className="nav-item ">
                              <Link
                                to={anees?.Status === true ? '/founder' : ''}
                                className={` nav-link cardtextt  aa ${
                                  location.pathname === '/founder'
                                    ? ' active-link-vipar'
                                    : ''
                                }`}
                              >
                                FOUNDER
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <ul
                            className=" navbar-nav d-flex  justify-content-center mx-auto ms-0 asdddd"
                            style={{ border: 'none' }}
                          >
                            <li className="nav-item ">
                              <Link
                                to={anees?.Status === true ? '/brand' : ''}
                                className={`nav-link cardtextt  aa ${
                                  location.pathname === '/brand'
                                    ? ' active-link-vipar'
                                    : ''
                                }`}
                              >
                                &nbsp;&nbsp;THE&nbsp;BRAND&nbsp;
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-6">
                          <ul
                            className=" navbar-nav d-flex  justify-content-center mx-auto ms-0 asdddd"
                            style={{ border: 'none' }}
                          >
                            <li className="nav-item ">
                              <Link
                                to=""
                                className={`nav-link cardtextt aa asdddd`}
                              >
                                PRESENCE
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="col-4">
                      <div
                        className="row"
                        style={{ border: 'none', height: '100%' }}
                      >
                        <div className="col-6 d-flex  justify-content-center align-items-center pe-0 ps-0">
                          <Link
                            to={
                              anees?.Status === true ? '/one_work_one_wage' : ''
                            }
                            className={` nav-link cardtextt aa ${
                              location.pathname === '/one_work_one_wage'
                                ? ' active-link-vipar'
                                : ''
                            }`}
                          >
                            <span className="onefont">&nbsp;&nbsp;&nbsp;1</span>
                          </Link>
                        </div>

                        <div className="col-6 nav-link">
                          <div
                            className="ssvc nav-link"
                            style={{ lineHeight: '22px' }}
                          >
                            <Link
                              to={
                                anees?.Status === true
                                  ? '/one_work_one_wage'
                                  : ''
                              }
                              className={` nav-link cardtextt aa ${
                                location.pathname === '/one_work_one_wage'
                                  ? ' active-link-vipar'
                                  : ''
                              }`}
                            >
                              WORK
                              <br />
                              WAGE
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* main */}
                  <ul
                    className=" navbar-nav d-flex  justify-content-between mx-auto ms-0"
                    style={{ border: 'none' }}
                  >
                    <li className="nav-item ">
                      <Link
                        to={'/founder&inception'}
                        className={` nav-link cardtextt  aa ${
                          location.pathname === '/founder&inception'
                            ? ' active-link-vipar'
                            : ''
                        }`}
                      >
                        FOUNDER&nbsp;&nbsp;&&nbsp;&nbsp;INCEPTION
                      </Link>
                    </li>
                    <li className="nav-item ">
                      <Link
                        to={'/the_brand'}
                        className={`nav-link cardtextt  aa ${
                          location.pathname === '/the_brand'
                            ? ' active-link-vipar'
                            : ''
                        }`}
                      >
                        THE&nbsp;BRAND
                      </Link>
                    </li>
                  </ul>
                  <ul
                    className=" navbar-nav d-flex  justify-content-center mx-auto ms-0"
                    style={{ border: 'none' }}
                  >
                    {/* <li className="nav-item ">
                      <Link
                        to={anees?.Status === true ? '/one_work_one_wage' : ''}
                        className={` nav-link cardtextt aa ${
                          location.pathname === '/one_work_one_wage'
                            ? ' active-link-vipar'
                            : ''
                        }`}
                      >
                        1&#8239;WORK&#8239;1&#8239;WAGE
                      </Link>
                    </li> */}
                    {/* <li className="nav-item ">
                      <Link
                        style={{ paddingTop: 'px' }}
                        // to={anees?.Status === true ? '/vipar_man' : ''}
                        className={'nav-link cardtextt  aa'}
                        // ${
                        //   location.pathname === '/vipar_man'
                        //     ? ' active-link-vipar'
                        //     : ''
                      >
                        EMPLOYEES
                      </Link>
                    </li> */}
                    {/* <li className="nav-item ">
                      <Link to="" className=" nav-link cardtextt aa ">
                        PRESENCE
                      </Link>
                    </li> */}
                  </ul>
                </div>
                <div className="d-none d-md-block ">
                  <div id="nsknfkjdsnfksjn" className="cntttr">
                    <ul
                      className="  d-flex ps-0  justify-content-center mx-auto ms-0"
                      style={{ border: 'none' }}
                    >
                      <li className="nav-item d-flex align-items-center ">
                        <Link
                          to={'/founder&inception'}
                          className={` nav-link cardtextt  aa ${
                            location.pathname === '/founder&inception'
                              ? ' active-link-vipar'
                              : ''
                          }`}
                        >
                          FOUNDER&nbsp;&nbsp;&&nbsp;&nbsp;INCEPTION
                        </Link>
                      </li>

                      <li className="nav-item  d-flex align-items-center">
                        <Link
                          to={'/the_brand'}
                          className={`nav-link cardtextt  aa ${
                            location.pathname === '/the_brand'
                              ? ' active-link-vipar'
                              : ''
                          }`}
                        >
                          THE&nbsp;BRAND
                        </Link>
                      </li>
                      {/* <li className="nav-item  d-flex align-items-center">
                        <Link
                          to={
                            anees?.Status === true ? '/one_work_one_wage' : ''
                          }
                          className={` nav-link cardtextt aa ${
                            location.pathname === '/one_work_one_wage'
                              ? ' active-link-vipar'
                              : ''
                          }`}
                        >
                          1&#8239;WORK&#8239;1&#8239;WAGE
                        </Link>
                      </li> */}

                      {/* <li className="nav-item  d-flex align-items-center">
                        <Link
                          // to={anees?.Status === true ? '/vipar_man' : ''}
                          className={'nav-link cardtextt  aa'}
                          // ${
                          //   location.pathname === '/vipar_man'
                          //     ? ' active-link-vipar'
                          //     : ''
                          // }
                        >
                          EMPLOYEES
                        </Link>
                      </li> */}
                      {/* <li className="nav-item  d-flex align-items-center">
                        <Link to="" className={` nav-link cardtextt aa `}>
                          PRESENCE
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
