import axios from 'axios'
import React, { useState } from 'react'



export const SubadminchangePassword = () => {
    const [err, seterr] = useState();

    const [Validate, setValidate] = useState(false);
    const [state, setState] = useState({
        cpassword:"",
        password:"",
        confirmpassword:"",
    })


    const submit = () =>{
        axios.post("/chum/subadminchangepassword",state).then((res)=>
        console.log("Zc")
        ).catch((err)=>{
            console.log(err)})
    }

  return (
    <section className="vh-100" style={{ backgroundColor: '#508bfc' }}>
    <div className="container py-5 h-100">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
          <div
            className="card shadow-2-strong"
            style={{ borderRadius: '1rem' }}
          >
            <form     onSubmit={(e) => submit(e)}
                    noValidate
                    className={Validate ? 'was-validated' : ''}>
            <div className="card-body p-5 ">
              <center>
                <h3 className="mb-5 ">Update Password </h3>
              </center>
              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="typeEmailX-2">
                  Current Password
                </label>
                <input
                required
                  type="password"
                  name="email"
                  id="typeEmailX-2"
                  className="form-control form-control-lg"
                  fdprocessedid="ydop06"
                  onChange={(e) =>
                    {
                    if (state.password === state.confirmpassword) {
                        e.target.setCustomValidity('');
                      } else {
                        setTimeout(function () {
                          e.target.setCustomValidity('Password Missmatch');
                          seterr('Password Missmatch');
                        }, 4000);
                      }
                    setState({ ...state, cpassword: e.target.value })
                  }}
                />
                  <div class="invalid-feedback">
                        Please enter the Password
                        </div>
              </div>

              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="typePasswordX-2">
                  New Password
                </label>
                <input
                required

                  type="password"
                  name="password"
                  id="typePasswordX-2"
                  className="form-control form-control-lg"
                  fdprocessedid="kao81l"
                  onChange={(e) =>{
                    if (state.password === state.confirmpassword) {
                        e.target.setCustomValidity('');
                      } else {
                        setTimeout(function () {
                          e.target.setCustomValidity('Password Missmatch');
                          seterr('Password Missmatch');
                        }, 4000);
                      }
                    setState({ ...state, password: e.target.value })
                  }}
                />
                     {err ?( <div className="invalid-feedback">{err}</div> ) :<div class="invalid-feedback">
                        Please enter the Password
                      </div>}
              </div>

              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="typePasswordX-2">
                  Confirm New Password
                </label>
                <input
                required

                  type="password"
                  name="password"
                  id="typePasswordX-2"
                  className="form-control form-control-lg"
                  fdprocessedid="kao81l"
                  onChange={(e) =>
                  {
                    if (state.password === state.confirmpassword) {
                        e.target.setCustomValidity('');
                      } else {
                        setTimeout(function () {
                          e.target.setCustomValidity('Password Missmatch');
                          seterr('Password Missmatch');
                        }, 4000);
                      }
                    setState({ ...state, confirmpassword: e.target.value })
                  }}
                />
                     {err ?( <div className="invalid-feedback">{err}</div> ) :<div class="invalid-feedback">
                        Please enter the ConfirmPassword
                      </div>}
              </div>

              <button
                className="btn btn-primary btn-lg btn-block"
                type="submit"
                fdprocessedid="i8iyob"
                onClick={(e) => setValidate(true)}
              >
                Update
              </button>

              <hr className="my-4" />
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}
