import './css/App.css';
import './css/style.css';
import './css/owl.carousel.min.css';
import './css/animate.min.css';
import './css/Testimonials.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { ToastContainer, toast } from 'react-toastify';
import { VRoutes } from './Routes';
import { useDispatch } from 'react-redux';
import { initialFromDB } from './redux/viparChic/cartSlice';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { NewNavViparc } from './components/Vipar-chic/NewNavViparc/NewNavViparc';
import Button from 'react-bootstrap/Button';
import { addUser } from './redux/viparChic/userSlice';
import { Navigate, useNavigate } from 'react-router-dom';
import { logout } from './components/NavbarVipar/NavbarVipar';
import { useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

export const notify = (message, sab) => {
  if (sab) {
    toast.success(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.warning(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};
function App() {
  console.log = function () {};
  console.warn = function () {};
  console.error = function () {};

  let dispatch = useDispatch();
  const [cart, setCart] = useState([]);
  const cartLoader = () => {
    axios
      .get('/public/shopping')
      .then((res) => {
        console.log(res.data);
        setCart(res.data);
        if (res.data.Items) {
          dispatch(
            initialFromDB({
              value: res.data.Items,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [loading, setloading] = useState(true);
  const userLoader = () => {
    axios
      .get('/public/userLoader')
      .then((res) => {
        console.log(res.data);
        dispatch(
          addUser({
            item: {
              UserName: res.data.userName,
              Status: res.data.loginStatus,
              Email: res.data.email,
              AccountType: res.data.accountType,
              addresses: [],
            },
          })
        );

        console.log('hii');
        console.log(res);
        localStorage.setItem('UserName', res.data.userName);
        localStorage.setItem('Email', res.data.email);
        localStorage.setItem('Status', res.data.loginStatus);
        localStorage.setItem('AccountType', res.data.accountType);
      })
      .catch((err) => {
        // localStorage.clear();
        console.log(err);
      })
      .finally((err) => {
        setloading(false);
      });
  };
  useEffect(() => {
    cartLoader();
    userLoader();

    return () => {};
  }, []);

  useEffect(() => {
    window.addEventListener('click', (e) => {
      // console.log('scroll ');
      document.body.style.overflow = 'scroll';

      // console.log(e);
    }); // Fire myFunction when you scroll/are scrolling, but only every 1000ms.
    // window.addEventListener('dragstart', (e) => {
    //   console.log('drag ');
    //   console.log(e);
    // }); //
    window.addEventListener('mousedown', (e) => {
      console.log('down');
      document.body.style.overflow = 'scroll';

      // console.log(e);
    });
    window.addEventListener('mousemove', (e) => {
      // console.clear();
      document.body.style.overflow = 'scroll';

      // console.log('move');
      // console.log(e);
    });
    // window.addEventListener('mouseup', (e) => {
    //   console.log('up');
    //   console.log(e);
    // });
    return () => {};
  }, []);

  const [first, setfirst] = useState();
  let userY;
  useEffect(() => {
    userY = navigator.userAgent;
    setfirst(userY);
  }, []);

  useEffect(() => {
    let first = navigator.userAgent;
    if (first) {
      var sp = first.indexOf('Mobile');
      var sup = first.indexOf('iPad');
      var w = window.innerWidth;
      if (sup >= 0) {
        return;
      }
      if (sp >= 0) {
        document.getElementById('root').classList.add('mobileclass');
        window.addEventListener(
          'load',
          function () {
            // if(w>320)
            setTimeout(() => {
              let tommy = document.getElementById('Tommy');
              tommy.children[0].innerHTML = `Please Rotate your device`;
              tommy.children[1].innerHTML = `We don't support Landscape mode.`;
              tommy.children[2].innerHTML = `Please switch to Portrait mode.`;
            }, 100);
          },
          false
        );
      }
    }
  });

  const [key, setkey] = useState(10);

  return (
    <div
      id="appWindow"
      className="App"
      style={{ backgroundColor: 'white', height: '100vh' }}
    >
      {/* <NewNavViparc  key={'h' + key} setkey={setkey} />  */}

      {!loading && <VRoutes />}
      <ToastContainer />
      <LoginModals />
      <Namsthe />
    </div>
  );
}

export function keyGen() {
  var length = 5;
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function Namsthe() {
  const [show, setshow] = useState(false);

  const handleClose = () => setshow(false);
  const handleShow = () => setshow(true);

  var url = window.location.href;

  useEffect(() => {
    let namsthe = localStorage.getItem('namasthe');
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    const currentDate = `${year}-${month}-${day}`;
    console.log(namsthe, currentDate);
    if (url.indexOf('admin').length >= 0) {
      console.log('ASdd');
    } else {
      if (namsthe) {
        const diffTime = Math.abs(new Date(currentDate) - new Date(namsthe));
        console.log(diffTime);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        console.log(diffDays);
        if (diffDays) {
          handleShow();
          localStorage.setItem('namasthe', currentDate);
        }
      } else {
        handleShow();
        localStorage.setItem('namasthe', currentDate);
      }
    }
  }, []);
  const myFunction = () => {
    document.getElementById('myDropdownvipar').classList.toggle('show');
  };

  return (
    <>
      <Modal
        className="d-flex justify-content-center align-items-center"
        show={show}
        onHide={handleClose}
        size="lg"
      >
        <Modal.Header
          className="d-flex justify-content-end"
          style={{ border: 'none', backgroundColor: '#0c0c0c' }}
        >
          <button
            className="btn btn-dark"
            onClick={(e) => {
              handleClose();
            }}
          >
            X
          </button>
        </Modal.Header>

        <Modal.Body
          onClick={(e) => {
            handleClose();
          }}
          className="p-0"
        >
          <img
            src="/img/namastelast.png"
            height="100%"
            width="100%"
            alt=""
          ></img>
        </Modal.Body>
      </Modal>
    </>
  );
}
export const LoginModals = () => {
  const [regloading, setRegloading] = useState(false);
  const [logloading, setLogloading] = useState(false);
  let Navigate = useNavigate();
  const [user, setuser] = useState({
    Name: '',
    Email: '',
    Password: '',
    Confirmpassword: '',
  });
  const [userlogin, setuserlogin] = useState({
    Email: '',
    Password: '',
  });
  const [ferr, setFerr] = useState();
  const [fsubmit, setFsubmit] = useState(false);

  const [forgot, setforgot] = useState({
    Email: '',
  });
  const fvalidate = () => {
    if (forgot.Email.length <= 0) {
      return setFerr('Please enter a Email');
    }
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(forgot.Email)) {
      setLerr('');
    } else {
      return setFerr('Please enter a valid Email');
    }
    return true;
  };
  const forgotpassword = () => {
    setFsubmit(true);
    if (fvalidate() === true) {
      axios
        .post('/public/forgot', forgot)
        .then((res) => {
          document.getElementById('forgetmodalclosebtn').click();
          document.getElementById('otpModalbtn').click();
        })
        .catch((err) => {
          console.log(err);
          return setFerr(err.response.data);
        });
    }
  };

  let dispatch = useDispatch();

  const [err, seterr] = useState('');
  const [Text, setText] = useState('');
  const [Text2, setText2] = useState(false);

  const [lerr, setLerr] = useState('');
  const validate = () => {
    if (userlogin.Email.length <= 0) {
      return setLerr('Please enter a Email');
    }
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userlogin.Email)) {
      setLerr('');
    } else {
      return setLerr('Please enter a valid Email');
    }

    if (userlogin.Password.length <= 0) {
      return setLerr('Please enter your Password');
    }
    if (userlogin.Password.length < 4) {
      return setLerr('Password is too short');
    }
    return true;
  };
  const login = () => {
    if (validate() === true) {
      setLogloading(true);
      axios
        .post('/public/userlogin', userlogin)
        .then((res) => {
          setLogloading(false);

          dispatch(
            addUser({
              item: {
                UserName: res.data.Name,
                Status: true,
                Email: res.data.Email,
                AccountType: res.data.AccountType,
                addresses: [],
              },
            })
          );

          console.log('hii');
          console.log(res);
          localStorage.setItem('UserName', res.data.Name);
          localStorage.setItem('Email', res.data.Email);
          localStorage.setItem('Status', res.data.Status);
          localStorage.setItem('AccountType', res.data.AccountType);
          setText('Successfully Logged In');
          setText2('');

          document.getElementById('successloginbtn').click();
          setTimeout(() => {
            document.getElementById('successclosemodalbtn').click();
            // window.reload();
          }, 3000);
        })
        .catch((err) => {
          setLogloading(false);

          console.log(err);
          console.log(err.response.data);
          return setLerr(err.response.data);
          // return setLerr(err.resp);
        });
    }
  };

  const [aa, setaa] = useState('');
  const validation = () => {
    if (user.Name.length <= 0) {
      return seterr('Please enter a Name');
    }
    if (user.Email.length <= 0) {
      return seterr('Please enter a Email');
    }
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user.Email)) {
      seterr('');
    } else {
      return seterr('Please enter a valid Email');
    }

    if (user.Password.length <= 0) {
      return seterr('Please enter a Password');
    }
    if (user.Password.length < 4) {
      return seterr('Password is too short');
    }
    if (user.Confirmpassword.length <= 0) {
      return seterr('Please enter the Confirm Password');
    }
    if (user.Password != user.Confirmpassword) {
      return seterr('Password Missmatch');
    }
    return true;
  };

  const submit = () => {
    if (validation() === true) {
      setRegloading(true);
      axios
        .post('/public/user', user)
        .then((res) => {
          setRegloading(false);

          setText('Successfully Registered');
          setText2(true);

          dispatch(
            addUser({
              item: {
                UserName: res.data.Name,
                Status: true,
                Email: res.data.Email,
                AccountType: res.data.AccountType,
                addresses: [],
              },
            })
          );
          localStorage.setItem('UserName', res.data.Name);
          localStorage.setItem('Email', res.data.Email);
          localStorage.setItem('Status', res.data.Status);
          localStorage.setItem('AccountType', res.data.AccountType);
          document.getElementById('successloginbtn').click();

          setTimeout(
            () => document.getElementById('successclosemodalbtn').click(),
            3000
          );
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response.data);
          seterr(err.response.data);
          setRegloading(false);
        });
    }
  };

  // changepassword
  const [changepass, setchangepass] = useState(false);
  const [changetext, setchangetext] = useState('');
  const [change, setchange] = useState({
    CurrentPassword: '',
    NewPassword: '',
    ConfirmPassword: '',
  });

  const [passerr, setpasserr] = useState('');
  let changevalidation = () => {
    if (change.CurrentPassword.length <= 0) {
      return setpasserr('Enter Current Password');
    }

    if (change.NewPassword.length <= 0) {
      return setpasserr('Enter New Password');
    }
    if (change.CurrentPassword === change.NewPassword) {
      return setpasserr('You Are Using Old Password');
    }
    if (change.ConfirmPassword.length <= 0) {
      return setpasserr('Enter Confirm Password');
    }
    if (change.NewPassword != change.ConfirmPassword) {
      return setpasserr('Password Missmatch');
    }
    setpasserr('');

    return true;
  };

  useEffect(() => {
    if (changepass) {
      changevalidation();
    }
  }, [change]);

  const changepassword = () => {
    setchangepass(true);
    if (changevalidation() === true) {
      axios
        .post('/public/changepassword', change)
        .then((res) => {
          // notify("Password Updated",true)
          setchangepass(false);
          setText('Successfully Password Changed');
          document.getElementById('successloginbtn').click();
          setchange({
            CurrentPassword: '',
            NewPassword: '',
            ConfirmPassword: '',
          });

          setTimeout(
            () => document.getElementById('successclosemodalbtn').click(),
            3000
          );
        })
        .catch((err) => {
          setpasserr(err.response.data);
        });
    }
  };
  const [Text3, setText3] = useState(false);
  const [yes, setyes] = useState(true);
  const [passdelete, setpassdelete] = useState({
    CurrentPassword: '',
  });
  const [accounterr, setaccounterr] = useState('');
  let accountvalidation = () => {
    if (passdelete.CurrentPassword.length <= 0) {
      return setaccounterr('Enter Your Password');
    }
    setaccounterr('');
    return true;
  };
  const [accoutsubmit, setaccoutsubmit] = useState(false);
  useEffect(() => {
    if (accoutsubmit) {
      accountvalidation();
    }
  }, [passdelete]);

  let navigate = useNavigate();
  let passdeletefun = (cb) => {
    setaccoutsubmit(true);
    if (accountvalidation() === true) {
      axios
        .post('/public/accountdeletion', { passdelete })
        .then((res) => {
          logout(cb);
          setaccoutsubmit(false);
          setText('Successfully Deleted');
          setText3(true);

          document.getElementById('successloginbtn').click();
          setTimeout(
            () => document.getElementById('successclosemodalbtn').click(),
            3000
          );
        })
        .catch((err) => {
          setaccounterr(err.response.data);
        });
    }
  };

  return (
    <>
      {/* <button
        className="btn btn-primary"
        data-bs-target="#RegisterModal"
        data-bs-toggle="modal"
      >
        modal
      </button> */}

      {/* register modal */}
      {/* <MyPage /> */}
      <div
        className="modal fade "
        data-easein="flipXIn"
        id="loginModal"
        tabIndex="-1"
        role="dialog"
      >
        <center style={{ height: '100dvh' }}>
          <div
            className="modal-dialog modal-dialog-centered modal-login mt-5"
            role="document"
          >
            <div className="modal-content">
              <div className="card card-signup card-plain">
                <div className="modal-header px-2">
                  <div className="card-header card-header-primarys text-center bg-white m-0">
                    <button
                      type="button"
                      className="btn-close  float-end close-button-modal"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    <h4 className="card-title text-black">NEW REGISTRATION</h4>
                  </div>
                </div>
                <div className="modal-body">
                  <form className="form" method="" action="">
                    <div className="card-body">
                      <div className="form-group bmd-form-group">
                        <div className="input-group">
                          {/* <div className="input-group-prepend">
                                  <span className="input-group-text">
                                  <FontAwesomeIcon icon={regular('face-smile')} />
                                  </span>
                              </div> */}
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) =>
                              setuser({ ...user, Name: e.target.value })
                            }
                            placeholder="Name"
                          />
                        </div>
                      </div>

                      <div className="form-group bmd-form-group">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) =>
                              setuser({ ...user, Email: e.target.value })
                            }
                            placeholder="Email Id"
                          />
                        </div>
                      </div>

                      <div className="form-group bmd-form-group">
                        <div className="input-group">
                          <input
                            type="password"
                            placeholder="Password"
                            onChange={(e) =>
                              setuser({ ...user, Password: e.target.value })
                            }
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="form-group bmd-form-group">
                        <div className="input-group">
                          <input
                            type="password"
                            placeholder="Confirm Password"
                            onChange={(e) =>
                              setuser({
                                ...user,
                                Confirmpassword: e.target.value,
                              })
                            }
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="form-group bmd-form-group">
                        <p className="text-danger">{err}</p>
                        <div className="input-group d-flex justify-content-center">
                          {!regloading && (
                            <button
                              className="btn btn-groceryTheam rounded-pill "
                              style={{ letterSpacing: '1px' }}
                              type="button"
                              onClick={(e) => submit()}
                            >
                              Register
                            </button>
                          )}
                          {regloading && (
                            <button
                              disabled
                              className="btn btn-groceryTheam rounded-pill "
                              type="button"
                            >
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </button>
                          )}
                          {/* <p className="pt-2 text-dark">
                          By clicking Register, you agree to comply with our
                          Terms of Use, Privacy Policy, Disclaimer
                        </p> */}
                        </div>
                        <br />

                        <center>
                          <a
                            className="text-center text-dark"
                            data-bs-target="#RegisterModal"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                            style={{ cursor: 'pointer' }}
                          >
                            Already a member ?{' '}
                            <button className="btnouttt ms-2">
                              LOG&nbsp;IN
                            </button>
                          </a>
                        </center>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </center>
      </div>
      {/* register modal */}

      {/* login modal */}

      <div
        className="modal fade"
        id="RegisterModal"
        aria-hidden="true"
        aria-labelledby="RegisterModalLabel"
        tabIndex="-1"
      >
        <center style={{ height: '100dvh' }}>
          <div
            className="modal-dialog modal-dialog-centered modal-login "
            role="document"
          >
            <div className="modal-content">
              <div className="card card-signup card-plain">
                <div className="modal-header px-2">
                  <div className="card-header card-header-primarys text-center bg-white m-0">
                    <button
                      type="button"
                      className="btn-close float-end close-button-modal "
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    <h4 className="card-title text-black">SIGN IN / LOG IN</h4>
                  </div>
                </div>
                <div className="modal-body">
                  <form className="form" method="" action="">
                    <div className="card-body">
                      <div className="form-group ">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email Id"
                            onChange={(e) =>
                              setuserlogin({
                                ...userlogin,
                                Email: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group bmd-form-group">
                        <div className="input-group">
                          <input
                            type="password"
                            placeholder="Password"
                            className="form-control"
                            onChange={(e) =>
                              setuserlogin({
                                ...userlogin,
                                Password: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="float-start">
                        <a
                          href=""
                          data-bs-target="#ForgetPasswordModal"
                          data-bs-toggle="modal"
                          data-bs-dismiss="modal"
                          style={{ fontSize: '10px' }}
                        >
                          Forgot password ?
                        </a>
                      </div>
                      <br />
                      <div className="form-group bmd-form-group">
                        <p className="text-danger">{lerr}</p>
                        <div className="input-group d-flex justify-content-center">
                          {!logloading && (
                            <button
                              className="btn btn-groceryTheam rounded-pill px-4"
                              type="button"
                              onClick={(e) => login()}
                            >
                              Log In
                            </button>
                          )}
                          {logloading && (
                            <button
                              disabled
                              className="btn btn-groceryTheam rounded-pill px-4"
                              type="button"
                            >
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </button>
                          )}

                          <button
                            id="successloginbtn"
                            className="btn d-none btn-groceryTheam rounded-pill px-4"
                            data-bs-target="#success_tic_modal_success"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                            type="button"
                          >
                            Log In
                          </button>
                        </div>
                        <br />
                        <div className="input-group d-flex justify-content-center">
                          <a
                            className="text-center text-dark"
                            // data-bs-target="#loginModal"

                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                            style={{ cursor: 'pointer' }}
                          >
                            Not a Registered member ?
                            <button
                              style={{ letterSpacing: '1px' }}
                              className=" btnoutt ms-2"
                            >
                              REGISTER
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </center>
      </div>
      {/* login modal */}

      {/* forget password modal */}
      <div
        className="modal fade"
        id="ForgetPasswordModal"
        aria-hidden="true"
        aria-labelledby="ForgetPasswordModal"
        tabIndex="-1"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-login mt-5"
          role="document"
        >
          <div className="modal-content">
            <div className="card card-signup card-plain">
              <div class="modal-header">
                <h4 class="modal-title">Forgot Password ?</h4>
                <button
                  type="button"
                  id="forgetmodalclosebtn"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                <form className="form" method="" action="">
                  <div className="card-body">
                    <div className="form-group bmd-form-group">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email Id"
                          onChange={(e) => {
                            setFerr('');
                            setforgot({ ...forgot, Email: e.target.value });
                          }}
                        />
                      </div>
                    </div>

                    <div className="form-group bmd-form-group">
                      <p className="text-danger">{ferr}</p>
                      <div className="input-group d-flex justify-content-center">
                        {ferr?.length ? (
                          <></>
                        ) : (
                          <button
                            className="btn hhchange rounded-pill px-4 mt-3"
                            style={{ letterSpacing: '1px' }}
                            type="button"
                            onClick={(e) => forgotpassword()}
                          >
                            CONTINUE
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* forget password modal */}

      {/* forgetpassphase2 btn */}
      <button
        type="button"
        className="btn btn-primary d-none"
        id="otpModalbtn"
        data-bs-toggle="modal"
        data-bs-target="#forgetpassphase2"
      >
        otpmodalbutton
      </button>
      {/* forgetpassphase2 btn */}

      {/* logindemobtn */}
      <button
        type="button"
        className="btn btn-primary d-none"
        id="RegisterModalbtndemo"
        data-bs-toggle="modal"
        data-bs-target="#RegisterModallo"
      >
        RegisterModaldemobtn
      </button>
      {/* logindemobtn */}

      {/* otp modal */}

      <div
        className="modal fade"
        id="otpModal"
        aria-hidden="true"
        aria-labelledby="otpModalLabel"
        tabIndex="-1"
      >
        <div
          className="modal-dialog modal-dialog-centered  mt-5"
          role="document"
        >
          <div className="modal-content">
            <div className="container d-flex justify-content-center align-items-center">
              <div className="position-relative">
                <div className="p-2 text-center">
                  <h6>
                    Please enter the one time password <br /> to verify your
                    account
                  </h6>
                  <div>
                    {' '}
                    <span>A code has been sent to</span>{' '}
                    <small>*******9897</small>{' '}
                  </div>
                  <div
                    id="otp"
                    className="inputs d-flex flex-row justify-content-center mt-2"
                  >
                    {' '}
                    <input
                      className="m-2 text-center form-control rounded"
                      type="text"
                      id="first"
                      maxLength="1"
                    />{' '}
                    <input
                      className="m-2 text-center form-control rounded"
                      type="text"
                      id="second"
                      maxLength="1"
                    />{' '}
                    <input
                      className="m-2 text-center form-control rounded"
                      type="text"
                      id="third"
                      maxLength="1"
                    />{' '}
                    <input
                      className="m-2 text-center form-control rounded"
                      type="text"
                      id="fourth"
                      maxLength="1"
                    />{' '}
                    <input
                      className="m-2 text-center form-control rounded"
                      type="text"
                      id="fifth"
                      maxLength="1"
                    />{' '}
                    <input
                      className="m-2 text-center form-control rounded"
                      type="text"
                      id="sixth"
                      maxLength="1"
                    />{' '}
                  </div>
                  <div className="mt-4">
                    {' '}
                    <button
                      className="btn btn-danger px-4 validate"
                      data-bs-target="#success_tic_modal_success"
                      data-bs-toggle="modal"
                      data-bs-dismiss="modal"
                      type="button"
                    >
                      Validate
                    </button>{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* otp modal */}

      {/* ///forgetpassphase2 */}
      <div
        className="modal fade"
        id="forgetpassphase2"
        aria-hidden="true"
        aria-labelledby="otpModalLabel"
        tabIndex="-1"
      >
        <div
          className="modal-dialog  modal-dialog-centered modal-login mt-5"
          role="document"
        >
          <div className="modal-content">
            <div className="card card-signup card-plain">
              <div className="modal-header">
                <div className="modal-body">
                  <div className="container d-flex justify-content-center align-items-center mt-3">
                    <div className="position-relative">
                      <div className="p-2 text-center">
                        <h6>
                          A Link to Reset your Password has been sent to your
                          <br />
                          Email Id : {forgot.Email}
                        </h6>

                        <div className="mt-4">
                          {' '}
                          <button
                            className=" btnouttt px-4 "
                            data-bs-dismiss="modal"
                            type="button"
                          >
                            GOT IT
                          </button>{' '}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        id="btnsuccess_tic_modal_success"
        className="btn btn-danger d-none"
        data-bs-toggle="modal"
        data-bs-target="#success_tic_modal_success"
      >
        {' '}
        click
      </button>
      <div id="success_tic_modal_success" className="modal fade" role="dialog">
        <button
          type="button"
          id="successclosemodalbtn"
          className="btn-close float-end d-none close-button-modal btn-close-white"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
        <div className="modal-dialog modal-dialog-centered d-flex justify-content-center align-items-center">
          <div className="modal-content text-center">
            {/* <a className="close" href="#" data-dismiss="modal">&times;</a>  */}
            <div className="page-boy text-center">
              <div className="head mt-4">
                <h4
                  style={{ marginTop: '5px' }}
                  className="text-groceryTheam text-center"
                >
                  {Text ? Text : ''}
                </h4>
                <h4 style={{ marginTop: '5px' }}>
                  {Text2 ? (
                    <span className="text-groceryTheam">
                      Welcome&nbsp;to&nbsp;the
                      <span
                        className="vipar"
                        style={{ fontSize: '22px', color: '#99d050' }}
                      >
                        &nbsp;Vipar&nbsp;
                      </span>
                       Family !
                    </span>
                  ) : (
                    ''
                  )}

                  {Text3 ? (
                    <span className="text-groceryTheam text-center">
                      We&nbsp;hope&nbsp;you&nbsp;will&nbsp;join&nbsp;us&nbsp;back&nbsp;soon&nbsp;!
                    </span>
                  ) : (
                    ''
                  )}
                </h4>
              </div>
              <h1 style={{ textAlign: 'center' }} className="mt-3 mb-4">
                <div className="checkmark-circle">
                  <div className="background"></div>
                  <div className="checkmark draw"></div>
                </div>
              </h1>
            </div>
          </div>
        </div>
      </div>

      {/* please  login modal */}

      <button
        type="button"
        id="notLoginModalbtn"
        class="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#notLoginModal"
      >
        please login Modal
      </button>
      <div
        class="modal fade"
        id="notLoginModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              ...
              <button>login</button>
            </div>
          </div>
        </div>
      </div>
      {/* please login modal */}

      {/*change password */}

      <div
        className="modal fade"
        id="ChangePasswordModal"
        aria-hidden="true"
        aria-labelledby="ChangePasswordModal"
        tabIndex="-1"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-login mt-5"
          role="document"
        >
          <div className="modal-content">
            <div className="card card-signup card-plain">
              <div class="modal-header">
                <h4 class="modal-title">Change Password ?</h4>
                <button
                  type="button"
                  id="Changemodalclosebtn"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                <form className="form" method="" action="">
                  <div className="card-body">
                    <div className="form-group bmd-form-group">
                      <div className="input-group">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Current Password"
                          value={change.CurrentPassword}
                          onChange={(e) =>
                            setchange({
                              ...change,
                              CurrentPassword: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group bmd-form-group pt-4">
                      <div className="input-group">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="New Password"
                          value={change.NewPassword}
                          onChange={(e) =>
                            setchange({
                              ...change,
                              NewPassword: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group bmd-form-group pt-4">
                      <div className="input-group">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Confirm New Password"
                          value={change.ConfirmPassword}
                          onChange={(e) =>
                            setchange({
                              ...change,
                              ConfirmPassword: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <p className="text-danger pt-3">{passerr}</p>
                    <div className="form-group bmd-form-group">
                      <div className="input-group d-flex justify-content-center">
                        <button
                          className="btn hhchange rounded-pill px-4 mt-3"
                          style={{ letterSpacing: '1px' }}
                          type="button"
                          onClick={(e) => changepassword()}
                        >
                          CONTINUE
                        </button>
                        <button
                          id="successloginbtn"
                          className="btn d-none btn-groceryTheam rounded-pill px-4"
                          data-bs-target="#success_tic_modal_success"
                          data-bs-toggle="modal"
                          data-bs-dismiss="modal"
                          type="button"
                        >
                          CONTINUE
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*change password */}

      {/* account deletion */}

      <div
        className="modal fade"
        id="accountdeletion"
        aria-hidden="true"
        aria-labelledby="ChangePasswordModal"
        data-bs-backdrop="static"
        tabIndex="-1"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-login mt-5"
          role="document"
        >
          <div className="modal-content">
            <div className="card card-signup card-plain">
              <div class="modal-header">
                <h4 class="modal-title">Account Deletion</h4>
                <button
                  type="button"
                  id="Changemodalclosebtn"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setyes(true)}
                ></button>
              </div>
              <div className="modal-body">
                <form className="form" method="" action="">
                  <div className="card-body p-0">
                    <div className="form-group bmd-form-group">
                      <div className="input-group">
                        <span
                          className="text-danger"
                          style={{ fontSize: '22px' }}
                        >
                          Are&nbsp;you&nbsp;sure&nbsp;you&nbsp;want&nbsp;to&nbsp;leave
                          the
                          <span
                            className="vipar"
                            style={{ fontSize: '25px', color: '#99d050' }}
                          >
                            &nbsp;Vipar&nbsp;
                          </span>
                          &nbsp;Family&nbsp;?
                        </span>
                      </div>
                    </div>

                    <div className="form-group bmd-form-group" hidden={!yes}>
                      <div className="input-group d-flex justify-content-center">
                        <span className="px-2">
                          <button
                            className="btn hhchange rounded-pill px-4 mt-3"
                            style={{ letterSpacing: '1px' }}
                            type="button"
                            onClick={(e) => setyes(false)}
                          >
                            yes
                          </button>
                        </span>
                        <span className="px-2">
                          <button
                            id="Changemodalclosebtn"
                            class="btn-close float-end"
                            data-bs-dismiss="modal"
                            className="btn hhchange rounded-pill px-4 mt-3"
                            style={{ letterSpacing: '1px' }}
                            type="button"
                          >
                            no
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </form>

                <div className="mt-2">
                  <div className=" card-signup card-plain">
                    <div className="" hidden={yes}>
                      <form className="form" method="" action="">
                        <div className="" style={{ border: 'none' }}>
                          <div className="form-group bmd-form-group">
                            <div>
                              <label className="" style={{ fontSize: '20px' }}>
                                Please enter your password
                              </label>
                            </div>
                            <div className="input-group mt-3">
                              <input
                                type="password"
                                className="form-control"
                                placeholder="Password"
                                onChange={(e) =>
                                  setpassdelete({
                                    ...passdelete,
                                    CurrentPassword: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>

                          <p className="text-danger">{accounterr}</p>
                          <div className="form-group bmd-form-group">
                            <div className="input-group d-flex justify-content-center">
                              <button
                                className="btn hhchange rounded-pill px-4 mt-3"
                                style={{ letterSpacing: '1px' }}
                                type="button"
                                onClick={(e) => {
                                  passdeletefun(navigate);
                                }}
                              >
                                DELETE
                              </button>
                              <button
                                id="successloginbtn"
                                className="btn d-none btn-groceryTheam rounded-pill px-4"
                                data-bs-target="#success_tic_modal_success"
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                                type="button"
                              >
                                DELETE
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* account deletion */}

      {/* non login members modal */}
      <div
        className="modal fade"
        id="RegisterModallo"
        aria-hidden="true"
        aria-labelledby="RegisterModalLabel"
        tabIndex="-1"
      >
        <center style={{ height: '100dvh' }}>
          <div
            className="modal-dialog modal-dialog-centered modal-login "
            role="document"
          >
            <div className="modal-content">
              <div className="card card-signup card-plain">
                <div className="modal-header px-2">
                  <div className="card-header card-header-primarys text-center bg-white m-0">
                    <button
                      type="button"
                      className="btn-close float-end close-button-modal "
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    {/* <h4 className="card-title text-black">SIGN IN / LOG IN</h4> */}
                    <h5
                      className=" text-black ftte"
                      style={{
                        textAlign: 'left',
                      }}
                    >
                      To&nbsp;Purchase&nbsp;Products&nbsp;from
                      <span
                        className="vipar"
                        style={{ fontSize: '22px', color: '#99d050' }}
                      >
                        &nbsp;Vipar&nbsp;
                      </span>
                      &nbsp;CHIC,&nbsp;you&nbsp;must&nbsp;be&nbsp;a member.
                    </h5>
                  </div>
                </div>
                <div className="modal-body">
                  <form className="form" method="" action="">
                    <div className="card-body">
                      <div className="mb-3 ms-0">
                        <p className="text-dark">
                          Sign In / Log In if you are a member
                        </p>
                      </div>
                      <div className="form-group ">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email Id"
                            onChange={(e) =>
                              setuserlogin({
                                ...userlogin,
                                Email: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group ">
                        <div className="input-group">
                          <input
                            type="password"
                            placeholder="Password"
                            className="form-control"
                            onChange={(e) =>
                              setuserlogin({
                                ...userlogin,
                                Password: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="float-start">
                        <a
                          href=""
                          data-bs-target="#ForgetPasswordModal"
                          data-bs-toggle="modal"
                          data-bs-dismiss="modal"
                          style={{ fontSize: '10px' }}
                        >
                          Forgot password ?
                        </a>
                      </div>
                      <br />
                      <div className="form-group bmd-form-group">
                        <p className="text-danger">{lerr}</p>
                        <div className="input-group d-flex justify-content-center">
                          {!logloading && (
                            <button
                              className="btn btn-groceryTheam rounded-pill px-4"
                              type="button"
                              onClick={(e) => login()}
                            >
                              Log In
                            </button>
                          )}
                          {logloading && (
                            <button
                              disabled
                              className="btn btn-groceryTheam rounded-pill px-4"
                              type="button"
                            >
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </button>
                          )}

                          <button
                            id="successloginbtn"
                            className="btn d-none btn-groceryTheam rounded-pill px-4"
                            data-bs-target="#success_tic_modal_success"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                            type="button"
                          >
                            Log In
                          </button>
                        </div>
                        <br />
                        <div className="input-group d-flex justify-content-center">
                          <a
                            className="text-center text-dark"
                            data-bs-target="#loginModal"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                            style={{ cursor: 'pointer' }}
                          >
                            Not a member ?
                            <button
                              style={{ letterSpacing: '1px' }}
                              className=" btnoutt ms-2"
                            >
                              REGISTER
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </center>
      </div>

      {/* non login members modal */}

      {/* deletion password */}

      {/* <div
        className="modal fade"
        id="deletepassword"
        aria-hidden="true"
        aria-labelledby="ChangePasswordModal"
        tabIndex="-1"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-login mt-5"
          role="document"
        >
          <div className="modal-content">
            <div className="card card-signup card-plain">
              <div class="modal-header">
                <h4 class="modal-title">Account Deletion</h4>
                <button
                  type="button"
                  id="Changemodalclosebtn"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                <form className="form" method="" action="">
                  <div className="card-body">
                    <div className="form-group bmd-form-group">
                      <div className="input-group">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Enter Your Password"
                          onChange={(e) =>
                            setpassdelete({
                              ...passdelete,
                              CurrentPassword: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <p className="text-danger">{accounterr}</p>
                    <div className="form-group bmd-form-group">
                      <div className="input-group d-flex justify-content-center">
                        
                        <button
                          className="btn hhchange rounded-pill px-4 mt-3"
                          style={{ letterSpacing: '1px' }}
                          type="button"
                          onClick={(e) => {
                            passdeletefun(navigate);
                          }}
                        >
                          DELETE
                        </button>
                        <button
                          id="successloginbtn"
                          className="btn d-none btn-groceryTheam rounded-pill px-4"
                          data-bs-target="#success_tic_modal_success"
                          data-bs-toggle="modal"
                          data-bs-dismiss="modal"
                          type="button"
                        >
                          DELETE
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* deletion password */}
    </>
  );
};
export default App;
