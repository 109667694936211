import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { keyGen, notify } from '../../App';

export const Coupenviewadmin = () => {
  const [index, setIndex] = useState(null);
  const [editvalue, setEditvalue] = useState({
    Amount: '',
    Coupen: '',
    Status: '',
    MinimumOrderValue: '',
    MaximumDiscount: '',
  });
  const save = () => {
    axios
      .post('/adminlogin/insertvalue', { editvalue })
      .then((res) => {
        setIndex();
        edithandleClose();
        let data = [...state];
        data[index] = editvalue;
        setState([...data]);
        notify('value updated', true);
      })
      .catch((err) => {
        console.log(err);
        notify('value not updated', false);
      });
  };

  const [editshow, editsetShow] = useState(false);
  const edithandleClose = () => editsetShow(false);
  const edithandleShow = (id, index) => {
    editsetShow(true);
    setEditvalue({ ...state[index] });
  };
  const [deleteshow, deletesetShow] = useState(false);
  const deletehandleClose = () => deletesetShow(false);
  const deletehandleShow = (id, index) => {
    setDeletevalue({ id, index });

    deletesetShow(true);
  };

  const [state, setState] = useState([]);
  useEffect(() => {
    axios
      .get('/adminlogin/coupenview')
      .then((res) => {
        setState(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {};
  }, []);
  const [deletevalue, setDeletevalue] = useState({
    id: '',
    index: '',
  });

  const confirm = (e) => {
    e.preventDefault();
    axios
      .post('/adminlogin/deletecoupen', { deletevalue })
      .then((res) => {
        let temp = [...state];
        temp.splice(deletevalue.index, 1);
        setState(temp);
        setDeletevalue({
          id: '',
          index: '',
        });
        notify('value deleted', true);

        deletehandleClose();
      })
      .catch((err) => {
        console.log(err);
        notify('something went wrong', false);
      });
  };

  return (
    <>
      <div className="page-breadcrumb ">
        <div
          className="row align-items-center text-white"
          // style={{ backgroundColor: 'black' }}
        >
          <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
            <h4 className="page-title text-white">Coupen View</h4>
          </div>
          <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
            <div className="d-md-flex">
              <ol className="breadcrumb ms-auto"></ol>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid overflow-scroll mt-3"
        style={{ backgroundColor: 'white' }}
      >
        <div className="row pt-3">
          <div className="col-md-12">
            <div className="white-box">
              <table className="table ">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Coupen Name</th>
                    <th scope="col">Expiry</th>
                    <th scope="col">UseCount</th>

                    <th scope="col">Amount</th>

                    {/* <th scope="col">Status</th> */}
                    <th scope="col">Calc </th>
                    {/* <th scope="col">Products </th> */}
                    <th scope="col">Minordervalue </th>
                    <th scope="col">Maxdiscount </th>
                  </tr>
                </thead>
                <tbody>
                  {state.map((element, index) => {
                    return (
                      <tr key={keyGen()}>
                        <td>{index + 1}</td>
                        <td>{element.Coupen}</td>
                        <td>{element.Expiry}</td>
                        <td>{element.UseCount}</td>
                        <td>{element.Amount}</td>
                        {/* <td>{element.Status}</td> */}
                        <td>{element.Calc}</td>
                        {/* <td>{element.Products}</td> */}
                        <td>{element.MinimumOrderValue}</td>
                        <td>{element.MaximumDiscount}</td>

                        <td>
                          <button
                            onClick={() => {
                              setIndex(index);
                              edithandleShow(element._id, index);
                            }}
                            className="btn btn-success"
                          >
                            Edit
                          </button>
                        </td>
                        <td>
                          <button
                            onClick={() => deletehandleShow(element._id, index)}
                            className="btn btn-danger"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal
        centered
        show={deleteshow}
        onHide={deletehandleClose}
        animation={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={(e) => confirm(e)}>
              <h5>
                Are you sure do you want to delete{' '}
                <span className="text-danger">
                  {state[deletevalue?.index]?.Coupen}
                </span>
                ?
              </h5>
              <center>
                <button className="btn btn-primary">Confirm</button>
              </center>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        show={editshow}
        onHide={edithandleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-3">
                <label>coupen name:</label>
              </div>
              <div className=" col-9  ">
                <input
                  type="text"
                  className="form-control mt-1"
                  id="email"
                  name=""
                  required
                  placeholder="coupen name"
                  value={editvalue.Coupen}
                  onChange={(e) =>
                    setEditvalue({ ...editvalue, Coupen: e.target.value })
                  }
                />
              </div>
              <div className="col-3">
                <label>Minordervalue:</label>
              </div>
              <div className=" col-9  ">
                <input
                  type="text"
                  className="form-control mt-1"
                  id="email"
                  name=""
                  required
                  placeholder="Minordervalue"
                  value={editvalue.MinimumOrderValue}
                  onChange={(e) =>
                    setEditvalue({
                      ...editvalue,
                      MinimumOrderValue: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-3">
                <label>Maxdiscount:</label>
              </div>
              <div className=" col-9  ">
                <input
                  type="text"
                  className="form-control mt-1"
                  id="email"
                  name=""
                  required
                  placeholder="Maxdiscount"
                  value={editvalue.MaximumDiscount}
                  onChange={(e) =>
                    setEditvalue({
                      ...editvalue,
                      MaximumDiscount: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-3">
                <label>Amount:</label>
              </div>
              <div className=" col-9  ">
                <input
                  type="text"
                  className="form-control mt-1"
                  id="email"
                  name=""
                  required
                  placeholder="Amount"
                  value={editvalue.Amount}
                  onChange={(e) =>
                    setEditvalue({ ...editvalue, Amount: e.target.value })
                  }
                />
              </div>

              <div className="col-3">
                <label>Status:</label>
              </div>
              <div className=" col-9  ">
                <input
                  type={'checkbox'}
                  onChange={(e) =>
                    setEditvalue({ ...editvalue, Status: !editvalue.Status })
                  }
                  className="form-check"
                  aria-label="Default select example"
                  checked={editvalue.Status}
                />
              </div>
              <div className="mt-2">
                <button
                  className="btn btn-outline-dark float-end"
                  type="button"
                  onClick={save}
                >
                  Save
                </button>
              </div>
            </div>

            <div>{/* <p className="text-danger">{err}</p> */}</div>
            <hr />
            <div></div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
