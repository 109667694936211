import React from 'react';
import { Link } from 'react-router-dom';
import './Reviewmodal.css';
export const Reviewmodal = () => {
  return (
    <div>
      <div id="reviewmodal">
        <div id="success_tic">
          <div className="body">
            <div class="modal-body">
              <div class="modal-content" style={{ height: '100vh' }}>
                <div class="page-body" style={{ backgroundColor: '#1d2630' }}>
                  <div class="head">
                    <h3 className="text-white" style={{ marginTop: '5px' }}>
                      Thank you for your
                    </h3>
                    <h4 className="text-white">valuable feedback</h4>
                  </div>

                  <h1 style={{ textAlign: 'center' }}>
                    <div class="checkmark-circle">
                      <div class="background"></div>
                      <div class="checkmark draw"></div>
                    </div>
                  </h1>

                  <div>
                    <Link
                      to="/articles"
                      className="btn btn-success d-flex justify-content-center mt-4"
                    >
                      Go to shopping
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
