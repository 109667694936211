import React from 'react';

export const Confirmorderadmin = () => {
  return (
    <>
      <div className="page-breadcrumb bg-white">
        <div className="row align-items-center">
          <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
            <h4 className="page-title">Confirmed Orders</h4>
          </div>
          <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
            <div className="d-md-flex">
              <ol className="breadcrumb ms-auto"></ol>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="white-box">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Order id</th>

                    <th scope="col">Products</th>
                    <th scope="col">Date</th>
                    <th scope="col">Tracking id</th>
                    <th scope="col">Email</th>
                    <th>Phone number</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
