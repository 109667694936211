import { modalClasses } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import './About.css';
function About() {
  let modal = useSelector((state) => {
    return state.modal.value;
  });
  console.log(modal);
  return (
    <>
      <hr
        className="mt-0"
        style={{
          color: 'rgb(1, 32, 96)',
          opacity: '1',
          borderTop: '3px solid',
        }}
      />
      {/* <section className="bgvipar ">
        <div className="container w-100">
          <div className=" align-items-center py-2 py-md-3 px-2">
            <p
              style={{ fontSize: '15px', fontFamily: 'calibri' }}
              className="m-0 mt-3 text-light "
            >
              We are an online retail store for unique, innovative accessories
              and articles, and exquisite art including
              <span
                className="vipar"
                style={{ fontSize: '22px', color: '#99d050' }}
              >
                &nbsp;Vipar
              </span>
              ‘s own creations.
            </p>
            <p className="text-light mt-2" style={{ fontFamily: 'calibri' }}>
              Type of Business : Retailers{' '}
            </p>
            <p className="text-light" style={{ fontFamily: 'calibri' }}>
              {' '}
              Type of Entity : E-commerce{' '}
            </p>
          </div>
        </div>
      </section> */}
      {/* <section className="bgvipar d-none d-md-block">
        <div className="container d.none w-100">
          <div className="row align-items-center py-2 py-md-3">
            <div className="col-md-4 d-flex justify-content-center">
              <div className="row">
                <div className="col-12">
                  <center>
                    <img className="imagesize" src="/img/realicon.png" alt="" />
                  </center>
                </div>
                <div className="col-12">
                  <center>
                    <h3>
                      <span className="vipiargrrencolforabout monot">
                        Vipar&nbsp;
                      </span>
                      <span className="abouttextcon">-&nbsp;chic</span>
                      <br />
                      <span className="abouttextname2">
                        &nbsp;so chic.&nbsp;so unique.
                      </span>{' '}
                    </h3>
                  </center>
                </div>
              </div>
            </div>
            <div className="col-md-8 ">
              <p className="toperrrr">
                <span className="blueicon2">c</span>
                <span className="abouttextname">ollection&nbsp;of</span>
                <span className="blueicon2"> h</span>
                <span className="abouttextname">ome décor&nbsp;and</span>
                <span className="blueicon2"> i</span>
                <span className="abouttextname">nnovative</span>&nbsp;&nbsp;
                <span className="blueicon2">c</span>
                <span className="abouttextname">ommodities</span> <br />
              </p>
            </div>
          </div>
        </div>
        <div>
          <center>
            <span className="pfont abouttextname">
              <span className="vipar monot">Vipar&nbsp;</span>
              <span className="chicc">
                -&nbsp;chic is an online retail store of{' '}
              </span>
              <span className="vipar monot">Vipar&nbsp;</span>
              <sup className="reg">&reg;</sup>
            </span>
            <br />
          </center>
        </div>
      </section> */}
      {/* <!-- Close Banner -->

    <!-- Start Section --> */}
      <section className="container py-2 py-md-3">
        <div className=" text-center pb-3">
          <div className="col-lg-6 m-auto">
            <h1 className="h1" style={{ fontFamily: 'FootlightMTLight' }}></h1>
            {/* <!-- <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                    Lorem ipsum dolor sit amet.
                </p> --> */}
          </div>
        </div>
        <div className="container">
          <div className="row g-3 g-md-5">
            <div
              className="col-6 col-lg-3"
              onClick={(e) => {
                modal.toumodal();
              }}
            >
              <div
                className="h-100 py-2 py-md-3 services-icon-wap shadow"
                style={{ backgroundColor: 'rgb(255 255 255)' }}
              >
                <div className="h1 text-vio text-center">
                  <i className="fa fa-address-book"></i>
                </div>
                <p className=" mt-4 text-center fs">Terms of use</p>
              </div>
            </div>
            <div className="col-6 col-lg-3" onClick={modal.ppmodal}>
              <div
                className="h-100 py-2 py-md-3 services-icon-wap shadow"
                style={{ backgroundColor: 'rgb(255 255 255)' }}
              >
                <div className="h1 text-vio text-center">
                  <i
                    className="far fa-eye-slash"
                    style={{ fontSize: '35px' }}
                  ></i>
                </div>
                <p className=" mt-4 text-center fs">Privacy Policy</p>
              </div>
            </div>

            <div className="col-6 col-lg-3 " onClick={modal.dpmodal}>
              <div
                className="h-100 py-2 py-md-3 services-icon-wap shadow"
                style={{ backgroundColor: 'rgb(255 255 255)' }}
              >
                <div className="h1 text-vio text-center">
                  <i className="fa fa-truck fa-lg"></i>
                </div>
                <p className="fs mt-4 text-center">Delivery Policy</p>
              </div>
            </div>

            <div className="col-6 col-lg-3" onClick={modal.epmodal}>
              <div
                className="h-100 py-2 py-md-3 services-icon-wap shadow"
                style={{ backgroundColor: 'rgb(255 255 255)' }}
              >
                <div className="h1 text-vio text-center">
                  <i
                    className="fas fa-exchange-alt"
                    style={{ fontSize: '35px' }}
                  ></i>
                </div>
                <p className="fs mt-4 text-center">Exchange Policy</p>
              </div>
            </div>
          </div>
        </div>

        <hr />
        <span style={{ fontSize: '20px' }}>
          POLICIES of every online shopping site are to be read clearly and
          understood before placing an order. PLEASE do so to avoid unnecessary
          confusion for either parties.
        </span>
        <br />
      </section>
      {/* <!-- End Section -->

    <!-- Start Brands -->
    
    <!--End Brands--> */}

      <div id="termsofmodal" className="modal">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <center>
                    <h3 className="modal-title mb-5">TERMS OF USE</h3>
                  </center>
                  <p className="mb-5">
                    We value the trust you place in{' '}
                    <span className="vipar" style={{ fontSize: '22px' }}>
                      Vipar
                    </span>
                    . That is why we insist upon your reading these Terms of Use
                    carefully. The Terms of Use and policies about Privacy,
                    Delivery and Exchange together shall form the entire ‘Terms
                    of Use’.
                  </p>
                  <h5 className="mb-3">
                    <b>1] WHAT IS THIS DOCUMENT</b>
                  </h5>
                  <p className="mb-5">
                    These terms of use, read together with the (i) privacy
                    policy, (ii) delivery policy, and (iii) exchange policy
                    constitute a legal and binding agreement between you and
                    VIPAR, a Proprietary concern, having its registered office
                    at 101, Aadheesh Avenue, Kalakunj road, Mangaluru – 575003
                    (“VIPAR”). The Agreement, inter alia, provides the terms
                    that govern your access to use (i) VIPAR’s website
                    www.vipar.in (“Site”), and (ii) the purchase of Products,
                    and any other service that may be provided by VIPAR from
                    time to time (collectively referred to as the “Services”).
                    You hereby understand and agree that the Agreement forms a
                    binding contract between VIPAR and anyone who accesses,
                    browses, or purchases the Products and uses the Services in
                    any manner (“User”) and accordingly, you hereby agree to be
                    bound by the terms contained in the Agreement. If you do not
                    agree to the terms contained in the Agreement, you are
                    advised not to proceed with purchasing the Products or using
                    the Services. The terms contained in the Agreement shall be
                    accepted without modification. The use of the Services would
                    constitute acceptance of the terms of the Agreement.
                  </p>
                  <h5 className="mb-3">
                    <b>2] ELECTRONIC COMMUNICATION</b>
                  </h5>
                  <p className="mb-5">
                    When you visit the Site, or send us e-mails, you are
                    communicating with us electronically. Also when you place an
                    order as a guest or by registering on the website, you are
                    accepting the terms of service. In doing so, you consent to
                    receive communications from us electronically. We will
                    communicate with you by e-mail or by posting notices on this
                    Site. You agree that all agreements, notices, disclosures
                    and other communications that we provide to you
                    electronically satisfy any legal requirement that such
                    communication be in writing.
                  </p>
                  <h5 className="mb-3">
                    <b>3] ELIGIBILITY</b>
                  </h5>
                  <p className="mb-5">
                    Users must be 18 years of age or older to register, or visit
                    or use the Services in any manner. By registering, visiting
                    or using the Services, you hereby represent and warrant to
                    VIPAR that you are 18 years of age or older, and that you
                    have the right, authority and capacity to use the Services,
                    and agree to abide by the Agreement. If a User is below 18
                    years of age, it is assumed that he/she is using/browsing
                    the Platforms under the supervision of his/her parent or
                    legal guardian and that such User’s parent or legal guardian
                    has read and agrees to the terms of this Agreement,
                    including terms of purchase of Products, on behalf of the
                    minor User. Should VIPAR be made aware that a User is under
                    the age of 18 and is using/browsing the Platforms without
                    the supervision of his/her parent or legal guardian, VIPAR
                    reserves the right to deactivate such User’s account without
                    further notice. If you are using the Site on behalf of a
                    company, entity, or organization (collectively
                    “Organization”), then you represent and warrant that you (a)
                    are an authorized representative of that Organization; (b)
                    have the authority to bind that Organization to these Terms;
                    and (c) agree to be bound by these Terms on behalf of that
                    Organization.
                  </p>
                  <h5 className="mb-3">
                    <b>4] COPYRIGHT</b>
                  </h5>
                  <p className="mb-5">
                    Images of people, places and/or products posted on this Site
                    are either the property of VIPAR or our licensors. Unless
                    otherwise noted, all content included on this Site,
                    including images, illustrations, designs, icons,
                    photographs, video clips and written and other materials
                    (together, with “Marks” (as defined below), “VIPAR Content”
                    is the property of VIPAR or its licensors, or affiliates and
                    is protected by law. The compilation of this Site is the
                    exclusive property of VIPAR and is protected by law. Any
                    unauthorized use of any content or materials on this Site is
                    strictly prohibited and may violate copyright and trademark
                    laws, and/or the laws of privacy, publicity, and/or
                    communications regulations and statutes. You may use the
                    materials or content on this Site only with our prior
                    written and express authorization. To inquire about
                    obtaining authorization to use the materials or content on
                    this Site, please contact us at connect@vipar.in
                  </p>
                  <h5 className="mb-3">
                    <b>5] TRADEMARKS</b>
                  </h5>
                  <p className="mb-5">
                    All trademarks, service marks, and trade names (collectively
                    the “Marks”) that appear on this Site are proprietary to
                    VIPAR, or the other respective owners of such Marks. You may
                    not display or reproduce the Marks in any manner without the
                    prior written consent of VIPAR, and you may not remove or
                    otherwise modify in any manner any trademark notices from
                    any content offered or received through the Site.
                  </p>
                  <h5 className="mb-3">
                    <b>6] LIMITED LICENSE AND SITE ACCESS</b>
                  </h5>
                  <p className="mb-5">
                    We grant you a limited license to use the Site for personal
                    use only. Consequently, this grant does not allow you to do
                    any of the following: (a) resell or make any commercial use
                    of this Site or any of the contents of this Site; (b)
                    modify, adapt, translate, reverse engineer, decompile,
                    disassemble or convert into human readable form any of the
                    contents of this Site not intended to be so read. This
                    includes using or directly viewing the underlying HTML or
                    other code from this Site except as interpreted and
                    displayed in a web browser; (c) copy, imitate, mirror,
                    reproduce, distribute, publish, download, display, perform,
                    post or transmit any of the contents of this Site (including
                    any Marks) in any form or by any means, including, but not
                    limited to, electronic, mechanical, photocopying, recording
                    or otherwise; or (d) use any data mining, bots, spiders,
                    automated tools or similar data gathering and extraction
                    methods on the contents of the Site or to collect any
                    information from the Site or any other user of the Site.
                  </p>
                  <h5 className="mb-3">
                    <b>7] PRICE AND PAYMENTS</b>
                  </h5>
                  <p className="mb-5">
                    Prices:- The price for Products available for purchase
                    through the Site will be displayed to you on the Site. Any
                    applicable taxes or delivery charges (if any) will be
                    communicated to you before you place an order. Payments:-
                    The Site currently uses third parties to process payments.
                    Our third-party payment processors accept payments through
                    various credit cards, debit cards and net banking as
                    detailed on the applicable payment screen.
                  </p>
                  <h5 className="mb-3">
                    <b>8] GIFT AND OFFER CODES</b>
                  </h5>
                  <p className="mb-5">
                    From time to time, VIPAR may offer qualified consumers “gift
                    codes” or “offer codes” through a variety of promotional
                    activities and communications (collectively referred to
                    herein as “offer codes”) that are redeemable towards a
                    purchase on www.vipar.in, while supplies last, and subject
                    to certain merchandise exclusions or any other restrictions
                    as may be determined and communicated by VIPAR in its sole
                    discretion. Only valid offer codes provided or promoted by
                    VIPAR will be honoured at checkout. Codes supplied or
                    promoted by third parties unauthorized by VIPAR (including
                    any unauthorized third party websites) will not be
                    considered valid. Each offer code promoted by VIPAR is
                    non-transferable and valid for single use on an item (or
                    items) of merchandise as determined by VIPAR. Offer codes
                    cannot be used towards purchase of non-branded merchandise,
                    packaging or applicable taxes. For online purchases, the
                    code must be entered in the ‘offer code’ field at checkout.
                    Offer codes are only valid for purchases made through
                    www.vipar.in. VIPAR is not responsible for lost, stolen or
                    corrupted codes or any unauthorized use of codes. Offer
                    codes cannot be redeemed for cash or any cash equivalent; no
                    substitutions or credits allowed. Expiry dates may apply to
                    each offer code. Offer codes are void if copied,
                    transferred, sold, exchanged or expired, and where
                    prohibited. Requests not complying with all offer code
                    requirements will not be honoured.
                  </p>
                  <h5 className="mb-3">
                    <b>9] PACKAGING PROTOCALS FOLLOWED BY VIPAR</b>
                  </h5>
                  <p className="mb-5">
                    VIPAR follows a distinct packaging procedure where it packs
                    all its products with a unique VIPAR seal tape to ensure
                    that no unauthorised tampering or meddling is done after the
                    product is shipped from the VIPAR’s warehouse. Therefore if
                    any such unauthorised tampering is found at the time of
                    delivery then the User is advised to kindly refuse the
                    delivery of such package and follow the protocols mentioned
                    under clause 10 of this terms of use.
                  </p>
                  <h5 className="mb-3">
                    <b>10] OBLIGATION / DUTY</b>
                  </h5>
                  <p className="mb-5">
                    i] That at the time of delivery, if the User/purchaser feels
                    that the packaging is tampered with (that is, if the VIPAR
                    seal tape is torn to open or if the VIPAR seal tape is
                    completely missing), then the User shall kindly
                    reject/refuse to take the delivery of such package. ii] That
                    at the time of delivery, if the User/purchaser feels that
                    the packaging is not tampered with, then the User shall as a
                    protocol, open the packaging in the presence of the delivery
                    agent so as to ensure that the package delivered to him/her
                    is not empty. That any claim by the purchaser citing
                    delivery of empty package (i.e. empty box without the
                    product/article inside the package) shall not be entertained
                    by VIPAR if the aforementioned precaution/procedure is not
                    complied with and VIPAR shall not to liable in any manner
                    whatsoever if such claims are made without following the
                    protocol. That in an event where the package is found to be
                    empty on inspection of the same in the presence of the
                    delivery agent or if the User feels that the packaging has
                    been tampered with, then the purchaser/user shall kindly
                    refuse to accept the delivery and whatsapp us at +91
                    948-148-0022 or e-mail us at connect@vipar.in mentioning the
                    order reference number within 24 hours from the time of
                    delivery.
                  </p>
                  <h5 className="mb-3">
                    <b>11] WARRANTY</b>
                  </h5>
                  <p className="mb-5">
                    All the products included on or otherwise made available to
                    you by VIPAR through the Site or otherwise are provided
                    without any representation or warranties, express or implied
                    except otherwise specified in writing.
                  </p>
                  <h5 className="mb-3">
                    <b>12] IMAGES</b>
                  </h5>
                  <p className="mb-5">
                    Product images are for illustrative purposes only and may
                    have minor variations from the actual product. Due to
                    differences in monitors and its picture quality and
                    resolutions, colours of products may also appear different
                    to those shown on the site. The images on www.vipar.in are
                    copyright protected and shall not be used without prior
                    written consent of VIPAR.
                  </p>
                  <h5 className="mb-3">
                    <b>13] FEEDBACK</b>
                  </h5>
                  <p className="mb-5">
                    We appreciate hearing from our users and welcome your
                    comments regarding the Site. Please be advised, however,
                    that if you send us creative ideas, suggestions, inventions,
                    or materials (“Creative Ideas”), we will (a) own,
                    exclusively, all now known or later discovered rights to the
                    Creative Ideas; (b) not be subject to any obligation of
                    confidentiality and will not be liable for any use or
                    disclosure of any Creative Ideas; and (c) be entitled to
                    unrestricted use of the Creative Ideas for any purpose
                    whatsoever, commercial or otherwise, without compensation to
                    you or any other person.
                  </p>
                  <h5 className="mb-3">
                    <b>14] CONTENT YOU SUBMIT TO VIPAR</b>
                  </h5>
                  <p className="mb-5">
                    From time to time, VIPAR may allow you to post reviews,
                    comments, photos, or similar materials on the Site
                    (collectively, “Reviews”). We welcome your comments
                    regarding our Products and services, including our Site.
                    However, any Reviews you submit to VIPAR must not harass,
                    slander, malign, libel, defame, threaten, or otherwise
                    violate any rights of any third-parties and must not include
                    any profanity, obscene, indecent, pornographic, defamatory
                    or unlawful material. By posting a Review on the Site, you
                    hereby grant VIPAR an unrestricted, assignable, sub
                    licensable, perpetual royalty-free, fully paid up license
                    throughout the world to reproduce, distribute, publicly
                    display, transmit, communicate to the public, publicly
                    perform on a through-to-the-audience basis, create
                    derivative works from, and otherwise use and exploit
                    (collectively, “Use”) all Reviews you post to the Site, for
                    any purpose, including promoting and marketing VIPAR and the
                    Products. You further grant VIPAR a royalty-free license to
                    Use the name, image, and likeness of any person identifiable
                    in any Review you post to the Site. By posting a Review, you
                    waive any and all rights of publicity, privacy or any other
                    rights of a similar nature, in addition to any moral rights
                    you may have in your Review. Except for materials provided
                    to you by VIPAR through the Site, you represent, warrant,
                    and covenant that (i) you either are the sole and exclusive
                    owner of Reviews that you post to the Site, or you have all
                    rights, licenses, consents, and releases that are necessary
                    to grant to VIPAR the rights in your Reviews as contemplated
                    under the Terms, and (ii) the Reviews you post to the Site
                    do not (A) infringe, misappropriate or violate the rights of
                    any party or entity, including a third party’s patent,
                    copyright, trademark, trade secret, moral rights, rights of
                    publicity, rights of privacy or other intellectual property
                    or proprietary rights, (B) constitute or result in
                    defamation, libel, slander, or the violation of any
                    applicable law or regulation (including, without limitation,
                    restrictions on obscenity, wiretap laws or other similar
                    restrictions on non-consensual recording, or otherwise), or
                    (C) require VIPAR to obtain any licenses from or make any
                    payments in any amounts to any third party throughout the
                    world. No Objectionable Content:- You agree not to submit
                    Reviews that: (i) are or could be interpreted to be
                    infringing, defamatory, libelous, inaccurate, unlawful,
                    harmful, threatening, abusive, harassing, vulgar, offensive,
                    obscene, objectionable, hateful, or promotes discrimination,
                    bigotry, racism, or hatred, as determined by VIPAR in its
                    sole discretion; or (ii) introduce viruses, time-bombs,
                    worms, cancel bots, Trojan Horses and/or other harmful or
                    malicious code. Monitoring:- VIPAR has no obligation to
                    monitor this Site or any portion thereof. However, we
                    reserve the right to review any Reviews and remove, delete,
                    redact or otherwise modify such Reviews, in our sole
                    discretion, at any time and from time to time, without
                    notice or further obligation to you. VIPAR has no obligation
                    to display or post any Reviews. VIPAR, subject to our
                    Privacy Policy, reserves the right to disclose, at any time
                    and from time to time, any information or posted content
                    that it deems necessary or appropriate, including without
                    limitation to satisfy any applicable, law, regulation,
                    contractual obligation, legal, dispute process, or
                    governmental request.
                  </p>
                  <h5 className="mb-3">
                    <b>15] USE OF THE SITE</b>
                  </h5>
                  <p className="mb-5">
                    You agree to use the Site only for its intended purpose. You
                    must use the Site in compliance with all privacy, data
                    protection, intellectual property, and other applicable
                    laws. The following uses of the Site are prohibited. You may
                    not: · attempt to interfere with, harm, reverse engineer,
                    steal from, or gain unauthorized access to the Site, user
                    accounts, or the technology and equipment supporting the
                    Site; · frame or link to the Site without permission; · use
                    data mining, robots, or other data gathering devices on or
                    through the Site; · post incomplete, false, or misleading
                    information, impersonate another person, or misrepresent
                    your affiliation with a person or entity; · disclose
                    personal information about another person or harass, abuse,
                    or post objectionable material; · sell, transfer, or assign
                    any of your rights to use the Site to a third party without
                    our express written consent; · post advertising or marketing
                    links or content, except as specifically allowed by these
                    Terms; · use the Site in an illegal way or to commit an
                    illegal act in relation to the Site or that otherwise
                    results in fines, penalties, and other liability to VIPAR or
                    others; or · Access the Site from a jurisdiction where it is
                    illegal or unauthorized.
                  </p>
                  <h5 className="mb-3">
                    <b>16] DISCLAIMER</b>
                  </h5>
                  <p className="mb-5">
                    We do not represent or guarantee the truthfulness, accuracy,
                    or reliability of content, posted by third parties. You
                    accept that any reliance on material posted by third-party
                    service providers will be at your own risk. By using the
                    Site you accept the risk that you might be exposed to
                    content that is objectionable or otherwise inappropriate. We
                    may change, suspend, or discontinue any aspect of the Site
                    at any time, including hours of operation or availability of
                    the Site or any feature, without notice or liability. User
                    Disputes:- We are not responsible for any disputes or
                    disagreements between you and any third party you interact
                    with using the Site. You assume all risk associated with
                    dealing with third parties. You agree to resolve disputes
                    directly with the other party. You release VIPAR of all
                    claims, demands, and damages in disputes among users of the
                    Site. You also agree not to involve us in such disputes. Use
                    caution and common sense when using the Site. Content
                    Accuracy:- We make no representations about accuracy,
                    reliability, completeness, or timeliness of any contents of
                    the Site. Similarly, we make no representations about
                    accuracy, reliability, completeness, or timeliness of any
                    data from a third-party service provider or the quality or
                    nature of third-party products or services obtained through
                    the Services. Use the Site at your own risk. We make no
                    promises and disclaim all liability of specific results from
                    the use of the Site. Released Parties Defined:- “RELEASED
                    PARTIES” include VIPAR and its affiliates, officers,
                    employees, agents, partners, and licensors. YOU EXPRESSLY
                    UNDERSTAND AND AGREE THAT: (A) YOUR USE OF THE SERVICES IS
                    AT YOUR SOLE RISK, AND THE SERVICES ARE PROVIDED ON AN “AS
                    IS” AND “AS AVAILABLE” BASIS AND THE RELEASED PARTIES
                    EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER
                    EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
                    WARRANTIES AS TO PRODUCTS OR SERVICES LISTED ON THE SITE,
                    IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                    PARTICULAR PURPOSE, AND NON-INFRINGEMENT; (B) THE RELEASED
                    PARTIES MAKE NO WARRANTY THAT (i) THE SITE WILL MEET YOUR
                    REQUIREMENTS, (ii) THE SITE WILL BE UNINTERRUPTED, TIMELY,
                    SECURE, OR ERROR-FREE, (iii) THE RESULTS THAT MAY BE
                    OBTAINED FROM THE USE OF THE SITE WILL BE ACCURATE OR
                    RELIABLE, (iv) THE QUALITY OF ANY GOODS OR SERVICE AVAILABLE
                    ON THE SITE WILL MEET YOUR EXPECTATIONS, OR (v) ANY ERRORS
                    IN THE SITE WILL BE CORRECTED; AND (C) ANY MATERIAL
                    DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SITE
                    IS ACCESSED AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE
                    SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR
                    MOBILE DEVICE THAT RESULTS FROM THE USE OF ANY SUCH
                    MATERIAL.
                  </p>
                  <h5 className="mb-3">
                    <b>17] WHAT IS THIS DOCUMENT</b>
                  </h5>
                  <p className="mb-5">
                    YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE RELEASED PARTIES
                    WILL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT,
                    INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES,
                    INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS,
                    GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF
                    VIPAR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES),
                    RESULTING FROM: (i) THE USE OR THE INABILITY TO USE THE
                    SITE; (ii) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND
                    SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR
                    SERVICES OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS
                    ENTERED INTO THROUGH, FROM, OR AS A RESULT OF THE SITE;
                    (iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
                    TRANSMISSIONS OR DATA; (iv) STATEMENTS OR CONDUCT OF ANY
                    USER OR THIRD PARTY ON THE SITE; (v) YOUR RELIANCE ON
                    CONTENT MADE AVAILABLE BY US; OR (vi) ANY OTHER MATTER
                    RELATING TO THE SITE. SOME JURISDICTIONS DO NOT ALLOW THE
                    EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR
                    EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL
                    DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS IN THIS
                    PARAGRAPH MAY NOT APPLY TO YOU. TO THE FULLEST EXTENT
                    POSSIBLE BY LAW, THE RELEASED PARTIES’ MAXIMUM LIABILITY
                    ARISING OUT OF OR IN CONNECTION WITH THE SITE OR YOUR USE OF
                    VIPAR CONTENT, REGARDLESS OF THE CAUSE OF ACTION (WHETHER IN
                    CONTRACT, TORT, OR OTHERWISE), WILL NOT EXCEED INR1000.
                    <br />
                    You agree to defend, indemnify, and hold harmless the
                    Released Parties from and against any claims, actions, or
                    demands, including without limitation reasonable legal and
                    accounting fees, alleging or resulting from (i) your use of
                    or reliance on any third-party content, (ii) your use of or
                    reliance on any Vipar Content, or (iii) your breach of these
                    Terms. We will provide notice to you promptly of any such
                    claim, suit, or proceeding.
                  </p>
                  <h5 className="mb-3">
                    <b>18] WHAT IS THIS DOCUMENT</b>
                  </h5>
                  <p className="mb-5">
                    These Terms and the relationship between you and VIPAR will
                    be governed by the laws of Karnataka, India without regard
                    to its conflict of law provisions. You and VIPAR agree that
                    all disputes will be subject to arbitration at Mangaluru,
                    Karnataka in accordance with the Arbitration and
                    Conciliation Act, 1996. The arbitration proceedings shall be
                    conducted in the English language. That you and VIPAR hereby
                    expressly and irrevocably attorn to the jurisdiction of the
                    courts of Mangaluru with respect to any matter or claim,
                    suit, action or proceeding arising under or related to this
                    Agreement. You covenant not to sue VIPAR in any other forum.
                  </p>
                  <h5 className="mb-3">
                    <b>19] WHAT IS THIS DOCUMENT</b>
                  </h5>
                  <p className="mb-5">
                    Notwithstanding any of these Terms, we reserve the right,
                    without notice or attendant liability, and in our sole
                    discretion, to terminate your license to use this Site, and
                    to block or prevent future access to and use of this Site
                    for any reason or no reason. Upon termination, these Terms
                    will still apply.
                  </p>
                  <h5 className="mb-3">
                    <b>20] WHAT IS THIS DOCUMENT</b>
                  </h5>
                  <p className="mb-5">
                    From time to time we review these Terms to ensure that they
                    comply with applicable law. Consequently, we reserve the
                    right to update and revise these Terms at any time. You will
                    know if these Terms have been revised since your last visit
                    to the website by referring to the “Effective Date of
                    Current Policy” date at the top of this page; provided,
                    however, that we will endeavour to provide you with prior
                    notice of any material changes. Your use of our website
                    constitutes your acceptance of the terms of these Terms as
                    amended or revised by us from time to time, and you should
                    therefore review these Terms regularly to ensure that you
                    are aware of its terms.
                  </p>
                  <h5 className="mb-3">
                    <b>21] WHAT IS THIS DOCUMENT</b>
                  </h5>
                  <p className="mb-5">
                    VIPAR will not be responsible for failure to perform in a
                    timely manner under the Agreement when its failure results
                    from events beyond its reasonable control (an event of
                    “Force Majeure”), including acts of God, epidemics, acts of
                    war whether declared or undeclared, blockades, labour
                    disputes (whether of VIPAR’s employees or the employees of
                    others), raw material shortages and material increases in
                    costs of raw materials. In the event of Force Majeure, the
                    time for performance will extend for such time as reasonably
                    necessary to enable VIPAR to perform.
                  </p>
                  <h5 className="mb-3">
                    <b>22] WHAT IS THIS DOCUMENT</b>
                  </h5>
                  <p className="mb-5">
                    Our failure to partially or fully exercise any rights or our
                    waiver of any breach of these Terms by user shall not
                    prevent our subsequent exercise of such right or be deemed a
                    waiver by us of any subsequent breach by you of the same or
                    any other term of these Terms. Our rights and remedies under
                    these Terms and any other applicable agreement between you
                    and us shall be cumulative, and the exercise of any such
                    right or remedy shall not limit our right to exercise any
                    other right or remedy.
                  </p>
                  <h5 className="mb-3">
                    <b>23] WHAT IS THIS DOCUMENT</b>
                  </h5>
                  <p className="mb-5">
                    If any of these Terms is be deemed invalid, void, or for any
                    reason unenforceable, that term will be severed and will not
                    affect the validity and enforceability of any remaining term
                    or condition.
                  </p>
                  <h5 className="mb-3">
                    <b>24] WHAT IS THIS DOCUMENT</b>
                  </h5>
                  <p className="mb-5">
                    These Terms constitute the entire agreement between the user
                    and VIPAR with respect to this Site and supersede all prior
                    or contemporaneous communications and proposals, whether
                    electronic, oral or written with respect to this Site. A
                    printed version of these Terms and of any notice given in
                    electronic form will be admissible in judicial or
                    administrative proceedings based upon or relating to these
                    Terms to the same extent and subject to the same conditions
                    as other business documents and records originally generated
                    and maintained in printed form. Any rights not expressly
                    granted herein are reserved.
                  </p>
                  <h5 className="mb-3">
                    <b>25] WHAT IS THIS DOCUMENT</b>
                  </h5>
                  <p className="mb-5">
                    In accordance with IT Act and rules made there under, the
                    name and contact details of the Grievance Officer are
                    provided below: <br />
                    <br />
                    Address : VIPAR, 101, Aadheesh Avenue, Kalakunj road,
                    Mangaluru– 575003
                    <br />
                    Email : connect@vipar.in
                    <br />
                    Time : (Mon to Sat between 09:00 hrs and 18:00 hrs)
                  </p>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="deleverymodal" className="modal">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <center>
                    <h3 className="modal-title mb-5">DELIVERY POLICY</h3>
                  </center>
                  <div className="mb-5">
                    This delivery policy, together with the terms of use,
                    describes VIPAR’s policies and procedures towards delivery
                    of Products purchased on the Site. VIPAR shall make all
                    commercially reasonable endeavours to ensure that the
                    Products are delivered to Users in a timely fashion.
                    <br />
                    Users are required to peruse and understand the terms of
                    this Delivery Policy. If you do not agree to the terms
                    contained in this Delivery Policy, you are advised not to
                    accept the Terms of Use and the Delivery Policy and may
                    forthwith leave and stop using the Site. The terms contained
                    in this Delivery Policy shall be accepted without
                    modification and accordingly, you agree to be bound by the
                    terms contained herein.
                  </div>
                  <h5 className="mb-3">
                    <b>TERMS OF DELIVERY</b>
                  </h5>
                  <div className="mb-5">
                    <ul>
                      <li>
                        VIPAR partners with third party logistic service
                        providers in order to effectuate Product delivery to
                        Users (“Logistic Partners”). Details of the Logistic
                        Partner who will be processing the delivery of the
                        purchased Product(s) will be provided to the User upon
                        the purchased Product(s) being handed over to the
                        Logistic Partner by VIPAR. The User will also be
                        provided with an approximate days of delivery of the
                        purchased Product on the order confirmation page. VIPAR
                        may also effectuate Product delivery to Users on its own
                        without engaging Logistic Partners.
                      </li>
                      <li>
                        While VIPAR aims to provide its services through the
                        Site and ensure the delivery of its Products all across
                        India, currently, VIPAR has a select list of areas where
                        delivery can be undertaken. At the time of placing an
                        order for purchase of Products on the Site, Users are
                        required to enter their pin-code details to verify if
                        deliveries can be carried out in their areas. If the
                        area where the User wishes that the purchased Products
                        be delivered is not within VIPAR’s recognised delivery
                        network, VIPAR will not be able to process the order
                        further.
                      </li>
                      <li>
                        Prior to making payments on the Site for the purchase of
                        Products, the User will be prompted to provide a
                        shipping address. While entering shipping address
                        details, the User should ensure to provide correct,
                        complete and accurate information along with sufficient
                        landmarks in order to aid identification of the address.
                        Any failure in delivering the purchased Products arising
                        out of the User’s failure to provide correct, complete
                        and accurate information shall not hold VIPAR liable at
                        any point in time.
                      </li>
                      <li>
                        A maximum of 3 (three) attempts shall be made to deliver
                        purchased Products to the User. Should the User continue
                        to remain unavailable after 3 (three) attempts, VIPAR
                        reserves the right to cancel the order pertaining to the
                        purchased Products at its sole discretion. While VIPAR
                        shall make reasonable endeavours in ensuring that
                        purchased Products are delivered to its Users in a
                        timely manner, delivery may be delayed on account of:
                        <ol>
                          <li> logistical issues beyond VIPAR’s control;</li>
                          <li> unsuitable weather conditions;</li>
                          <li>
                            political disruptions, strikes, employee-lockouts,
                            etc.;
                          </li>
                          <li>
                            acts of god such as floods, earthquakes, etc.; and
                          </li>
                          <li> Other unforeseen circumstances.</li>
                        </ol>
                        In such events of delay, VIPAR shall make reasonable
                        attempt at proactively intimating the User by writing to
                        the User on his/her registered email account and/or
                        mobile number. VIPAR disclaims all liabilities that may
                        arise on account of its failure to intimate the User of
                        anticipated delays in the delivery of purchased Products
                        on the Site. Further, VIPAR shall be under no obligation
                        to compensate the User for any mental agony or any
                        tortuous claim that may otherwise arise on account of a
                        delay in the shipment and delivery or use of the
                        purchased Products.
                      </li>
                      <li>
                        VIPAR, as an internal process, undertakes multiple
                        diligences to ensure that their delivery executives and
                        the individuals employed by their Logistic Partners are
                        individuals with the highest regard for ethics and
                        integrity. However, it is not possible for VIPAR to
                        ensure that its own delivery executives or employees of
                        its Logistic Partners behave in a fashion that exudes
                        thorough professionalism, competence and good mannerism.
                        It is expressly clarified that any ill-mannerism,
                        impoliteness, discourtesy or offensiveness shown by
                        VIPAR’s delivery executives or by the employees of the
                        Logistic Partners is beyond VIPAR’s control and any
                        issue arising between a User and VIPAR’s delivery
                        executive or an employee of the Logistic Provider will
                        have to be resolved by the User, independently.
                      </li>
                      <li>
                        Upon the successful placing of an order on the Site and
                        after VIPAR has successfully handed over the purchased
                        Product(s) to its Logistic Partner, the User will
                        receive a unique tracking identity number, which will
                        enable the User in tracking the status of delivery of
                        the purchased Products. The User may use the tracking
                        identity number on the Site or the website and/or the
                        mobile application of the Logistic Partner to check the
                        whereabouts of the purchased Product and the estimated
                        time of its delivery. VIPAR shall make reasonable
                        attempts in ensuring that the tracking status of the
                        purchased Products is updated in a timely manner.
                        However, VIPAR does not guarantee the accuracy of the
                        tracking status since it is updated on a real-time basis
                        and is subject to inconsistencies arising out of
                        time-lags in updating the information and other
                        technicalities beyond VIPAR’s control.
                      </li>
                      <li>
                        VIPAR reserves the right to charge shipping charges on
                        Products of a value of lower than a specified amount
                        and/or Products, the payment for which is made by online
                        mode of payment. If charged, such charges shall not be
                        refunded to the User on cancellations or exchange that
                        are made after the shipping of the purchased Products,
                        except in case of a Product having a defect at the time
                        of delivery (for reasons attributable to, and accepted
                        by VIPAR after due verification at its sole discretion).
                        Title and risk of loss for all purchased Products shall
                        pass onto the User upon the delivery of the purchased
                        Products to the User.
                      </li>
                      <li>
                        {' '}
                        Exchange/Replacement of purchased Products shall be
                        carried out by VIPAR’s reverse-logistics partners or its
                        own delivery executives. Further details on how Users
                        may process exchanges of purchased Products have been
                        set out under the Exchange Policy which may be accessed
                        here : https://vipar.in
                      </li>
                    </ul>
                  </div>
                  <br />
                  <h5 className="mb-3 ">
                    <b>GRIEVANCE REDRESSAL</b>
                  </h5>
                  <div className="mb-5">
                    <ul>
                      <li>
                        Any grievances relating to the Delivery Policy may be
                        directed by you to the grievance officer of VIPAR who
                        can be contacted at connect@vipar.in.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="privacymodal" className="modal">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <center>
                    <h3 className="modal-title mb-5">PRIVACY POLICY</h3>
                  </center>
                  <p className="mb-5">
                    This Privacy policy (“Privacy Policy”), together with the
                    terms of use, describes the VIPAR’s policies and procedures
                    on the collection, use and disclosure of the information
                    provided by Users and Visitors (as defined herein below) of
                    the Site. Every User who accesses or uses the Site shall be
                    bound by this Privacy Policy.
                    <br />
                    The Privacy Policy explains how VIPAR may collect, use, and
                    disclose information we obtain through the Site. All other
                    terms not defined herein will have the meanings set forth in
                    the Terms of Use.
                    <br />
                    “Personal Information” means information that alone or when
                    in combination with other information may be used to readily
                    identify, contact, or locate you, such as: name, address,
                    email address, or phone number. We do not consider Personal
                    Information to include information that has been anonymized
                    so that it does not allow a third party to easily identify a
                    specific individual. We may also collect Personal
                    Information in connection with surveys (as described below).
                    We do not collect Personal Information from you unless you
                    voluntarily provide it to us.
                    <br />
                    “Non-Personal Information” means information that does not
                    identify any individual, including without limitation IP
                    type, IP host address, ISP address, cookie IDs and segment
                    IDs associated with cookie IDs, pages viewed and/or search
                    terms entered, browser type, operating system, duration and
                    frequency of visits, anonymous usage statistics including
                    frequency of messaging and interaction with other users,
                    clickstream data other log file data, or social analytics
                    and tracking measurements. On mobile devices, we may also
                    collect device type, make and model, operating system,
                    carrier, user ID, similar device IDs, and information about
                    the apps you use.
                  </p>
                  <h5 className="mb-3">
                    <b>COLLECTION OF INFORMATION</b>
                  </h5>
                  <div className="mb-5">
                    <ul>
                      <li> register to use the Site;</li>
                      <li>use the Site; and</li>
                      <li>communicate with us.</li>
                    </ul>
                    <br />
                    We also collect Non-Personal Information, by using cookies,
                    pixels, server logs, and other similar technology as you use
                    the Site. Please note that we may use third party service
                    providers to help operate the Site and these third party
                    service providers will collect Non-Personal Information from
                    our Site as well.
                    <br />
                    Personal Information Collection: – When you purchase a
                    product, you must provide us with Personal Information, such
                    as your name, email address, and address.
                    <br />
                    Using the Site: – We collect information you post through
                    the Site. For example, when you interact with other users or
                    the Site by posting a review or other materials, the Site
                    will collect the information you provide in such
                    submissions, including any Personal Information. If you
                    choose to submit content to any public area of the Site,
                    such content will be considered “public” and will not be
                    subject to the privacy protections set forth herein. <br />
                    Communications with Us: – We may also collect Personal
                    Information from you when you choose to communicate with us.
                    For example, you may choose to send us Personal Information
                    in an email to us, when signing up for an account,
                    newsletter or other service. By providing your email address
                    to us, you expressly consent to receive emails from us. We
                    may use email to communicate with you, to send information
                    that you have requested or to send information about other
                    products or services developed or provided by us or our
                    partners, provided that, we will not give your email address
                    to another party to promote their products or services
                    directly to you without your consent. If you contact us, we
                    may keep a record of that correspondence. <br />
                    Surveys: – From time to time, we may contact you to
                    participate in online surveys. If you do decide to
                    participate, you may be asked to provide certain information
                    which may include Personal Information. All information
                    collected from your participation in our surveys is provided
                    by you voluntarily. We may use such information to improve
                    our products, Site and/or services and in any manner
                    consistent with the policies provided herein. <br />
                    Cookies, Automatic Data Collection, and Related Technologies
                    :-The Site collects and stores information that is generated
                    automatically as you use it, including your preferences and
                    anonymous usage statistics. <br />
                    When you visit the Site, we and our third-party service
                    providers receive and record information on our server logs
                    from your browser, including your IP address, and from
                    cookies, pixels and similar technology. Cookies are small
                    text files placed in visitors’ computer browsers to store
                    their preferences. Most browsers allow you to block and
                    delete cookies. However, if you do that, the Site may not
                    work properly.
                    <br />A pixel is a piece of code embedded on the Site that
                    collects information about users’ engagement on that web
                    page. The use of a pixel allows us to record, for example,
                    that a user has visited a particular web page or clicked on
                    a particular advertisement. By using the Site, you are
                    authorizing us to gather, parse, and retain data related to
                    the provision of the Site.
                  </div>

                  <h5 className="mb-3">
                    <b>USES YOUR INFORMATION</b>
                  </h5>
                  <div className="mb-5">
                    We use Personal Information to
                    <br />
                    <ul>
                      <li>facilitate and improve our services; and</li>
                      <li> communicate with you.</li>
                    </ul>
                    <br />
                    We may use aggregate information for any purpose, including
                    for marketing purposes. Internal and Service-Related Usage.
                    We use information, including Personal Information, for
                    internal and service-related purposes only and may provide
                    it to third parties to allow us to facilitate the Site. We
                    may use and retain any data we collect to provide and
                    improve our services. We may also use such data to help us
                    deliver targeted advertising to consumers, to analyse
                    interactions with and performance of our Site, measure the
                    effectiveness of advertising on behalf of our advertising
                    partners, and to identify the audience most likely to
                    respond to an advertisement. We may also use data from third
                    parties (such as data vendors) pursuant to their own privacy
                    policies, and provide access to or share non-personally
                    identifiable information with any number of third parties,
                    including but not limited to business partners, affiliates
                    and advertising clients. Similarly, we may enhance the
                    non-personally identifiable information collected via our
                    Site with such information collected from our business
                    partners. We believe that the use of such information is
                    helpful to providing users with better services.
                    <br />
                    Communications:- We may send email to the email address you
                    provide to us to verify your account and for informational
                    and operational purposes, such as account management,
                    customer service, or system maintenance.
                    <br />
                    Marketing:-We may use information, including Personal
                    Information, to facilitate transmittal of information that
                    may be useful, relevant, valuable or otherwise of interest
                    to you.
                    <br />
                    Non-Personal Information and Aggregate Data :- We may use
                    Non-Personal Information as well as any anonymized and
                    aggregated data collected through the Site for any purpose.
                    These uses may include but are not limited to analysing
                    interactions with and performance of the Site, enabling us
                    to improve our Site and services, and sharing such
                    information with our business partners, affiliates, or any
                    other third party. Similarly, we may enhance any such
                    Non-Personal Information collected via our Site with other
                    non-personal information collected from our business
                    partners.
                    <br />
                  </div>
                  <h5 className="mb-3">
                    <b>DISCLOSE YOUR INFORMATION</b>
                  </h5>
                  <div className="mb-5">
                    We may share your information:
                    <br />
                    <ul>
                      <li> with our third-party service providers;</li>
                      <li> to comply with legal obligations;</li>
                      <li>
                        to protect and defend our rights and property; and
                      </li>
                      <li>with your permission.</li>
                    </ul>
                    <br />
                    We do not rent, sell, or share Personal Information about
                    you with other people or non-affiliated companies for their
                    direct marketing purposes, unless we have your permission.
                    <br />
                    We Use Vendors and Service Providers:- We may share any
                    information we receive with vendors and service providers
                    retained in connection with the provision of the Site.
                    <br />
                    Displaying to Other Users:- The content you post to the Site
                    may be displayed on the Site. Other users of the Site may be
                    able to see some information about you, such as your name if
                    you submit a review. We are not responsible for privacy
                    practices of the other users who will view and use the
                    posted information.
                    <br />
                    Marketing:- We may allow access to other data collected by
                    the Site to facilitate transmittal of information that may
                    be useful, relevant, valuable or otherwise of interest to
                    you.
                    <br />
                    As Required By Law and Similar Disclosures:- We may access,
                    preserve, and disclose your Personal Information, other
                    account information, and content if we believe doing so is
                    required or appropriate to: comply with law enforcement
                    requests and legal process, such as a court order or
                    summons; respond to your requests; or protect yours’, ours’
                    or others’ rights, property, or safety.
                    <br />
                  </div>
                  <h5 className="mb-3">
                    <b>THIRD PARTY SITES AND SOCIAL MEDIA PLATFORMS</b>
                  </h5>
                  <p className="mb-5">
                    The Site may contain links to other websites and other
                    websites may reference or link to our Site. These other
                    domains and websites are not controlled by us. We encourage
                    our users to read the privacy policies of each and every
                    website and application that they interact with. We do not
                    endorse, screen or approve, and are not responsible for the
                    privacy practices or content of such other websites or
                    applications. Visiting these other websites or applications
                    is at your own risk.
                    <br />
                    The Site may also contains links and interactive features
                    with various social media platforms. If you already use
                    these platforms, their cookies may be set on your device
                    when using our Site. You should be aware that Personal
                    Information which you voluntarily include and transmit
                    online in a publicly accessible blog, chat room, social
                    media platform or otherwise online, or that you share in an
                    open forum may be viewed and used by others without any
                    restrictions. We are unable to control such uses of your
                    information when interacting with a social media platform,
                    and by using such services you assume the risk that the
                    Personal Information provided by you may be viewed and used
                    by third parties for any number of purposes.
                  </p>
                  <h5 className="mb-3">
                    <b>SECURITY OF YOUR INFORMATION</b>
                  </h5>
                  <p className="mb-5">
                    We take steps to ensure that your information is treated
                    securely and in accordance with this Privacy Policy.
                    Unfortunately, the Internet cannot be guaranteed to be 100%
                    secure, and we cannot ensure or warrant the security of any
                    information you provide to us. We do not accept liability
                    for unintentional disclosure. <br />
                    By using the Site or providing Personal Information to us,
                    you agree that we may communicate with you electronically
                    regarding security, privacy, and administrative issues
                    relating to your use of the Site. If we learn of a security
                    system’s breach, we may attempt to notify you electronically
                    by posting a notice on the Site or sending an e-mail to you.
                  </p>
                  <h5 className="mb-3">
                    <b>CHANGES TO OUR PRIVACY POLICY AND PRACTICES</b>
                  </h5>
                  <p className="mb-5">
                    We may revise this Privacy Policy, so review it
                    periodically. If you continue to visit this Site and use the
                    services made available to you after such changes have been
                    made, you hereby provide your consent to the changes.
                    <br />
                    Posting of Revised Privacy Policy:- We will post any
                    adjustments to the Privacy Policy on this web page, and the
                    revised version will be effective when it is posted. If you
                    are concerned about how your information is used, bookmark
                    this page and read this Privacy Policy periodically.
                    <br />
                    New Uses of Personal Information:- From time to time, we may
                    desire to use Personal Information for uses not previously
                    disclosed in our Privacy Policy. If our practices change
                    regarding previously collected Personal Information in a way
                    that would be materially less restrictive than stated in the
                    version of this Privacy Policy in effect at the time we
                    collected the information, we will make reasonable efforts
                    to provide notice and obtain consent to any such uses as may
                    be required by law.
                  </p>
                  <br />
                  <br />
                  <h5 className="mb-3">
                    <b>CONTACT INFORMATION</b>
                  </h5>
                  <p className="mb-5">
                    VIPAR, 101, Aadheesh Avenue, Kalakunj road, Mangaluru–
                    575003. <br />
                    email : connect@vipar.in{' '}
                  </p>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="ppp" className="modal">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <center>
                    <h3 className="modal-title mb-5">EXCHANGE POLICY</h3>
                  </center>
                  <p className="mb-5">
                    In keeping with VIPAR’s goal of ensuring User satisfaction,
                    this Exchange Policy (“Exchange Policy”), together with the
                    terms of use, sets out VIPAR’s procedures and policies in
                    accepting exchange/replacement of a product that has been
                    delivered to a User after purchase from the Site. Any
                    Exchange/Replacement of Products by Users shall be governed
                    by and subject to the terms and conditions set out under
                    this Exchange Policy.
                    <br />
                    Users are required to peruse and understand the terms of
                    this Exchange Policy. If you do not agree to the terms
                    contained in this Exchange Policy, you are advised not to
                    accept the Terms of Use and may forthwith leave and stop
                    using the Site. The terms contained in this Exchange Policy
                    shall be accepted without modification and you agree to be
                    bound by the terms contained herein by initiating a request
                    for purchase of Product(s) on the Site.
                  </p>
                  <h5 className="mb-3">
                    <b>TERMS OF EXCHANGE/REPLACEMENT</b>
                  </h5>
                  <div className="mb-5">
                    <ul>
                      <li>
                        {' '}
                        The Exchange clause set out below shall apply to the
                        product/s, subject to the other Terms and Conditions of
                        this Exchange Policy. Do read all sections carefully to
                        understand the conditions and cases under which
                        exchange/replacement shall be accepted and check the
                        Products’ display page for Product specific exchange
                        information.
                        <ol>
                          <li>
                            The User shall be entitled for exchange/replacement
                            of a product, only if there is any apparent damage
                            or manufacturing defects in the product. That the
                            User shall make an exchange/replacement request
                            within 48 hours from the date of delivery and any
                            request/claim made after the expiry of the said 48
                            hours shall not be entertained by VIPAR and VIPAR
                            shall not be liable for any loss/damages under such
                            circumstances.
                          </li>
                        </ol>
                      </li>
                      <li>
                        {' '}
                        It is hereby clarified that notwithstanding anything to
                        the contrary, Products may be exchanged/replaced only in
                        case of manufacturing defects in the Product, and not
                        for any other reason. It is further clarified and made
                        clear that VIPAR has a strict NO REFUND policy on all
                        its products.
                      </li>
                      <li>
                        {' '}
                        The exchange/replacement request shall be initiated by
                        writing to us at connect@vipar.in. That the email shall
                        contain the exact reason for initiating such request
                        along with photographs of the product from different
                        angles so to enable VIPAR to assess the condition of the
                        product.
                      </li>
                      <li>
                        That if VIPAR is prima facie satisfied after inspecting
                        and assess the photographs sent along with the
                        exchange/replacement request, it shall initiate pick up
                        of the said product. That on receipt of the product in
                        question, VIPAR shall conduct its own verifications and
                        checks and thereafter finds that there are no
                        manufacturing defects in the product then VIPAR shall
                        not be liable to replace/exchange the product in
                        question. It is made clear that if the abovementioned
                        situation arises then VIPAR shallship back/deliver the
                        product in question to the User only after payment of
                        the Shipping Charges by the User.
                      </li>
                      <li>
                        That, if VIPAR after due verification is satisfied and
                        finds that there are/is manufacturing defects in the
                        product in question, then VIPAR shall replace the
                        product in question with a new one and ship it
                        back/deliver it to the User at the cost and expense of
                        VIPAR to the extent of Delivery/Shipping charges only.
                      </li>
                      <li>
                        Exchange/Replacement for purchased Products shall not be
                        entertained by VIPAR if:
                        <ol>
                          <li>
                            There is no manufacturing defect in the product;
                          </li>
                          <li>
                            The price tags, brand tags, box, and original
                            packaging material have been damaged or discarded by
                            the User;
                          </li>
                          <li>
                            If any damage to the Product is found to be due to
                            use of excessive force and/or rough use;
                          </li>
                          <li>
                            if the User in any way breaches the Exchange Policy.
                          </li>
                        </ol>
                      </li>
                      <li>
                        VIPAR shall make all reasonable attempts to keep the
                        User apprised of the status of exchange/replacement
                        through updates shared on the User’s registered mobile
                        number and email address. VIPAR disclaims all
                        liabilities that may arise on account of its failure to
                        keep the User apprised of the status of the
                        exchange/replacement.
                      </li>
                      <li>
                        {' '}
                        Pick up of the purchased Products for
                        exchange/replacement is facilitated through VIPAR’s
                        reverse-logistics partners or by VIPAR itself. Upon a
                        User making a request for exchange of Product and the
                        same being duly acknowledged by VIPAR, VIPAR or VIPAR’s
                        reverse-logistics partners shall get in touch with the
                        User in order to collect the purchased Products from the
                        User and delivering it to VIPAR. While VIPAR has made
                        reasonable endeavours to ensure delivery and pick-ups
                        across multiple locations in India, currently, VIPAR has
                        a select list of areas where deliveries and collection
                        can be undertaken by its reverse-logistics partners. In
                        events where VIPAR is unable to facilitate a pick-up of
                        the purchased Product for exchange from the User, the
                        User is required to self-ship the Product to VIPAR’s
                        shipping warehouse, the address for which will be
                        intimated to the User upon the User placing such request
                        for return. Upon self-shipping the Product to VIPAR’s
                        warehouse, the User shall be required to scan the
                        courier/postal receipt to connect@vipar.in for VIPAR’s
                        records. The aforesaid return by self-shipping the
                        Products is subject to the returned Products passing
                        quality check by VIPAR.
                      </li>
                      <li>
                        That self-shipping by the User shall be done only on
                        prior written approval of VIPAR and in the event where
                        the User claims to have self-shipped the Product in
                        accordance with the process set out in the paragraph
                        above and VIPAR does not receive the returned Product or
                        the package containing such Product is empty, the User
                        shall be required to submit the ‘proof of delivery’
                        received from the courier/postal agency to VIPAR to
                        prove his/her claim of return of Products through
                        self-shipment to VIPAR. It is specifically clarified
                        that any exchange/replacement shall be processed by
                        VIPAR only upon it receiving the returned Products and
                        concluding required verifications and checks or
                        receiving the ‘proof of delivery’ from the User, as the
                        case may be.
                      </li>
                    </ul>
                  </div>
                  <br />
                  <h5 className="mb-3">
                    <b>GRIEVANCE REDRESSAL</b>
                  </h5>
                  <div className="mb-5">
                    <ul>
                      <li>
                        Any grievances relating to the Exchange Policy may be
                        directed by you to the grievance officer of VIPAR who
                        can be contacted at connect@vipar.in.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
