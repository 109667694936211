import React from 'react';
import './Articles.css';
import { Link } from 'react-router-dom';
import { Footer } from '../../Footer/Footer';
import Navbar from '../Navbar/Navbar';

const Articles = () => {
  return (
    <>
      <hr
        className="mt-0"
        style={{
          color: 'rgb(1, 32, 96)',
          opacity: '1',
          borderTop: '3px solid',
        }}
      />
      <section className="container-fluid  p-0">
        <div className="container pb-5 mt-1 fntt">
          <div className="row  w-100 p-0 m-0">
            <div className="col-12 col-md-6  col-lg-4 mt-5">
              <Link
                to="/vipar-chic/articles/DUB"
                className="linknew"
                style={{ textDecoration: 'none' }}
              >
                <center>
                  <div
                    className="card cardforproduct"
                    style={{ border: 'none' }}
                  >
                    <img
                      src="/img/dubnewcover.jpeg"
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body ">
                      <center>
                        <span className="text-dark fntt">
                          <span
                            style={{ textDecoration: 'none' }}
                            className="text-dark fntt"
                          >
                            <span
                              style={{ fontFamily: 'Monotype', color: 'green' }}
                            >
                              Vipar
                            </span>{' '}
                            &nbsp;D U B
                          </span>{' '}
                        </span>
                      </center>
                    </div>
                  </div>
                </center>
              </Link>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mt-5">
              <Link
                to="/vipar-chic/articles/DUBclassic"
                className="linknew"
                style={{ textDecoration: 'none' }}
              >
                <center>
                  <div
                    className="card cardforproduct"
                    style={{ border: 'none' }}
                  >
                    <img
                      src="/img/cover(3).jpg"
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <center>
                        <span className="text-dark fntt">
                          <span
                            style={{ fontFamily: 'Monotype', color: 'green' }}
                          >
                            Vipar
                          </span>{' '}
                          &nbsp;D U B&nbsp;&nbsp;Classic
                        </span>
                      </center>
                    </div>
                  </div>
                </center>
              </Link>
            </div>
            <div className=" col-12 col-md-6 col-lg-4 mt-5">
              <Link
                to="/vipar-chic/articles/DUBclassicAoA"
                className="linknew"
                style={{ textDecoration: 'none' }}
              >
                <center>
                  <div
                    className="card cardforproduct"
                    style={{ border: 'none' }}
                  >
                    <img
                      src="/img/cover(1).jpg"
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body ">
                      <center>
                        <span className="text-dark fntt">
                          <span
                            style={{ fontFamily: 'Monotype', color: 'green' }}
                          >
                            Vipar
                          </span>{' '}
                          &nbsp;D U B&nbsp;&nbsp;Classic Add-on Accessory
                        </span>
                      </center>
                    </div>
                  </div>
                </center>
              </Link>
            </div>
            {/* <div className="col-12 col-md-6 col-lg-4 mt-5">
              <Link
                to="/duo_combo"
                className="linknew"
                style={{ textDecoration: 'none' }}
              >
                <center>

                <div
                  className="card cardforproduct"
                  style={{ border: 'none', backgroundColor: '#e9eef5' }}
                >
                  <img
                    src="/img/VPDC100-COMBO.jpg"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <center>
                      <span className="text-dark fntt">Duo Combo</span>
                    </center>
                  </div>
                </div>
                </center>
              </Link>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mt-5">
              <Link
                to="/classic_combo"
                className="linknew"
                style={{ textDecoration: 'none' }}
              >
                <center>

                <div
                  className="card cardforproduct"
                  style={{ border: 'none', backgroundColor: '#e9eef5' }}
                >
                  <img
                    src="/img/VPCC100-COMBO.jpg"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body  ">
                    <center>
                      <span className="text-dark fntt">Classic Combo</span>
                    </center>
                  </div>
                </div>
                </center>
              </Link>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Articles;
