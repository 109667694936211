import React, { useEffect } from 'react';
import { useContext } from 'react';
import { Bucketcontext } from './Viparclassiccombo';
import { Cappode, Viparshow } from './Viparshow';
import { useDispatch, useSelector } from 'react-redux';
import { addTocart } from '../../../../redux/viparChic/cartSlice';
import { useState } from 'react';
import { keyGen } from '../../../../App';

export const Viparclassic = () => {
  const val = useContext(Bucketcontext);
  let state = val.hook;
  let setState = val.setHook;

  return (
    <>
      <div className="" id="korg">
        <center>
          <img src="/img/dubchookorg.jpg" height="300px" width="300px" alt="" />
        </center>
        <center>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio1"
              value="a"
              onClick={(e) => setState(e.target.value)}
            />
            <label className="form-check-label" htmlhtmlFor="inlineRadio1">
              A
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio2"
              value="b"
              onClick={(e) => setState(e.target.value)}
            />
            <label className="form-check-label" htmlhtmlFor="inlineRadio2">
              B
            </label>
          </div>
        </center>

        <center>
          <button
            className={`btn btn-outline-dark mt-3 me-1 mb-5 ${
              state ? '' : ' d-none'
            }`}
            id="colourbutton"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
            onClick={(e) => val.dispatch({ type: 'color' })}
          >
            SELECT YOUR FAVOURITE COLOUR(s)
          </button>
        </center>
      </div>
    </>
  );
};

export const Bucketcolor = (optional) => {
  const state = useContext(Bucketcontext);
  console.log(state);
  console.log(state.state);
  console.log(state.first);

  let cart = useSelector((state) => state.cart.value);
  let dispatch = useDispatch();
  let { productId, varientId, _id } = { ...state };
  useEffect(() => {
    console.log(productId);
    return () => {};
  }, []);
  const [err, seterr] = useState();

  const validation = (item1, item2, item3, item4, item5) => {
    if (item1.length === 0) {
      seterr('select a DUB');
    }
    if (item2.length === 0) {
      seterr('select a cap~pod');
    }
    if (item3.length === 0) {
      seterr('select a cap~pod');
    }
    if (item4.length === 0) {
      seterr('select a cap~pod');
    }
    if (item5.length === 0) {
      seterr('select a cap~pod');
    }
    seterr('');
    return true;
  };

  const addtocart = () => {
    if (validation(item1, item2, item3, item4, item5) === true) {
      dispatch(
        addTocart({
          item: {
            productId: state?.state?.variant[0]?.productId,
            varientId: item1,
            qty: 1,
            optional: state?.hook,
            selected: [item2, item3, item4, item5],
            combo: true,
          },
          cart,
        })
      );
    }
  };

  const [item1, setitem1] = useState('');
  const [item2, setitem2] = useState('');

  const [item3, setitem3] = useState('');

  const [item4, setitem4] = useState('');
  const [item5, setitem5] = useState('');

  useEffect(() => {
    console.log(item1);
    console.log(item2);
    console.log(item3);
  }, [item1, item2, item3]);

  return (
    <>
      <div className="" id="collapseExample">
        <div className="row">
          <button
            className="btn btn-outline-dark mb-5 "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
            onClick={() => {
              state.dispatch({ type: 'bucket' });
            }}
          >
            Select Hook Location Again ?
          </button>
        </div>

        <div>
          {state.state.variant?.map((variants) => {
            console.log('hi');
            return (
              <Viparshow
                value={variants}
                // key={keyGen()}
                onChange={(e) => {
                  setitem1(e);
                }}
              ></Viparshow>
            );
          })}
        </div>
        <hr />

        <Cappode
          onChange={(e) => {
            setitem2(e);
          }}
          // key={keyGen()}
          value={state.first.variant}
          label="Select 1st Cap-pod"
        ></Cappode>
        <Cappode
          onChange={(e) => {
            setitem3(e);
          }}
          // key={keyGen()}
          value={state.first.variant}
          label="Select 2nd Cap-pod"
        ></Cappode>
        <Cappode
          onChange={(e) => {
            setitem4(e);
          }}
          // key={keyGen()}
          value={state.first.variant}
          label="Select 3rd Cap-pod"
        ></Cappode>
        <Cappode
          onChange={(e) => {
            setitem5(e);
          }}
          // key={keyGen()}
          value={state.first.variant}
          label="Select 4th Cap-pod"
        ></Cappode>
        <div>
          <button
            className="btn btn-outline-dark"
            type="button"
            onClick={(e) => addtocart()}
          >
            Add To Cart
          </button>
        </div>
      </div>
    </>
  );
};
