import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Connect from './components/Vipar-chic/connect/Connect';
import { Mayihelpyou } from './components/Vipar-chic/Mayihelyou/Mayihelpyou';
import Articles from './components/Vipar-chic/Articles/Articles';
import { ContactUs } from './pages/ContactUs';
import { FoodVanHome } from './pages/FoodVanHome';
import { GroceryVanCart } from './pages/GroceryVanCart';
import { GroceryVanHome } from './pages/GroceryVanHome';
import Home from './pages/Home';
import Accessories from './components/Vipar-chic/Accessories/Accessories';
import Art from './components/Vipar-chic/Art/Art';
import Vipardub from './components/Vipar-chic/Articles/Vipardub/Vipardub';
import Vipardubclassic from './components/Vipar-chic/Articles/vipardubclassic/Vipardubclassic';
import Vipardubadd from './components/Vipar-chic/Articles/vipardubclassicadd/Vipardubadd';
import { Viperduocombo } from './components/Vipar-chic/Articles/viperduocombo/Viperduocombo';
import { Viparclassiccombo } from './components/Vipar-chic/Articles/viparclassiccombo/Viparclassiccombo';
import Accessoriescappod from './components/Vipar-chic/Accessories/Accessoriescappod';
import ViparChicAbout from './pages/ViparChicAbout';
import ViparChicCart from './pages/ViparChicCart';
import ViparChicHome from './pages/ViparChicHome';
import { TestEditor } from './pages/TestEditor';
import Navbar from './components/Vipar-chic/Navbar/Navbar';
import Pagenotfound from './pages/Pagenotfound';
import Adminlogin from './components/vipar-chicAdmin/Adminlogin';
import Adminupdatepassword from './components/vipar-chicAdmin/Adminupdatepassword';
import Connectadmin from './components/vipar-chicAdmin/Connectadmin';
import { User } from './components/vipar-chicAdmin/User';
import {
  Adminnavbar,
  Unauthorized,
} from './components/vipar-chicAdmin/Adminnavbar/Adminnavbar';
import { Confirmorderadmin } from './components/vipar-chicAdmin/Adminnavbar/Confirmorderadmin';
import { Cancelorderadmin } from './components/vipar-chicAdmin/Cancelorderadmin';
import { Reviewadmin } from './components/vipar-chicAdmin/Reviewadmin';
import { Order } from './components/Vipar-chic/order/Order';
import { Linkbroken } from './components/vipar-chicAdmin/Linkbroken';
import { Reviewmodal } from './components/vipar-chicAdmin/Reviewmodal';
import { Editmodal } from './components/Vipar-chic/Cartaddinglist/Editmodal';
import { Invoice, Linkbroke, Loading } from './components/Invoice/Invoice';
import { Footer } from './components/Footer/Footer';
import { Cancel, Sub } from './components/cancellorder/Cancel';
import { Invoice as Invoicecomponent } from './components/Invoice/Invoiceadmin';
import { NavbarVipar } from './components/NavbarVipar/NavbarVipar';
import { NewNavViparc } from './components/Vipar-chic/NewNavViparc/NewNavViparc';
import { Useremail } from './components/useremail/Useremail';
import { Orderdetails } from './components/Vipar-chic/Orderdetails/Orderdetails';
import { Viparcomfort } from './components/vipar-comfort/Viparcomfort';
import { Viparcarehome } from './components/Vipar care/Viparcarehome';

import { Viparcasa } from './components/vipar-casa/Viparcasa';
import { Getintouch } from './components/Vipar care/Getintouch';
import { Inceptionpagelink } from './components/Viparhome/Inceptionpagelink';
import { Grievances } from './components/Vipar-chic/grievances/Grievances';
import { Newform } from './components/Vipar-chic/grievances/Newform';
import { Neworderdetails } from './components/Vipar-chic/Orderdetails/Neworderdetails';
import { Articlesmain } from './components/Vipar-chic/Articles/Articlesmain';
import { Nonloginpage } from './components/Viparhome/Nonloginpage';
import { useSelector } from 'react-redux';
import { Pending } from './components/Vipar-chic/grievances/Pending';
import { Resolved } from './components/Vipar-chic/grievances/Resolved';
import { Invoicenew } from './components/Invoicenew';
import Viparchicmain from './components/Vipar-chic/Vipar-chic_about/Viparchicmain';
import { Invoicedrop } from './components/Invoice/Invoicedrop';
import { Viparcasaarchitects } from './components/vipar-casa/Viparcasaarchitects';
import { Viparcasadevelopers } from './components/vipar-casa/Viparcasadevelopers';
import { Navviparcasa } from './components/vipar-casa/Navviparcasa';
import { Connectnavbar } from './components/vipar-connect/ConnectNavbar';
import { Refunddetails } from './components/Vipar-chic/Orderdetails/Refunddetails';
import { Refundadmin } from './components/vipar-chicAdmin/Refundadmin';
import { Refundviewadmin } from './components/vipar-chicAdmin/Refundviewadmin';
import { Content } from './components/vipar-connect/Content';
import { Founder } from './components/Viparhome/Founder';
import { Brand } from './components/Viparhome/Brand';
import { Oneworkwage } from './components/Viparhome/Oneworkwage';
import { Provinvoice } from './components/Provinvoice';
import { Taxinvoice } from './components/Taxinvoice';
import { Viparman } from './components/Viparhome/Viparman';
import { Viparcruise } from './components/Vipar-cruise/Viparcruise';

import { Viparchum } from './components/Vipar-chum/Viparchum';

import { Navbarclean } from './components/viparclean/Navbarclean';
import { Cleanmain } from './components/viparclean/Cleanmain';
import { Viparchef } from './components/Viparchef/Viparchef';
import { Viparcine } from './components/Vipar-cine/Viparcine';
import { Cabs } from './components/Vipar-chum/Cabs';

import {
  Cabmanagerlogin,
  Stafflogin,
} from './components/vipar-chicAdmin/Chumadmin/Stafflogin';
import { Subadminchumnavbar } from './components/vipar-chicAdmin/Chumadmin/Subadminchum/Subadminchumnavbar';
import { Staffchum } from './components/vipar-chicAdmin/Chumadmin/Subadminchum/Staffchum';
import { Subadminlogin } from './components/vipar-chicAdmin/Chumadmin/Subadminlogin';
import { Staffemail } from './components/vipar-chicAdmin/Chumadmin/Staffemail';
import { Subadminemail } from './components/vipar-chicAdmin/Chumadmin/Subadminemail';
import { SubadminchangePassword } from './components/vipar-chicAdmin/Chumadmin/SubadminchangePassword';
import { StaffChangepassword } from './components/vipar-chicAdmin/Chumadmin/StaffChangepassword';
import { Viparcarenav } from './components/Vipar care/Viparcarenav';
import { Philosophy } from './components/Vipar care/Philosophy';
import { Humane } from './components/Vipar care/Humane';
import { Demoidentity } from './components/Demo/Demoidentity';

export function VRoutes() {
  const [key, setkey] = React.useState(10);
  const user = useSelector((state) => {
    return state.user.value;
  });
  return (
    <>
      <Routes>
        <Route
          element={<Demoidentity />}
          path="/employee_details/EMPLOYEE_ID:BLRFSE01"
        />

        <Route exact element={<NormalRoutes />} path="/*" />
        <Route element={<Adminnavbar />} path="/admin/*" />
        <Route element={<Subadminchumnavbar />} path="/subadmin_chum/*" />
        <Route element={<Staffchum />} path="/staff_chum/*" />

        <Route element={<Stafflogin />} path="/stafflogin" />
        <Route element={<Subadminlogin />} path="/subadminlogin" />

        {/* <Route element={<Cabmangerresgister />} path="/staffregister" /> */}
        <Route element={<Viparcomfort />} path="/vipar-comfort" />
        <Route element={<Viparcruise />} path="/vipar-cruise" />
        <Route element={<Viparchum />} path="/vipar-chum" />
        <Route element={<Cabs />} path="/vipar-chum/cabs" />

        <Route element={<Viparchef />} path="/vipar-chef" />
        <Route element={<Viparcine />} path="/vipar-cine" />

        <Route element={<Viparcarehome />} path="/vipar-care" />
        <Route element={<Content />} path="/vipar-connect" />

        <Route element={<Getintouch />} path="/vipar-care/connect" />
        <Route element={<Linkbroken />} path="/pagenotfound"></Route>
        <Route element={<Invoice />} path="/invoice"></Route>
        <Route element={<Adminlogin />} path="/login" />

        <Route element={<Invoicedrop />} path="/invoicedrop" />
        <Route element={<Adminupdatepassword />} path="/updatepassword" />
        <Route
          element={<SubadminchangePassword />}
          path="/SubadminchangePassword"
        />
        <Route element={<StaffChangepassword />} path="/StaffChangepassword" />
      </Routes>
    </>
  );
}

const Viparcasaa = () => {
  const [casakey, setcasakey] = React.useState(10);
  return (
    <div>
      <Navviparcasa casakey={'i' + casakey} setcasakey={setcasakey} />
      <div>
        <Routes>
          <Route element={<Viparcasa />} path="/"></Route>
          <Route element={<Viparcasaarchitects />} path="/enquiry" />
          <Route element={<Viparcasadevelopers />} path="/as_developers" />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

const Def = () => {
  const [key, setkey] = React.useState(10);

  return (
    <div>
      <NewNavViparc key={'h' + key} setkey={setkey} />

      <div id="fo">
        <Routes>
          <Route element={<Viparchicmain />} path="/"></Route>
          <Route element={<Refunddetails />} path="/refund_details"></Route>
          <Route element={<Orderdetails />} path="/purchase_history" />
          <Route element={<Neworderdetails />} path="/new_order_status" />
          <Route element={<Newform />} path="/grievances" />
          <Route element={<ViparChicHome />} path="/windowshopping" />
          <Route element={<ViparChicCart />} path="/shopping_bag" />
          <Route element={<ViparChicAbout />} path="/policies" />
          <Route
            element={<Accessoriescappod />}
            path="/articles/cappod"
          ></Route>
          <Route element={<Viparclassiccombo />} path="/classic_combo"></Route>
          <Route element={<Mayihelpyou />} path="/helpdesk"></Route>
          <Route element={<Connect />} path="/connect"></Route>
          <Route
            element={<Articles />}
            path="/articles/articles_dual_utility_basket"
          ></Route>
          <Route element={<Vipardub />} path="/articles/DUB"></Route>
          <Route
            element={<Vipardubclassic />}
            path="/articles/DUBclassic"
          ></Route>
          <Route
            element={<Vipardubadd />}
            path="/articles/DUBclassicAoA"
          ></Route>
          <Route element={<Viperduocombo />} path="/articles/duo_combo"></Route>
          <Route element={<Accessories />} path="/appliances"></Route>
          <Route element={<Articlesmain />} path="/articles"></Route>
          <Route element={<ContactUs />} path="/contact_us" />
          <Route element={<Art />} path="/art"></Route>
          <Route element={<Editmodal />} path="/edit"></Route>
          <Route element={<Linkbroken />} path="/*"></Route>
          <Route element={<Loading />} path="/load"></Route>
          <Route element={<Linkbroke />} path="/linkk"></Route>
          {/* vipar care */}
        </Routes>
      </div>

      <Footer />
    </div>
  );
};

const NormalRoutes = () => {
  const user = useSelector((state) => {
    return state.user.value;
  });
  return (
    <>
      <NavbarVipar />
      <Routes>
        {/* {user.Status ? (
          <Route exact element={<Home />} path="/" />
        ) : (
          <Route element={<Nonloginpage />} path="/" />
        )} */}
        <Route exact element={<Home />} path="/" />

        <Route element={<Inceptionpagelink />} path="/inception" />

        <Route element={<Founder />} path="/founder&inception" />
        <Route element={<Brand />} path="/the_brand" />
        <Route element={<Viparman />} path="/vipar_man" />

        <Route element={<Oneworkwage />} path="/one_work_one_wage" />

        {/* {user.Status && <Route element={<Def />} path="/vipar-chic/*" />} */}
        <Route element={<Def />} path="/vipar-chic/*" />
        <Route element={<Viparcasaa />} path="/vipar-casa/*" />
        <Route element={<Philosophy />} path="/vipar-care/philosophy" />
        <Route element={<Humane />} path="/vipar-care/humane" />

        <Route element={<Cleanmain />} path="/vipar-clean" />

        {/*------------------------>*/}
        <Route element={<Pagenotfound />} path="/pagenotfound" />
        <Route element={<TestEditor />} path="/texteditor" />
        <Route element={<Adminnavbar />} path="/admin/*" />
        <Route element={<Connectadmin />} path="/connectadmin" />
        <Route element={<User />} path="/customer_review" />
        <Route element={<Useremail />} path="/resetpassword" />
        <Route element={<Staffemail />} path="/staffemailresetpassword" />
        <Route element={<Subadminemail />} path="/subadminemailresetpassword" />

        {/* <Route element={< Userloading/>} path="/user" /> */}
        <Route element={<Linkbroken />} path="/link" />
        <Route element={<Adminnavbar />} path="adminnavbar" />
        <Route element={<Confirmorderadmin />} path="/adminconfirmorder" />
        <Route element={<Cancelorderadmin />} path="/admincancelorder" />
        <Route element={<Reviewadmin />} path="/adminreview" />
        <Route element={<Refundadmin />} path="/adminrefundrequest" />
        <Route element={<Refundviewadmin />} path="/adminrefundview" />

        <Route element={<Reviewmodal />} path="/modal" />
        <Route element={<Sub />} path="/cancel"></Route>
        <Route element={<Invoicecomponent />} path="/invoiceprint" />
        <Route element={<Order />} path="/vipar-chic/order" />

        <Route element={<Pending />} path="/vipar-chic/pending" />
        <Route element={<Resolved />} path="/vipar-chic/resolved" />
        <Route element={<Invoicenew />} path="/vipar-chic/invoice" />
        <Route element={<Provinvoice />} path="/vipar-chic/provinvoice" />
        <Route element={<Taxinvoice />} path="/vipar-chic/taxinvoice" />

        <Route element={<Connectnavbar />} path="/vipar-connect" />

        <Route element={<Linkbroken />} path="/*"></Route>
      </Routes>
    </>
  );
};
