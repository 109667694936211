import React, { useEffect, useReducer, useState } from 'react'
import { createContext } from 'react'
import Userloading from '../Userloading'
import { Linkbroken } from '../Linkbroken'
import { useNavigate, useSearchParams } from 'react-router-dom'
import axios from 'axios'
  const loading = createContext()
export const Staffemail = () => {
    const reducer = (state,action) => {
      switch (action.type){
          case "loading": return {component : <Userloading/> , type:action.type};
          case "linkbroken": return {component:<Linkbroken/> , type:action.type};
          case "Email" : return {component:<Email/>, type:action.type}
      }
    }


    
    const [state, dispatch] = useReducer(reducer,{component:<Email/>,type:"Email"} )

  const [params,setparams] = useSearchParams()
  let resetid = params.get("resetid")

  useEffect(() => {
    axios.post("chum/staffemailfind",{resetid}).then((res)=>{
      console.log("cz")
    }).catch((err)=>{
      console.log(err)
    })
  
  
  }, [])
  

  return (
    <div>
          <loading.Provider>{state.component}</loading.Provider>
    </div>
  )
}
 


export const Email = () =>{

  let navigate = useNavigate();

  const [change, setchange] = useState({
    Password:"",
    Confirmpassword:""
  })



  const [params,setparams] = useSearchParams()
  let resetid = params.get("resetid")

  useEffect(() => {
    axios.post("chum/staffemailfind",{resetid}).then((res)=>{
      console.log("cz")
    }).catch((err)=>{
      console.log(err)
    })
  
  
  }, [])

  const [Validate, setValidate] = useState(false);

  const [err, seterr] = useState();

  const submit = () =>{




    
    console.log("kjk")
    axios.post("chum/staffupdatepassword",{change,resetid}).then((res)=>{
      console.log(res.data)
      navigate("/subadminlogin")

      setValidate(false);
    }).catch((err=>{
      console.log(err)
    }))
  }
  return(
    <>
   <div className="d-flex justify-content-center align-items-center mt-5">
        <button
          className="btn hhchange rounded-pill"
          data-bs-target="#staffchangepassword"
          data-bs-toggle="modal"
        >
          Reset your Password
        </button>
      </div>
      <div
        className="modal fade"
        id="staffchangepassword"
        aria-hidden="true"
        aria-labelledby="ForgetPasswordModal"
        tabIndex="-1"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-login mt-5"
          role="document"
        >
          <div className="modal-content">
            <div className="card card-signup card-plain">
              <div className="modal-header">
                <div className="  text-center">
                  <h4 className="card-title">Reset your Password</h4>
                </div>
                <button
                  type="button"
                  id="successcloseresetmodalbtn"
                  className="btn-close float-end close-button-modal "
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form   onSubmit={(e) => submit(e)}
                    noValidate
                    className={Validate ? 'was-validated' : ''}>
                  <div className="card-body">
                    <div className="form-group bmd-form-group">
                      <div className="input-group">
                        <input
                        required
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          onChange={(e) =>
                            {
                              if (change.Password === change.Confirmpassword) {
                                e.target.setCustomValidity('');
                              } else {
                            
                                  e.target.setCustomValidity('Password Missmatch');
                                  seterr('Password Missmatch');
                               
                              }

                            setchange({ ...change, Password: e.target.value })

                            }
                          }
                        />
                          {err ?( <div className="invalid-feedback">{err}</div> ) :<div class="invalid-feedback">
                        Please enter the Password
                      </div>}
                      </div>
                    </div>
                    <div className="form-group bmd-form-group mt-3">
                      <div className="input-group">
                        <input
                        required
                          type="password"
                          className="form-control"
                          placeholder="Confirm password"
                          onChange={(e) =>


                            {
                              if (change.Password === change.Confirmpassword) {
                                e.target.setCustomValidity('');
                       
                                  e.target.setCustomValidity('Password Missmatch');
                                  seterr('Password Missmatch');
                          
                              }

                              setchange({
                                ...change,
                                Confirmpassword: e.target.value,
                              })

                            }
                          }
                           
                        />
                      {err && <div className="invalid-feedback">{err}</div>}

                      </div>
                    </div>

                    <div className="form-group bmd-form-group mt-3">
                      <div className="input-group d-flex justify-content-center">
                        <button
                          className="btn hhchange rounded-pill px-4"
                          type="button"
                          onClick={(e) => setValidate(true)}
                        >
                          Reset
                        </button>
                      </div>
                      <br />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}