import axios from 'axios';
import React from 'react';
import { useState } from 'react';

const Adminupdatepassword = () => {
  const [state, setState] = useState({
    password: '',
    cpassword: '',
    confirmpassword: '',
  });

  const submit = () => {
    axios
      .post('/adminlogin/adminupdate', state)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <section className="vh-100" style={{ backgroundColor: '#508bfc' }}>
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div
                className="card shadow-2-strong"
                style={{ borderRadius: '1rem' }}
              >
                <div className="card-body p-5 ">
                  <center>
                    <h3 className="mb-5 ">Update Password </h3>
                  </center>
                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="typeEmailX-2">
                      Current Password
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="typeEmailX-2"
                      className="form-control form-control-lg"
                      fdprocessedid="ydop06"
                      onChange={(e) =>
                        setState({ ...state, cpassword: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="typePasswordX-2">
                      New Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      id="typePasswordX-2"
                      className="form-control form-control-lg"
                      fdprocessedid="kao81l"
                      onChange={(e) =>
                        setState({ ...state, password: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="typePasswordX-2">
                      Confirm New Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      id="typePasswordX-2"
                      className="form-control form-control-lg"
                      fdprocessedid="kao81l"
                      onChange={(e) =>
                        setState({ ...state, confirmpassword: e.target.value })
                      }
                    />
                  </div>

                  <button
                    className="btn btn-primary btn-lg btn-block"
                    type="submit"
                    fdprocessedid="i8iyob"
                    onClick={(e) => submit()}
                  >
                    Update
                  </button>

                  <hr className="my-4" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Adminupdatepassword;
