import React from 'react';

export function ContactUs() {
  return (
    <>
      <div className="container-xxl py-6">
        <div className="container">
          <div
            className="section-header text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{
              maxWidth: '500px',
              visibility: 'visible',
              ' animation-delay': ' 0.5s',
              'animation-name': 'fadeInUp',
            }}
          >
            <h1 className="display-5 mb-3"> Contact Us</h1>
            <p>
              Tempor ut dolore lorem kasd vero ipsum sit eirmod sit. Ipsum diam
              justo sed rebum vero dolor duo.
            </p>
          </div>
          <div className="row g-5 justify-content-center">
            <div
              className="col-lg-5 col-md-12 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{
                visibility: 'visible',
                ' animation-delay': ' 0.5s',
                'animation-name': 'fadeInUp',
              }}
            >
              <div className="bg-groceryTheam text-white d-flex flex-column justify-content-center h-100 p-5">
                <h5 className="text-white">Call Us</h5>
                <p className="mb-5">
                  <i className="fa fa-phone-alt me-3"></i>+012 345 67890
                </p>
                <h5 className="text-white">Email Us</h5>
                <p className="mb-5">
                  <i className="fa fa-envelope me-3"></i>info@example.com
                </p>
                <h5 className="text-white">Office Address</h5>
                <p className="mb-5">
                  <i className="fa fa-map-marker-alt me-3"></i>123 Street,
                  Bengaluru
                </p>
                <h5 className="text-white">Follow Us</h5>
                <div className="d-flex pt-2 justify-content-evenly">
                  <a
                    className="btn btn-square btn-outline-light rounded-circle me-1"
                    href="/"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a
                    className="btn btn-square btn-outline-light rounded-circle me-1"
                    href="/"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a
                    className="btn btn-square btn-outline-light rounded-circle me-0"
                    href="/"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                  <a
                    className="btn btn-square btn-outline-light rounded-circle me-0"
                    href="/"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-7 col-md-12 wow fadeInUp"
              data-wow-delay="0.5s"
              style={{
                visibility: 'visible',
                ' animation-delay': ' 0.5s',
                'animation-name': 'fadeInUp',
              }}
            >
              <p className="mb-4">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Minima
                magnam ab voluptatem quaerat delectus pariatur molestiae sit
                sapiente suscipit omnis.{' '}
              </p>
              <form>
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                      />
                      <label htmlFor="name">Your Name</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Your Email"
                      />
                      <label htmlFor="email">Your Email</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="subject"
                        placeholder="Subject"
                      />
                      <label htmlFor="subject">Subject</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <textarea
                        className="form-control"
                        placeholder="Leave a message here"
                        id="message"
                        style={{ height: '200px' }}
                      ></textarea>
                      <label htmlFor="message">Message</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      className="btn btn-groceryTheam rounded-pill py-3 px-5"
                      type="submit"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
