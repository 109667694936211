import { configureStore } from '@reduxjs/toolkit';
import cartReducer from './viparChic/cartSlice';
import modalReducer from './viparChic/modalslice';
import userReducer from './viparChic/userSlice';
export default configureStore({
  reducer: {
    cart: cartReducer,
    modal: modalReducer,
    user:userReducer,
  },
});
