import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import { keyGen, notify } from '../../App';
import { Link } from 'react-router-dom';

export const Oldorderadmin = () => {
  const [index, setIndex] = useState(null);
  const [ind, setInd] = useState(null);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [state, setState] = useState([]);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    axios
      .get('/adminlogin/oldorder')
      .then((res) => {
        console.log(res.data);
        setState(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {};
  }, []);
  console.log('hai');
  let no = 1;
  return (
    <>
      <div className="page-breadcrumb ">
        <div className="row align-items-center">
          <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
            <h4 className="page-title text-white">Old Orders</h4>
          </div>
          <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
            <div className="d-md-flex">
              <ol className="breadcrumb ms-auto"></ol>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid overflow-scroll mt-3"
        style={{ backgroundColor: 'white' }}
      >
        <div className="row pt-3">
          <div className="col-md-12">
            <div
              style={{ overflow: 'scroll', width: '100%' }}
              className="white-box"
            >
              <table className="table">
                <thead>
                  <tr className="">
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Product_Name</th>

                    <th scope="col">Order_Id</th>

                    <th scope="col"> Order_date</th>

                    <th scope="col">Amount</th>
                    <th scope="col">Coupon</th>

                    <th scope="col">Receipt</th>
                  </tr>
                </thead>

                <tbody>
                  {state.map((element, index) => {
                    return element?.items.map((elem, ind) => {
                      console.log(elem);
                      return (
                        <>
                          <tr
                            className={
                              elem.Status === 'rejected'
                                ? 'text-warning'
                                : elem.Status === 'confirmed'
                                ? 'text-success'
                                : elem.Status === 'cancelled'
                                ? 'text-danger'
                                : elem.Status === 'returned'
                                ? 'text-primary'
                                : 'text-black'
                            }
                            key={keyGen()}
                          >
                            <td>{no++}</td>

                            <td>
                              {element.billingAddress.firstname +
                                ' ' +
                                element.billingAddress.lastname}
                            </td>
                            <td>{elem.name}</td>

                            <td>{element.Order_Id}</td>
                            <td>
                              {Moment(element.Order_date).format('DD-MM-YYYY')}
                            </td>

                            <td>{elem.price}</td>
                            <td>{element.Coupon}</td>

                            <td>{element.Receipt}</td>
                            <td>
                              <Button
                                variant="primary"
                                onClick={(e) => {
                                  setIndex(index);
                                  setInd(ind);
                                  handleShow();
                                }}
                              >
                                View&nbsp;details
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    });
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal
        scrollable
        centered
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>View details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 d-flex justify-content-center my-1">
              <Link
                to={`/invoice?order=${state[index]?.Order_Id}`}
                className="text-success "
                style={{ textDecoration: 'none' }}
              >
                INVOICE
              </Link>
            </div>
          </div>
          {index >= 0 ? (
            <>
              <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ flexShrink: 0 }}>
                    Shipping Address
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="ptag">
                      <p>
                        Name:&nbsp;
                        {state[index]?.shippingAddress.firstname +
                          ' ' +
                          state[index]?.shippingAddress.lastname}
                        &nbsp;
                      </p>
                      <p>
                        Address:&nbsp;
                        {state[index]?.shippingAddress.address}
                        &nbsp;
                      </p>
                      <p>
                        Country:&nbsp;
                        {state[index]?.shippingAddress.country}
                        &nbsp;
                      </p>
                      <p>
                        State:&nbsp;
                        {state[index]?.shippingAddress.state}&nbsp;
                      </p>
                      <p>
                        City:&nbsp;
                        {state[index]?.shippingAddress.city}&nbsp;
                      </p>
                      <p>
                        Pincode:&nbsp;
                        {state[index]?.shippingAddress.pincode}
                        &nbsp;
                      </p>
                      <p>
                        Email:&nbsp;
                        {state[index]?.shippingAddress.email}&nbsp;
                      </p>
                      <p>
                        Mobileno:&nbsp;
                        {state[index]?.shippingAddress.mobileno}
                      </p>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ flexShrink: 0 }}>
                    Billing Address
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="ptag">
                      <p>
                        Name:&nbsp;
                        {state[index]?.billingAddress.firstname +
                          ' ' +
                          state[index]?.billingAddress.lastname}
                        &nbsp;
                      </p>
                      <p>
                        Address:&nbsp;
                        {state[index]?.billingAddress.address}
                        &nbsp;
                      </p>
                      <p>
                        Country:&nbsp;
                        {state[index]?.billingAddress.country}
                        &nbsp;
                      </p>
                      <p>
                        State:&nbsp;
                        {state[index]?.billingAddress.state}&nbsp;
                      </p>
                      <p>
                        City:&nbsp;
                        {state[index]?.billingAddress.city}&nbsp;
                      </p>
                      <p>
                        Pincode:&nbsp;
                        {state[index]?.billingAddress.pincode}
                        &nbsp;
                      </p>
                      <p>
                        Email:&nbsp;
                        {state[index]?.billingAddress.email}&nbsp;
                      </p>
                      <p>
                        Mobileno:&nbsp;
                        {state[index]?.billingAddress.mobileno}
                      </p>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === 'panel4'}
                onChange={handleChange('panel4')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ flexShrink: 0 }}>Cart</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <>
                      {state[index]?.Cart_Id?.Items.map((element, indexx) => {
                        console.log(ind);
                        if (ind === indexx) {
                          return (
                            <div className="ptag" key={keyGen()}>
                              <p>
                                Status:&nbsp;
                                <span className="text-uppercase">
                                  {state[index]?.items[ind]?.Status}
                                </span>
                              </p>

                              <p>
                                Product Name:&nbsp;{element.productId.pname}
                              </p>
                              <p>Quantity:&nbsp;{element.qty}</p>
                              <p>
                                Varient Name:&nbsp;
                                {element.varientId.varentName}
                              </p>
                              <p>
                                Varient Code:&nbsp;
                                {element.varientId.varentCode}
                              </p>
                              {state[index]?.items[ind]?.Cancel_reason ? (
                                <>
                                  <hr />
                                  <h4 className="text-danger">Cancel</h4>
                                  <p>
                                    Cancel&nbsp;Reason:&nbsp;
                                    {state[index]?.items[ind]?.Cancel_reason}
                                  </p>
                                </>
                              ) : (
                                <></>
                              )}
                              {state[index]?.items[ind]?.Return_reason ? (
                                <>
                                  <hr />
                                  <h4 className="text-danger">Return</h4>
                                  <p>
                                    Return&nbsp;Reason:&nbsp;
                                    {state[index]?.items[ind]?.Return_reason}
                                  </p>
                                  <p>Return&nbsp;Image:&nbsp;</p>

                                  {state[index]?.items[ind]?.Images.map(
                                    (elem) => {
                                      console.log(elem);
                                      return (
                                        <>
                                          <img
                                            src={elem.link}
                                            style={{ width: '100%' }}
                                          ></img>
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                <></>
                              )}

                              {element?.gift?.status === true && (
                                <div className="border p-2 mt-2">
                                  <h4 className="text-success ">Gift</h4>
                                  {element?.gift?.Occassion && (
                                    <div>
                                      Occassion:&nbsp;{element?.gift?.Occassion}
                                    </div>
                                  )}
                                  <div>
                                    Gift Message: &nbsp;
                                    {element?.gift?.GiftcardMessage}
                                  </div>
                                  r
                                  <div>
                                    Date:&nbsp;
                                    {element?.gift?.Specificdeliverydate}
                                  </div>
                                  <div>
                                    Recipient's Name:&nbsp;
                                    {element?.gift?.rName}
                                  </div>
                                  <div>
                                    Recipient's Contact Number: &nbsp;
                                    {element?.gift?.rNumber}
                                  </div>
                                  {/* <span>Chocolates:</span>
                                {element?.gift?.choclates?.map((elem) => {
                                  return <span className="px-2">{elem}</span>;
                                })}{' '} */}
                                </div>
                              )}
                              <hr />
                            </div>
                          );
                        }
                      })}
                    </>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </>
          ) : (
            <></>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
