import React, { useEffect } from 'react';
import Contentcart from '../components/Vipar-chic/Contentcart/Contentcart';
import Emptycart from '../components/Vipar-chic/Emptycart/Emptycart';
import axios from 'axios';
import { useSelector } from 'react-redux';

function ViparChicCart() {
  let cart = useSelector((state) => state.cart.value);
  console.log(cart);
  // const [cart, setCart] = useState([]);
  useEffect(() => {
    axios
      .get('/public/shopping')
      .then((res) => {
        console.log(res.data);
        // setCart(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {};
  }, []);

  return <>{cart?.length ? <Contentcart Items={cart} /> : <Emptycart />}</>;
}

export default ViparChicCart;
