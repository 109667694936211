import { height } from '@mui/system'
import React from 'react'

export const Demoidentity = () => {
  return (
    <section className=" h-100" style={{ backgroundColor: "#012060" }}>
        <div className=" d-flex justify-content-center align-items-center">
          <div className="col-12 col-md-7 col-lg-7 col-xl-7 p-0">
            <div
              className="card shadow-2-strong mt-5"
              style={{ borderRadius: "1rem" }}
            >
              <div className="card-body py-0">
              <center>
                  <h3 className="mb-3 mt-3" >Employee Details</h3>
                  <hr className="" style={{width:"192px"}} />

                </center>
                <div className="row">
              
                  <div className='col-12 col-md-4 col-lg-4 pe-0 mt-4'>
               
                    <center>
                 
                   <img src="/rm.jpeg" class="img-thumbnail" alt="..." style={{height:"200px",width:"200px",objectFit:"cover"}}></img></center>
                 

                  </div>
                  <div className='col-12 col-md-8 col-lg-8 ps-3 pe-0 mt-3'>
                  <div className="form-outline mb-3 mt-3">
                      <label className="form-label" htmlFor="typeEmailX-22">
                      <span
                  className="vipar"
                  style={{ fontSize: '22px', color: '#99d050' }}
                >
                  {' '}
                  Vipar&nbsp;
                </span>&nbsp;WING : MARKETING and SALES
                      </label>
                  </div>
                     
                    <div className="form-outline mb-3 mt-3">
                      <label className="form-label" htmlFor="typeEmailX-22">
                        DESIGNATION : PROchure FIELD SALES EXECUTIVE
                      </label>
                   </div>

                    <div className="form-outline mb-3 mt-3">
                      <label className="form-label" htmlFor="typeEmailX-22">
                        EMPLOYEE ID : BLRFSE01
                      </label>
                     
                    </div>

                    <div className="form-outline mb-3">
                      <label className="form-label" htmlFor="typeEmailX-22">
                      JOINED ON : 15/11/2023
                      </label>
                      
                    </div>

                    <div className="form-outline mb-3">
                      <label className="form-label" htmlFor="typeEmailX-22">
                        FATHER'S NAME : Antheshwar
                      </label>
                  
                    </div>


                    <div className="form-outline mb-3">
                      <label className="form-label" htmlFor="typeEmailX-22">
                      D-O-B : 19-01-1998 
                      </label>
                 
                    </div>

                    <div className="form-outline mb-3">
                      <label className="form-label" htmlFor="typeEmailX-22">
                        BLOOD GROUP : B+ve
                      </label>
                     
                    </div>

                    <div className="form-outline mb-3 ">
                      <label className="form-label" htmlFor="typeEmailX-22">
                        OFFICIAL CONTACT NUMBER : +91 7899856057
                      </label>
                     
                    </div>
                    <hr className="my-4" />

                </div>
</div>
             
              </div>
            </div>
          </div>
        </div>
    </section>
  )
}
