import { InlineShareButtons } from 'sharethis-reactjs';
import { Modal } from 'react-bootstrap';
import { BsInstagram } from 'react-icons/bs';
import { useState } from 'react';
import { notify } from '../../../App';
import { ImFacebook } from 'react-icons/im';

export const ShareThis = ({ shareshow, setshareshow, url }) => {
  const handleClose = () => {
    setshareshow(false);
  };
  const [urlcopy, setUrlcopy] = useState();

  const copyurl = () => {
    const url = window.location.href;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        notify('URL copied to clipboard', true);
      })
      .catch((error) => {
        notify('Failed to copy URL', false);
      });
    setUrlcopy(url);
  };

  return (
    <>
      <Modal
        show={shareshow}
        onHide={() => {
          handleClose();
        }}
        backdrop={'static'}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {' '}
            How sweet. 🤗
            <br />
            Thank you so much !!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="row gy-3">
            <div className="col-auto">
              <input
                type="button"
                className="btn btn-outline-dark"
                value="Copy Url"
                onClick={copyurl}
              />
            </div>
            <div className="col">
              <textarea
                id="url"
                className="form-control "
                rows="1"
                cols="30"
                value={urlcopy}
              ></textarea>
            </div>
          </div> */}
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <InlineShareButtons
              config={{
                // alignment: "center", // alignment of buttons (left, center, right)
                color: 'social', // set the color of buttons (social, white)
                enabled: true, // show/hide buttons (true, false)
                font_size: 16, // font size for the buttons
                labels: 'null', // button labels (cta, counts, null)
                language: 'en', // which language to use (see LANGUAGES)
                networks: [
                  // which networks to include (see SHARING NETWORKS)
                  'whatsapp',
                  //   'linkedin',
                  //   'messenger',
                  'facebook',

                  'twitter',
                ],
                padding: 12, // padding within buttons (INTEGER)
                radius: 4, // the corner radius on each button (INTEGER)
                // show_total: true,
                size: 40, // the size of each button (INTEGER)

                // OPTIONAL PARAMETERS
                url: url, // (defaults to current url)
                // image: "https://bit.ly/2CMhCMC", // (defaults to og:image or twitter:image)
                // description: "custom text", // (defaults to og:description or twitter:description)
                // title: "custom title", // (defaults to og:title or twitter:title)
                // message: "custom email text", // (only for email sharing)
                // subject: "custom email subject", // (only for email sharing)
                // username: "custom twitter handle", // (only for twitter sharing)
              }}
            />

            {/* <a
              href="https://www.instagram.com/"
              className=" fa instagramnew  d-flex align-items-center justify-content-center "
            >
              <BsInstagram size={20} />
            </a> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
