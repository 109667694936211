import React, { useEffect, useState } from 'react';
import './Accessoriescappod.css';
import { BsTwitter, BsWhatsapp, BsLinkedin, BsInstagram } from 'react-icons/bs';
import { ImFacebook } from 'react-icons/im';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import { keyGen, notify } from '../../../App';
import { Access } from './Access';
import { Rating } from '../Rating';
import { AiFillStar } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import userSlice from '../../../redux/viparChic/userSlice';
import { ShareThis } from '../Share/Share';
import { Viparexclusivemodal } from '../Articles/Viparexclusivemodal';

function Accessoriescappod() {
  const [urlcopy, setUrlcopy] = useState();
  const [share, setShare] = useState(false);

  let isLogin = useSelector((state) => {
    return state.user.value.Status;
  });
  console.log(isLogin);
  const copyurl = () => {
    const url = window.location.href;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        notify('URL copied to clipboard', true);
      })
      .catch((error) => {
        notify('Failed to copy URL', false);
      });
    setUrlcopy(url);
  };
  let [searchParams, setSearchParams] = useSearchParams();
  var mod = searchParams.get('modal');

  const [loading, setloading] = useState(true);
  let navigate = useNavigate();
  const [state, setState] = useState([]);
  useEffect(() => {
    axios
      .get('/public/getproducts?id=63abf74851626cde3b9d7c6b')
      .then((res) => {
        console.log(res.data);
        setState(res.data[0]);
        setloading(false);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
    if (mod) {
      handleShow();
    }
    return () => {
      handleClose();
    };
  }, []);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [review, setReview] = useState([]);

  const [desshow, setdesShow] = useState(false);
  const deshandleClose = () => setdesShow(false);
  const deshandleShow = () => setdesShow(true);

  const [friendshow, setfriendShow] = useState(false);
  const friendhandleClose = () => setfriendShow(false);
  const friendhandleShow = () => setfriendShow(true);

  const [specshow, setspecShow] = useState(false);
  const spechandleClose = () => setspecShow(false);
  const spechandleShow = () => setspecShow(true);

  const [detailsshow, setdetailsShow] = useState(false);
  const detailshandleClose = () => setdetailsShow(false);
  const detailshandleShow = () => setdetailsShow(true);

  const [careshow, setcareShow] = useState(false);
  const carehandleClose = () => setcareShow(false);
  const carehandleShow = () => setcareShow(true);

  const [returnshow, setreturnShow] = useState(false);
  const returnhandleClose = () => setreturnShow(false);
  const returnhandleShow = () => setreturnShow(true);

  const [cfshow, setCfshow] = useState(false);
  const cfhandleClose = () => setCfshow(false);
  const cfhandleShow = () => setCfshow(true);

  const modal = useSelector((state) => {
    return state.modal.value;
  });

  return (
    <>
      <hr
        className="mt-0"
        style={{
          color: 'rgb(1, 32, 96)',
          opacity: '1',
          borderTop: '3px solid',
        }}
      />

      <section>
        <div className="container ">
          <div className="row">
            <div className="col-lg-5 mt-5 d-flex justify-content-center">
              <div
                id="carouselExampleControls"
                className="carousel slide cardimgwidth"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  {/* <ul
                    id="controller"
                    style={{ position: 'relative' }}
                    className="carousel-indicators "
                  >
                    <li
                      data-bs-target="#template-mo-zay-hero-carousel-2"
                      style={{ width: '5px' }}
                      data-bs-slide-to="0"
                      className="active"
                    ></li>
                    <li
                      data-bs-target="#template-mo-zay-hero-carousel-2"
                      style={{ width: '5px' }}
                      data-bs-slide-to="1"
                    ></li>
                    <li
                      data-bs-target="#template-mo-zay-hero-carousel-2"
                      style={{ width: '5px' }}
                      data-bs-slide-to="2"
                    ></li>
                    <li
                      data-bs-target="#template-mo-zay-hero-carousel-2"
                      style={{ width: '5px' }}
                      data-bs-slide-to="3"
                    ></li>
                    <li
                      data-bs-target="#template-mo-zay-hero-carousel-2"
                      style={{ width: '5px' }}
                      data-bs-slide-to="4"
                    ></li>
                    <li
                      data-bs-target="#template-mo-zay-hero-carousel-2"
                      style={{ width: '5px' }}
                      data-bs-slide-to="5"
                    ></li>
                    <li
                      data-bs-target="#template-mo-zay-hero-carousel-2"
                      style={{ width: '5px' }}
                      data-bs-slide-to="6"
                    ></li>
                    <li
                      data-bs-target="#template-mo-zay-hero-carousel-2"
                      style={{ width: '5px' }}
                      data-bs-slide-to="7"
                    ></li>
                    <li
                      data-bs-target="#template-mo-zay-hero-carousel-2"
                      style={{ width: '5px' }}
                      data-bs-slide-to="8"
                    ></li>
                    <li
                      data-bs-target="#template-mo-zay-hero-carousel-2"
                      style={{ width: '5px' }}
                      data-bs-slide-to="9"
                    ></li>
                  </ul> */}

                  <div className="carousel-item active">
                    <div className="card cardborder">
                      <img
                        id="f1"
                        src="/img/one.jpg"
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body cardheight">
                        <p className="cardtext  mb-0">
                          This dust-proof and moisture-proof holder is the most
                          hygienic way to use and carry your mask around,
                          effectively preventing secondary pollution of the
                          mask.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="card cardborder ">
                      <img
                        src="/img/two.jpg"
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body cardheight">
                        <p className="cardtext">
                          Women can now conveniently carry their sanitary
                          products to their work-place and elsewhere in a small
                          and sleek holder.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="card  cardborder">
                      <img
                        src="/img/three.jpg"
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body cardheight">
                        <p className="cardtext">
                          Ladies……here’s the cutest and fashionable cosmetics
                          holder ever. In colours you cannot resist.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="card  cardborder">
                      <img
                        src="/img/four.jpg"
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body cardheight">
                        <p className="cardtext">
                          Hey girls, a beautiful holder for your hair
                          accessories and costume jewelry.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="card  cardborder">
                      <img
                        src="/img/five.jpg"
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body cardheight">
                        <p className="cardtext">
                          It is also a food-grade container.
                          <br />
                          Carry fruits / dry fruits to office or while
                          travelling to have a quick munch.
                          <br />
                          Send candies and biscuits with your tiny tots to
                          school. &nbsp;How cool !
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="card cardborder">
                      <img
                        src="/img/six.jpg"
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body cardheight">
                        <p className="cardtext">
                          Ever wondered where to keep your half-eaten snack /
                          chocolate bar ?<br />
                          Air-tight closure also ensures that your edibles are
                          maintained fresh, clean and crisp.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="card cardborder">
                      <img
                        src="/img/seven.jpg"
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body cardheight">
                        <p className="cardtext">
                          A blessing for those who would like to organize their
                          medicines at home and while travelling. You can neatly
                          segregate your medicines with the multiple colour
                          options.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="card cardborder">
                      <img
                        src="/img/eight.jpg"
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body cardheight">
                        <p className="cardtext">
                          Now you do not have to throw your keys haphazardly
                          into your suitcases or handbags while going out on a
                          holiday. Store your bunch of house keys in one holder,
                          without the fear of misplacing them.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="card cardborder">
                      <img
                        src="/img/nine.jpg"
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body cardheight">
                        <p className="cardtext">
                          Safely keep and carry coins / currency notes in these
                          handy holders at home / in your car / in your office
                          drawers.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="card cardborder">
                      <img
                        src="/img/cover.jpg"
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body cardheight">
                        <p className="cardtext">
                          Choose from 9 amazing Chrome, Nature and
                          Floral colours !
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="card cardborder">
                      <img
                        src="/img/c9.jpg"
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body cardheight"></div>
                    </div>
                  </div>
                </div>
                <a
                  className="carousel-control-prev text-decoration-none w-auto ps-3"
                  href="#carouselExampleControls"
                  role="button"
                  data-bs-slide="prev"
                >
                  <i className="fas fassp fa-chevron-left"></i>
                </a>
                <a
                  className="carousel-control-next text-decoration-none w-auto pe-3"
                  href="#carouselExampleControls"
                  role="button"
                  data-bs-slide="next"
                >
                  <i className="fas fassp fa-chevron-right"></i>
                </a>
              </div>
              {/* </div>
          </div> */}
            </div>

            <div className="col-lg-7 mt-5">
              <div className="card cardborder">
                <div className="card-body pt-0">
                  <span
                    className=""
                    style={{ fontSize: '30px', fontWeight: '100' }}
                  >
                    {loading ? (
                      loading && (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )
                    ) : (
                      <>
                        <span
                          style={{ fontFamily: 'Monotype', color: 'green' }}
                        >
                          Vipar
                        </span>
                        &nbsp;&nbsp;{state?.pname}
                      </>
                    )}
                  </span>
                  <br></br>
                  <span>
                    <h3 className="" style={{ fontSize: '23px' }}>
                      a capsule-shaped compact receptacle
                    </h3>
                  </span>
                  <h4 className="pt-2">
                    <Viparexclusivemodal />
                  </h4>
                  <p
                    className=" pt-2 mb-0"
                    style={{ fontSize: '20px', fontWeight: '100' }}
                  >
                    ₹{' '}
                    {loading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      state?.price + ' /- for a Pack of 3'
                    )}
                    <br />
                    <span>
                      <Rating value={{ review, setReview }}></Rating>
                    </span>
                    <br />
                  </p>
                  <p className="pt-3 mb-0">
                    A simple product to carry your Essentials all Sorted.
                  </p>
                  <p className="pt-3 mb-0">
                    {' '}
                    Elegant, Sleek, Handy and Convenient-to-use
                  </p>
                  <p className="pt-3 mb-0">
                    One Product &gt; Multifarious Uses &gt; All Age groups !
                  </p>
                  <p className="pt-3 pb-0 mb-0">
                    {' '}
                    Vibrant and Sober colours | Appealing Fashion article |
                    Unique Gift item
                  </p>

                  {/* <p
                    className=" pt-3 pb-0 mb-0"
                    style={{ fontSize: '20px', fontWeight: '100' }}
                  >
                    Elegant, Sleek, Handy and Convenient-to-use
                    <br />
                    One Product &gt; Multifarious Uses &gt; All Age groups !
                    <br />
                    Vibrant and Sober colours | Appealing Fashion article |
                    Unique Gift item
                  </p> */}
                  <br />
                  <span style={{ fontSize: '17px' }}>
                    Product dispatch time : within 1 business day
                  </span>
                  <br></br>
                  <span></span>
                  <ul />
                  <form action="" method="GET">
                    <input
                      type="hidden"
                      name="product-title"
                      value="Activewear"
                    />

                    <div className="row pb-3">
                      <div className="col d-grid">
                        <button
                          type="button"
                          // id="open"
                          onClick={() =>
                            isLogin
                              ? handleShow()
                              : document
                                  .getElementById('RegisterModalbtndemo')
                                  ?.click()
                          }
                          // onClick={handleShow}
                          className="btn btn-vio btn-lg text-white"
                          name="submit"
                          // data-bs-toggle="modal"
                          // data-bs-target="#fmhdiscrption"
                          style={{ fontFamily: 'FootlightMTLight' }}
                        >
                          BUY IT NOW
                        </button>
                      </div>
                      <div className="col d-grid ">
                        <button
                          type="button"
                          className="btn btn-outline-dark btn-lg"
                          name="submit"
                          onClick={cfhandleShow}
                          style={{ fontSize: '17px' }}
                        >
                          Reviews
                        </button>
                      </div>
                    </div>

                    <div className="row pb-3">
                      <div className="col d-grid ">
                        <button
                          type="button"
                          className="btn btn-outline-dark btn-lg"
                          name="submit"
                          onClick={returnhandleShow}
                          style={{ fontSize: '17px' }}
                        >
                          return&nbsp;/&nbsp;cancel
                        </button>
                      </div>
                      <div className="col d-grid ">
                        <button
                          type="button"
                          className="btn btn-outline-dark btn-lg"
                          name="submit"
                          onClick={() => setShare(true)}
                          value="addtocard"
                          style={{
                            fontFamily: 'FootlightMTLight',
                            fontSize: '17px',
                          }}
                        >
                          RECOMMEND
                        </button>
                      </div>
                    </div>

                    <Modal
                      show={show}
                      onHide={handleClose}
                      scrollable={true}
                      size="lg"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          <h5 id="staticBackdropLabel">
                            This product can be purchased only in multiples of
                            three in a regular order.
                          </h5>
                        </Modal.Title>
                      </Modal.Header>

                      <Modal.Footer>
                        <button
                          onClick={() => {
                            navigate('/vipar-chic/shopping_bag');
                          }}
                          type="button"
                          className="btn btn-outline-dark"
                        >
                          GO TO SHOPPING BAG
                        </button>
                      </Modal.Footer>
                    </Modal>
                    <Modal
                      show={desshow}
                      onHide={deshandleClose}
                      scrollable={true}
                      size="lg"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title id="staticBackdropLabel">
                          Descriptions
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <h5 className="cardtext">
                          Made of high quality, non-toxic and sustainable
                          plastic, it is hygienic, dust-proof and
                          moisture-proof.
                        </h5>
                        <h5 className="cardtext">
                          A 100% safe-to-use US FDA approved food-grade
                          container, it is reusable and durable for long term
                          usage and can be cleaned time and again. It is
                          odour-free and unbreakable. A simple thread-locking
                          mechanism helps you open and close the holder easily.
                        </h5>
                      </Modal.Body>
                    </Modal>
                    <Modal
                      show={cfshow}
                      onHide={cfhandleClose}
                      scrollable={true}
                      size="lg"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Customer Feedback</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="container">
                          <div className="row mb-5">
                            <div className="col-sm-12">
                              <div className="review-block">
                                {review?.map((elem, ind) => {
                                  return (
                                    <>
                                      <div className="row">
                                        <div className="col-12">
                                          <div className="review-block-name">
                                            <div className="d-flex align-items-center">
                                              <img
                                                src="/img/avatar.webp"
                                                className="img-rounded me-2"
                                                alt=""
                                                height="50px"
                                                width="50px"
                                                style={{
                                                  borderRadius: '30px',
                                                  display: 'inline-block',
                                                }}
                                              />
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                }}
                                              >
                                                <p className="m-0">
                                                  {elem.orderdetails[0]
                                                    .billingAddress.firstname +
                                                    ' ' +
                                                    elem.orderdetails[0]
                                                      .billingAddress.lastname}
                                                </p>
                                                <div className="review-block-rate">
                                                  {elem?.rating &&
                                                    new Array(elem.rating)
                                                      .fill(0)
                                                      .map((elem) => {
                                                        return (
                                                          <AiFillStar color="yellow" />
                                                        );
                                                      })}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="container">
                                        <div className="row">
                                          <div className="col-12">
                                            <div className="review-block-date px-1"></div>
                                          </div>
                                          <div className="col-sm-12 col-md-12 p-0">
                                            <div className="review-block-description">
                                              {review?.map((elm, ind) => {
                                                return (
                                                  <>
                                                    <p>{elm.comment}</p>
                                                  </>
                                                );
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                                {review.length == 0 ? 'No Reviews to show' : ''}
                                <hr />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>

                    <Modal
                      show={friendshow}
                      onHide={friendhandleClose}
                      scrollable={true}
                      size="lg"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          How sweet. 🤗
                          <br />
                          Thank you so much !!
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row gy-3">
                          <div className="col-auto">
                            <input
                              type="button"
                              className="btn btn-outline-dark"
                              value="Copy Url"
                              onClick={copyurl}
                            />
                          </div>
                          <div className="col">
                            <textarea
                              id="url"
                              className="form-control "
                              rows="1"
                              cols="30"
                              value={urlcopy}
                            ></textarea>
                          </div>
                        </div>
                        <div className="row d-flex justify-content-center mt-3">
                          <div className="col-auto  p-0 px-2">
                            <a
                              href="https://www.facebook.com/"
                              className=" fa facebook d-flex align-items-center justify-content-center"
                            >
                              <ImFacebook size={30} />
                            </a>
                          </div>

                          <div className="col-auto p-0 px-2">
                            <a
                              href="https://web.whatsapp.com/"
                              className=" fa whatsapp  d-flex align-items-center justify-content-center"
                            >
                              <BsWhatsapp size={30} />
                            </a>
                          </div>

                          <div className="col-auto  p-0 px-2">
                            <a
                              href="https://www.instagram.com/"
                              className=" fa instagram  d-flex align-items-center justify-content-center"
                            >
                              <BsInstagram size={35} />
                            </a>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>

                    <Modal
                      show={specshow}
                      onHide={spechandleClose}
                      scrollable={true}
                      size="lg"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Specification</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <h5 className="cardtext">
                          Material type : Rubber wood, Acrylic, Anodised
                          aluminium
                        </h5>
                        <h5 className="cardtext">
                          Size : 10 inches (width) x 9 inches (height) x 6
                          inches (depth)
                        </h5>
                        <h5 className="cardtext">Weight : 1660 grams</h5>
                      </Modal.Body>
                    </Modal>

                    <Modal
                      show={detailsshow}
                      onHide={detailshandleClose}
                      scrollable={true}
                      size="lg"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Details</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <h5 className="cardtext">
                          Vipar D U B&nbsp;&nbsp;classic is a Vipar product
                        </h5>
                        <h5 className="cardtext">
                          Conceptualized, Designed and Marketed by :{' '}
                          <span className="dddddd monot">Vipar&nbsp;</span>
                          <sup className="reggg">&reg;</sup>
                        </h5>
                        <h5 className="cardtext">Country of Origin : India</h5>
                        <h5 className="cardtext">
                          HSN code (of the product) : 3924
                        </h5>
                      </Modal.Body>
                    </Modal>

                    <Modal
                      show={careshow}
                      onHide={carehandleClose}
                      scrollable={true}
                      size="lg"
                      centered
                    >
                      <Modal.Header closeButton>
                        <h5 className="modal-title" id="staticBackdropLabel">
                          Care instructions
                        </h5>
                      </Modal.Header>
                      <Modal.Body>
                        <h5 className="cardtext">
                          Acrylic is a delicate material. You are to "Handle
                          with Care" the acrylic plates while removing, wiping,
                          washing and replacing.
                        </h5>
                        <h5 className="cardtext">
                          Milk bottles and packets must be placed inside the
                          front compartment with care. Rough usage will damage
                          the acrylic plate.
                        </h5>
                        <h5 className="cardtext">
                          You may wash the acrylic plates daily with water.
                        </h5>
                        <h5 className="cardtext">
                          In case of spillage, wipe clean the wood/aluminium
                          components and lock with a dry cloth only.
                        </h5>
                        <h5 className="cardtext">
                          MAINTAIN the wood/aluminium components and lock DRY
                          ALWAYS. Wood component is only water-resistant and not
                          water-proof.
                        </h5>
                        <h5 className="cardtext">
                          Sharp, pointed objects or blades can damage the
                          surface of the product if scraped on it.
                        </h5>
                        <h5 className="cardtext">
                          The D U B&nbsp;&nbsp;classic must not be placed in
                          areas which are directly exposed to rain.
                        </h5>
                      </Modal.Body>
                    </Modal>

                    <Modal
                      show={returnshow}
                      onHide={returnhandleClose}
                      scrollable={true}
                      size="lg"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Return / Cancellation terms</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p className="assss">Q. Can we return our order ?</p>
                        <p>
                          A. All our products are{' '}
                          <b style={{ fontWeight: '800' }}>non</b>-returnable.
                          However, products with manufacturing defects can be
                          exchanged on the basis of strict terms and conditions.
                          Please read our{' '}
                          <a
                            className="as"
                            href="#"
                            onClick={(e) => modal.epmodal()}
                            data-bs-dismiss="modal"
                          >
                            exchange policies
                          </a>{' '}
                          to know more about this.
                          <br />
                          <br />
                          To Return the Order, follow these steps :<br />
                          1. Log in to your Vipar account.
                          <br />
                          2. Go to 'New Order' on your Shopping Bag icon.
                          <br />
                          3. Click on the 'Return' button.
                        </p>
                        <p className="assss">Q. Can we cancel our order ?</p>
                        <span>
                          Yes. ONLY if you cancel the order before we dispatch
                          it from our warehouse.
                          <br />
                          <br />
                          To Cancel the Order, follow these steps :<br />
                          1. Log in to your Vipar account.
                          <br />
                          2. Go to 'New Order' on your Shopping Bag icon.
                          <br />
                          3. Click on the 'Cancel' button.
                          <br />
                          <br />
                          Once the order is booked for shipping with our
                          logistics partner, and then you cancel the order, you
                          will have to bear both the to-and-fro shipping costs.
                          And, the parcel box must be returned STRICTLY without
                          the VIPAR seal tape tampered with or damaged or
                          broken. Refund (after deduction of to-and-fro shipping
                          costs) will be initiated only after we receive the
                          parcel box in the manner we have specified above and
                          we are assured that the product is unused.
                        </span>
                      </Modal.Body>
                    </Modal>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container ">
          <div className="col-md-2 col-sm-dnone "></div>
          <div className="col-md-8 col-sm-dnone ">
            <div className="pppp fontfordetalis">
              <b className="" style={{ fontWeight: '700' }}>
                Product description
              </b>{' '}
              : <br />
              Made of high quality, non-toxic and sustainable plastic, it is
              hygienic, dust-proof and moisture-proof.
              <br />A 100% safe-to-use US FDA approved food-grade container, it
              is reusable and durable for long term usage and can be cleaned
              time and again. It is odour-free and unbreakable. A simple
              thread-locking mechanism helps you open and close the holder
              easily.
              <br />
              <b className="" style={{ fontWeight: '700' }}>
                Product specifications and details
              </b>{' '}
              : <br /> Material type : ABS Plastic <br />
              Size : 49 mm / 1.93 inches (dia) x 101.6 mm / 4 inches (height)
              <br />
              Weight : 36 grams
              <br />
              Style : Capsule Swivel
              <br />
              <span style={{ fontFamily: 'Monotype', color: 'green' }}>
                Vipar
              </span>
              &nbsp;&nbsp;Cap-pod is a{' '}
              <span style={{ fontFamily: 'Monotype', color: 'green' }}>
                Vipar
              </span>{' '}
              &nbsp;product
              <br />
              Conceptualized, Designed and Marketed by :{' '}
              <span className="dddddd monot">Vipar&nbsp;</span>
              <sup className="reggg">&reg;</sup> <br />
              Country of Origin : India
              <br />
              HSN code (of the product) : 3924
              <br />
              <b className="discrpfvniv" style={{ fontWeight: '700' }}>
                Care instructions{' '}
              </b>{' '}
              : <br />
              You may wash the container daily with water and soap as well.
              <br />
              To prevent damage, please do not place the holder near flame.
              <br />
              Sharp, pointed objects or blades can damage the surface of the
              product if scraped on it.
              <br />
              <br />
            </div>
          </div>
          <div className="col-md-2 col-sm-dnone"></div>
        </div>
      </section>

      <Modal
        show={show}
        onHide={handleClose}
        scrollable={true}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 id="staticBackdropLabel">
              This product can be purchased only in multiples of three in a
              regular order.
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Access key={keyGen()} value={state?.variant} name={state?.pname} />
        </Modal.Body>
        <Modal.Footer>
          <button
            data-bs-dismiss="modal"
            onClick={() => {
              navigate('/vipar-chic/shopping_bag');
            }}
            type="button"
            className="btn btn-outline-dark"
          >
            GO TO SHOPPING BAG
          </button>
        </Modal.Footer>
      </Modal>

      <ShareThis
        shareshow={share}
        setshareshow={setShare}
        url={window.location.href}
      />
    </>
  );
}

export default Accessoriescappod;
