import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { DataGrid } from '@mui/x-data-grid';
import { notify } from '../../App';

export const Vacanciesview = () => {
  const [opperins, setopperins] = useState({
    JobTitle: '',
    JobDescription: '',
    Qualfication: '',
    Experience: '',
    Vacancies: '',
    JoiningDate: '',
    verticals: '',
    Status: false,
  });

  const [opperins3, setopperins3] = useState();
  useEffect(() => {
    console.log(opperins3);
  }, [opperins3]);

  const submit = () => {
    axios
      .post('/adminlogin/customeropporupdate', { opperins })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [state, setState] = useState([]);
  const [index, setIndex] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    axios
      .post('/adminlogin/applicantview')
      .then((res) => {
        console.log(res.data);
        const newState = res.data
          .map((e, index) => {
            console.log(e);
            return {
              ...e,
              id: index + 1,
              ind: index,
            };
            //   else {
            //     return null;
            //   }
          })
          .filter((e) => e !== undefined);
        console.log(newState);
        setState(newState);
      })
      .catch((err) => {});
  }, []);

  const [arr, setarr] = useState([]);
  console.log(state);
  useEffect(() => {
    var array = state.map((e, index) => {
      return {
        ...e,
        id: e.id,
        ind: e.ind,
        JobTitle: e.opp[0]?.JobTitle,
        JobDescription: e.opp[0]?.JobDescription,
        Qualfication: e.opp[0]?.Qualfication,
        Experience: e.opp[0]?.Experience,
        Vacancies: e.opp[0]?.Vacancies,

        JoiningDate: e.opp[0]?.JoiningDate,
        verticals: e.opp[0]?.verticals,

        jobId: e.jobId,
        Image: e.Image[0],
        applyStatus: e.applyStatus,
      };
    });
    setarr(array);
  }, [state]);

  useEffect(() => {
    console.log(state);
  }, [state]);

  useEffect(() => {
    console.log(opperins3);
  }, [opperins3]);

  const columns = [
    { field: 'id', headerName: 'Sl No', width: 70 },
    { field: 'verticals', headerName: 'Verticals', width: 130 },
    { field: 'JobTitle', headerName: 'Job Title', width: 130 },
    { field: 'Vacancies', headerName: 'Vacancies', width: 130 },

    {
      field: 'Details',
      headerName: 'Details',
      sortable: false,
      width: 90,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <button
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#detailsModal"
          onClick={(e) => {
            console.log(params.row);
            setopperins3(params.row);
          }}
        >
          Details
        </button>
      ),
    },

    {
      field: 'Delete',
      headerName: 'Delete',
      sortable: false,
      width: 90,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <button
          className="btn btn-danger"
          data-bs-toggle="modal"
          data-bs-target={'#exampleModal' + params.row.ind}
          onClick={(e) => {
            console.log(params.row);
          }}
        >
          Delete
        </button>
      ),
    },
  ];
  const tableStyles = {
    backgroundColor: '#f0f0f0',
  };

  const Deleteee = (id, ind) => {
    console.log(id);
    console.log(ind);

    axios
      .post('/adminlogin/applicantviewdelet', { id, ind })
      .then((res) => {
        console.log(res.data);
        notify('Successfully Deleted', true);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="page-breadcrumb ">
        <div className="row align-items-center text-white">
          <h4 className="page-title text-white">Applicants View</h4>
        </div>
      </div>
      <div
        style={{
          height: 400,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <DataGrid
          rows={arr}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          style={tableStyles}
          className="mt-5 pt-3 sswewe"
        />
      </div>

      {/* details modal */}

      <div
        class="modal fade"
        id="detailsModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content ">
            <div class="modal-header">
              <h5 class="modal-title px-3" id="exampleModalLabel">
                Details
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div class="modal-body">
              <div className="px-3">
                {/* <div className="">Job id : {opperins?.jobId}</div> */}

                <div className="">Job Title : {opperins3?.JobTitle}</div>
                <div className="">
                  Job Description : {opperins3?.JobDescription}
                </div>
                <div className="">
                  Qualification : {opperins3?.Qualfication}
                </div>
                <div className="">Experience: {opperins3?.Experience}</div>
                <div className="">Vacancies : {opperins3?.Vacancies}</div>
                <div className="">
                  Joining Date :{' '}
                  {moment(opperins3?.JoiningDate).format('MMMM Do YYYY')}{' '}
                </div>
              
                    <div className="">CurrentCTS : {opperins3?.CurrentCTS}</div>
                    <div className="">SalaryExpectation : {opperins3?.SalaryExpectation}</div>
                   
             
          
                <center>
                  <button className="btn btn-sm btn-primary my-2">
                    <a
                      href={opperins3?.Image?.link}
                      style={{ textDecoration: 'none', color: 'white' }}
                      download
                    >
                      Download CV
                    </a>
                  </button>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* details modal */}
      {/* edit modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {' '}
          <div>
            <label htmlhtmlFor="inputsubject">Verticles</label>
            <select
              class="form-select"
              aria-label="Default select example"
              value={opperins.verticals}
              onChange={(e) => {
                setopperins({ ...opperins, verticals: e.target.value });
              }}
            >
              <option></option>
              <option value="CONNECT">CONNECT</option>
              <option value="CHUM">CHUM</option>
              <option value="COMFORT">COMFORT</option>
              <option value="CRUISE">CRUISE</option>
              <option value="CLEAN">CLEAN</option>
              <option value="CHIC">CHIC</option>
              <option value="CASA">CASA</option>
              <option value="CARE">CARE</option>
            </select>
          </div>
          <div className="pt-4">
            <label htmlhtmlFor="inputname">Job Title</label>
            <input
              type="text"
              className="form-control mt-1"
              id="name"
              name="name"
              value={opperins?.JobTitle}
              onChange={(e) => {
                setopperins({ ...opperins, JobTitle: e.target.value });
              }}
            />
          </div>{' '}
          <div className="py-4">
            <label htmlhtmlFor="inputname">Job Description</label>
            <input
              value={opperins?.JobDescription}
              type="text"
              className="form-control mt-1"
              id="name"
              name="name"
              onChange={(e) => {
                setopperins({ ...opperins, JobDescription: e.target.value });
              }}
            />
          </div>
          <div>
            <label htmlhtmlFor="inputname">Qualification</label>
            <input
              type="text"
              className="form-control mt-1"
              id="name"
              name="name"
              value={opperins?.Qualfication}
              onChange={(e) => {
                setopperins({ ...opperins, Qualfication: e.target.value });
              }}
            />
          </div>
          <div className="py-4">
            <label htmlhtmlFor="inputname">Experience</label>
            <input
              type="text"
              className="form-control mt-1"
              id="name"
              name="name"
              value={opperins?.Experience}
              onChange={(e) => {
                setopperins({ ...opperins, Experience: e.target.value });
              }}
            />
          </div>
          <div>
            <label htmlhtmlFor="inputname">Vacancies</label>
            <input
              type="text"
              className="form-control mt-1"
              id="name"
              name="name"
              value={opperins?.Vacancies}
              onChange={(e) => {
                setopperins({ ...opperins, Vacancies: e.target.value });
              }}
            />
          </div>
          <div className="py-4">
            <label htmlhtmlFor="inputname">Joining Date</label>
            <input
              type=""
              className="form-control mt-1"
              id="name"
              name="name"
              value={opperins?.JoiningDate}
              onChange={(e) => {
                setopperins({ ...opperins, JoiningDate: e.target.value });
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="secondary"
            onClick={(e) => {
              submit();
              handleClose();
            }}
          >
            APPLY
          </Button>
        </Modal.Footer>
      </Modal>
      {/* edit modal */}

      {/* delet modal */}

      {arr?.map((elem, index) => {
        console.log(elem);
        return (
          <>
            <div
              class="modal fade"
              id={'exampleModal' + index}
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="btn-close"
                      id={'btnclose'}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body text-danger">
                    Are you sure you want to delete ?
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={(e) => {
                        Deleteee(elem._id, elem.ind);
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};
