import axios from 'axios';
import React, { useState } from 'react';

export const Subadminresgistration = () => {
  const [data, setData] = useState({
    Name: '',
    Email: '',
    Dateofbirth: '',
    Phone: '',
    Gender: 'male',
    Address: '',
    Password: '',
    Confirmpassword: '',
  });

  const [img, setimg] = useState([]);
  const [err, seterr] = useState();

  const [Validate, setValidate] = useState(false);

  const submit = (e) => {
    e.preventDefault();
    console.log(img);
    var formData = new FormData();
    {
      img?.map((elem) => {
        formData.append('file', elem);
      });
    }

    var datasend = JSON.stringify(data);
    formData.append('data', datasend);
    axios
      .post('/chum/chumuserinsert', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        console.log();
        setValidate(false);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  };

  return (
    <>
      <section className="" style={{ backgroundColor: '' }}>
        <div className="container  h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div
                className="card shadow-2-strong"
                style={{ borderRadius: '1rem' }}
              >
                <div className="card-body p-5 ">
                  <center>
                    <h3 className="mb-5">Sub Admin Registration</h3>
                  </center>
                  <form
                    onSubmit={(e) => submit(e)}
                    noValidate
                    className={Validate ? 'was-validated' : ''}
                  >
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="typeEmailX-22">
                        Name
                      </label>
                      <input
                        required
                        type="text"
                        name="name"
                        id="typeEmailX-22"
                        className="form-control form-control-lg"
                        fdprocessedid="ydop066"
                        onChange={(e) =>
                          setData({ ...data, Name: e.target.value })
                        }
                      />
                      <div class="invalid-feedback">Please enter your Name</div>
                    </div>
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="typePhoneX-2">
                        Gender
                      </label>
                      <div>
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          required
                          value="male"
                          onChange={(e) =>
                            setData({ ...data, Gender: e.target.value })
                          }
                        />
                        <label
                          class="form-check-label ms-2"
                          for="flexRadioDefault1"
                        >
                          Male
                        </label>

                        <input
                          class="form-check-input ms-4"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          required
                          value="female"
                          onChange={(e) =>
                            setData({ ...data, Gender: e.target.value })
                          }
                        />
                        <label
                          class="form-check-label ms-2"
                          for="flexRadioDefault2"
                        >
                          Female
                        </label>
                      </div>
                      <div class="invalid-feedback">
                        Please select your Gender
                      </div>
                    </div>
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="typeEmailX-22">
                        Date Of Birth
                      </label>
                      <input
                        required
                        type="date"
                        name="dob"
                        id="typeEmailX-22"
                        className="form-control form-control-lg"
                        fdprocessedid="ydop066"
                        onChange={(e) =>
                          setData({ ...data, Dateofbirth: e.target.value })
                        }
                      />
                      <div class="invalid-feedback">
                        Please select your date of birth
                      </div>
                    </div>

                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="typeEmailX-22">
                        Address
                      </label>
                      <textarea
                        className="form-control"
                        rows={3}
                        required
                        onChange={(e) => {
                          setData({ ...data, Address: e.target.value });
                        }}
                      ></textarea>
                      <div class="invalid-feedback">
                        Please enter your address
                      </div>
                    </div>
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="typeEmailX-2">
                        Email
                      </label>
                      <input
                        required
                        type="email"
                        name="email"
                        id="typeEmailX-2"
                        className="form-control form-control-lg"
                        fdprocessedid="ydop06"
                        onChange={(e) => {
                          setData({ ...data, Email: e.target.value });
                        }}
                      />
                      <div class="invalid-feedback">Please enter the Email</div>
                    </div>
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="typePhoneX-2">
                        Phone Number
                      </label>
                      <input
                        required
                        type="number"
                        name="phoneno"
                        id="typephonelX-2"
                        className="form-control form-control-lg"
                        fdprocessedid="ydop06"
                        onChange={(e) => {
                          setData({ ...data, Phone: e.target.value });
                        }}
                      />
                      <div class="invalid-feedback">
                        Please enter the Phone Number
                      </div>
                    </div>

                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="typePhoneX-2">
                        Identity Proof
                      </label>
                      <input
                        class="form-control"
                        type="file"
                        id="formFile"
                        required
                        onChange={(e) => {
                          setimg([...img, ...e.target.files]);
                        }}
                      />
                      <div class="invalid-feedback">
                        Please add your Identity Proof{' '}
                      </div>
                    </div>
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="typePasswordX-2">
                        New Password
                      </label>
                      <input
                        required
                        type="password"
                        name="npassword"
                        id="typePasswordX-2"
                        className="form-control form-control-lg"
                        fdprocessedid="kao81l"
                        onChange={(e) => {
                          if (data.Password === data.Confirmpassword) {
                            e.target.setCustomValidity('');
                          } else {
                            setTimeout(function () {
                              e.target.setCustomValidity('Password Missmatch');
                              seterr('Password Missmatch');
                            }, 4000);
                          }
                          setData({ ...data, Password: e.target.value });
                        }}
                      />
                      <div class="invalid-feedback">
                        Please enter the Password
                      </div>
                    </div>

                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="typePasswordX-22">
                        Confirm Password
                      </label>
                      <input
                        required
                        type="password"
                        name="cpassword"
                        id="typePasswordX-22"
                        className="form-control form-control-lg"
                        fdprocessedid="kao81ll"
                        onChange={(e) => {
                          if (data.Password === data.Confirmpassword) {
                            e.target.setCustomValidity('');
                          } else {
                            setTimeout(function () {
                              e.target.setCustomValidity('Password Missmatch');
                              seterr('Password Missmatch');
                            }, 4000);
                          }
                          setData({ ...data, Confirmpassword: e.target.value });
                        }}
                      />
                      {err && <div className="invalid-feedback">{err}</div>}
                    </div>
                    {/* <p style={{ color: 'red' }}>{err}</p> */}

                    <button
                      className="btn btn-primary btn-lg btn-block"
                      type="submit"
                      fdprocessedid="i8iyob"
                      onClick={(e) => setValidate(true)}
                    >
                      Register
                    </button>
                  </form>
                  <hr className="my-4" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
