import React from "react";
import { Navviparchum } from "./Navviparchum";
import { NavbarVipar } from "../NavbarVipar/NavbarVipar";

export const Cabs = () => {
  return (
    <>
      <NavbarVipar />
      <Navviparchum />
      <div className="container">
        <div className="pt-5">
          <label htmlhtmlFor="inputsubject">Dog Breed *</label>
          <select name="Dog Breed" className="form-select mt-1">
            <option value=""></option>
            {/* <option>Product Review</option>
                  <option>Product Enquiry</option> */}
            <option>A</option>
            <option>B</option>
            <option>C</option>
            {/* <option>Purchase Grievances</option> */}
          </select>
        </div>

        <div className="pt-4">
          <label htmlhtmlFor="inputsubject">Number of Dogs in one trip *</label>
          <select name="Dog Breed" className="form-select mt-1">
            <option value=""></option>
            {/* <option>Product Review</option>
                  <option>Product Enquiry</option> */}
            <option>Trip Purpose</option>
            <option>Casual</option>
            <option>Medical</option>
            <option>Medical Emergency</option>

            {/* <option>Purchase Grievances</option> */}
          </select>
        </div>

        <div className="pt-4">
          <label htmlhtmlFor="inputsubject">Accompanying Persons *</label>
          <select name="Dog Breed" className="form-select mt-1">
            <option value=""></option>
            {/* <option>Product Review</option>
                  <option>Product Enquiry</option> */}
            <option>Destination</option>
            <option>Local</option>
            <option>Regional</option>
            <option>National</option>

            {/* <option>Purchase Grievances</option> */}
          </select>
        </div>
      </div>
    </>
  );
};
