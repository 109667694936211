import React, { useState, useEffect } from 'react';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { Dashbord } from '../Dashbord';
import { Generatecoupen } from '../Generatecoupen';
import './Adminnavbar.css';
import Topnavbar, { Sidenavbar } from './Sideandtopnavbar';
import Adminproducts from './Adminproducts';
import { Coupenviewadmin } from '../Coupenviewadmin';
import { Neworderadmin } from '../Neworderadmin';
import { Confirmorderadmin } from '../Confirmorderadmin';
import { Reviewadmin } from '../Reviewadmin';
import { Adminconnect } from '../Adminconnect';
import { Adminart } from '../Adminart';
import { Oldorderadmin } from '../Oldorderadmin';
import { Cancelorderadmin } from '../Cancelorderadmin';
import axios from 'axios';
import { Admingrivence } from '../Admingrivence';
import { Returnrequestadmin } from '../Returnrequestadmin';
import { Refundadmin } from '../Refundadmin';
import { Refundviewadmin } from '../Refundviewadmin';
import { Opportunities } from '../Opportunities';
import { Jobopportunities, Jobvacancies } from '../Jobopportunities';
import { Opperview } from '../Opperview';
import { Vacanciesview } from '../Vacanciesview';
import { Country } from '../Chumadmin/Country';
import { Countryview } from '../Chumadmin/Countryview';
import { Subadminresgistration } from '../Chumadmin/Subadminregistration';
import { Staffregistration } from '../Chumadmin/Staffregistration';
import { Subadminlist } from '../Chumadmin/Subadminlist';
import { Stafflist } from '../Chumadmin/Stafflist';
import { Employedetails, Employeregistration } from '../../employedetails/Employeregistration';
// import { Cabdriverregister } from '../Chumadmin/cabdriverregister';

export const Adminnavbar = () => {
  const [Authenticated, setAuthenticated] = useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    axios
      .post('/api/isAdmin')
      .then((res) => {
        if (res.status === 200) {
          setAuthenticated(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setTimeout(() => {
          navigate('/');
        }, 2000);
      });
    return () => {};
  }, []);

  const [open, setopen] = useState(window.innerWidth >= 991 ? false : true);
  if (Authenticated) {
    return (
      <div className="container-fluid position-relative d-flex p-0 ">
        <div
          className={`${open ? 'open' : ''} sidebar pe-4 pb-3`}
          style={{ backgroundColor: 'white' }}
        >
          <Sidenavbar />
        </div>
        <div
          className={`${open ? 'open' : ''} content`}
          style={{ backgroundColor: 'rgb(1, 32, 96)' }}
        >
          <Topnavbar setopen={setopen} />
          <div style={{ paddingTop: '70px' }} className="container-fluid px-4 ">
            <Routes>
              <Route element={<Dashbord />} path="/" />
              <Route element={<Generatecoupen />} path="/coupen" />
              <Route element={<Adminproducts />} path="/adminpannel" />
              <Route element={<Coupenviewadmin />} path="/admincoupenview" />
              <Route element={<Neworderadmin />} path="/adminneworder" />
              <Route element={<Oldorderadmin />} path="/adminoldorder" />
              <Route element={<Cancelorderadmin />} path="/admincancelorder" />
              <Route
                element={<Confirmorderadmin />}
                path="/adminconfirmorder"
              />
              <Route
                element={<Returnrequestadmin />}
                path="/adminreturnorder"
              />
              <Route element={<Refundadmin />} path="adminrefundrequest" />
              <Route element={<Refundviewadmin />} path="adminrefundview" />

              <Route element={<Reviewadmin />} path="/adminreview" />
              <Route element={<Adminconnect />} path="/adminconnect" />
              <Route element={<Adminart />} path="/adminart" />
              <Route element={<Admingrivence />} path="/grievances" />
              <Route element={<Jobopportunities />} path="/job_opportunities" />
              <Route element={<Vacanciesview />} path="/applicantsview" />
              <Route element={<Country />} path="/country" />
              <Route element={<Countryview />} path="/countryview" />
              <Route
                element={<Subadminresgistration />}
                path="/subadminregistration"
              />
              <Route element={<Subadminlist />} path="/subadminlist" />
              <Route
                element={<Staffregistration />}
                path="/staffregistration"
              />
              <Route element={<Stafflist />} path="Stafflist" />
              <Route element={<Employeregistration />} path="employee_registration" />
              
            </Routes>
          </div>
        </div>
      </div>
    );
  } else {
    return <Unauthorized />;
  }
};

export const Unauthorized = () => {
  return (
    <div className="  bg-dark h-100 d-flex justify-content-center align-items-center">
      <span style={{ fontSize: '2vh' }} className="text-light">
        403 ACCESS FORBIDDEN !
      </span>
    </div>
  );
};
