import React, { useState } from 'react';
import { NavbarVipar } from '../NavbarVipar/NavbarVipar';

import { Link, useLocation } from 'react-router-dom';
import { FaShoppingBag } from 'react-icons/fa';
import { Footer } from '../Footer/Footer';

import { Navviparchum } from './Navviparchum';

export const Viparchum = () => {
  return (
    <>
      <NavbarVipar />
      <Navviparchum />
      <div id="fooo">
        {/* <section className="bgvipar d-none d-md-block">
          <div className="container w-100">
            <div className="d-flex justify-content-center align-items-center py-2 py-md-3 px-2">
              <p
                style={{ fontSize: '15px', fontFamily: 'calibri' }}
                className="m-0 mt-3 text-light invisible "
              >
                We are an online retail store for unique, innovative accessories
                and articles, and exquisite art including
                <span
                  className="vipar"
                  style={{ fontSize: '22px', color: '#99d050' }}
                >
                  &nbsp;Vipar
                </span>
                ‘s own creations.
              </p>
              <p
                className="text-light  invisible text-center mt-2  "
                id="blink"
                style={{ fontFamily: 'calibri' }}
              >
                LAUNCHING SOON
              </p>
              <br />
              <br />
              <div className="mt-5">hlo</div>
              <p>hlo</p>
              <p>jjdncjsdnc</p>
              <p
                className="text-light d-flex justify-content-center align-items-center mt-2  "
                id="blink"
                style={{
                  fontFamily: 'calibri',
                  // zIndex: 99999,
                  position: 'absolute',
                }}
              >
                LAUNCHING SOON
              </p>
              <p
                className="text-light invisible"
                style={{ fontFamily: 'calibri' }}
              >
                {' '}
                Type of Entity : E-commerce{' '}
              </p>
            </div>
          </div>

          <div style={{ backgroundColor: 'rgb(233, 238, 245)' }}>
            <div className="container  d-none d-md-block invisible">
              <span
                className="vipar"
                style={{ fontSize: '22px', color: '#99d050' }}
              >
                &nbsp;Vipar
              </span>
              &nbsp;CARE is funded only by a substantial percentage of Proceeds
              from the Commercial Verticals of
              <span
                className="vipar"
                style={{ fontSize: '22px', color: '#99d050' }}
              >
                &nbsp;Vipar
              </span>
              <br />
              <br />
              Each and every Customer / Subscriber of our Services and Products
              is Indirectly contributing towards the welfare of those in need of
            </div>
          </div>
        </section> */}
        <section className="bgvipar d-none d-md-block">
          <div className="container w-100">
            <div className="row  d-flex justify-content-center align-items-center py-2 py-md-3 px-2">
              <p
                style={{
                  fontSize: '15px',
                  fontFamily: 'maiandra',
                  textAlign: 'left',
                }}
                className="m-0 my-3 text-light  "
              >
                <div className="pt-3 fdd">
                  The pet care services wing of{' '}
                  <span
                    className="vipar "
                    style={{
                      fontSize: '22px',
                      color: '#99d050',
                    }}
                  >
                    Vipar&nbsp;
                  </span>{' '}
                  <sup>®</sup>.
                </div>
                <span className="fdd" style={{ fontSize: '30px' }}>
                  C
                </span>
                ozy{' '}
                <span className="fdd" style={{ fontSize: '30px' }}>
                  H
                </span>
                ospitality for{' '}
                <span className="fdd" style={{ fontSize: '30px' }}>
                  U
                </span>
                r pet{' '}
                <span className="fdd" style={{ fontSize: '30px' }}>
                  M
                </span>
                ate
              </p>
              <br />
            </div>
          </div>
        </section>
        <section className="bgvipar d-block d-md-none">
          <div className="container w-100">
            <div className=" py-2 py-md-3 px-2">
              <p
                className="text-light my-5"
                style={{
                  fontFamily: 'maiandra',

                  textAlign: 'left',
                }}
              >
                <div className="pt-1 fdd">
                  The pet care services wing of{' '}
                  <span
                    className="vipar "
                    style={{
                      fontSize: '22px',
                      color: '#99d050',
                    }}
                  >
                    Vipar&nbsp;
                  </span>{' '}
                  <sup>®</sup>.
                </div>
                <span className="fdd" style={{ fontSize: '30px' }}>
                  C
                </span>
                ozy{' '}
                <span className="fdd" style={{ fontSize: '30px' }}>
                  H
                </span>
                ospitality for{' '}
                <span className="fdd" style={{ fontSize: '30px' }}>
                  U
                </span>
                r pet{' '}
                <span className="fdd" style={{ fontSize: '30px' }}>
                  M
                </span>
                ate
              </p>
            </div>
          </div>
        </section>
        <div>
          <div className="container  d-none d-md-block invisible">
            <span
              className="vipar"
              style={{ fontSize: '22px', color: '#99d050' }}
            >
              &nbsp;Vipar
            </span>
            &nbsp;CARE is funded only by a substantial percentage of Proceeds
            from the Commercial Verticals of
            <span
              className="vipar"
              style={{ fontSize: '22px', color: '#99d050' }}
            >
              &nbsp;Vipar
            </span>
            <br />
            <br />
            Each and every Customer / Subscriber of our Services and Products is
            Indirectly contributing towards the welfare of those in need of
            support.
            <br />
            <br />
            We are Only an Intermediary.
          </div>
          <div className="container  d-block d-md-none invisible">
            <span
              className="vipar"
              style={{ fontSize: '22px', color: '#99d050' }}
            >
              &nbsp;Vipar
            </span>
            &nbsp;CARE is funded only by a substantial percentage of Proceeds
            from the Commercial Verticals of
            <span
              className="vipar"
              style={{ fontSize: '22px', color: '#99d050' }}
            >
              &nbsp;Vipar
            </span>
            <br />
            <br />
            Each and every Customer / Subscriber of our Services and Products is
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </>
  );
};
