import React, { useRef, useState } from 'react';
import JoditEditor from 'jodit-react';

export const TestEditor = ({ onChange, value }) => {
  const editor = useRef(null);

  return (
    <div
    //   style={{ height: '100vh' }}
    //   className="d-flex justify-content-center align-items-center"
    >
      <div className="row">
        <div className="col-12">
          <JoditEditor
            ref={editor}
            value={value}
            onChange={(e) => onChange(e)}
          />
        </div>
        {/* <div className="col-12">{content}</div> */}
      </div>
    </div>
  );
};
