import React, { useState, useEffect, useReducer, createContext } from 'react';
import axios from 'axios';
import './Vipar.css';
import { ImFacebook } from 'react-icons/im';
import { BsTwitter, BsWhatsapp, BsLinkedin, BsInstagram } from 'react-icons/bs';
import { ViparBucketadding, Bucketcolour } from '../Vipardub/ViparBucketadding';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { Footer } from '../../../Footer/Footer';
import { notify } from '../../../../App';
import { AiFillStar } from 'react-icons/ai';
import { Rating } from '../../Rating';
import { useSelector } from 'react-redux';
import { ShareThis } from '../../Share/Share';
import { Viparexclusivemodal } from '../Viparexclusivemodal';

export const BucketContext = createContext();
const productId = '63abfaab51626cde3b9d7c6f';
const Vipardub = () => {
  const [urlcopy, setUrlcopy] = useState();
  const copyurl = () => {
    const url = window.location.href;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setUrlcopy(url);
        notify('URL copied to clipboard', true);
      })
      .catch((error) => {
        notify('Failed to copy URL', false);
      });
  };
  const reducer = (state, action) => {
    switch (action.type) {
      case 'Bucket':
        return { Component: <ViparBucketadding />, type: action.type };
      case 'color':
        return { Component: <Bucketcolour />, type: action.type };
      default:
        <></>;
    }
  };
  let navigate = useNavigate();
  const [share, setShare] = useState(false);

  const [state, setState] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [friendshow, setfriendShow] = useState(false);
  const friendhandleClose = () => setfriendShow(false);
  const friendhandleShow = () => setfriendShow(true);

  const [specshow, setspecShow] = useState(false);
  const spechandleClose = () => setspecShow(false);
  const spechandleShow = () => setspecShow(true);

  const [detailsshow, setdetailsShow] = useState(false);
  const detailshandleClose = () => setdetailsShow(false);
  const detailshandleShow = () => setdetailsShow(true);

  const [careshow, setcareShow] = useState(false);
  const carehandleClose = () => setcareShow(false);
  const carehandleShow = () => setcareShow(true);

  const [returnshow, setreturnShow] = useState(false);
  const returnhandleClose = () => setreturnShow(false);
  const returnhandleShow = () => setreturnShow(true);

  const [cfshow, setCfshow] = useState(false);
  const cfhandleClose = () => setCfshow(false);
  const cfhandleShow = () => setCfshow(true);

  let [searchParams, setSearchParams] = useSearchParams();
  var mod = searchParams.get('modal');

  useEffect(() => {
    if (mod) {
      document.getElementById('opendub').click();
    }
    return () => {
      handleClose();
    };
  }, []);
  useEffect(() => {
    axios
      .get('/public/getproducts?id=' + productId)
      .then((res) => {
        console.log(res.data);
        console.log('ho');

        console.log(res.data);
        console.log('ho');
        setState(res.data[0]);
        setloading(false);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });

    return () => {};
  }, []);

  const modal = useSelector((state) => {
    return state.modal.value;
  });

  const [Buckcolor, dispatch] = useReducer(reducer, {
    Component: <ViparBucketadding />,
    type: 'Bucket',
  });

  const [hook, setHook] = useState(false);
  const [loading, setloading] = useState(true);

  const [review, setReview] = useState([]);
  const submit = () => {
    axios
      .get(`/public/getreview?productid=${productId}`)
      .then((res) => {
        console.log(res.data);
        setReview(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    submit();
  }, []);

  let isLogin = useSelector((state) => {
    return state.user.value.Status;
  });

  return (
    <>
      <hr
        className="mt-0"
        style={{
          color: 'rgb(1, 32, 96)',
          opacity: '1',
          borderTop: '3px solid',
        }}
      />
      <div className="container-fluid p-0">
        <div className="container pb-5">
          <div className="row">
            <div className="col-lg-5 mt-5 d-flex justify-content-center">
              <div
                id="carouselExampleControls"
                className="carousel slide cardimgwidth"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  {/* <ul
                    id="controller"
                    style={{ position: 'relative' }}
                    className="carousel-indicators "
                  >
                    <li
                      data-bs-target="#template-mo-zay-hero-carousel-2"
                      style={{ width: '5px' }}
                      data-bs-slide-to="0"
                      className="active"
                    ></li>
                    <li
                      data-bs-target="#template-mo-zay-hero-carousel-2"
                      style={{ width: '5px' }}
                      data-bs-slide-to="1"
                    ></li>
                    <li
                      data-bs-target="#template-mo-zay-hero-carousel-2"
                      style={{ width: '5px' }}
                      data-bs-slide-to="2"
                    ></li>
                    <li
                      data-bs-target="#template-mo-zay-hero-carousel-2"
                      style={{ width: '5px' }}
                      data-bs-slide-to="3"
                    ></li>
                    <li
                      data-bs-target="#template-mo-zay-hero-carousel-2"
                      style={{ width: '5px' }}
                      data-bs-slide-to="4"
                    ></li>
                    <li
                      data-bs-target="#template-mo-zay-hero-carousel-2"
                      style={{ width: '5px' }}
                      data-bs-slide-to="5"
                    ></li>
                  </ul> */}
                  <div className="carousel-item active">
                    <div className="card cardborder" style={{ border: 'none' }}>
                      <img
                        id="f1"
                        src="/img/dubcovernew.jpeg"
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body dubcbodyheight cardmarginbotom">
                        <p
                          className="cardtext"
                          style={{
                            textAlign: 'left',
                          }}
                        >
                          A must-have article in every household,
                          <br /> the{' '}
                          <span
                            style={{ fontFamily: 'Monotype', color: 'green' }}
                          >
                            Vipar
                          </span>{' '}
                          &nbsp;D U B is meticulously designed as an aesthetic
                          accessory !<br />
                          <br />
                          The{' '}
                          <span
                            style={{ fontFamily: 'Monotype', color: 'green' }}
                          >
                            Vipar
                          </span>{' '}
                          &nbsp;D U B can be placed in covered areas such as
                          apartments and the entrance foyer of independent
                          villas.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div
                      className="card  cardborder"
                      style={{ border: 'none' }}
                    >
                      <img
                        src="/img/dubtop.jpeg"
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body dubcbodyheight cardmarginbotom">
                        <p className="cardtext">
                          The rear compartment can fit in upto 4 Newspapers when
                          folded in A4 size.
                          <br />
                          The front compartment can accommodate 6 half litre
                          packets of milk/curd or 2 one litre bottles of 89 mm
                          diameter.
                          <br />
                          Design ensures that the newspaper does not get soaked
                          even if there is spillage of milk.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="card cardborder" style={{ border: 'none' }}>
                      <img
                        src="/img/dubhook.jpeg"
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body dubcbodyheight cardmarginbotom">
                        <p className="cardtext">
                          Has a hook to hang food packets / an umbrella.
                          <br />
                          <br />
                          Has an Anti-Theft mechanism : Front milk compartment
                          can be removed only when unlocked. <br />
                          Please note that the Anti-theft mechanism only
                          protects the product and not the contents placed
                          inside the product.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  className="carousel-control-prev text-decoration-none w-auto ps-3"
                  href="#carouselExampleControls"
                  role="button"
                  data-bs-slide="prev"
                >
                  <i className="fas fassp fa-chevron-left"></i>
                </a>
                <a
                  className="carousel-control-next text-decoration-none w-auto pe-3"
                  href="#carouselExampleControls"
                  role="button"
                  data-bs-slide="next"
                >
                  <i className="fas fassp fa-chevron-right"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-7 mt-5 ">
              <div className="card cardborder ftnn" style={{ border: 'none' }}>
                <div className="card-body  pt-0 ">
                  <span className="" style={{ fontSize: '25px' }}>
                    {loading ? (
                      loading && (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )
                    ) : (
                      <>
                        <span
                          style={{ fontFamily: 'Monotype', color: 'green' }}
                        >
                          Vipar
                        </span>{' '}
                        &nbsp;{state.pname}
                      </>
                    )}
                  </span>
                  <h3>
                    <span style={{ fontSize: '23px' }}>
                      a simple dual utility basket
                    </span>
                  </h3>
                  <h4 className="pt-2">
                    <Viparexclusivemodal />
                  </h4>

                  <p className="pt-2 mb-0" style={{ fontSize: '19px' }}>
                    ₹{' '}
                    {loading ? (
                      <span
                        className="spinner-border spinner-border-sm mb-0"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      state.price + ' /-'
                    )}
                    <br />
                    <span>
                      <Rating value={{ review, setReview }}></Rating>
                    </span>
                  </p>
                  <p className="pt-3 pb-0 mb-0 " style={{ fontSize: '19px' }}>
                    Good riddance to dirt-smudged newspapers and soily plastic
                    bags.
                  </p>
                  <p className="pt-3 pb-0 mb-0">
                    Pick your morning newspaper hassle-free… No more bending
                    down.&nbsp;And No more early morning eyesore.
                  </p>
                  <p className="pt-3 pb-0 mb-0">
                    Not at home when your food is delivered?
                    <br />
                    Your food packet need not be kept on the floor when you have
                    the{' '}
                    <span style={{ fontFamily: 'Monotype', color: 'green' }}>
                      Vipar
                    </span>{' '}
                    &nbsp;D U B.
                    <br />
                  </p>

                  <br />
                  <span
                    className=""
                    style={{
                      fontWeight: '100',
                      fontSize: '1rem',
                    }}
                  >
                    Product dispatch time : within 2-3 business days
                  </span>

                  <ul></ul>
                  <form action="" method="GET" />
                  <input
                    type="hidden"
                    name="product-title"
                    value="Activewear"
                  />

                  <div className="row pb-3">
                    <div className="col d-grid">
                      <button
                        type="button"
                        id="opendub"
                        className="btn btn-lg text-white"
                        name="submit"
                        onClick={() =>
                          isLogin
                            ? handleShow()
                            : document
                                .getElementById('RegisterModalbtndemo')
                                ?.click()
                        }
                        style={{ backgroundColor: '#012060' }}
                      >
                        BUY IT NOW
                      </button>
                    </div>
                    {/* <!-- <div className="col d-grid">
                                        <button type="button" className="btn btn-outline-dark btn-lg" name="submit"
                                             data-bs-toggle="modal" data-bs-target="#dubc">SAVE TO BUY LATER</button>
                                    </div> --> */}

                    <div className="col d-grid ">
                      <button
                        type="button"
                        className="btn btn-outline-dark btn-lg"
                        name="submit"
                        onClick={(e) => {
                          submit(cfhandleShow());
                        }}
                        style={{ fontSize: '17px' }}
                      >
                        <span
                        // style={{ fontSize: "15px", textTransform: "none" }}
                        >
                          Reviews
                        </span>
                      </button>
                    </div>
                  </div>

                  <div className="row pb-3">
                    <div className="col d-grid ">
                      <button
                        type="button"
                        className="btn btn-outline-dark btn-lg"
                        name="submit"
                        onClick={returnhandleShow}
                      >
                        <span
                          style={{ fontSize: '17px' }}
                          // style={{ fontSize: "15px", textTransform: "none" }}
                        >
                          return&nbsp;/&nbsp;cancel
                        </span>
                      </button>
                    </div>
                    <div className="col d-grid ">
                      <button
                        type="button"
                        className="btn btn-outline-dark btn-lg"
                        name="submit"
                        onClick={() => setShare(true)}
                        value="addtocard"
                        style={{ fontSize: '17px' }}
                      >
                        RECOMMEND
                      </button>
                    </div>
                  </div>

                  <Modal
                    show={show}
                    onHide={handleClose}
                    scrollable={true}
                    size="lg"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title style={{ fontSize: '19px' }} id="hujofou">
                        {Buckcolor.type === 'Bucket' ? (
                          <>
                            Select your choice of hook location depending on
                            your product fixing location
                          </>
                        ) : (
                          <>Pick your favourites from NINE amazing colours !</>
                        )}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <BucketContext.Provider
                        value={{ dispatch, state, setHook, hook }}
                      >
                        {Buckcolor.Component}
                      </BucketContext.Provider>
                    </Modal.Body>

                    <Modal.Footer>
                      <button
                        data-bs-dismiss="modal"
                        onClick={() => {
                          navigate('/vipar-chic/shopping_bag');
                        }}
                        type="button"
                        className="btn btn-outline-dark"
                      >
                        GO TO SHOPPING BAG
                      </button>
                    </Modal.Footer>
                  </Modal>

                  <Modal
                    show={friendshow}
                    onHide={friendhandleClose}
                    scrollable={true}
                    size="lg"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>
                        How sweet. 🤗
                        <br />
                        Thank you so much !!
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row gy-3">
                        <div className="col-auto">
                          <input
                            type="button"
                            className="btn btn-outline-dark"
                            value="Copy Url"
                            onClick={copyurl}
                          />
                        </div>
                        <div className="col">
                          <textarea
                            id="url"
                            className="form-control "
                            rows="1"
                            cols="30"
                            value={urlcopy}
                          ></textarea>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center mt-3">
                        <div className="col-auto  p-0 px-2">
                          <a
                            href="https://www.facebook.com/"
                            className=" fa facebook d-flex align-items-center justify-content-center"
                          >
                            <ImFacebook size={30} />
                          </a>
                        </div>

                        <div className="col-auto p-0 px-2">
                          <a
                            href="https://web.whatsapp.com/"
                            className=" fa whatsapp  d-flex align-items-center justify-content-center"
                          >
                            <BsWhatsapp size={30} />
                          </a>
                        </div>

                        <div className="col-auto  p-0 px-2">
                          <a
                            href="https://www.instagram.com/"
                            className=" fa instagram  d-flex align-items-center justify-content-center"
                          >
                            <BsInstagram size={35} />
                          </a>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>

                  <Modal
                    show={cfshow}
                    onHide={cfhandleClose}
                    scrollable={true}
                    size="lg"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Customer Feedback</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="container">
                        <div className="row mb-5">
                          <div className="col-sm-12">
                            <div className="review-block">
                              {review?.length
                                ? review.map((elem, ind) => {
                                    return (
                                      <>
                                        <div className="row">
                                          <div className="col-12">
                                            <div className="review-block-name">
                                              <div className="d-flex align-items-center">
                                                <img
                                                  src="/img/avatar.webp"
                                                  className="img-rounded me-2"
                                                  alt=""
                                                  height="50px"
                                                  width="50px"
                                                  style={{
                                                    borderRadius: '30px',
                                                    display: 'inline-block',
                                                  }}
                                                />
                                                <div
                                                  style={{
                                                    display: 'inline-block',
                                                  }}
                                                >
                                                  <p className="m-0">
                                                    {elem?.orderdetails[0]
                                                      ?.billingAddress
                                                      ?.firstname +
                                                      ' ' +
                                                      elem?.orderdetails[0]
                                                        ?.billingAddress
                                                        ?.lastname}
                                                  </p>
                                                  <div className="review-block-rate">
                                                    {elem?.rating &&
                                                      new Array(elem.rating)
                                                        .fill(0)
                                                        .map((elem) => {
                                                          return (
                                                            <AiFillStar color="yellow" />
                                                          );
                                                        })}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="container">
                                          <div className="row">
                                            <div className="col-12">
                                              <div className="review-block-date px-1"></div>
                                            </div>
                                            <div className="col-sm-12 col-md-12 p-0">
                                              <div className="review-block-description">
                                                {review?.map((elm, ind) => {
                                                  return (
                                                    <>
                                                      <p>{elm.comment}</p>
                                                    </>
                                                  );
                                                })}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })
                                : ''}

                              {review?.length == 0 ? 'No Reviews to show' : ''}
                              <hr />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>

                  <Modal
                    show={careshow}
                    onHide={carehandleClose}
                    scrollable={true}
                    size="lg"
                    centered
                  >
                    <Modal.Header closeButton>
                      <h5 className="modal-title" id="staticBackdropLabel">
                        Care instructions
                      </h5>
                    </Modal.Header>
                    <Modal.Body>
                      <h5 className="cardtext">
                        Acrylic is a delicate material. You are to "Handle with
                        Care" the acrylic plates while removing, wiping, washing
                        and replacing.
                      </h5>
                      <h5 className="cardtext">
                        Milk bottles and packets must be placed inside the front
                        compartment with care. Rough usage will damage the
                        acrylic plate.
                      </h5>
                      <h5 className="cardtext">
                        You may wash the acrylic plates daily with water.
                      </h5>
                      <h5 className="cardtext">
                        In case of spillage, wipe clean the wood/aluminium
                        components and lock with a dry cloth only.
                      </h5>
                      <h5 className="cardtext">
                        MAINTAIN the wood/aluminium components and lock DRY
                        ALWAYS. Wood component is only water-resistant and not
                        water-proof.
                      </h5>
                      <h5 className="cardtext">
                        Sharp, pointed objects or blades can damage the surface
                        of the product if scraped on it.
                      </h5>
                      <h5 className="cardtext">
                        The D U B&nbsp;&nbsp;classic must not be placed in areas
                        which are directly exposed to rain.
                      </h5>
                    </Modal.Body>
                  </Modal>

                  <Modal
                    show={specshow}
                    onHide={spechandleClose}
                    scrollable={true}
                    size="lg"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Specification</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <h5 className="cardtext">
                        Material type : WPC, Acrylic, Anodised Aluminium
                      </h5>
                      <h5 className="cardtext">
                      Size : 9 inches (front width) x 9 inches (height) x 4.75 inches
                  (side width / gazette)
                      </h5>
                      <h5 className="cardtext"> Weight : 1210 grams</h5>
                    </Modal.Body>
                  </Modal>

                  <Modal
                    show={detailsshow}
                    onHide={detailshandleClose}
                    scrollable={true}
                    size="lg"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <h5 className="cardtext">
                        Vipar D U B&nbsp;&nbsp; is a Vipar product
                      </h5>
                      <h5 className="cardtext">
                        Conceptualized, Designed and Marketed by :{' '}
                        <span className="dddddd monot">Vipar&nbsp;</span>
                        <sup className="reggg">&reg;</sup>
                      </h5>
                      <h5 className="cardtext">Country of Origin : India</h5>
                      <h5 className="cardtext">
                        HSN code (of the product) : 3924
                      </h5>
                    </Modal.Body>
                  </Modal>
                  <Modal
                    show={returnshow}
                    onHide={returnhandleClose}
                    scrollable={true}
                    size="lg"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Return / Cancellation terms</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p className="assss">Q. Can we return our order ?</p>
                      <p>
                        A. All our products are{' '}
                        <b style={{ fontWeight: '800' }}>non</b>-returnable.
                        However, products with manufacturing defects can be
                        exchanged on the basis of strict terms and conditions.
                        Please read our{' '}
                        <a
                          className="as"
                          href="#"
                          onClick={(e) => modal.epmodal()}
                          data-bs-dismiss="modal"
                        >
                          exchange policies
                        </a>{' '}
                        to know more about this.
                        <br />
                        <br />
                        To Return the Order, follow these steps :<br />
                        1. Log in to your Vipar account.
                        <br />
                        2. Go to 'New Order' on your Shopping Bag icon.
                        <br />
                        3. Click on the 'Return' button.
                      </p>
                      <p className="assss">Q. Can we cancel our order ?</p>
                      <span>
                        Yes. ONLY if you cancel the order before we dispatch it
                        from our warehouse.
                        <br />
                        <br />
                        To Cancel the Order, follow these steps :<br />
                        1. Log in to your Vipar account.
                        <br />
                        2. Go to 'New Order' on your Shopping Bag icon.
                        <br />
                        3. Click on the 'Cancel' button.
                        <br />
                        <br />
                        Once the order is booked for shipping with our logistics
                        partner, and then you cancel the order, you will have to
                        bear both the to-and-fro shipping costs. And, the parcel
                        box must be returned STRICTLY without the Vipar seal
                        tape tampered with or damaged or broken. Refund (after
                        deduction of to-and-fro shipping costs) will be
                        initiated only after we receive the parcel box in the
                        manner we have specified above and we are assured that
                        the product is unused.
                      </span>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </div>
          </div>

          <section>
            <div className="container-fluid">
              <div className="col-md-2 col-sm-dnone "></div>
              <div className="col-md-8 col-sm-dnone ">
                <p className="pppp ">
                  <b
                    className="discrpfvniv"
                    style={{
                      fontSize: '20px',
                      fontWeight: '700',
                    }}
                  >
                    Product specifications and details
                  </b>{' '}
                  : <br /> Material type : WPC, Acrylic, Anodised
                  Aluminium <br />
                  Size : 9 inches (front width) x 9 inches (height) x 4.75 inches
                  (side width / gazette)
                  <br />
                  Weight : 1210 grams
                  <br />
                  <span style={{ fontFamily: 'Monotype', color: 'green' }}>
                    Vipar
                  </span>{' '}
                  &nbsp;D U B&nbsp;&nbsp;is a{' '}
                  <span style={{ fontFamily: 'Monotype', color: 'green' }}>
                    Vipar
                  </span>{' '}
                  &nbsp; product
                  <br />
                  Conceptualized, Designed and Marketed by :{' '}
                  <span className="dddddd monot">Vipar&nbsp;</span>
                  <sup className="reggg">&reg;</sup>
                  <br />
                  Country of Origin : India
                  <br />
                  HSN code (of the product) : 3924
                  <br />
                  <b
                    className="discrpfvniv"
                    style={{
                      fontSize: '20px',
                      fontWeight: '700',
                    }}
                  >
                    Care instructions{' '}
                  </b>{' '}
                  : <br />
                  To maintain the D U B clean, you can wipe clean the inner and
                  outer portions, except the printed stickers, with a<br></br>{' '}
                  wet cloth. Wipe clean the printed stickers with a dry cloth
                  only
                  <br></br>
                  Sharp, pointed objects or blades can damage the surface of the
                  product if scraped on it.<br></br>
                  The D U B must not be placed in areas which are directly
                  exposed to rain.
                  <br />
                </p>
              </div>
              <div className="col-md-2 col-sm-dnone"></div>
            </div>
          </section>
        </div>
      </div>
      <ShareThis
        shareshow={share}
        setshareshow={setShare}
        url={window.location.href}
      />
    </>
  );
};

export default Vipardub;
