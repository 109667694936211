import React from 'react';
import { FaShoppingBag } from 'react-icons/fa';
import { RiFileTextFill } from 'react-icons/ri';
import { Link, useLocation } from 'react-router-dom';
import { Logocenter } from '../Logocenter';

export const Navviparchum = () => {
  var location = useLocation();
  return (
    <>
      <nav
        className="navbar navbar-expand navbar-light shadow"
        style={{ fontFamily: 'FootlightMTLight', display: 'block' }}
      >
        <div
          className="  px-4  w-100"
          style={{
            display: 'block',
            height: '122px',
            backgroundColor: 'rgb(1,32,96,1)',
          }}
        >
          <div className="">
            <div className="d-flex justify-content-between">
              <div style={{ marginTop: '20px' }}>
                <Link
                  class="navbar-brand text-success logo align-self-center"
                  to="/vipar-chum"
                >
                  {/* <img
                    className=""
                    src="/img/viparchum.png"
                    alt=""
                    height="80px"
                    width="80px"
                  /> */}
                  <div style={{ width: '80px', height: '80px' }}>
                    <Logocenter link={'/img/viparchum.png'} />
                  </div>
                </Link>
              </div>
              <div className="xn">
                {/* <span className="invisible">
                  Innovative&nbsp;Utilitarian&nbsp;Products
                </span> */}
                <div className="pt-2">
                  <div className="mt-2  ">
                    <span
                      className="vipar transform "
                      style={{
                        fontSize: '22px',
                        color: '#99d050',
                        paddingLeft: '7px',
                      }}
                    >
                      &nbsp;&nbsp;Vipar
                    </span>
                    <span
                      className="ps-1 text-light transform"
                      style={{
                        fontSize: '20px',
                        fontFamily: 'maiandra',
                      }}
                    >
                      &nbsp;CHUM
                    </span>
                  </div>
                  <div className=" " style={{ height: '13px' }}></div>
                  <div className="mt-3">
                    <span
                      className="  text-light   btnsss maiandra "
                      style={{
                        fontSize: '16px',
                        textTransform: 'none',
                      }}
                    >
                      &nbsp;&nbsp;Dog's Best Friend.
                    </span>
                  </div>
                </div>
              </div>
              <div className="invisible">
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container-fluid mt-2 w-100"
          style={{ display: 'block' }}
        >
          <div className=" d-flex justify-content-center d-md-block  ">
            <div className="r" id="templatemo_main_nav">
              <div id="navnew" className="flex-fill">
                <div
                  className="d-block d-md-none"
                  style={{ width: '100vw', overflow: 'hidden' }}
                >
                  {/* <ul
                    className=" navbar-nav d-flex justify-content-center mx-auto ms-0 ssviparc"
                    style={{ border: 'none', fontSize: '15px' }}
                  >
                    <li className="nav-item ">
                      <Link className={` nav-linkKK sscomfortfont cardtextt `}>
                        CART
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className={` nav-linkKK sscomfortfont cardtextt  `}>
                        CARE-A-VAN
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className={` nav-linkKK sscomfortfont cardtextt  `}>
                        CRUISE
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className={` nav-linkKK sscomfortfont cardtextt  `}>
                        CRITTER&nbsp;CAB
                      </Link>
                    </li>
                  </ul> */}

                  {/* <div className=" navbar-nav row " style={{ border: 'none' }}>
                    <div className="col-4 nav-item ">
                      <Link to="" className={`nav-link cardtextt aa `}>
                        <span className="invisible">.</span>CART
                      </Link>
                    </div>
                    <div className="col-auto nav-item ">
                      <Link to="" className={`nav-link cardtextt aa `}>
                        CARE-A-VAN
                      </Link>
                    </div>
                  </div>
                  <div className=" navbar-nav row" style={{ border: 'none' }}>
                    <div className="col-4 nav-item  ">
                      <Link to="" className={`nav-link cardtextt aa pd`}>
                        CRUISE
                      </Link>
                    </div>
                    <div className="col-auto nav-item  ">
                      <Link to="" className={`nav-link cardtextt aa pd`}>
                        CRITTER CAB
                      </Link>
                    </div>
                  </div> */}

                  {/* {hlo} */}

                  {/* {demo} */}
                  <ul
                    className=" navbar-nav d-flex  justify-content-center mx-auto ms-0"
                    style={{ border: 'none' }}
                  >
                    <li className="nav-item ">
                      <Link
                        //  to="/vipar-chum/cabs"

                        className={`nav-link cardtextt aa  ${
                          location.pathname === '/vipar-casa/as_architects'
                            ? ' active-link-vipar'
                            : ''
                        }`}
                      >
                        CABS<span className="invisible">...</span>
                      </Link>
                    </li>
                    <li className="nav-item ">
                      <Link to="" className="nav-link cardtextt aa ">
                        CHECK-IN<span className="invisible">...</span>
                      </Link>
                    </li>
                    <li className="nav-item ">
                      <Link to="" className="nav-link cardtextt aa">
                        CHECK-UP<span className="invisible">..</span>
                      </Link>
                    </li>
                    <li className="nav-item ">
                      <Link
                        to=""
                        className="nav-icon position-relative text-decoration-none nav-link     "
                      >
                        <span className="invisible">.</span>
                        <RiFileTextFill size={22} />
                      </Link>
                    </li>
                  </ul>
                  <ul
                    className=" navbar-nav d-flex justify-content-center mx-auto ms-0 ssviparc"
                    style={{ border: 'none', fontSize: '15px' }}
                  >
                    <li className="nav-item ">
                      <Link to="" className="ssviparc nav-link cardtextt">
                        SERVICE&nbsp;POLICIES
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="" className="ssviparc nav-link cardtextt">
                        &nbsp;HELP&nbsp;DESK&nbsp;
                      </Link>
                    </li>
                    <li className="nav-item ">
                      <Link to="" className="ssviparc nav-link cardtextt  aa ">
                        GET&nbsp;IN&nbsp;TOUCH
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="d-none d-md-block ">
                  <div id="nsknfkjdsnfksjn">
                    <ul
                      className="  d-flex ps-0  justify-content-center mx-auto ms-0"
                      style={{ border: 'none' }}
                    >
                      <li className="nav-item ">
                        <Link
                          // to="/vipar-chum/cabs"

                          className={`ssviparc nav-link cardtextt aa  ${
                            location.pathname === '/vipar-chum/cabs'
                              ? ' active-link-vipar'
                              : ''
                          }`}
                        >
                          CABS
                        </Link>
                      </li>
                      <li className="nav-item ">
                        <Link to="" className="ssviparc nav-link cardtextt aa">
                          CHECK-IN
                        </Link>
                      </li>
                      <li className="nav-item ">
                        <Link to="" className=" ssviparc nav-link cardtextt aa">
                          CHECK-UP
                        </Link>
                      </li>
                      <li className="nav-item ">
                        <div id="bdr">
                          <Link
                            className="nav-icon position-relative text-decoration-none nav-link   cardtextt "
                            style={{ color: 'black' }}
                          >
                            <RiFileTextFill size={22} />
                          </Link>
                        </div>
                      </li>

                      <li className="nav-item">
                        <Link to="" className="ssviparc nav-link cardtextt">
                          SERVICE&nbsp;POLICIES
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="" className="ssviparc nav-link cardtextt ">
                          HELP&nbsp;DESK
                        </Link>
                      </li>
                      <li className="nav-item ">
                        <Link to="" className="ssviparc nav-link cardtextt aa ">
                          GET&nbsp;IN&nbsp;TOUCH
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
