import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { DataGrid } from '@mui/x-data-grid';
import { notify } from '../../App';
import { TestEditor } from '../../pages/TestEditor';
import { Jobsectionselectbox } from './Jobopportunities';
export const Tableverticals = () => {
  const [opperins, setopperins] = useState({
    JobTitle: '',
    JobDescription: '',
    Qualfication: '',
    Skills: '',
    Experience: '',
    WorkJoblocation: '',
    Vacancies: '',
    JoiningDate: '',
    JobSection: '',
    JobSubSection: '',
    verticals: '',
    Status: false,
  });

  const [opperinsto, setopperinsto] = useState(false);

  const [err, seterr] = useState();

  const submit = (e) => {
    e.preventDefault();
    handleClose();
    axios
      .post('/adminlogin/customeropporupdate', { opperins })
      .then((res) => {
        notify('Updated Successfully', true);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        console.log(res.data);
      })
      .catch((err) => {
        notify('Something went wrong', false);

        console.log(err);
      });
  };
  const Deleteee = (val, ver, ind) => {
    axios
      .post('/adminlogin/customeroppordelete', { val, ver, ind })
      .then((res) => {
        console.log(res.data);
        let temp = [...state];
        console.log(temp);
        temp.splice(ind, 1);
        setState([...temp]);

        notify('Successfully Deleted', true);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        notify('Something Went Wrong', false);
      });
  };

  const update = (params, e) => {
    handleClose();

    let opperins = params;
    axios
      .post('/adminlogin/switchupdate', { opperins })
      .then((res) => {})

      .catch((err) => {
        console.log(err);
      });
  };

  const [state, setState] = useState([]);
  const [index, setIndex] = useState([]);
  useEffect(() => {
    console.log(state);
  }, [state]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [Submit2, setSubmit2] = useState(false);

  useEffect(() => {
    axios
      .post('/adminlogin/customeropporshow')
      .then((res) => {
        console.log(res.data);
        const newState = res.data.map((e, index) => {
          return {
            ...e,
            id: index + 1,
            ind: index,
          };
        });
        console.log(newState);
        setState(newState);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    console.log(opperins);
  }, [opperins]);

  const columns = [
    // { field: 'id', headerName: 'Sl No', width: 70 },
    { field: 'verticals', headerName: 'Verticals', width: 130 },
    { field: 'JobTitle', headerName: 'Job Title', width: 130 },
    { field: 'Vacancies', headerName: 'Vacancies', width: 130 },
    {
      field: 'switch',
      headerName: 'Hide/Show',
      sortable: false,
      width: 90,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <span style={{ border: 'none' }} class="form-check form-switch">
          <input
            defaultChecked={params.row.Status}
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
            value={params.row.Status}
            onChange={(e) => {
              // console.log(params.row);
              // let temp = { ...params.row };
              // temp.Status = !temp.Status;
              // console.log(temp.Status);
              params.row.Status = !params.row.Status;
              console.log(params.row);
              update(params.row);
            }}
          />
        </span>
      ),
    },

    {
      field: 'Details',
      headerName: 'Details',
      sortable: false,
      width: 90,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <button
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#detailsModal"
          onClick={(e) => {
            console.log(params.row);
            setopperins(params.row);
          }}
        >
          Details
        </button>
      ),
    },

    {
      field: 'Edit',
      headerName: 'Edit',
      sortable: false,
      width: 90,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <button
          className="btn btn-success"
          onClick={(e) => {
            console.log(params.row);
            setopperins(params.row);
            // setIndex(ind);
            handleShow();
          }}
        >
          Edit
        </button>
      ),
    },
    {
      field: 'Delete',
      headerName: 'Delete',
      sortable: false,
      width: 90,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <button
          className="btn btn-danger"
          data-bs-toggle="modal"
          data-bs-target={'#exampleModal' + params.row.ind}
          onClick={(e) => {
            console.log(params.row.ind);
          }}
        >
          Delete
        </button>
      ),
    },
  ];
  const tableStyles = {
    backgroundColor: '#f0f0f0',
  };
  return (
    <>
      <div
        style={{
          height: 400,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <DataGrid
          rows={state}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          style={tableStyles}
          className="mt-5 pt-3 sswewe"
        />
      </div>

      {/* details modal */}
      <div
        class="modal fade"
        id="detailsModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content ">
            <div class="modal-header">
              <h5 class="modal-title px-3" id="exampleModalLabel">
                Details
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="pt-3">Job Section : {opperins?.JobSection}</div>
              <div className="pt-3">
                Job Sub Section : {opperins?.JobSubSection}
              </div>
              <div className="pt-3">Vacancies : {opperins?.Vacancies}</div>

              <div className="pt-3">
                <div className="">Job Title : {opperins?.JobTitle}</div>
                <div className="pt-3">
                  Job Description :
                  <div
                    className="pt-2"
                    dangerouslySetInnerHTML={{
                      __html: opperins?.JobDescription,
                    }}
                  ></div>
                </div>
                <div className="pt-3">
                  Qualification : {opperins?.Qualfication}
                </div>
                <div className="pt-3">Skills : {opperins?.Skills}</div>
                <div className="pt-3">Experience: {opperins?.Experience}</div>
                <div className="pt-3">
                  Work / Job Location: {opperins?.WorkJoblocation}
                </div>

                <div className="pt-3">
                  Joining Date :{' '}
                  {moment(opperins?.JoiningDate).format('MMMM Do YYYY')}{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* details modal */}
      {/* edit modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <form
          onSubmit={(e) => submit(e)}
          novalidate
          className={Submit2 ? 'was-validated' : ''}
        >
          <Modal.Body>
            <form>
              {' '}
              <div>
                <label htmlhtmlFor="inputsubject">Verticles *</label>

                <select
                  required
                  class="form-select"
                  aria-label="Default select example"
                  value={opperins.verticals}
                  onChange={(e) => {
                    setopperins({ ...opperins, verticals: e.target.value });
                  }}
                >
                  <option></option>
                  <option value="CONNECT">CONNECT</option>
                  <option value="CASA">CASA</option>
                  <option value="CHUM">CHUM</option>
                  <option value="CHIC">CHIC</option>
                  {/* <option value="COMFORT">COMFORT</option>
                  <option value="CRUISE">CRUISE</option>
                  <option value="CLEAN">CLEAN</option>
                       <option value="CHEF">CHEF</option>
                <option value="CINÉ">CINÉ</option> 
                  
                  */}
                  <option value="CARE">CARE</option>
                </select>
                <div class="invalid-feedback">PLEASE SELECT Verticles</div>
              </div>
              <div className="pt-4">
                <label htmlhtmlFor="inputname">Vacancies *</label>

                <input
                  type="text"
                  className="form-control mt-1"
                  id="name"
                  name="name"
                  value={opperins?.Vacancies}
                  required
                  onChange={(e) => {
                    setopperins({ ...opperins, Vacancies: e.target.value });
                  }}
                />
                <div class="invalid-feedback">PLEASE ENTER Vacancies</div>
              </div>
              <div className="pt-4">
                <label htmlhtmlFor="inputsubject">Job Section *</label>
                <select
                  required
                  value={opperins?.JobSection}
                  class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setopperins({ ...opperins, JobSection: e.target.value });
                  }}
                >
                  <option></option>
                  {Jobsectionselectbox.map((element) => {
                    return <option>{element.category}</option>;
                  })}
                </select>
                <div class="invalid-feedback">PLEASE SELECT Job Section</div>
              </div>
              <div className="pt-4">
                <label htmlhtmlFor="inputsubject">Job Sub Section *</label>
                <select
                  required
                  value={opperins?.JobSubSection}
                  class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setopperins({ ...opperins, JobSubSection: e.target.value });
                  }}
                >
                  <option></option>
                  {Jobsectionselectbox.map((element) => {
                    if (element.category === opperins?.JobSection) {
                      return element.subcategory.map((elem) => {
                        return <option>{elem}</option>;
                      });
                    }
                  })}
                </select>
                <div class="invalid-feedback">
                  PLEASE SELECT Job Sub Section
                </div>
              </div>
              <div className="pt-4">
                <label htmlhtmlFor="inputname">Job Title *</label>

                <input
                  type="text"
                  className="form-control mt-1"
                  id="name"
                  name="name"
                  value={opperins?.JobTitle}
                  required
                  onChange={(e) => {
                    setopperins({ ...opperins, JobTitle: e.target.value });
                  }}
                />
                <div class="invalid-feedback">PLEASE ENTER Job Title</div>
              </div>{' '}
              <div className="py-4">
                <label htmlhtmlFor="inputname">Job Description *</label>
                <TestEditor
                  value={opperins?.JobDescription}
                  onChange={(e) => {
                    setopperins({ ...opperins, JobDescription: e });
                  }}
                />

                <div class="invalid-feedback">PLEASE ENTER Job Description</div>
              </div>
              <div>
                <label htmlhtmlFor="inputname">Qualification *</label>

                <input
                  type="text"
                  className="form-control mt-1"
                  id="name"
                  name="name"
                  value={opperins?.Qualfication}
                  required
                  onChange={(e) => {
                    setopperins({ ...opperins, Qualfication: e.target.value });
                  }}
                />
                <div class="invalid-feedback">PLEASE ENTER Qualification</div>
              </div>
              <div className="pt-4">
                <label htmlhtmlFor="inputname">Skills *</label>

                <textarea
                  rows={3}
                  className="form-control mt-1"
                  id="name"
                  name="name"
                  value={opperins?.Skills}
                  required
                  onChange={(e) => {
                    setopperins({ ...opperins, Skills: e.target.value });
                  }}
                />
                <div class="invalid-feedback">PLEASE ENTER Skills</div>
              </div>
              <div className="py-4">
                <label htmlhtmlFor="inputname">Experience *</label>

                <select
                  required
                  value={opperins.Experience}
                  class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setopperins({ ...opperins, Experience: e.target.value });
                  }}
                >
                  <option></option>
                  <option value="Fresher">Fresher</option>
                  <option value="0-1 year">0-1 year</option>
                  <option value="1-2 years">1-2 years</option>
                  <option value="2-3 years">2-3 years</option>
                  <option value="3-4 years">3-4 years</option>
                  <option value="4-5 years">4-5 years</option>
                  <option value="5+ years">5+ years</option>
                </select>
                <div class="invalid-feedback">PLEASE ENTER Experience</div>
              </div>
              <div className="pb-4">
                <label htmlhtmlFor="inputname">Work / Job Location *</label>

                <input
                  type="text"
                  className="form-control mt-1"
                  id="name"
                  name="name"
                  value={opperins?.WorkJoblocation}
                  required
                  onChange={(e) => {
                    setopperins({
                      ...opperins,
                      WorkJoblocation: e.target.value,
                    });
                  }}
                />
                <div class="invalid-feedback">
                  PLEASE ENTER Work / Job Location
                </div>
              </div>
              <div className="pb-4">
                <label htmlhtmlFor="inputname">Joining Date *</label>

                <input
                  type="date"
                  className="form-control mt-1"
                  id="name"
                  required
                  name="name"
                  value={
                    opperins?.JoiningDate
                      ? opperins.JoiningDate.substring(0, 10)
                      : ''
                  }
                  onChange={(e) => {
                    setopperins({ ...opperins, JoiningDate: e.target.value });
                  }}
                />
                <div class="invalid-feedback">PLEASE SELECT Joining Date</div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="primary"
              onClick={(e) => {
                setSubmit2(true);
              }}
            >
              Update
            </Button>{' '}
          </Modal.Footer>
        </form>
      </Modal>
      {/* edit modal */}

      {state?.map((elem, index) => {
        return (
          <>
            <div
              class="modal fade"
              id={'exampleModal' + index}
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="btn-close"
                      id={'btnclose'}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body text-danger">
                    Are you sure you want to delete ?
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={(e) => {
                        Deleteee(elem._id, elem.verticals, elem.ind);
                        setopperins(elem.ind);
                        setIndex(elem.ind);
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};
