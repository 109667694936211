import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ImFacebook } from 'react-icons/im';
import { BsTwitter, BsWhatsapp, BsLinkedin, BsInstagram } from 'react-icons/bs';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import './Vipardubadd.css';
import { Footer } from '../../../Footer/Footer';
import Addcomponent from '../../../Addcomponent';
import Modal from 'react-bootstrap/Modal';
import { keyGen, notify } from '../../../../App';
import { AiFillStar } from 'react-icons/ai';
import { Rating } from '../../Rating';
import { useSelector } from 'react-redux';
import { ShareThis } from '../../Share/Share';
import { Viparexclusivemodal } from '../Viparexclusivemodal';

const Vipardubadd = () => {
  let navigate = useNavigate();
  const [urlcopy, setUrlcopy] = useState();
  const copyurl = () => {
    const url = window.location.href;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setUrlcopy(url);
        notify('URL copied to clipboard', true);
      })
      .catch((error) => {
        notify('Failed to copy URL', false);
      });
  };
  const [share, setShare] = useState(false);

  const [state, setState] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [friendshow, setfriendShow] = useState(false);
  const friendhandleClose = () => setfriendShow(false);
  const friendhandleShow = () => setfriendShow(true);

  const [specshow, setspecShow] = useState(false);
  const spechandleClose = () => setspecShow(false);
  const spechandleShow = () => setspecShow(true);

  const [detailsshow, setdetailsShow] = useState(false);
  const detailshandleClose = () => setdetailsShow(false);
  const detailshandleShow = () => setdetailsShow(true);

  const [careshow, setcareShow] = useState(false);
  const carehandleClose = () => setcareShow(false);
  const carehandleShow = () => setcareShow(true);

  const [returnshow, setreturnShow] = useState(false);
  const returnhandleClose = () => setreturnShow(false);
  const returnhandleShow = () => setreturnShow(true);

  const [cfshow, setCfshow] = useState(false);
  const cfhandleClose = () => setCfshow(false);
  const cfhandleShow = () => setCfshow(true);
  var mod = searchParams.get('modal');

  const modal = useSelector((state) => {
    return state.modal.value;
  });

  useEffect(() => {
    if (mod) {
      handleShow();
    }
    return () => {
      handleClose();
    };
  }, []);

  useEffect(() => {
    axios
      .get('/public/getproducts?id=63abfc3251626cde3b9d7c75')
      .then((res) => {
        console.log(res.data);
        setState(res.data[0]);
        setloading(false);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });

    return () => {};
  }, []);

  const [review, setReview] = useState([]);

  const submit = () => {
    axios
      .get('/public/getreview?id=63abfc3251626cde3b9d7c75')
      .then((res) => {
        console.log(res.data);
        setReview(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [loading, setloading] = useState(true);

  let isLogin = useSelector((state) => {
    return state.user.value.Status;
  });

  return (
    <>
      <hr
        className="mt-0"
        style={{
          color: 'rgb(1, 32, 96)',
          opacity: '1',
          borderTop: '3px solid',
        }}
      />

      <div className="container-fluid p-0">
        <div className="container pb-5" style={{ textAlign: 'justify' }}>
          <div className="row">
            <div className="col-lg-5 mt-5 d-flex justify-content-center">
              <div
                id="carouselExampleControls"
                className="carousel slide cardimgwidth"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div
                      className="card bg-light cardborder"
                      style={{ border: 'none' }}
                    >
                      <img
                        id="f1"
                        src="/img/cover(5).jpg"
                        className="card-img-top"
                        alt="..."
                      />
                      {/* <div
                        className="card-body dubcbodyheight"
                        style={{ backgroundColor: '#e9eef5' }}
                      ></div> */}
                    </div>
                  </div>

                  <div className="carousel-item ">
                    <div
                      className="card bg-light cardborder"
                      style={{ border: 'none' }}
                    >
                      <img
                        id="f1"
                        src="/img/2(2).jpg"
                        className="card-img-top"
                        alt="..."
                      />
                      {/* <div
                        className="card-body "
                        style={{ backgroundColor: '#e9eef5' }}
                      ></div> */}
                    </div>
                  </div>
                </div>
                <a
                  className="carousel-control-prev text-decoration-none w-auto ps-3"
                  href="#carouselExampleControls"
                  role="button"
                  data-bs-slide="prev"
                >
                  <i className="fas  fa-chevron-left"></i>
                </a>
                <a
                  className="carousel-control-next text-decoration-none w-auto pe-3"
                  href="#carouselExampleControls"
                  role="button"
                  data-bs-slide="next"
                >
                  <i className="fas  fa-chevron-right"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-7 mt-5 ">
              <div className="card  cardborder ftnn" style={{ border: 'none' }}>
                <div className="card-body pt-0">
                  <div
                    className="font25px"
                    style={{
                      fontSize: '25px',
                      textAlign: 'left',
                      fontFamily: 'FootlightMTLight',
                    }}
                  >
                    {loading ? (
                      loading && (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></div>
                      )
                    ) : (
                      <>
                        <span
                          style={{ fontFamily: 'Monotype', color: 'green' }}
                        >
                          Vipar
                        </span>{' '}
                        &nbsp;D U B&nbsp;&nbsp;Classic Add-on Accessory
                        {/* {state.pname} */}
                      </>
                    )}
                  </div>
                  <h4 className="pt-2">
                    <Viparexclusivemodal />
                  </h4>

                  <p className="pt-2 mb-0" style={{ fontSize: '19px' }}>
                    ₹{' '}
                    {loading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      state.price + ' /-'
                    )}
                    <br />
                    <span>
                      <Rating value={{ review, setReview }}></Rating>
                    </span>
                    <br />
                  </p>
                  <p
                    className="pt-3 mb-0"
                    style={{ fontSize: '19px', textAlign: 'left' }}
                  >
                    With the{' '}
                    <span style={{ fontFamily: 'Monotype', color: 'green' }}>
                      Vipar&nbsp;
                    </span>{' '}
                    D U B&nbsp;&nbsp;Classic&nbsp;Add-on Accessory, you can
                    change the colour of your favourite utility basket every
                    day/week/month.
                  </p>
                  <div className="">
                    <Link
                      to="/vipar-chic/articles/DUBclassic"
                      style={{ fontSize: '17px' }}
                    >
                      (Check out the Vipar D U B&nbsp;&nbsp;Classic)
                    </Link>
                  </div>
                  <div className="">
                    <br />
                    <span
                      style={{
                        fontWeight: '100',
                        fontSize: '1rem',
                      }}
                    >
                      Product dispatch time : within 2-3 business days
                    </span>
                  </div>

                  <ul></ul>

                  <form action="" method="GET" />
                  <input
                    type="hidden"
                    name="product-title"
                    value="Activewear"
                  />

                  <div className="row pb-3">
                    <div className="col d-grid">
                      <button
                        type="button"
                        id="opendubcadd"
                        className="btn  btn-lg text-white"
                        name="submit"
                        onClick={() =>
                          isLogin
                            ? handleShow()
                            : document
                                .getElementById('RegisterModalbtndemo')
                                ?.click()
                        }
                        style={{ backgroundColor: '#012060' }}
                      >
                        BUY IT NOW
                      </button>
                    </div>
                    <div className="col d-grid ">
                      <button
                        type="button"
                        className="btn btn-outline-dark btn-lg"
                        onClick={(e) => {
                          submit(cfhandleShow());
                        }}
                        name="submit"
                        style={{ fontSize: '17px' }}
                      >
                        <span>Reviews</span>
                      </button>
                    </div>
                  </div>

                  <div className="row pb-3">
                    <div className="col d-grid ">
                      <button
                        type="button"
                        className="btn btn-outline-dark btn-lg"
                        name="submit"
                        onClick={returnhandleShow}
                      >
                        <span style={{ fontSize: '17px' }}>
                          Return&nbsp;/&nbsp;cancel
                        </span>
                      </button>
                    </div>
                    <div className="col d-grid ">
                      <button
                        type="button"
                        className="btn btn-outline-dark btn-lg"
                        name="submit"
                        onClick={() => setShare(true)}
                        style={{ fontSize: '17px' }}
                        value="addtocard"
                      >
                        RECOMMEND
                      </button>
                    </div>
                  </div>

                  <Modal
                    show={show}
                    onHide={handleClose}
                    scrollable={true}
                    size="lg"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title style={{ lineHeight: '1' }}>
                        <span
                          style={{ fontSize: '1.25rem', fontWeight: '500' }}
                          id="staticBackdropLabel"
                        >
                          Pick your favourites from NINE amazing colours !
                        </span>
                        <br></br>

                        <br></br>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {state.variant?.map((variants) => {
                        return <Addcomponent key={keyGen()} value={variants} />;
                      })}
                    </Modal.Body>

                    <Modal.Footer>
                      <button
                        data-bs-dismiss="modal"
                        onClick={() => {
                          navigate('/vipar-chic/shopping_bag');
                        }}
                        type="button"
                        className="btn btn-outline-dark"
                      >
                        GO TO SHOPPING BAG
                      </button>
                    </Modal.Footer>
                  </Modal>

                  <Modal
                    show={cfshow}
                    onHide={cfhandleClose}
                    scrollable={true}
                    size="lg"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Customer Feedback</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="container">
                        <div className="row mb-5">
                          <div className="col-sm-12">
                            <div className="review-block">
                              {review?.map((elem, ind) => {
                                return (
                                  <>
                                    <div className="row">
                                      <div className="col-12">
                                        <div className="review-block-name">
                                          <div className="d-flex align-items-center">
                                            <img
                                              src="/img/avatar.webp"
                                              className="img-rounded me-2"
                                              alt=""
                                              height="50px"
                                              width="50px"
                                              style={{
                                                borderRadius: '30px',
                                                display: 'inline-block',
                                              }}
                                            />
                                            <div
                                              style={{
                                                display: 'inline-block',
                                              }}
                                            >
                                              <p className="m-0">
                                                {elem.orderdetails[0]
                                                  .billingAddress.firstname +
                                                  ' ' +
                                                  elem.orderdetails[0]
                                                    .billingAddress.lastname}
                                              </p>
                                              <div className="review-block-rate">
                                                {elem?.rating &&
                                                  new Array(elem.rating)
                                                    .fill(0)
                                                    .map((elem) => {
                                                      return (
                                                        <AiFillStar color="yellow" />
                                                      );
                                                    })}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="container">
                                      <div className="row">
                                        <div className="col-12">
                                          <div className="review-block-date px-1"></div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 p-0">
                                          <div className="review-block-description">
                                            {review?.map((elm, ind) => {
                                              return (
                                                <>
                                                  <p>{elm.comment}</p>
                                                </>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                              {review.length == 0 ? 'No Reviews to show' : ''}
                              <hr />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>

                  <Modal
                    show={friendshow}
                    onHide={friendhandleClose}
                    scrollable={true}
                    size="lg"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>
                        How sweet. 🤗
                        <br />
                        Thank you so much !!
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row gy-3">
                        <div className="col-auto">
                          <input
                            type="button"
                            className="btn btn-outline-dark"
                            value="Copy Url"
                            onClick={copyurl}
                          />
                        </div>
                        <div className="col">
                          <textarea
                            id="url"
                            className="form-control "
                            rows="1"
                            cols="30"
                            value={urlcopy}
                          ></textarea>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center mt-3">
                        <div className="col-auto  p-0 px-2">
                          <a
                            href="https://www.facebook.com/"
                            className=" fa facebook d-flex align-items-center justify-content-center"
                          >
                            <ImFacebook size={30} />
                          </a>
                        </div>

                        <div className="col-auto p-0 px-2">
                          <a
                            href="https://web.whatsapp.com/"
                            className=" fa whatsapp  d-flex align-items-center justify-content-center"
                          >
                            <BsWhatsapp size={30} />
                          </a>
                        </div>

                        <div className="col-auto  p-0 px-2">
                          <a
                            href="https://www.instagram.com/"
                            className=" fa instagram  d-flex align-items-center justify-content-center"
                          >
                            <BsInstagram size={35} />
                          </a>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                  <Modal
                    show={specshow}
                    onHide={spechandleClose}
                    scrollable={true}
                    size="lg"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Specification</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <h5 className="cardtext">
                        Material type : Rubber wood, Acrylic, Anodised aluminium
                      </h5>
                      <h5 className="cardtext">
                        Size : 10 inches (width) x 9 inches (height) x 6 inches
                        (depth)
                      </h5>
                      <h5 className="cardtext">Weight : 1660 grams</h5>
                    </Modal.Body>
                  </Modal>

                  <Modal
                    show={detailsshow}
                    onHide={detailshandleClose}
                    scrollable={true}
                    size="lg"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <h5 className="cardtext">
                        Vipar D U B&nbsp;&nbsp;Classic Add-on Accessory is a Vipar product
                      </h5>
                      <h5 className="cardtext">
                        Conceptualized, Designed and Marketed by :{' '}
                        <span className="dddddd monot">Vipar&nbsp;</span>
                        <sup className="reggg">&reg;</sup>
                      </h5>
                      <h5 className="cardtext">Country of Origin : India</h5>
                      <h5 className="cardtext">
                        HSN code (of the product) : 3924
                      </h5>
                    </Modal.Body>
                  </Modal>

                  <Modal
                    show={careshow}
                    onHide={carehandleClose}
                    scrollable={true}
                    size="lg"
                    centered
                  >
                    <Modal.Header closeButton>
                      <h5 className="modal-title" id="staticBackdropLabel">
                        Care instructions
                      </h5>
                    </Modal.Header>
                    <Modal.Body>
                      <h5 className="cardtext">
                        Acrylic is a delicate material. You are to "Handle with
                        Care" the acrylic plates while removing, wiping, washing
                        and replacing.
                      </h5>
                      <h5 className="cardtext">
                        Milk bottles and packets must be placed inside the front
                        compartment with care. Rough usage will damage the
                        acrylic plate.
                      </h5>
                      <h5 className="cardtext">
                        You may wash the acrylic plates daily with water.
                      </h5>
                      <h5 className="cardtext">
                        In case of spillage, wipe clean the wood/aluminium
                        components and lock with a dry cloth only.
                      </h5>
                      <h5 className="cardtext">
                        MAINTAIN the wood/aluminium components and lock DRY
                        ALWAYS. Wood component is only water-resistant and not
                        water-proof.
                      </h5>
                      <h5 className="cardtext">
                        Sharp, pointed objects or blades can damage the surface
                        of the product if scraped on it.
                      </h5>
                      <h5 className="cardtext">
                        The D U B&nbsp;&nbsp;Classic must not be placed in areas
                        which are directly exposed to rain.
                      </h5>
                    </Modal.Body>
                  </Modal>

                  <Modal
                    show={returnshow}
                    onHide={returnhandleClose}
                    scrollable={true}
                    size="lg"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Return / Cancellation terms</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p className="assss">Q. Can we return our order ?</p>
                      <p>
                        A. All our products are{' '}
                        <b style={{ fontWeight: '800' }}>non</b>-returnable.
                        However, products with manufacturing defects can be
                        exchanged on the basis of strict terms and conditions.
                        Please read our{' '}
                        <a
                          className="as"
                          href="#"
                          onClick={(e) => modal.epmodal()}
                          data-bs-dismiss="modal"
                        >
                          exchange policies
                        </a>{' '}
                        to know more about this.
                        <br />
                        <br />
                        To Return the Order, follow these steps :<br />
                        1. Log in to your Vipar account.
                        <br />
                        2. Go to 'New Order' on your Shopping Bag icon.
                        <br />
                        3. Click on the 'Return' button.
                      </p>
                      <p className="assss">Q. Can we cancel our order ?</p>
                      <span>
                        Yes. ONLY if you cancel the order before we dispatch it
                        from our warehouse.
                        <br />
                        <br />
                        To Cancel the Order, follow these steps :<br />
                        1. Log in to your Vipar account.
                        <br />
                        2. Go to 'New Order' on your Shopping Bag icon.
                        <br />
                        3. Click on the 'Cancel' button.
                        <br />
                        <br />
                        Once the order is booked for shipping with our logistics
                        partner, and then you cancel the order, you will have to
                        bear both the to-and-fro shipping costs. And, the parcel
                        box must be returned STRICTLY without the Vipar seal
                        tape tampered with or damaged or broken. Refund (after
                        deduction of to-and-fro shipping costs) will be
                        initiated only after we receive the parcel box in the
                        manner we have specified above and we are assured that
                        the product is unused.
                      </span>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </div>
          </div>

          <section>
            <div className="container-fluid pt-0  pt-md-5">
              <div className="col-md-2 col-sm-dnone "></div>
              <div className="col-md-8 col-sm-dnone ">
                <p className="pppp fontfordetalis">
                  <b
                    className=""
                    style={{
                      fontSize: '20px',
                      fontWeight: '700',
                    }}
                  >
                    Product specifications and details
                  </b>{' '}
                  : <br /> Material type : Acrylic
                  <br />
                  Size : 8.5 inches (front width) x 9 inches (height) x 3.75 inches
                  (side width / gazette)
                  <br />
                  Weight : 320 grams
                  <br />
                  <span style={{ fontFamily: 'Monotype', color: 'green' }}>
                    Vipar
                  </span>{' '}
                  &nbsp;D U B&nbsp;&nbsp;Classic Add-on Accessory is a{' '}
                  <span style={{ fontFamily: 'Monotype', color: 'green' }}>
                    Vipar
                  </span>{' '}
                  &nbsp;product
                  <br />
                  Conceptualized, Designed and Marketed by :{' '}
                  <span className="dddddd monot">Vipar&nbsp;</span>
                  <sup className="reggg">&reg;</sup>
                  <br />
                  Country of Origin : India
                  <br />
                  HSN code (of the product) : 3924
                  <br />
                  <b
                    className="discrpfvniv"
                    style={{
                      fontSize: '20px',
                      fontWeight: '700',
                    }}
                  >
                    Care instructions{' '}
                  </b>{' '}
                  : <br />
                  You may wash the acrylic plate daily with water.<br></br>
                  Sharp, pointed objects or blades can damage the surface of the
                  product if scraped on it.<br></br>
                </p>
              </div>
              <div className="col-md-2 col-sm-dnone"></div>
            </div>
          </section>
        </div>
      </div>
      <ShareThis
        shareshow={share}
        setshareshow={setShare}
        url={window.location.href}
      />
    </>
  );
};

export default Vipardubadd;
