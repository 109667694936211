import React from 'react'
import { Navbarclean } from './Navbarclean'
import { Footer } from '../Footer/Footer'

export const Cleanmain = () => {
  return (
    <>
    <Navbarclean/>
    <div id="fooo" >
        <section className="bgvipar d-none d-md-block ">
          <div className="container w-100">
            <div className="row  d-flex justify-content-center align-items-center py-2 py-md-3 px-2">
              <p
                style={{
                  fontSize: '15px',
                  fontFamily: 'maiandra',
                  textAlign: 'left',
                }}
                className="m-0 my-5 text-light invisible "
              >
                Professional-Networking, Informative, Educative and Awareness
                Creation online platforms which are Accessible to users
                Globally.
              </p>
              <br />
            </div>
          </div>
        </section>
        <div
         
          className="py-5 d-none d-md-block"
        >
          <div className="container invisible   d-none d-md-block ">
            <div>
              These are annual subscription-based web and mobile apps available
              on Windows, Android and iOS.
            </div>
            <div className="pt-3">
              80% of the revenue generated from the subscriptions to these apps
              is used for funding our humane and social welfare initiatives at
              <span
                className="vipar"
                style={{ fontSize: '22px', color: '#99d050' }}
              >
                {' '}
                Vipar
              </span>
              &nbsp;&nbsp;CARE.
            </div>
          </div>
        </div>
        <section className="bgvipar d-block d-md-none">
          <div className="container invisible w-100">
            <div className=" py-2 py-md-3 px-2">
              <p
                className="text-light my-4"
                style={{
                  fontFamily: 'maiandra',

                  textAlign: 'left',
                }}
              >
                Professional-Networking, Informative, Educative and Awareness
                Creation online platforms which are Accessible to users
                Globally.
              </p>
            </div>
          </div>
        </section>
        <div
          style={{  overflow: 'hidden' }}
          className="py-5  d-block d-md-none"
        >
          <div className="container invisible ps-3">
            <div className="">
              These are annual subscription-based web and mobile apps available
              on Windows, Android and iOS.
            </div>

            <div className="pt-3">
              80% of the revenue generated from the subscription to these apps
              is used for funding our humane and social welfare initiatives at
              <span
                className="vipar"
                style={{ fontSize: '22px', color: '#99d050' }}
              >
                {' '}
                Vipar
              </span>
              &nbsp;&nbsp;CARE.
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  )
}
