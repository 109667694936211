import React from 'react';
import { Footer } from '../Footer/Footer';

import { Navviparhome } from './Navviparhome';

export const Inceptionpagelink = () => {
  console.log('ppp');
  return (
    <>
      <Navviparhome />
      <div id="foooo" style={{ backgroundColor: '#e9eef5' }}>
        <section className="bgvipar text-white py-3">
          <div className="container w-100">
            <div className="  d-flex justify-content-center align-items-center py-2 py-md-3 px-2">
              <p className="mono" style={{ fontSize: '20px' }}>
                <span
                  className="vipar"
                  style={{ fontSize: '30px', color: '#99d050' }}
                >
                  {' '}
                  Vipar
                </span>
                {/* <sup className="ms-1 mono" style={{ fontSize: "16px" }}>
                  ®
                </sup>{" "} */}{' '}
                &nbsp;is an Architect’s brainchild to originate Services and
                Products which are essentially useful to all, thereby creating
                employment opportunities to many.
              </p>
            </div>
          </div>
        </section>
        <section className="pb-5" style={{ backgroundColor: '#e9eef5' }}>
          <div className="container ps-3">
            <div className="pt-5">
              <span className="">
                Covid-19 shook the world. Put majority of the people around the
                globe in a dilemma. Many lost jobs and their livelihood. It was
                an eye-opener for many. A game-changer for a few.
              </span>
              <br />
            </div>
            <div className="pt-3">
              <span className="">
                For some, it was the commencement of something better and
                bigger. But for many, it was a closure to whatever they were
                into until then.
              </span>
              <br />
            </div>
            <div className="pt-3">
              <span className="">
                Supply overtook Demand on the Employment front.
              </span>
              <br />
            </div>
            <div className="pt-3">
              <span className="">
                Creation of Jobs was Indispensible.<br></br>Reaching out to
                those finding it hard to make both ends meet was Inevitable.
              </span>
              <br />
            </div>
            <div className="pt-3">
              <span className="">
                From this plight, stemmed the idea of originating small
                businesses that would provide livelihood and sustainability to
                as many possible across the length and breadth of this country,
                and furthermore financially support the absolutely hapless with
                a margin of profits from these commercial entities.
              </span>
              <br />
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};
