import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ImFacebook } from 'react-icons/im';
import { BsTwitter, BsWhatsapp, BsLinkedin, BsInstagram } from 'react-icons/bs';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import './Viperduocombo';
import { Footer } from '../../../Footer/Footer';
import { Viparduo, Bucketcolor } from './Viparduo';
import { useReducer } from 'react';
import { createContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { notify } from '../../../../App';

export const Bucketcontext = createContext();
export const Viperduocombo = () => {
  let navigate = useNavigate();
  const [urlcopy, setUrlcopy] = useState();
  const copyurl = () => {
    const url = window.location.href;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setUrlcopy(url);
        notify('URL copied to clipboard', true);
      })
      .catch((error) => {
        notify('Failed to copy URL', false);
      });
  };
  const reducer = (state, action) => {
    switch (action.type) {
      case 'bucket':
        return { component: <Viparduo />, type: action.type };
      case 'color':
        return { component: <Bucketcolor />, type: action.type };
    }
  };
  const [Buckcolor, dispatch] = useReducer(reducer, {
    component: <Viparduo />,
    type: 'bucket',
  });

  const [hook, setHook] = useState(false);

  const [state, setState] = useState([]);
  const [loading, setloading] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [friendshow, setfriendShow] = useState(false);
  const friendhandleClose = () => setfriendShow(false);
  const friendhandleShow = () => setfriendShow(true);

  let [searchParams, setSearchParams] = useSearchParams();
  var mod = searchParams.get('modal');

  useEffect(() => {
    if (mod) {
      handleShow();
    }
    return () => {
      handleClose();
    };
  }, []);

  useEffect(() => {
    axios
      .get('/public/getproducts?id=63abfd5651626cde3b9d7c7b')
      .then((res) => {
        console.log(res.data);
        setState(res.data[0]);
        setloading(false);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });

    return () => {};
  }, []);

  const [first, setfirst] = useState([]);
  useEffect(() => {
    console.log(state);
  }, [state]);
  useEffect(() => {
    console.log(first);
  }, [first]);

  useEffect(() => {
    axios
      .get('/public/getproducts?id=63abf74851626cde3b9d7c6b')
      .then((res) => {
        console.log(res.data);
        setfirst(res.data[0]);
        setloading(false);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });

    return () => {};
  }, []);

  return (
    <>
      <div
        className="container-fluid p-0"
        style={{ backgroundColor: '#e9eef5' }}
      >
        <div className="container pb-5">
          <div className="row">
            <div className="col-lg-5 mt-5 d-flex justify-content-center">
              <div
                id="carouselExampleControls"
                className="carousel slide cardimgwidth"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  {/* <ul
                    id="controller"
                    style={{ position: 'relative' }}
                    className="carousel-indicators "
                  >
                    <li
                      data-bs-target="#template-mo-zay-hero-carousel-2"
                      style={{ width: '5px' }}
                      data-bs-slide-to="0"
                      className="active"
                    ></li>
                    <li
                      data-bs-target="#template-mo-zay-hero-carousel-2"
                      style={{ width: '5px' }}
                      data-bs-slide-to="1"
                    ></li>
                    <li
                      data-bs-target="#template-mo-zay-hero-carousel-2"
                      style={{ width: '5px' }}
                      data-bs-slide-to="2"
                    ></li>
                    <li
                      data-bs-target="#template-mo-zay-hero-carousel-2"
                      style={{ width: '5px' }}
                      data-bs-slide-to="3"
                    ></li>
                    <li
                      data-bs-target="#template-mo-zay-hero-carousel-2"
                      style={{ width: '5px' }}
                      data-bs-slide-to="4"
                    ></li>
                    <li
                      data-bs-target="#template-mo-zay-hero-carousel-2"
                      style={{ width: '5px' }}
                      data-bs-slide-to="5"
                    ></li>
                  </ul> */}
                  <div className="carousel-item active">
                    <div
                      className="card bg-light cardborder"
                      style={{ border: 'none' }}
                    >
                      <img
                        id="f1"
                        src="/img/VPDC100-COMBO.jpg"
                        className="card-img-top"
                        alt="..."
                      />
                      <div
                        className="card-body dubcbodyheight"
                        style={{ backgroundColor: '#e9eef5' }}
                      >
                        <p
                          className="cardtext"
                          style={{ backgroundColor: '#e9eef5' }}
                        >
                          Duo Combo consist of
                        </p>

                        <ul>
                          <li>1 x Vipar D U B</li>
                          <li>2 x Vipar Cap-pod</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  className="carousel-control-prev text-decoration-none w-auto ps-3"
                  href="#carouselExampleControls"
                  role="button"
                  data-bs-slide="prev"
                >
                  <i className="fas  fa-chevron-left"></i>
                </a>
                <a
                  className="carousel-control-next text-decoration-none w-auto pe-3"
                  href="#carouselExampleControls"
                  role="button"
                  data-bs-slide="next"
                >
                  <i className="fas  fa-chevron-right"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-7 mt-5 ">
              <div
                className="card bg-light cardborder ftnn"
                style={{ border: 'none' }}
              >
                <div
                  className="card-body pt-0"
                  style={{ backgroundColor: '#e9eef5' }}
                >
                  <span className="" style={{ fontSize: '25px' }}>
                    {loading
                      ? loading && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )
                      : state.pname}
                  </span>

                  <p className="pt-2 mb-0" style={{ fontSize: '19px' }}>
                    ₹{' '}
                    {loading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      state.price + ' /-'
                    )}
                  </p>
                  <p className="pt-1 " style={{ fontSize: '19px' }}>
                    1 DUB and 2 Cap-pod for ₹ 1525/- only (saving : ₹ 225/-)
                    <br />
                  </p>
                  <p>
                    <Link to="/DUB" style={{ fontSize: '18px' }}>
                      Check out Vipar D U B here
                    </Link>
                  </p>
                  <p>
                    <Link to="/cappod" style={{ fontSize: '18px' }}>
                      <span>Check out Vipar Cap~pod here</span>
                    </Link>
                  </p>

                  <span
                    className=""
                    style={{
                      fontWeight: '100',
                      fontSize: '1rem',
                    }}
                  >
                    Product dispatch time : within 2-3 business days
                  </span>

                  <form action="" method="GET" />
                  <input
                    type="hidden"
                    name="product-title"
                    value="Activewear"
                  />

                  <div className="row pb-3">
                    <div className="col d-grid">
                      <button
                        type="button"
                        id="opendubcombo"
                        className="btn btn-lg text-white"
                        name="submit"
                        onClick={handleShow}
                        style={{ backgroundColor: '#012060' }}
                      >
                        BUY IT NOW
                      </button>
                    </div>
                    {/* <!-- <div className="col d-grid">
                                        <button type="button" className="btn btn-outline-dark btn-lg" name="submit"
                                             data-bs-toggle="modal" data-bs-target="#dubc">SAVE TO BUY LATER</button>
                                    </div> --> */}
                    <div className="col d-grid ">
                      <button
                        type="button"
                        className="btn btn-outline-dark btn-lg"
                        name="submit"
                        onClick={friendhandleShow}
                        value="addtocard"
                      >
                        RECOMMEND
                      </button>
                    </div>
                  </div>

                  <Modal
                    show={show}
                    onHide={handleClose}
                    scrollable={true}
                    size="lg"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>
                        Select your choice of hook and lock location depending
                        on your product fixing location
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Bucketcontext.Provider
                        value={{ dispatch, first, state, hook, setHook }}
                      >
                        {Buckcolor.component}
                      </Bucketcontext.Provider>
                    </Modal.Body>

                    <Modal.Footer>
                      <button
                        data-bs-dismiss="modal"
                        onClick={() => {
                          navigate('/vipar-chic/shopping_bag');
                        }}
                        type="button"
                        className="btn btn-outline-dark"
                      >
                        GO TO CART
                      </button>
                    </Modal.Footer>
                  </Modal>

                  <Modal
                    show={friendshow}
                    onHide={friendhandleClose}
                    scrollable={true}
                    size="lg"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>
                        How sweet. 🤗
                        <br />
                        Thank you so much !!
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row gy-3">
                        <div className="col-auto">
                          <input
                            type="button"
                            className="btn btn-outline-dark"
                            value="Copy Url"
                            onClick={copyurl}
                          />
                        </div>
                        <div className="col">
                          <textarea
                            id="url"
                            className="form-control "
                            rows="1"
                            cols="30"
                            value={urlcopy}
                          ></textarea>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center mt-3">
                        <div className="col-auto  p-0 px-2">
                          <a
                            href="https://www.facebook.com/"
                            className=" fa facebook d-flex align-items-center justify-content-center"
                          >
                            <ImFacebook size={30} />
                          </a>
                        </div>
                        <div className="col-auto  p-0 px-2">
                          <a
                            href="https://twitter.com/i/flow/login?input_flow_data=%7B%22requested_variant%22%3A%22eyJsYW5nIjoiZW4ifQ%3D%3D%22%7D"
                            className=" fa twitter  d-flex align-items-center justify-content-center"
                          >
                            <BsTwitter size={30} />
                          </a>
                        </div>
                        <div className="col-auto p-0 px-2">
                          <a
                            href="https://web.whatsapp.com/"
                            className=" fa whatsapp  d-flex align-items-center justify-content-center"
                          >
                            <BsWhatsapp size={30} />
                          </a>
                        </div>
                        <div className="col-auto  p-0 px-2">
                          {' '}
                          <a
                            href="https://www.linkedin.com/"
                            className=" fa linkedin  d-flex align-items-center justify-content-center"
                          >
                            <BsLinkedin size={30} />
                          </a>
                        </div>
                        <div className="col-auto  p-0 px-2">
                          <a
                            href="https://www.instagram.com/"
                            className=" fa instagram  d-flex align-items-center justify-content-center"
                          >
                            <BsInstagram size={35} />
                          </a>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
