import React, { useState } from 'react';
// import { FaShoppingBag } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import { RiArrowDropDownFill } from 'react-icons/ri';
import { FaShoppingBag } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { addModal } from '../../../redux/viparChic/modalslice';
import { useEffect } from 'react';

function Navbar({ setkey }) {
  const [toushow, tousetShow] = useState(false);
  const touhandleClose = () => tousetShow(false);
  const touhandleShow = () => tousetShow(true);
  let dispatch = useDispatch();

  const [ppshow, ppsetShow] = useState(false);
  const pphandleClose = () => ppsetShow(false);
  const pphandleShow = () => ppsetShow(true);

  const [dpshow, dpsetShow] = useState(false);
  const dphandleClose = () => dpsetShow(false);
  const dphandleShow = () => dpsetShow(true);

  const [epshow, epsetShow] = useState(false);
  const ephandleClose = () => epsetShow(false);
  const ephandleShow = () => epsetShow(true);
  let cart = useSelector((state) => state.cart.value);
  console.log(cart);
  let location = useLocation();

  let modal = useSelector((state) => state.modal.value);

  const myFunction = () => {
    document.getElementById('myDropdown').classList.toggle('show');
  };

  useEffect(() => {
    dispatch(
      addModal({
        type: {
          termsofuse: touhandleShow,
          privacypolicy: pphandleShow,
          deliverypolicy: dphandleShow,
          exchangepolicy: ephandleShow,
        },
      })
    );
  }, []);

  return (
    <>
      {/* <div
        className="d-flex justify-content-center"
        style={{ height: '100px', width: '100%', backgroundColor: 'white' }}
      > */}
      <nav
        className="navbar navbar-expand-lg navbar-light shadow d-none"
        style={{ fontFamily: 'FootlightMTLight' }}
      >
        <div className="container d-flex justify-content-between align-items-center">
          <Link
            class="navbar-brand text-success logo align-self-center"
            to="/vipar-chic/policies"
          >
            <img
              class="logoVipar"
              src="/img/round.png"
              alt=""
              height="80px"
              width="80px"
            />
          </Link>
          {/* <Link
            style={{ color: 'black' }}
            className={`nav-icon position-relative text-decoration-none  d-block d-lg-none ${
              location.pathname === '/vipar-chic/cart'
                ? ' active-link-vipar'
                : ''
            }`}
            onClick={() => setkey((prev) => prev + 1)}
            to="/vipar-chic/cart"
          >
            <FaShoppingBag size={22} />
            <span
              id="cartno2"
              className="position-absolute top-0 left-100 translate-middle badge rounded-pill bg-light text-dark"
            >
              {cart?.length}
            </span>
          </Link> */}
          {/* <button
            className="navbar-toggler border-0 bb"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#templatemo_main_nav"
            aria-expanded="false"
            aria-controls="templatemo_main_nav"
          >
            <span className="">
              COME-ON IN{' '}
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </span>
          </button> */}

          <div
            className="align-self-center collapse navbar-collapse flex-fill w-100  d-lg-flex justify-content-lg-between"
            id="templatemo_main_nav"
          >
            <div className="flex-fill">
              <ul className=" navbar-nav d-flex justify-content-center mx-lg-auto ms-0">
                <li className="nav-item ">
                  <Link
                    to="/vipar-chic/accessories"
                    onClick={() => setkey((prev) => prev + 1)}
                    className={`nav-link cardtextt aa ${
                      location.pathname === '/vipar-chic/accessories'
                        ? ' active-link-vipar'
                        : ''
                    }`}
                  >
                    APPLIANCES
                  </Link>
                </li>
                <li className="nav-item ">
                  <Link
                    to="/vipar-chic/articles"
                    onClick={() => setkey((prev) => prev + 1)}
                    className={`nav-link cardtextt aa ${
                      location.pathname === '/vipar-chic/articles'
                        ? ' active-link-vipar'
                        : ''
                    }`}
                  >
                    ARTICLES
                  </Link>
                </li>
                <li className="nav-item ">
                  <Link
                    to={'/vipar-chic/art'}
                    className={`nav-link cardtextt aa ${
                      location.pathname === '/vipar-chic/art'
                        ? ' active-link-vipar'
                        : ''
                    }`}
                    onClick={() => setkey((prev) => prev + 1)}
                  >
                    ART
                  </Link>
                </li>
                <li className="nav-item ">
                  <Link
                    className={`nav-icon position-relative text-decoration-none d-none d-lg-block cardtextt ${
                      location.pathname === '/vipar-chic/shopping_bag'
                        ? ' active-link-vipar'
                        : ''
                    }`}
                    onClick={() => setkey((prev) => prev + 1)}
                    style={{ color: 'black' }}
                    to="/vipar-chic/shopping_bag"
                  >
                    <FaShoppingBag size={22} />
                    <span
                      id="cartno"
                      // className="position-absolute top-0 left-100 translate-middle  bg-light text-dark"
                    >
                      {/* {cart?.length} */}
                    </span>
                  </Link>
                </li>
              </ul>

              <ul className=" navbar-nav d-flex justify-content-center mx-lg-auto ms-0">
                {/* <li className="nav-item act">
                  <Link
                    className={`nav-link cardtextt ${
                      location.pathname === '/vipar-chic/policies'
                        ? ' active-link-vipar'
                        : ''
                    }`}
                    onClick={() => setkey((prev) => prev + 1)}
                    to="/vipar-chic/policies"
                  >
                    WINDOW&nbsp;SHOPPING
                  </Link>
                </li> */}

                <li className="nav-item">
                  <Link
                    to="/vipar-chic/policies"
                    onClick={() => setkey((prev) => prev + 1)}
                    className={`nav-link cardtextt ${
                      location.pathname === '/vipar-chic/policies'
                        ? ' active-link-vipar'
                        : ''
                    }`}
                  >
                    STORE&nbsp;POLICIES
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/vipar-chic/helpdesk"
                    onClick={() => setkey((prev) => prev + 1)}
                    className={`nav-link cardtextt ${
                      location.pathname === '/vipar-chic/helpdesk'
                        ? ' active-link-vipar'
                        : ''
                    }`}
                  >
                    HELP&nbsp;DESK
                  </Link>
                </li>
                <li className="nav-item ">
                  <Link
                    to="/vipar-chic/connect"
                    onClick={() => setkey((prev) => prev + 1)}
                    className={`nav-link cardtextt aa ${
                      location.pathname === '/vipar-chic/connect'
                        ? ' active-link-vipar'
                        : ''
                    }`}
                  >
                    CONNECT
                  </Link>
                </li>
              </ul>
            </div>
            {/* <div className="navbar align-self-center d-flex">
              <div className="d-lg-none flex-sm-fill mt-3 mb-4 col-7 col-sm-auto pr-3"></div>
              
              <Link
                className={`nav-icon position-relative text-decoration-none d-none d-lg-block cardtextt ${
                  location.pathname === '/vipar-chic/cart'
                    ? ' active-link-vipar'
                    : ''
                }`}
                onClick={() => setkey((prev) => prev + 1)}
                style={{ color: 'black' }}
                to="/vipar-chic/cart"
              >
                <FaShoppingBag size={22} />
                <span
                  id="cartno"
                  className="position-absolute top-0 left-100 translate-middle badge rounded-pill bg-light text-dark"
                >
                  {cart?.length}
                </span>
              </Link>
            </div> */}
          </div>
        </div>
      </nav>

      {/* </div> */}
    </>
  );
}

export default Navbar;
