import axios from 'axios';
import React from 'react';
import { useState, useEffect } from 'react';
import { notify } from '../../App';

export const Generatecoupen = () => {
  const [err, setErr] = useState('');
  const [first, setFirst] = useState({
    Coupen: '',
    Expiry: '',
    UseCount: '',
    MinimumOrderValue: 0,
    MaximumDiscount: 0,
    Amount: 0,
    Calc: '',
    Products: ['INVOICE'],
  });
  const validate = (data) => {
    if (data.Coupen.length === 0) {
      return setErr('Enter the coupon');
    }
    if (data.Coupen.length < 4) {
      return setErr('Enter the minumum characters for  coupon');
    }
    if (data.MinimumOrderValue < 0) {
      return setErr('Enter the min value');
    }
    if (data.MaximumDiscount < 0) {
      return setErr('Enter the  max value');
    }
    if (data.Calc.length === 0) {
      return setErr('select a type');
    }
    if (parseInt(data.Amount) === 0 || data.Amount.length === 0) {
      return setErr('Enter the amount ');
    }
    if (data.Expiry.length === 0) {
      return setErr('Enter the date ');
    }
    if (data.UseCount.length === 0) {
      return setErr('Enter the use ');
    }

    if (data.Products.length === 0) {
      return setErr('Select any one of the products ');
    }
    setErr('');
    return true;
  };
  const submit = (e) => {
    e.preventDefault();

    if (validate(first) === true) {
      axios
        .post('/public/coupencode', first)
        .then((res) => {
          console.log(res.data);
          notify('Coupon generated', true);
          e.target.reset();
        })
        .catch((err) => {
          console.log(err);
          notify('Something went wrong', false);
        });
    }
  };
  return (
    <form onSubmit={(e) => submit(e)}>
      <div className="card p-4" style={{ backgroundColor: '#edf1f5' }}>
        <div className="card-header">
          <h4 className="pt-4">Generate Coupon</h4>
        </div>

        <div className="card-body" style={{ backgroundColor: '#edf1f5' }}>
          <h5>Generate for all</h5>
          <br></br>
          <div className="row gy-2">
            <div className="col-6 col-md-3 ">
              <label>CoupenName:</label>
            </div>
            <div className=" col-6 col-md-9   mb-2">
              <div className="">
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="coupon"
                  value={first.Coupen}
                  onChange={(e) =>
                    setFirst({ ...first, Coupen: e.target.value.toUpperCase() })
                  }
                />
              </div>
            </div>
          </div>
          <div className="row gy-2">
            <div className="col-6 col-md-3 ">
              <label>Minordervalue:</label>
            </div>
            <div className="col-6 col-md-9 mb-2">
              <div className="">
                <input
                  type="number"
                  className="form-control"
                  id="min"
                  placeholder="Minimum order value"
                  value={first.MinimumOrderValue}
                  onChange={(e) =>
                    setFirst({ ...first, MinimumOrderValue: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="row gy-2">
            <div className="d-none d-md-block col-md-3"></div>
            <div className="col-6 col-md-2 mb-3">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="P"
                  onChange={(e) => setFirst({ ...first, Calc: e.target.value })}
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  Percentage
                </label>
              </div>
            </div>
            <div className="col-6 col-md-2 ">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="A"
                  onChange={(e) =>
                    setFirst({
                      ...first,
                      Calc: e.target.value,
                      MaximumDiscount: 0,
                    })
                  }
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  Amount
                </label>
              </div>
            </div>
          </div>
          <div className="row gy-2">
            <div className="col-6 col-md-3">
              <label>Amount:</label>
            </div>
            <div className="col-6  col-md-9">
              <div className="mb-3">
                <input
                  type="number"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="value here! Eg:30"
                  onChange={(e) =>
                    setFirst({ ...first, Amount: e.target.value })
                  }
                />
              </div>
            </div>
          </div>

          <div className="row gy-2">
            {first.Calc === 'P' && (
              <>
                <div className="col-6 col-md-3">
                  <label>Maxdiscount:</label>
                </div>
                <div className="col-6 col-md-9">
                  <div className="mb-3">
                    <input
                      type="number"
                      className="form-control"
                      id="max"
                      placeholder="Maximum discount"
                      value={first.MaximumDiscount}
                      onChange={(e) =>
                        setFirst({ ...first, MaximumDiscount: e.target.value })
                      }
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="row gy-2">
            <div className="col-6 col-md-3">Expiry Date:</div>
            <div className="col-6 col-md-9">
              <input
                type="date"
                className="form-control"
                onChange={(e) => setFirst({ ...first, Expiry: e.target.value })}
              ></input>
            </div>
          </div>
          <div className="row gy-2 mt-2">
            <div className="col-6 col-md-3">
              <label>Count:</label>
            </div>
            <div className="col-6 col-md-9 ">
              <div className="">
                <input
                  type="number"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="number of use"
                  onChange={(e) =>
                    setFirst({ ...first, UseCount: e.target.value })
                  }
                />
              </div>
            </div>
          </div>

          <div className="row d-flex j mt-3">
            {/* <div className="col-6  col-md-4 col-lg-3 ">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox1"
                    value="63abfb6751626cde3b9d7c72"
                    // style={{ marginLeft: '30px' }}
                    onChange={(e) =>
                      setFirst((prev) => ({
                        ...first,
                        Products: e.target.checked
                          ? [...prev.Products, e.target.value]
                          : prev.Products.filter(
                              (elem) => elem != e.target.value
                            ),
                      }))
                    }
                  />
                  &nbsp; &nbsp;
                  <label className="form-check-label" htmlFor="inlineCheckbox1">
                    DUBC
                  </label>
                </div>
              </div>
              <div className="col-6   col-md-4 col-lg-3 ">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox1"
                    value="63abfaab51626cde3b9d7c6f"
                    // style={{ marginLeft: '30px' }}
                    onChange={(e) =>
                      setFirst((prev) => ({
                        ...first,
                        Products: e.target.checked
                          ? [...prev.Products, e.target.value]
                          : prev.Products.filter(
                              (elem) => elem != e.target.value
                            ),
                      }))
                    }
                  />
                  &nbsp; &nbsp;
                  <label className="form-check-label" htmlFor="inlineCheckbox1">
                    DUB
                  </label>
                </div>
              </div>
              <div className="col-6 col-md-4 col-lg-3 ">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox1"
                    value="63abfc3251626cde3b9d7c75"
                    // style={{ marginLeft: '30px' }}
                    onChange={(e) =>
                      setFirst((prev) => ({
                        ...first,
                        Products: e.target.checked
                          ? [...prev.Products, e.target.value]
                          : prev.Products.filter(
                              (elem) => elem != e.target.value
                            ),
                      }))
                    }
                  />
                  &nbsp; &nbsp;
                  <label className="form-check-label" htmlFor="inlineCheckbox1">
                    DUBAOA
                  </label>
                </div>
              </div>
              <div className="col-6  col-md-4 col-lg-3 ">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox1"
                    value="63abf74851626cde3b9d7c6b"
                    // style={{ marginLeft: '30px' }}
                    onChange={(e) =>
                      setFirst((prev) => ({
                        ...first,
                        Products: e.target.checked
                          ? [...prev.Products, e.target.value]
                          : prev.Products.filter(
                              (elem) => elem != e.target.value
                            ),
                      }))
                    }
                  />
                  &nbsp; &nbsp;
                  <label className="form-check-label" htmlFor="inlineCheckbox1">
                    FMH
                  </label>
                </div>
              </div> */}
            <div className="col-6  col-md-4 col-lg-3 ">
              <div className="form-check form-check-inline d-none">
                <input
                  className="form-check-input "
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="INVOICE"
                  // style={{ marginLeft: '30px' }}
                  onChange={(e) =>
                    setFirst((prev) => ({
                      ...first,
                      Products: e.target.checked
                        ? [...prev.Products, e.target.value]
                        : prev.Products.filter(
                            (elem) => elem != e.target.value
                          ),
                    }))
                  }
                />
                &nbsp; &nbsp;
                <label className="form-check-label" htmlFor="inlineCheckbox1">
                  INVOICE
                </label>
              </div>
            </div>
          </div>
          <div>
            <h6 className="text-danger">{err}</h6>
          </div>
          <div className="pt-2">
            <button className="btn btn-warning text-dark" type="submit">
              Generate
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
