import axios from 'axios';
import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { notify } from '../../App';
import { Linkbroken } from '../vipar-chicAdmin/Linkbroken';
import Userloading from '../vipar-chicAdmin/Userloading';
const loaing = createContext();
export const Useremail = () => {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'loading':
        return { component: <Userloading />, type: action.type };
      case 'linkbroken':
        return { component: <Linkbroken />, type: action.type };
      case 'Email':
        return { component: <Email />, type: action.type };
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    component: <Email />,
    type: 'Email',
  });

  const [params, setparams] = useSearchParams();
  let resetid = params.get('resetid');
  useEffect(() => {
    axios
      .post('/public/useremail', { resetid })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <loaing.Provider>{state.component}</loaing.Provider>
    </>
  );
};

export const Email = () => {
  const loading = useContext(loaing);
  const [change, setchange] = useState({
    Password: '',
    Confirmpassword: '',
  });

  const [err, seterr] = useState();
  let validation = () => {
    if (change.Password.length <= 0) {
      return seterr('Please enter the Password');
    }
    if (change.Password.length < 4) {
      return seterr('Password is too short');
    }
    if (change.Confirmpassword.length <= 0) {
      return seterr('Please enter the Confirm Password');
    }
    if (change.Password != change.Confirmpassword) {
      return seterr('Password missmatch');
    }
    seterr('');
    return true;
  };
  let navigate = useNavigate();
  const [params, setparams] = useSearchParams();
  let resetid = params.get('resetid');
  useEffect(() => {
    axios
      .post('/public/useremail', { resetid })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const submit = () => {
    if (validation() == true) {
      console.log('fggfhfghgfhfghfghfghfgdgh');
      axios
        .post('/public/useremailupdate', { change, resetid })
        .then((res) => {
          notify('Password changed Successfully', true);
          document.getElementById('successcloseresetmodalbtn').click();
          navigate('/');
        })
        .catch((err) => {
          console.log(err);
          notify('Something went wrong', false);
        });
    }
  };
  return (
    <>
      <div className="d-flex justify-content-center align-items-center mt-5">
        <button
          className="btn hhchange rounded-pill"
          data-bs-target="#changepassword"
          data-bs-toggle="modal"
        >
          Reset your Password
        </button>
      </div>
      <div
        className="modal fade"
        id="changepassword"
        aria-hidden="true"
        aria-labelledby="ForgetPasswordModal"
        tabIndex="-1"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-login mt-5"
          role="document"
        >
          <div className="modal-content">
            <div className="card card-signup card-plain">
              <div className="modal-header">
                <div className="  text-center">
                  <h4 className="card-title">Reset your Password</h4>
                </div>
                <button
                  type="button"
                  id="successcloseresetmodalbtn"
                  className="btn-close float-end close-button-modal "
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form className="form" method="" action="">
                  <div className="card-body">
                    <div className="form-group bmd-form-group">
                      <div className="input-group">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          onChange={(e) =>
                            setchange({ ...change, Password: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group bmd-form-group mt-3">
                      <div className="input-group">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Confirm password"
                          onChange={(e) =>
                            setchange({
                              ...change,
                              Confirmpassword: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group bmd-form-group mt-3">
                      <p className="text-danger ">{err}</p>
                      <div className="input-group d-flex justify-content-center">
                        <button
                          className="btn hhchange rounded-pill px-4"
                          type="button"
                          onClick={(e) => submit()}
                        >
                          Reset
                        </button>
                      </div>
                      <br />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
