import React, { useEffect, useState } from 'react'
import { NewNavViparc } from '../NewNavViparc/NewNavViparc'
import { Grievances } from './Grievances'
import axios from 'axios'
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const Resolved = () => {
    const [resolve, setresolve] = useState()
    useEffect(() => {
      axios.post("/public/resolved").then((res)=>{
        console.log(res.data)
        setresolve(res.data)
      }).catch((err)=>{
        console.log(err)
      })
    
    
    }, [])
    
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : true);
  };
  const [expanded, setExpanded] = React.useState(false);


  return (
    <>
    <NewNavViparc/>
<div style={{backgroundColor:"#e9eef5",height:"100vh"}}>
  <div>
  <Grievances/>
  </div>
 
  <div className="mt-5">
      {resolve?.map((elem) => {
        return (

        <Accordion expanded={expanded === 'panel'} onChange={handleChange('panel')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>{elem.Order_Id}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Messages&nbsp;:{elem.Message}
            </Typography>
            <div className="row g-3 mt-4">
            {elem.Images?.map((ele)=>{
                return (
                    <>
                    <div className="col-12 col-md-6 col-lg-4">
                    <img src={ele.link}className="d-flex justify-content-center"   style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}>
                
                </img>
                    </div>
                    </>
                )
                
              
            })}
            </div>
          </AccordionDetails>
        </Accordion>
  )

      })}
              </div>     
  

    
  </div>
    </>
  )
}
