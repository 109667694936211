import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NewNavViparc } from '../NewNavViparc/NewNavViparc';

export const Grievances = () => {
  let location = useLocation();

  return (
    <>
      {/* <NewNavViparc /> */}
      <hr
        className="mt-0"
        style={{
          color: 'rgb(1, 32, 96)',
          opacity: '1',
          borderTop: '3px solid',
        }}
      />
      <div className="container">
        <div className="d-flex justify-content-center text-dark  p-3">
          <h3 style={{ paddingTop: '30px' }}>GRIEVANCE REDRESSAL</h3>
        </div>
        <div className="d-flex justify-content-center text-dark p-3">
          <Link
            to={'/vipar-chic/grievances'}
            className={`px-3 text-dark ${
              location.pathname === '/vipar-chic/grievances'
                ? 'active-link-vipar'
                : ''
            }`}
            style={{ fontSize: '18px', textDecoration: 'none' }}
          >
            NEW
          </Link>
          <Link
            to={'/vipar-chic/pending'}
            className={`px-3 text-dark ${
              location.pathname === '/vipar-chic/pending'
                ? 'active-link-vipar'
                : ''
            }`}
            style={{ fontSize: '18px', textDecoration: 'none' }}
          >
            PENDING
          </Link>
          <Link
            to={'/vipar-chic/resolved'}
            className={`px-3 text-dark ${
              location.pathname === '/vipar-chic/resolved'
                ? 'active-link-vipar'
                : ''
            }`}
            style={{ fontSize: '18px', textDecoration: 'none' }}
          >
            RESOLVED
          </Link>
        </div>
      </div>
    </>
  );
};
