import axios from 'axios';
import React, { useState, useEffect } from 'react';

const Adminproducts = () => {
  const [first, setFirst] = useState([]);
  useEffect(() => {
    axios
      .post('/public/adminproduct')
      .then((res) => {
        console.log(res.data);
        setFirst(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="page-breadcrumb bg-white">
        <div className="row align-items-center text-white">
          <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
            <h4 className="page-title">Product View </h4>
          </div>
          <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
            <div className="d-md-flex">
              <ol className="breadcrumb ms-auto"></ol>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid ">
        <div className="row">
          <div className="col-md-12">
            <div className="white-box">
              <table className="table text-white">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">product name</th>
                    <th scope="col">price</th>
                    <th scope="col">discreption</th>

                    <th scope="col">specification</th>

                    <th scope="col">category</th>
                    <th scope="col">productid </th>
                  </tr>
                </thead>
                <tbody>
                  {first.map((element, index) => {
                    return (
                      <>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{element.pname}</td>
                          <td>{element.price}</td>
                          <td>{element.discerption}</td>
                          <td>{element.specification}</td>
                          <td>{element.category}</td>
                          <td>{element.productid}</td>
                        </tr>
                        {element.variants.map((item) => {
                          return (
                            <tr>
                              <td>{item.varentName}</td>
                              <td>{item.stock}</td>
                              <td>{item.varentId}</td>
                            </tr>
                          );
                        })}
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Adminproducts;
