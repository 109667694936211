import { createSlice } from '@reduxjs/toolkit';

let userSlice = createSlice({
    name: 'user',
    initialState: {
        value: {
            UserName: null,
            Status:false,
            Email: null,
            AccountType:null,
            addresses: []

        },
    },
    reducers: {
        addUser: (state, action) => {
            try {
              let item = action.payload.item
              console.log(item)
           state.value.UserName = item.UserName
           state.value.Status = item.Status
           state.value.Email = item.Email
           state.value.AccountType = item.AccountType

            } catch (err) {
                console.log(err);
            }
        },
        updateUserAddress: (state, action) => {
            try {

            } catch (err) {
                console.log(err);
            }
        },
        RemoveUserAddress: (state, action) => {
            try {

            } catch (error) {
                console.log(error);
            }
        }
    },
});

export const { addUser } = userSlice.actions;
export default userSlice.reducer;
