import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';

export const Vparshow = (props) => {
  let onChange = props.onChange;

  const [dubvalue, setdubvalue] = useState();
  useEffect(() => {
    console.log(dubvalue);
  }, [dubvalue]);

  console.log(props);
  return (
    <>
      <div className="row py-2">
        <div className="col-md-4 col-sm-4 col-4">
          <img
            src={`/img/${props.value.varentCode}.jpg`}
            alt=""
            height="80px"
            width="80px"
          />
        </div>
        <div className="col-md-4 col-sm-4 col-4 mt-4">
          {props.value.varentName}
        </div>
        <div className="col-md-4 col-sm-4 col-4 mt-4">
          <input
            className="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault2"
            value={props.value._id}
            onChange={(e) => onChange(e.target.value, props.value.productId)}
          />
        </div>
      </div>
    </>
  );
};

export const Cappode = ({ value, label, optional, onChange }) => {
  const [capvalue, setcapvalue] = useState();
  const [cap, setcap] = useState();
  useEffect(() => {
    console.log(capvalue);
  }, [capvalue]);

  console.log(value);
  return (
    <>
      <div className="row">
        <div className="col-md-6 col-sm-6 col-6">{label}</div>
        <div className="col-md-6 col-sm-6 col-6 ">
          <select
            className="form-select form-select-lg mb-3"
            aria-label=".form-select-lg example"
            onChange={(e) => onChange(e.target.value, value[0].productId)}
          >
            {value?.map((elem) => {
              return <option value={elem._id}>{elem.varentName}</option>;
            })}
          </select>
        </div>
      </div>

      <div className="row">
        <div className="col-6"></div>

        <div className="col-6"></div>
      </div>
    </>
  );
};
