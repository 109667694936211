import React, { useEffect, useState } from 'react';
import { BiBarChart } from 'react-icons/bi';
import Adminpannel from './Adminpannel';
import axios from 'axios';

export const Dashbord = () => {
  const [state, setstate] = useState()
  useEffect(() => {
 axios.post("/public/dashboard").then((res)=>{
  console.log(res.data)
  setstate(res.data)
 }).catch((err)=>{
      console.log(err)
 })
  
   
  }, [])
  
  return (
    <>
      <div className="row">
        {/* <div className="col-4"><Adminpannel/></div> */}

        <div className="col-8"></div>

        <div
          className="card bg-light d-flex justify-content-center "
          style={{ minWidth: '250px' }}
        >
          <div className="card-header">Dashboard</div>

          <div className="card-body">
            <div className="container d-flex justify-content-center">
              <div className="card " style={{ maxWidth: '270px' }}>
                <div className="card-body ">
                  <div>
                    <h6>Total visit</h6>
                    <span style={{ paddingLeft: '30px' }}>
                      {' '}
                      <BiBarChart size="30px" style={{ color: 'green' }} />
                    </span>
                    <span style={{ color: 'green', paddingLeft: '50px' }}>
                     {state?.count}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
