import axios from 'axios';
import React, { useEffect, useReducer, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import './custom.css';
import './template.css';
import './monotypee.css';
import './newfonte.css';
import moment from 'moment';
import { Link } from 'react-router-dom';

export const Invoice = () => {
  const [item, setitem] = useState({});

  const reducer = (state, action) => {
    switch (action.type) {
      case 'invoice':
        return {
          component: <Invoicecomponent item={item} />,
          type: action.type,
        };
      case 'loading':
        return { component: <Loading />, type: action.type };
      case 'linkbroke':
        return { component: <Linkbroke />, type: action.type };
      default:
        return { component: <Linkbroke />, type: action.type };
    }
  };
  const [state, dispatch] = useReducer(reducer, {
    component: <Loading />,
    type: 'loading',
  });
  const [params, setparams] = useSearchParams();
  let order = params.get('order');
  useEffect(() => {
    axios
      .get('/public/invoice?id=' + order)
      .then((res) => {
        console.log(res.data);
        setitem(res.data);
        if (res.data) {
          dispatch({ type: 'invoice' });
        } else {
          dispatch({ type: 'linkbroke' });
        }
      })
      .catch((err) => {
        dispatch({ type: 'linkbroke' });

        console.log(err);
      });
  }, []);
  return <div>{state.component}</div>;
};

export const Invoicecomponent = ({ item }) => {
  return (
    <div id="invoiceid" style={{ background: '#fff' }}>
      <section className=" mt-5 border py-5 px-3 printheight ">
        <div className="container  ">
          <div className="row">
            <div className="col">
              <img
                className="mt-2"
                src="/img/viptrt.png"
                alt=""
                height="80px"
                width="270px"
              />
            </div>

            <div className="col text-end">
              <h5 className="me-2">
                <b>Tax Invoice</b>
              </h5>
              <h5 className="me-2">(Original for buyer)</h5>
            </div>
          </div>

          <section className="mt-3">
            <div className="row">
              <div className="col-6 text-start ">
                <h6>
                  <b>Sold By :</b>
                </h6>
                <b> VIPAR </b> <br />
                101, Aadheesh Avenue, Kalakunj road,
                <br />
                Mangaluru– 575003.
              </div>
              <div className="col-6 text-end ">
                <h6>
                  <b>Billing Address :</b>
                </h6>
                {item?.billingAddress?.address}
                <h6>
                  Pin Code:
                  {item?.billingAddress?.pincode}
                </h6>
              </div>
            </div>
          </section>

          <section className="mt-1">
            <div className="row">
              <div className="col-6 text-start mt-4">
                <h6>
                  <b>GST Registration No</b> : 29AERPV7005H2ZV
                </h6>
              </div>
              <div className="col-6 text-end ">
                <h6>
                  <b>Shipping Address :</b>
                </h6>
                {item?.shippingAddress?.address}

                <br />
                {/* {{$arr['Shipping'], $arr['ShippingCity'], $arr['ShippingState']}}, {{$arr['ShippingPincode']}} */}
                <h6>
                  Pin Code:
                  {item?.shippingAddress?.pincode}
                </h6>
              </div>
            </div>
          </section>
          <section className="mt-3">
            <div className="row">
              <div className="col-6 text-start">
                <h6>
                  <b>Order ID :</b>
                  {item?.Order_Id}
                </h6>
                <h6>
                  <b>Order Date :</b>
                  {moment(item?.Order_date).format('MMMM Do YYYY, h:mm:ss a')}
                </h6>
              </div>
            </div>
          </section>
          <section className="mt-2 ">
            <table>
              <thead>
                <tr>
                  <th scope="col">Sl.No.</th>
                  <th style={{ width: '100%' }} scope="col">
                    Product name
                  </th>
                  <th scope="col" className="pt">
                    Colour
                  </th>
                  <th scope="col">Unit Price</th>
                  <th scope="col">Discount</th>
                  <th scope="col">Qty</th>
                  <th scope="col">Gross Amount</th>
                  <th scope="col">Tax Rate</th>
                  <th scope="col">Tax Type</th>
                  <th scope="col">Net Amount</th>
                  <th scope="col">Tax Amount</th>
                  <th scope="col">Total Amount</th>
                </tr>
              </thead>
              <tbody>
                {item?.items?.map((elem, index) => {
                  console.log(elem);
                  let net = (parseFloat(elem.price) / 1.18).toFixed(2);
                  let tax = (parseFloat(elem.price) - parseFloat(net)).toFixed(
                    2
                  );
                  console.log(tax);
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{elem.name}</td>
                      <td>{elem.vname}</td>
                      <td>{elem.price}</td>
                      <td>0</td>
                      <td>{elem.qty}</td>
                      <td>{elem.price}</td>

                      <td>18%</td>
                      <td>GST</td>
                      <td>{net}</td>
                      <td>{tax}</td>

                      <td>{parseFloat(elem.price) * parseInt(elem.qty)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="text-end mt-3">
              <h6>
                <b>Total :</b>₹{item?.Amount}
                .00
              </h6>
            </div>
          </section>
          <section className="mt-5 mb-3 ms-2 me-2">
            <div className="row border">
              <div className="col border">Payment ID :</div>
              <div className="col border">Date & Time :</div>
              <div className="col border">Invoice Value :</div>
              <div className="col border">Payment Mode :</div>
            </div>
            <div className="row border">
              <div className="col border">{item.Payment_Id}</div>
              <div className="col border">
                {moment(item.PaymentDate).format('MMMM Do YYYY, h:mm:ss a')}
              </div>
              <div className="col border">
                ₹{item?.Amount}
                .00
              </div>
              <div className="col border">
                {/* {{strtoupper($arr['ModeOfPayment'])}} */}N/A-
              </div>
            </div>
          </section>
        </div>
        <p className="float-end me-3">
          ( This is a computer generated Invoice and needs no signature. )
        </p>
      </section>

      <section className=" mt-5 border py-5 px-3 printheight ">
        <div className="container  ">
          <div className="row">
            <div className="col">
              <img
                className="mt-2"
                src="/img/viptrt.png"
                alt=""
                height="80px"
                width="270px"
              />
            </div>

            <div className="col text-end">
              <h5 className="me-2">
                <b>Tax Invoice</b>
              </h5>
              <h5 className="me-2">(Duplicate for seller)</h5>
            </div>
          </div>

          <section className="mt-3">
            <div className="row">
              <div className="col-6 text-start ">
                <h6>
                  <b>Sold By :</b>
                </h6>
                <b> VIPAR </b>
                <br />
                101, Aadheesh Avenue, Kalakunj road,
                <br />
                Mangaluru– 575003.
              </div>
              <div className="col-6 text-end ">
                <h6>
                  <b>Billing Address :</b>
                </h6>
                {item?.billingAddress?.address}
                <h6>
                  Pin Code:
                  {item?.billingAddress?.pincode}
                </h6>
              </div>
            </div>
          </section>

          <section className="mt-1">
            <div className="row">
              <div className="col-6 text-start mt-4">
                <h6>
                  <b>GST Registration No</b> : 29AERPV7005H2ZV
                </h6>
              </div>
              <div className="col-6 text-end ">
                <h6>
                  <b>Shipping Address :</b>
                </h6>
                {item?.shippingAddress?.address}
                <h6>
                  Pin Code:
                  {item?.shippingAddress?.pincode}
                </h6>
              </div>
            </div>
          </section>
          <section className="mt-3">
            <div className="row">
              <div className="col-6 text-start">
                <h6>
                  <b>Order ID :</b>
                  {item?.Order_Id}
                </h6>
                <h6>
                  <b>Order Date :</b>
                  {moment(item?.Order_date).format('MMMM Do YYYY, h:mm:ss a')}
                </h6>
              </div>
            </div>
          </section>
          <section className="mt-2 ">
            <table>
              <thead>
                <tr>
                  <th scope="col">Sl.No.</th>
                  <th style={{ width: '100%' }} scope="col">
                    Product name
                  </th>
                  <th scope="col" className="pt">
                    Colour
                  </th>
                  <th scope="col">Unit Price</th>
                  <th scope="col">Discount</th>
                  <th scope="col">Qty</th>
                  <th scope="col">Gross Amount</th>
                  <th scope="col">Tax Rate</th>
                  <th scope="col">Tax Type</th>
                  <th scope="col">Net Amount</th>
                  <th scope="col">Tax Amount</th>
                  <th scope="col">Total Amount</th>
                </tr>
              </thead>
              <tbody>
                {item?.items?.map((elem, index) => {
                  console.log(elem);
                  let net = (parseFloat(elem.price) / 1.18).toFixed(2);
                  let tax = (parseFloat(elem.price) - parseFloat(net)).toFixed(
                    2
                  );
                  console.log(tax);
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{elem.name}</td>
                      <td>{elem.vname}</td>
                      <td>{elem.price}</td>
                      <td>0</td>
                      <td>{elem.qty}</td>
                      <td>{elem.price}</td>

                      <td>18%</td>
                      <td>GST</td>
                      <td>{net}</td>
                      <td>{tax}</td>

                      <td>{parseFloat(elem.price) * parseInt(elem.qty)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="text-end mt-3">
              <h6>
                <b>Total :</b> ₹{item?.Amount}
                .00
              </h6>
            </div>
          </section>
          <section className="mt-5 mb-3 ms-2 me-2">
            <div className="row border">
              <div className="col border">Payment ID :</div>
              <div className="col border">Date & Time :</div>
              <div className="col border">Invoice Value :</div>
              <div className="col border">Payment Mode :</div>
            </div>
            <div className="row border">
              <div className="col border">{item.Payment_Id}</div>
              <div className="col border">
                {moment(item.PaymentDate).format('MMMM Do YYYY, h:mm:ss a')}
              </div>
              <div className="col border">
                ₹{item?.Amount}
                .00
              </div>
              <div className="col border">
                {/* {{strtoupper($arr['ModeOfPayment'])}} */}N/A-
              </div>
            </div>
          </section>
        </div>
        <p className="float-end me-3">
          ( This is a computer generated Invoice and needs no signature. )
        </p>
      </section>
    </div>
  );
};
export const Loading = () => {
  return (
    <>
      <div id="pulse">
        <div className="pulse">
          <div className="spinner-box">
            <div className="pulse-container">
              <div className="pulse-bubble pulse-bubble-1"></div>
              <div className="pulse-bubble pulse-bubble-2"></div>
              <div className="pulse-bubble pulse-bubble-3"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const Linkbroke = () => {
  return (
    <div id="linkbroken">
      <div className="bodyof404">
        <svg
          width="380px"
          height="500px"
          viewBox="0 0 837 1045"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlnsSketch="http://www.bohemiancoding.com/sketch/ns"
        >
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
            sketchType="MSPage"
          >
            <path
              d="M353,9 L626.664028,170 L626.664028,487 L353,642 L79.3359724,487 L79.3359724,170 L353,9 Z"
              id="Polygon-1"
              stroke="#007FB2"
              stroke-width="6"
              sketchType="MSShapeGroup"
            ></path>
            <path
              d="M78.5,529 L147,569.186414 L147,648.311216 L78.5,687 L10,648.311216 L10,569.186414 L78.5,529 Z"
              id="Polygon-2"
              stroke="#EF4A5B"
              stroke-width="6"
              sketchType="MSShapeGroup"
            ></path>
            <path
              d="M773,186 L827,217.538705 L827,279.636651 L773,310 L719,279.636651 L719,217.538705 L773,186 Z"
              id="Polygon-3"
              stroke="#795D9C"
              stroke-width="6"
              sketchType="MSShapeGroup"
            ></path>
            <path
              d="M639,529 L773,607.846761 L773,763.091627 L639,839 L505,763.091627 L505,607.846761 L639,529 Z"
              id="Polygon-4"
              stroke="#F2773F"
              stroke-width="6"
              sketchType="MSShapeGroup"
            ></path>
            <path
              d="M281,801 L383,861.025276 L383,979.21169 L281,1037 L179,979.21169 L179,861.025276 L281,801 Z"
              id="Polygon-5"
              stroke="#36B455"
              stroke-width="6"
              sketchType="MSShapeGroup"
            ></path>
          </g>
        </svg>
        <div class="message-box">
          <h1 className="">404</h1>
          <p className="">Page not found</p>
          <div class="buttons-con">
            <div class="action-link-wrap ">
              {/* <a onclick="history.back(-1)" class="link-button link-back-button">Go Back</a> */}
              <Link
                to="/policies"
                class="link-button"
                style={{ backgroundColor: '#265d5d' }}
              >
                Go to Home Page
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
