import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useContext } from 'react';
import { addTocart } from '../../../../redux/viparChic/cartSlice';
import { BucketContext } from '../vipardubclassic/Vipardubclassic';

export const Viparshow = (props) => {
  console.log(props)
  let onChange = props.onChange
  const [dub, setdub] = useState()
  useEffect(() => {
  console.log(dub)
  }, [dub])


  

  return (
    <>
      <div className="row">
        <div className="col-md-4 col-sm-4 col-4">
          <img
            src={`/img/${props.value.varentCode}.jpg`}
            alt=""
            height="80px"
            width="80px"
          />
        </div>
        <div className="col-md-4 col-sm-4 col-4 mt-4">
          {props.value.varentName}
        </div>

        <div className="col-md-4 col-sm-4 col-4 mt-4">
          <input
            className="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault2"
            value={props.value._id}
            onChange={(e)=>onChange(e.target.value,props.value.productId)}
          />
        </div>
      </div>
    </>
  )
}




export const Cappode = ({ value, label, optional,onChange }) => {
  const [cap, setcap] = useState()
  useEffect(() => {
   console.log(cap)
  }, [cap])
  


  return (
    <>
      <div className="row">
        <div className="col-md-6 col-sm-6 col-6">{label}</div>
        <div className="col-md-6 col-sm-6 col-6 ">
           
          <select
            className="form-select form-select-lg mb-3"
            aria-label=".form-select-lg example"
            onChange={(e)=>onChange(e.target.value,value[0].productId)}
          >
            {value?.map((elem)=>{
              return(
                <option value={elem._id}>{elem.varentName}</option>
              )
            })}
          
          </select>
        </div>
      </div>


      
 
    </>
  );
};




