import React from 'react';
import { Link } from 'react-router-dom';
import { BiTimeFive } from 'react-icons/bi';
import { BsGlobe } from 'react-icons/bs';
import { BsCalendarPlus } from 'react-icons/bs';
import { BsCalendar4Range } from 'react-icons/bs';
import { SiIndeed } from 'react-icons/si';
import { BsFillPersonFill } from 'react-icons/bs';
import './Adminpannel.css';
import { Dashbord } from '../../components/vipar-chicAdmin/Dashbord';
import { Route, Routes } from 'react-router-dom';

const Adminpannel = () => {
  return (
    <>
      <div
        style={{ height: '100vh', width: '200px', display: 'inline-block' }}
        className="bg-dark"
      >
        {/* <nav className="navbar top-navbar navbar-expand-md navbar-dark">
          <div className="navbar-header" data-logobg="skin6">
            <Link className="navbar-brand">
              <b className="logo-icon"></b>

              <span className="logo-text">
                <img
                  src="/img/logoad.jpg"
                  height="40px"
                  width="200px"
                  alt="homepage"
                />
              </span>
            </Link>

            <Link className="nav-toggler text-dark d-block d-md-none">
              <i className="ti-menu ti-close"></i>
            </Link>
          </div>

        //   {/* <div className="navbar-collapse collapse" id="navbarSupportedContent" data-navbarbg="skin5">
        //             <ul className="navbar-nav d-none d-md-block d-lg-none ul">
        //                 <li className="nav-item li">
        //                     <a className="nav-toggler nav-link waves-effect waves-light text-white"
        //                         href="javascript:void(0)"><i className="ti-menu ti-close"></i></a>
        //                 </li>
        //             </ul>
            
           
        //         </div> */}
        {/* </nav> */}

        <nav
          id="sidebarnav"
          className="pt-3 ul"
          style={{ listStyleType: 'none' }}
        >
          <ul className="p-0">
            <li className="sidebar-item py-3 li">
              <Link
                className="sidebar-link waves-effect waves-dark sidebar-link"
                style={{ textDecoration: 'none' }}
                to={'/dash'}
                aria-expanded="false"
              >
                <BiTimeFive size="17px" style={{ color: 'black' }}></BiTimeFive>
                <span
                  className="hide-menu text-dark ps-2"
                  style={{ fontSize: '17px' }}
                >
                  Dashboard
                </span>
              </Link>
            </li>
            <li className="sidebar-item py-3 li">
              <Link
                className="sidebar-link waves-effect waves-dark sidebar-link"
                style={{ textDecoration: 'none' }}
                aria-expanded="false"
                to={'/coupen'}
              >
                <BsGlobe size="14px" style={{ color: 'black' }}></BsGlobe>
                <span
                  className="hide-menu  text-dark ps-2"
                  style={{ fontSize: '17px' }}
                >
                  Genarate Coupon
                </span>
              </Link>
            </li>
            <li className="sidebar-item py-3 li">
              <Link
                className="sidebar-link waves-effect waves-dark sidebar-link"
                style={{ textDecoration: 'none' }}
                aria-expanded="false"
              >
                <BsCalendarPlus
                  size="14px"
                  style={{ color: 'black' }}
                ></BsCalendarPlus>
                <span
                  className="hide-menu  text-dark ps-2"
                  style={{ fontSize: '17px' }}
                >
                  New order
                </span>
              </Link>
            </li>
            <li className="sidebar-item py-3 li">
              <Link
                className="sidebar-link waves-effect waves-dark sidebar-link"
                style={{ textDecoration: 'none' }}
                aria-expanded="false"
              >
                <BsCalendar4Range
                  size="14px"
                  style={{ color: 'black' }}
                ></BsCalendar4Range>
                <span
                  className="hide-menu  text-dark ps-2"
                  style={{ fontSize: '17px' }}
                >
                  Confirm order
                </span>
              </Link>
            </li>
            <li className="sidebar-item py-3 li">
              <Link
                className="sidebar-link waves-effect waves-dark sidebar-link"
                style={{ textDecoration: 'none' }}
                aria-expanded="false"
              >
                <SiIndeed size="17px" style={{ color: 'black' }}></SiIndeed>
                <span
                  className="hide-menu  text-dark ps-2"
                  style={{ fontSize: '17px' }}
                >
                  Cancel order
                </span>
              </Link>
            </li>
            <li className="sidebar-item py-3 li">
              <Link
                className="sidebar-link waves-effect waves-dark sidebar-link"
                style={{ textDecoration: 'none' }}
                aria-expanded="false"
              >
                <BiTimeFive size="17px" style={{ color: 'black' }}></BiTimeFive>
                <span
                  className="hide-menu  text-dark ps-2"
                  style={{ fontSize: '17px' }}
                >
                  Reviews
                </span>
              </Link>
            </li>
            <li className="sidebar-item py-3 li">
              <Link
                className="sidebar-link waves-effect waves-dark sidebar-link"
                style={{ textDecoration: 'none' }}
                aria-expanded="false"
              >
                <BsFillPersonFill
                  size="17px"
                  style={{ color: 'black' }}
                ></BsFillPersonFill>
                <span
                  className="hide-menu  text-dark ps-2"
                  style={{ fontSize: '17px' }}
                >
                  connect
                </span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <div style={{ display: 'inline-block', position: 'absolute' }}>
        <div>
          <Routes>
            <Route element={<Dashbord />} path="/dash" />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default Adminpannel;
