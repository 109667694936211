import React from 'react';

export const Accesspod = ({ value, label, onChange }) => {
  console.log(value);
  return (
    <>
      <div>
        <label>{label}</label>
        <select
          className="form-select form-select-lg mb-3"
          aria-label=".form-select-lg example"
          onChange={(e) => onChange(e.target.value, value[0].productId)}
        >
          <option selected></option>

          {value?.map((elem) => {
            console.log(elem);
            return <option value={elem._id}>{elem.varentName}</option>;
          })}
        </select>
      </div>
    </>
  );
};
