import React from 'react';
import { Link } from 'react-router-dom';
import { Logocenter } from '../Logocenter';

export const Navviparcruise = () => {
  return (
    <>
      <nav
        className="navbar navbar-expand navbar-light shadow"
        style={{ fontFamily: 'FootlightMTLight', display: 'block' }}
      >
        <div
          className=" px-4  w-100"
          style={{
            display: 'block',
            height: '122px',
            backgroundColor: 'rgb(1,32,96,1)',
          }}
        >
          <div className="text-light">
            <div className="d-flex justify-content-between">
              <div style={{ marginTop: '20px' }}>
                <Link
                  class="navbar-brand text-success logo align-self-center"
                  to="/vipar-cruise"
                >
                  {/* <img
                    className=""
                    src="/img/realiconhhh.png"
                    alt=""
                    height="80px"
                    width="80px"
                  /> */}
                  <div style={{ width: '80px', height: '80px' }}>
                    <Logocenter link={'/img/realiconhhh.png'} />
                  </div>
                </Link>
              </div>

              <div className="xn">
                {/* <span className="invisible">
                  Innovative&nbsp;Utilitarian&nbsp;Products
                </span> */}
                <div className="pt-2">
                  <div className="mt-2  ">
                    <span
                      className="vipar transform "
                      style={{
                        fontSize: '22px',
                        color: '#99d050',
                        paddingLeft: '5px',
                      }}
                    >
                      Vipar
                    </span>
                    <span
                      className="ps-1 text-light transform"
                      style={{
                        fontSize: '20px',
                        fontFamily: 'maiandra',
                      }}
                    >
                      &nbsp;CRUISE
                    </span>
                  </div>
                  <div className=" " style={{ height: '13px' }}></div>
                  <div className="mt-3">
                    {' '}
                    <span
                      className="  text-light   btnsss maiandra ps-4"
                      style={{
                        fontSize: '16px',
                        textTransform: 'none',
                      }}
                    >
                      Mobility&nbsp;for&nbsp;All.
                    </span>
                  </div>
                </div>
              </div>
              <div className="invisible">
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container-fluid mt-2 w-100"
          style={{ display: 'block' }}
        >
          <div className=" d-flex justify-content-center d-md-block  ">
            <div className="r" id="templatemo_main_nav">
              <div id="navnew" className="flex-fill">
                <div
                  className="d-block d-md-none"
                  style={{ width: '100vw', overflow: 'hidden' }}
                >
                  {/* {demo} */}
                  <ul
                    className=" navbar-nav d-flex invisible  justify-content-center mx-auto ms-0"
                    style={{ border: 'none' }}
                  >
                    <li className="nav-item ">
                      <Link to="" className="nav-link cardtextt aa">
                        PROchure
                      </Link>
                    </li>
                    <li className="nav-item invisible ps-3">
                      <Link to="" className="nav-link cardtextt aa ">
                        GROCERY
                      </Link>
                    </li>
                    <li className="nav-item invisible">
                      <Link to="" className="nav-link cardtextt aa">
                        HEALTH&nbsp;CHECK
                      </Link>
                    </li>
                    {/* <li className="nav-item invisible">
                      <Link
                        to=""
                        className="nav-icon position-relative text-decoration-none nav-link     "
                      >
                        <span className="invisible">.</span>
                        <RiFileTextFill size={22} />
                      </Link>
                    </li> */}
                  </ul>
                  {/* <ul
                    className=" navbar-nav d-flex justify-content-center mx-auto ms-0 ssviparc"
                    style={{ border: 'none', fontSize: '15px' }}
                  >
                    <li className="nav-item invisible">
                      <Link to="" className="ssviparc nav-link cardtextt">
                        SERVICE&nbsp;POLICIES
                      </Link>
                    </li>
                    <li className="nav-item invisible">
                      <Link to="" className="ssviparc nav-link cardtextt">
                        &nbsp;HELP&nbsp;DESK&nbsp;
                      </Link>
                    </li>
                    <li className="nav-item invisible">
                      <Link to="" className="ssviparc nav-link cardtextt  aa ">
                        GET&nbsp;IN&nbsp;TOUCH
                      </Link>
                    </li>
                  </ul> */}
                </div>
                <div className="d-none d-md-block ">
                  <div id="nsknfkjdsnfksjn">
                    {/* <ul
                      className="  d-flex ps-0  justify-content-center mx-auto ms-0"
                      style={{ border: 'none' }}
                    >
                      <li className="nav-item ">
                        <Link to="" className={`nav-link cardtextt aa `}>
                          &nbsp;CART &nbsp;
                        </Link>
                      </li>
                      <li className="nav-item ">
                        <Link to="" className={`nav-link cardtextt aa `}>
                          &nbsp;CARE-A-VAN &nbsp;
                        </Link>
                      </li>
                      <li className="nav-item ">
                        <Link to="" className={`nav-link cardtextt aa `}>
                          &nbsp;CRUISE &nbsp;
                        </Link>
                      </li>
                      <li className="nav-item ">
                        <Link to="" className={`nav-link cardtextt aa `}>
                          &nbsp;CRITTER CAB &nbsp;
                        </Link>
                      </li>
                    </ul> */}
                    {/* {hlo} */}
                    <ul
                      className="   mx-auto mb-0 ms-0"
                      style={{ border: 'none' }}
                    >
                      <li className="nav-item invisible">
                        <Link to="" className={`nav-link cardtextt aa `}>
                          PROchure
                        </Link>
                      </li>
                      <li className="nav-item invisible">
                        <Link to="" className={`nav-link cardtextt aa `}>
                          GROCERY
                        </Link>
                      </li>
                      <li className="nav-item invisible">
                        <Link to="" className={`nav-link cardtextt aa `}>
                          HEALTH&nbsp;CHECK
                        </Link>
                      </li>
                      <li className="nav-item invisible"></li>
                      <li className="nav-item invisible">
                        <Link to="" className={`nav-link cardtextt aa `}>
                          SERVICE&nbsp;POLICIES
                        </Link>
                      </li>
                      <li className="nav-item invisible">
                        <Link to="" className={`nav-link cardtextt aa `}>
                          &nbsp;HELP&nbsp;DESK&nbsp;
                        </Link>
                      </li>
                      <li className="nav-item invisible">
                        <Link to="" className={`nav-link cardtextt aa `}>
                          GET&nbsp;IN&nbsp;TOUCH
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
