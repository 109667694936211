import axios from 'axios';
import React, { useEffect, useReducer, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Linkbroken } from '../vipar-chicAdmin/Linkbroken';
import Userloading from '../vipar-chicAdmin/Userloading';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export const Sub = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const reducer = (state, action) => {
    switch (action.type) {
      case 'loading':
        return { component: <Userloading />, type: action.type };
      case 'cancel':
        return { component: <Cancel />, type: action.type };
      case 'linkbroken':
        return { component: <Linkbroken />, type: action.type };
    }
  };
  const [state, dispatch] = useReducer(reducer, {
    component: <Userloading />,
    type: 'loading',
  });
  const [params, setParams] = useSearchParams();
  let order = params.get('order');
  console.log(order);
  useEffect(() => {
    if (order.length <= 0) {
      dispatch({ type: 'linkbroken' });
    } else {
      axios
        .post('/public/cancel?id=' + order)
        .then((req, res) => {
          // console.log(first)
          dispatch({ type: 'cancel' });
        })
        .catch((err) => {
          dispatch({ type: 'linkbroken' });
          console.log(err);
        });
    }
  }, []);
  return <div>{state.component}</div>;
};

export const Cancel = () => {
  const [params, setParams] = useSearchParams();
  let order = params.get('order');
  const submit = () => {
    axios
      .post('/public/cancelstatus?id=' + order)
      .then((res) => {
        console.log(res.order);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: '100vh', backgroundColor: '#1d2630' }}
    >
      <div>
        <div className="w-100" style={{ display: 'block' }}>
          <span
            className="h1"
            style={{
              fontFamily: 'FootlightMTProLight',
              color: 'rgb(153,208,80)',
            }}
          >
            Are you sure to cancel this order
          </span>
        </div>
        <br />
        <div className="w-100 d-flex justify-content-center align-items-center">
          <Button
            style={{ backgroundColor: 'rgb(153,208,80)' }}
            className="btn btn-lg"
            type="button"
            onClick={(e) => {
              submit();
            }}
          >
            cancel
          </Button>
        </div>
      </div>
    </div>
  );
};
