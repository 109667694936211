import React from 'react';
import Carosuel from '../components/Vipar-chic/Carosuel/Carosuel';
import { Namsthe } from '../App';

function ViparChicHome() {
  return (
    <>
      <Carosuel />
      <Namsthe />
    </>
  );
}

export default ViparChicHome;
