import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { notify } from '../../../App';
import moment from 'moment';

export const Stafflist = () => {
  const [show, setShow] = useState(false);
  const [view, setView] = useState({
    Name: '',
    Role: '',

    Email: '',
    Dateofbirth: '',
    Phone: '',
    Gender: '',
    Address: '',
  });

  useEffect(() => {
    console.log(view);
  }, [view]);

  const [Validate, setValidate] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (params) => setShow(true);
  const columns = [
    // { field: 'id', headerName: 'No', width: 50 },
    {
      field: 'Name',
      headerName: 'Name',
      width: 150,
      editable: true,
    },
    {
      field: 'Role',
      headerName: 'Role',
      width: 150,
      editable: true,
    },

    {
      field: 'Details',
      headerName: 'Details',
      sortable: false,
      width: 90,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <button
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#detailsModal"
          onClick={(e) => {
            console.log(params.row);
            setView(params.row);
          }}
        >
          Details
        </button>
      ),
    },

    {
      field: 'Edit',
      headerName: 'Edit',
      sortable: false,
      width: 90,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <button
          className="btn btn-success"
          // data-bs-toggle="modal"
          // data-bs-target={"#editmodal" + params.row.ind}
          onClick={(e) => {
            console.log(params.row);
            //   setopperins(params.row);
            // setIndex(ind);
            setView(params.row);
            handleShow();
          }}
        >
          Edit
        </button>
      ),
    },
    {
      field: 'Delete',
      headerName: 'Delete',
      sortable: false,
      width: 90,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <button
          className="btn btn-danger"
          data-bs-toggle="modal"
          data-bs-target={'#exampleModal' + params.row.ind}
          onClick={(e) => {
            console.log(params.row.ind);
          }}
        >
          Delete
        </button>
      ),
    },
  ];

  const tableStyles = {
    backgroundColor: '#f0f0f0',
  };
  const [row, setRow] = useState([]);
  useEffect(() => {
    axios
      .post('/chum/chumdriverregistershow')
      .then((res) => {
        console.log(res.data);
        const newState = res.data
          .map((e, index) => {
            console.log(e);
            return {
              ...e,
              id: index + 1,
              ind: index,
            };
          })
          .filter((e) => e !== undefined);
        console.log(newState);
        setRow(newState);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    console.log(view);
  }, [view]);

  const submit = () => {
    axios
      .post('/chum/chumuserupdate', view)
      .then((res) => {
        console.log();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deletee = (id, ind) => {
    console.log(id);
    console.log(ind);
    axios
      .post('/chum/chumuserdelete', { id, ind })
      .then((res) => {
        console.log(res.data);
        let temp = [...row];
        temp.slice(ind, 1);
        setRow([...temp]);
        notify('Successfully Deleted', true);

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="page-breadcrumb ">
        <div
          className="row align-items-center text-white"
          // style={{ backgroundColor: 'black' }}
        >
          <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
            <h4 className="page-title text-white">Staff List</h4>
          </div>
          <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
            <div className="d-md-flex">
              <ol className="breadcrumb ms-auto"></ol>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          height: 400,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <DataGrid
          rows={row}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          style={tableStyles}
          className="mt-5 pt-3 sswewe"
        />
      </div>

      {/* details modal */}

      <div
        class="modal fade"
        id="detailsModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content ">
            <div class="modal-header">
              <h5 class="modal-title px-3" id="exampleModalLabel">
                Details
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="px-3">
                <div className="">Role : {view?.Role}</div>

                <div className="">Name : {view?.Name}</div>
                <div className="">
                  DOB :{moment(view?.Dateofbirth).format('Do MMMM YYYY')}
                </div>
                <div className="">Gender : {view?.Gender}</div>
                <div className="">Address : {view?.Address}</div>

                <div className="">Email : {view?.Email}</div>
                <div className="">Phone Number : {view?.Phone}</div>

                {view?.Image?.map((elem) => {
                  console.log(elem);
                  return (
                    <button className="btn btn-sm btn-primary my-2">
                      <a
                        href={elem?.link}
                        target="_blank"
                        style={{ textDecoration: 'none', color: 'white' }}
                        download
                      >
                        Download Identity
                      </a>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* details modal */}

      {/* edit modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <form
          onSubmit={(e) => submit(e)}
          novalidate
          className={Validate ? 'was-validated' : ''}
        >
          <Modal.Body>
            <form>
              {' '}
              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="typeEmailX-22">
                  Role
                </label>
                <input
                  required
                  type="text"
                  name="name"
                  id="typeEmailX-22"
                  className="form-control form-control-lg"
                  fdprocessedid="ydop066"
                  value={view?.Name}
                  onChange={(e) => setView({ ...view, Role: e.target.value })}
                />
                <div class="invalid-feedback">Please enter Role</div>
              </div>
              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="typeEmailX-22">
                  Name
                </label>
                <input
                  required
                  type="text"
                  name="name"
                  id="typeEmailX-22"
                  className="form-control form-control-lg"
                  fdprocessedid="ydop066"
                  value={view?.Name}
                  onChange={(e) => setView({ ...view, Name: e.target.value })}
                />
                <div class="invalid-feedback">Please enter the Name</div>
              </div>
              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="typePhoneX-2">
                  Gender
                </label>
                <div>
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    required
                    value="male"
                    checked={view?.Gender === 'male' ? true : false}
                    onChange={(e) => {
                      setView({ ...view, Gender: e.target.value });
                    }}
                  />
                  <label class="form-check-label ms-2" for="flexRadioDefault1">
                    Male
                  </label>

                  <input
                    class="form-check-input ms-4"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    value="female"
                    checked={view?.Gender === 'female' ? true : false}
                    onChange={(e) => {
                      setView({ ...view, Gender: e.target.value });
                    }}
                    required
                  />
                  <label class="form-check-label ms-2" for="flexRadioDefault2">
                    Female
                  </label>
                </div>
                <div class="invalid-feedback">Please select your Gender</div>
              </div>
              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="typeEmailX-22">
                  Date Of Birth
                </label>
                <input
                  required
                  type=""
                  name="dob"
                  id="typeEmailX-22"
                  className="form-control form-control-lg"
                  fdprocessedid="ydop066"
                  value={view?.Dateofbirth}
                  onChange={(e) =>
                    setView({ ...view, Dateofbirth: e.target.value })
                  }
                />
                <div class="invalid-feedback">
                  Please select your date of birth
                </div>
              </div>
              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="typeEmailX-22">
                  Address
                </label>
                <textarea
                  className="form-control"
                  value={view?.Address}
                  rows={3}
                  onChange={(e) =>
                    setView({ ...view, Address: e.target.value })
                  }
                  required
                ></textarea>
                <div class="invalid-feedback">Please enter your address</div>
              </div>
              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="typeEmailX-2">
                  Email
                </label>
                <input
                  required
                  type="email"
                  name="email"
                  id="typeEmailX-2"
                  className="form-control form-control-lg"
                  fdprocessedid="ydop06"
                  value={view?.Email}
                  onChange={(e) => {
                    setView({ ...view, Email: e.target.value });
                  }}
                />
                <div class="invalid-feedback">Please enter the Email</div>
              </div>
              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="typePhoneX-2">
                  Phone Number
                </label>
                <input
                  required
                  type="number"
                  name="phoneno"
                  id="typephonelX-2"
                  className="form-control form-control-lg"
                  fdprocessedid="ydop06"
                  value={view?.Phone}
                  onChange={(e) => {
                    setView({ ...view, Phone: e.target.value });
                  }}
                />
                <div class="invalid-feedback">
                  Please enter the Phone Number
                </div>
              </div>
              {/* <p style={{ color: 'red' }}>{err}</p> */}
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="primary"
              onClick={(e) => {
                setValidate(true);
              }}
            >
              Update
            </Button>{' '}
          </Modal.Footer>
        </form>
      </Modal>
      {/* edit modal */}

      {/* delete modal */}
      {row?.map((elem, index) => {
        console.log(elem);
        return (
          <>
            <div
              class="modal fade"
              id={'exampleModal' + index}
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="btn-close"
                      id={'btnclose'}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body text-danger">
                    Are you sure you want to delete ?
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={(e) => {
                        deletee(elem._id, elem.ind);
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
      {/* delete modal */}
    </>
  );
};
