import React, { useState } from 'react';
import {
  Sidenavbarchum,
  SubadminNavbar,
  Subadminnavbarchum,
  Topnavbarchum,
} from '../../Adminnavbar/Sideandtopnavbar';
import { Route, Routes } from 'react-router-dom';
import { Country } from '../Country';
import { Stafflist } from '../Stafflist';
import { Staffregistration } from '../Staffregistration';
import { Countryview } from '../Countryview';

export const Subadminchumnavbar = () => {
  const [open, setopen] = useState(window.innerWidth >= 991 ? false : true);
  return (
    <div className="container-fluid position-relative d-flex p-0 ">
      <div
        className={`${open ? 'open' : ''} sidebar pe-4 pb-3`}
        style={{ backgroundColor: 'white' }}
      >
        <SubadminNavbar />
      </div>
      <div
        className={`${open ? 'open' : ''} content`}
        style={{ backgroundColor: 'rgb(1, 32, 96)' }}
      >
        <Subadminnavbarchum setopen={setopen} />
        <div style={{ paddingTop: '70px' }} className="container-fluid px-4 ">
          <Routes>
            <Route element={<Country />} path="/country" />
            <Route element={<Countryview />} path="/countryview" />

            <Route element={<Staffregistration />} path="/staffregistration" />

            <Route element={<Stafflist />} path="/stafflist" />
          </Routes>
        </div>
      </div>
    </div>
  );
};
