import React, { useEffect, useState } from 'react';
import './Contentcart.css';
import { FaCaretDown, FaRegCheckSquare } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';

import { useRef } from 'react';
import '../../../css/cart.css';
import axios from 'axios';
import { Cartaddinglist } from '../Cartaddinglist/Cartaddinglist';
import { Payment } from '../../RazorPay/razorPay';
import cartSlice, { initialFromDB } from '../../../redux/viparChic/cartSlice';
import { keyGen } from '../../../App';

function Contentcart({ Items }) {
  const [errgift, setErrgift] = useState();
  const [submit, setSubmit] = useState(false);
  const [valstatus, setValstatus] = useState(false);
  const [err, setErr] = useState();
  const [gift, setGift] = useState({
    Occassion: '',
    GiftcardMessage: '',
    Specificdeliverydate: '',
    choclates: [],
  });

  const [shippingAddress, setShippingAddress] = useState({
    firstname: '',
    lastname: '',
    address: '',
    state: '',
    city: '',
    pincode: '',
    mobileno: '',
    email: '',
  });

  const [billingAddress, setBillingAddress] = useState({
    firstname: '',
    lastname: '',
    address: '',
    state: '',
    city: '',
    pincode: '',
    gstin: '',
    mobileno: '',
    email: '',
    Gst: '',
  });

  const [coupenStatus, setCoupenStatus] = useState({
    status: '',
    text: '',
  });
  useEffect(() => {
    console.log(setCoupenStatus);
  }, []);

  const [applyCode, setApplyCode] = useState('');
  useEffect(() => {
    console.log(gift);
    return () => {};
  }, [gift]);

  let cart = useSelector((state) => state.cart.value);
  const [Total, setTotal] = useState(0);
  useEffect(() => {
    console.log(cart);
    let total = 0;
    cart.forEach((element) => {
      console.log(total);
      total = total + element.qty * element.productId.price;
      console.log(element.qty);
      console.log(element.productId.price);
    });
    console.log(total);
    setTotal(total);
    return () => {};
  }, [cart]);

  const choco = (e) => {
    setGift((prev) => ({
      ...gift,
      choclates: e.target.checked
        ? [...prev.choclates, e.target.value]
        : prev.choclates.filter((elem) => elem != e.target.choclates),
    }));
  };
  useEffect(() => {
    if (submit) {
      val();
    }
    return () => {};
  }, [shippingAddress, billingAddress, gift]);

  const validate = (data) => {
    if (data.firstname.length === 0) {
      return setErr('Enter the first name ');
    }
    if (data.firstname.length < 3) {
      return setErr('enter a valid name ');
    }
    if (data.lastname.length === 0) {
      return setErr('Enter the last name ');
    }

    if (data.address.length === 0) {
      return setErr('Enter the address ');
    }
    if (data.state.length === 0) {
      return setErr('Enter the state');
    }
    if (data.city.length === 0) {
      return setErr('Enter the city');
    }
    if (data.pincode.length === 0) {
      return setErr('Enter the pincode ');
    }
    if (data.pincode.length < 6 || data.pincode.length > 6) {
      return setErr('Enter the valid pincode');
    }
    if (data.mobileno.length === 0) {
      return setErr('Enter the mobile number ');
    }
    if (data.mobileno.length < 10 || data.mobileno.length > 10) {
      return setErr('Enter the valid mobile number');
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email)) {
      return setErr('Enter the valid email');
    }
    setErr('');
    return true;
  };
  const val = () => {
    setSubmit(true);
    if (validate(shippingAddress) === true) {
      if (validate(billingAddress) === true) {
        setValstatus(true);
        console.log('hiii');
      }
    }
  };

  const validategift = (data) => {
    console.log(data);
    if (data.Occassion.length === 0) {
      return setErrgift('Enter the occasion ');
    }
    if (data.GiftcardMessage.length === 0) {
      return setErrgift('Enter the message ');
    }
    if (data.choclates.length === 0) {
      return setErrgift(' Atleast select one choclate ');
    }
    setErr('');
    try {
      document.getElementById('closevcandy').click();
    } catch (err) {
      console.log(err);
    }
    return true;
  };

  const [Discount, setDiscount] = useState(0);
  const CouponRef = useRef();
  const applyCodefun = () => {
    let applyCode = CouponRef.current.value;
    setApplyCode(applyCode);
    setDiscount(0);
    axios
      .post('/public/applycode', { applyCode })
      .then((res) => {
        console.log(res);
        if (res.status === 201) {
          setCoupenStatus({ text: res.data, status: false });
        } else {
          let data = res.data;
          setDiscount(data.Amount);
          return setCoupenStatus({ text: 'coupon applied', status: true });
        }
      })
      .catch((err) => {
        setCoupenStatus({ text: err.response.data, status: false });
        console.log(err.response.data);
      });
  };

  const giftitnow = (e) => {
    if (e.target.checked) {
      document.getElementById('giftbtn').click();
      setShippingAddress((prev) => ({ ...prev, gift: true }));
      // details.gift = 'true';
    } else {
      // setGift({
      //   Occassion: '',
      //   GiftcardMessage: '',
      //   Specificdeliverydate: '',
      //   choclates: [],
      // });
      setShippingAddress((prev) => ({ ...prev, gift: false }));
      // details.gift = 'false';
    }
  };

  useEffect(() => {
    console.log(shippingAddress.gift);
    return () => {};
  }, [shippingAddress]);

  const alertclosebuttonref = useRef();
  const alertclose = () => {
    try {
      setTimeout(function () {
        alertclosebuttonref.current.click();
      }, 5000);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    alertclose();
    return () => {};
  }, []);

  let dispatch = useDispatch();

  useEffect(() => {
    axios
      .get('/public/shopping')
      .then((res) => {
        console.log(res.data);
        if (res.data.Items) {
          dispatch(
            initialFromDB({
              value: res.data.Items,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {};
  }, []);

  return (
    <>
      <hr
        className="mt-0"
        style={{
          color: 'rgb(1, 32, 96)',
          opacity: '1',
          borderTop: '3px solid',
        }}
      />
      <div
        className={`${!valstatus ? '' : 'd-none'} container-fluid p-0 `}
        style={{ backgroundcolor: '#e9eef5' }}
      >
        <div className="container-fluid  pt-2">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div
                className="alert my-3 alert-success alert-dismissible fade show"
                role="alert"
              >
                <center>
                  <h4 className="alert-heading">Yayyy !!!</h4>
                  <h4>Good choice</h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    ref={alertclosebuttonref}
                  ></button>
                </center>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>

        <div className="container-fluid   "></div>
        <section className="h-100 h-custom ">
          <div className="container pb-5  h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-12">
                <div
                  className="card card-registration card-registration-2"
                  style={{ borderRadius: '15px' }}
                >
                  <div className="card-body p-0">
                    <div className="row g-0">
                      <div className="col-lg-12">
                        <div className="p-5">
                          <div className="d-flex justify-content-between align-items-center mb-5">
                            <span className="fw-bold mb-0 text-black vipar-heading-shopping">
                              Shopping Bag
                            </span>
                            <span
                              id="nocart"
                              className="mb-0 text-dark"
                              style={{ fontSize: '17px' }}
                            >
                              {Items?.length > 1
                                ? `${Items.length} Items`
                                : `${Items.length} Item`}
                            </span>
                          </div>

                          <hr className="my-4" />
                          {/* @foreach ($items as $item) */}
                          {Items.map((element, index) => {
                            console.log(element);
                            return (
                              <Cartaddinglist
                                _id={element._id}
                                index={index}
                                key={keyGen()}
                              />
                            );
                          })}

                          {/* @endforeach */}
                          <hr className="my-4" />

                          <div className="d-flex justify-content-between mb-2">
                            <span className="text-uppercase fontss">
                              Total price
                            </span>
                            <span
                              id="grandTotal"
                              className="fontss"
                              style={{ fontSize: '21px' }}
                            >
                              ₹ {Total + ' /-'}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between text-success mb-2">
                            <span className="text-uppercase fontss ">
                              Discount
                            </span>
                            <span
                              id="discounted"
                              className="fontss"
                              style={{ fontSize: '21px' }}
                            >
                              ₹ {Discount + ' /-'}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between ">
                            <span className="text-uppercase fontss">
                              Amount to be paid
                            </span>
                            <span id="payamount" className=" fontss">
                              ₹ {Total - Discount + ' /-'}
                            </span>
                          </div>
                          <p className="" style={{ fontSize: '21px' }}>
                            (includes free shipping)
                          </p>
                          {/* <span>
                            {' '}
                            <input
                              onChange={(e) => giftitnow(e)}
                              type="checkbox"
                              className=""
                            />{' '}
                            <span
                              style={{ fontSize: '20px', fontWeight: '100' }}
                            >
                              Are you Gifting this ?
                            </span>
                          </span> */}

                          <a
                            href="#address"
                            className="btn btn-outline-dark btn-block btn-lg text-capitalize mt-2"
                            data-mdb-ripple-color="dark"
                            data-bs-toggle="collapse"
                            //    href="#collapseExample"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                          >
                            Continue
                          </a>
                          <button
                            // onClick="clearCart()"
                            className="btn btn-outline-dark btn-block btn-lg ms-2 text-capitalize mt-2"
                            data-mdb-ripple-color="dark"
                          >
                            Cancel
                          </button>

                          {/* <button
                            type="button"
                            id="giftbtn"
                            className="d-none"
                            data-bs-toggle="modal"
                            data-bs-target="#gift"
                          ></button> */}
                          {/* <!--  --> */}
                          <div
                            className="modal fade"
                            id="gift"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabIndex="-1"
                            aria-labelledby="giftLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <button
                                    className="btn-close"
                                    id="closevcandy"
                                    data-bs-dismiss="modal"
                                  ></button>
                                </div>
                                <div className="modal-body">
                                  <div>
                                    <span
                                      className="modal-title "
                                      id="giftLabel"
                                      style={{ fontSize: '15px' }}
                                    >
                                      👌We will Gift 🎁 Wrap this and send it on
                                      your behalf !!
                                      <br />
                                      As a token of gratitude for choosing one
                                      of our products to gift to your loved
                                      ones, you can select any of the sweets
                                      mentioned below and we shall add that to
                                      your gift absolutely free of cost !
                                    </span>
                                  </div>
                                  <div className="mb-3 mt-3">
                                    <label
                                      htmlFor="Occassion"
                                      className="form-label "
                                      style={{ fontSize: '15px' }}
                                    >
                                      Occassion :
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={gift.Occassion}
                                      // onchange="giftcollect(event)"
                                      onChange={(e) =>
                                        setGift({
                                          ...gift,
                                          Occassion: e.target.value,
                                        })
                                      }
                                      name="Occassion"
                                      id="Occassion"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>

                                  <div className="mb-3">
                                    <label
                                      htmlFor="Message"
                                      className="form-label "
                                      style={{ fontSize: '15px' }}
                                    >
                                      Gift card Message :
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      //   onchange="giftcollect(event)"
                                      name="Message"
                                      id="Message"
                                      aria-describedby="emailHelp"
                                      value={gift.GiftcardMessage}
                                      onChange={(e) =>
                                        setGift({
                                          ...gift,
                                          GiftcardMessage: e.target.value,
                                        })
                                      }
                                    />
                                  </div>

                                  <div className="mb-3">
                                    <label
                                      htmlFor="delivery"
                                      className="form-label "
                                      style={{ fontSize: '15px' }}
                                    >
                                      Specific delivery date :
                                    </label>
                                    {/* <!--{{$d=date('Y-m-d', strtotime('+7 days'))}}--> */}
                                    <input
                                      type="date"
                                      className="form-control"
                                      //   onchange="giftcollect(event)"
                                      name="delivery"
                                      id="delivery"
                                      min="{{$d}}"
                                      aria-describedby="emailHelp"
                                      value={gift.Specificdeliverydate}
                                      onChange={(e) =>
                                        setGift({
                                          ...gift,
                                          Specificdeliverydate: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                  <div className="mb-3  row">
                                    <div className="container">
                                      <label
                                        htmlFor="delivery"
                                        className="form-label "
                                        style={{ fontSize: '15px' }}
                                      >
                                        Chocolates (applicable only on orders
                                        worth ₹ 700/- and above) :
                                      </label>
                                      <p
                                        className=""
                                        style={{ fontSize: '15px' }}
                                      >
                                        kindly note that only a maximum of Rs
                                        100/- worth chocolates (among those
                                        mentioned below) will be added to your
                                        gift.
                                      </p>
                                      <div className="col-12 form-check">
                                        <input
                                          //   onchange="addcandy(event)"
                                          className="form-check-input"
                                          type="checkbox"
                                          value="FerreroRocher"
                                          id="FerreroRocher"
                                          onChange={(e) => choco(e)}
                                        />
                                        <label
                                          className="form-check-label "
                                          htmlFor="FerreroRocher"
                                          style={{ fontSize: '15px' }}
                                        >
                                          Ferrero Rocher
                                        </label>
                                      </div>
                                      <div className="col-12 form-check">
                                        <input
                                          //   onchange="addcandy(event)"
                                          className="form-check-input"
                                          type="checkbox"
                                          value="CadburyDairyMilk"
                                          id="CadburyDairyMilk"
                                          onChange={(e) => choco(e)}
                                        />
                                        <label
                                          className="form-check-label "
                                          htmlFor="CadburyDairyMilk"
                                          style={{ fontSize: '15px' }}
                                        >
                                          Cadbury Dairy Milk
                                        </label>
                                      </div>
                                      <div className="col-12 form-check">
                                        <input
                                          //   onchange="addcandy(event)"
                                          className="form-check-input"
                                          type="checkbox"
                                          value="CadburyCelebrations"
                                          id="CadburyCelebrations"
                                          onChange={(e) => choco(e)}
                                        />
                                        <label
                                          className="form-check-label "
                                          htmlFor="CadburyCelebrations"
                                          style={{ fontSize: '15px' }}
                                        >
                                          Cadbury Celebrations
                                        </label>
                                      </div>
                                      <div className="co-12l form-check">
                                        <input
                                          //   onchange="addcandy(event)"
                                          className="form-check-input"
                                          type="checkbox"
                                          value="CadburyTemptations"
                                          id="CadburyTemptations"
                                          onChange={(e) => choco(e)}
                                        />
                                        <label
                                          className="form-check-label "
                                          htmlFor="CadburyTemptations"
                                          style={{ fontSize: '15px' }}
                                        >
                                          Cadbury Temptations
                                        </label>
                                      </div>
                                      <div className="col-12 form-check">
                                        <input
                                          //   onchange="addcandy(event)"
                                          className="form-check-input"
                                          type="checkbox"
                                          value="CadburyBournville"
                                          id="CadburyBournville"
                                          onChange={(e) => choco(e)}
                                        />
                                        <label
                                          className="form-check-label "
                                          htmlFor="CadburyBournville"
                                          style={{ fontSize: '15px' }}
                                        >
                                          Cadbury Bournville
                                        </label>
                                      </div>
                                      <div className="col-12 form-check">
                                        <input
                                          //   onchange="addcandy(event)"
                                          className="form-check-input"
                                          type="checkbox"
                                          value="Snickers"
                                          id="Snickers"
                                          onChange={(e) => choco(e)}
                                        />
                                        <label
                                          className="form-check-label "
                                          htmlFor="Snickers"
                                          style={{ fontSize: '15px' }}
                                        >
                                          Snickers
                                        </label>
                                      </div>
                                      <div className="col-12 form-check">
                                        <input
                                          //   onchange="addcandy(event)"
                                          className="form-check-input"
                                          type="checkbox"
                                          value="Mars"
                                          id="Mars"
                                          onChange={(e) => choco(e)}
                                        />
                                        <label
                                          className="form-check-label "
                                          htmlFor="Mars"
                                          style={{ fontSize: '15px' }}
                                        >
                                          Mars
                                        </label>
                                      </div>
                                      <div className="col-12 form-check">
                                        <input
                                          //   onchange="addcandy(event)"
                                          className="form-check-input"
                                          type="checkbox"
                                          value="Bounty"
                                          id="Bounty"
                                          onChange={(e) => choco(e)}
                                        />
                                        <label
                                          className="form-check-label "
                                          htmlFor="Bounty"
                                          style={{ fontSize: '15px' }}
                                        >
                                          Bounty
                                        </label>
                                      </div>
                                      <div className="col-12 form-check">
                                        <input
                                          //   onchange="addcandy(event)"
                                          className="form-check-input"
                                          type="checkbox"
                                          value="Galaxy"
                                          id="Galaxy"
                                          onChange={(e) => choco(e)}
                                        />
                                        <label
                                          className="form-check-label "
                                          htmlFor="Galaxy"
                                          style={{ fontSize: '15px' }}
                                        >
                                          Galaxy
                                        </label>
                                      </div>
                                      <div className="col-12 form-check">
                                        <input
                                          //   onchange="addcandy(event)"
                                          className="form-check-input"
                                          type="checkbox"
                                          value="Munch"
                                          id="Munch"
                                          onChange={(e) => choco(e)}
                                        />
                                        <label
                                          className="form-check-label "
                                          htmlFor="Munch"
                                          style={{ fontSize: '15px' }}
                                        >
                                          Munch
                                        </label>
                                      </div>
                                      <div className="col-12 form-check">
                                        <input
                                          //   onchange="addcandy(event)"
                                          className="form-check-input"
                                          type="checkbox"
                                          value="Perk"
                                          id="Perk"
                                          onChange={(e) => choco(e)}
                                        />
                                        <label
                                          className="form-check-label "
                                          htmlFor="Perk"
                                          style={{ fontSize: '15px' }}
                                        >
                                          Perk
                                        </label>
                                      </div>
                                      <div className="col-12 form-check">
                                        <input
                                          //   onchange="addcandy(event)"
                                          className="form-check-input"
                                          type="checkbox"
                                          value="5star"
                                          id="5star"
                                          onChange={(e) => choco(e)}
                                        />
                                        <label
                                          className="form-check-label "
                                          htmlFor="5star"
                                          style={{ fontSize: '15px' }}
                                        >
                                          5 star
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <p className="text-danger">{errgift}</p>
                                </div>
                                <div className="modal-footer">
                                  {/* <!-- <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> --> */}
                                  <button
                                    type="button"
                                    //   onClick="giftit()"
                                    onClick={(e) => validategift(gift)}
                                    className="btn btn-outline-dark "
                                  >
                                    Continue
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* {{-- <a href="#address" className="btn btn-outline-dark btn-block btn-lg" data-mdb-ripple-color="dark" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">Continue</a>
                  <button onClick="clearCart()" className="btn btn-outline-dark btn-block btn-lg"  data-mdb-ripple-color="dark">Cancel</button> --}} */}
                        </div>
                      </div>
                      <div className="collapse" id="address">
                        <div className="card card-body ">
                          <section>
                            <div className="container">
                              <span
                                style={{ fontSize: '40px', fontWeight: '100' }}
                              >
                                Shipping Address
                              </span>
                              <p>Please enter your shipping details.</p>
                              <hr />
                              <div className="form">
                                <div className="fields fields--2">
                                  <label className="field">
                                    <span
                                      className="field__label"
                                      htmlFor="firstname"
                                    >
                                      FIRST NAME *
                                    </span>
                                    <input
                                      className="form-control "
                                      type="text"
                                      id="firstname"
                                      name="fname"
                                      onChange={(e) =>
                                        setShippingAddress({
                                          ...shippingAddress,
                                          firstname: e.target.value,
                                        })
                                      }
                                    />
                                  </label>
                                  <label className="field">
                                    <span
                                      className="field__label"
                                      htmlFor="lastname"
                                    >
                                      LAST NAME *
                                    </span>
                                    <input
                                      className="form-control "
                                      type="text"
                                      id="lastname"
                                      name="lname"
                                      onChange={(e) =>
                                        setShippingAddress({
                                          ...shippingAddress,
                                          lastname: e.target.value,
                                        })
                                      }
                                    />
                                  </label>
                                </div>
                                <label className="field">
                                  <span
                                    className="field__label"
                                    htmlFor="address"
                                  >
                                    ADDRESS *
                                  </span>
                                  <input
                                    className="form-control mb-4 mt-2"
                                    type="text"
                                    id="address"
                                    name="address"
                                    placeholder="Enter your full address"
                                    onChange={(e) =>
                                      setShippingAddress({
                                        ...shippingAddress,
                                        address: e.target.value,
                                      })
                                    }
                                  />
                                </label>

                                <div className="row">
                                  <div className="col-sm-12 col-md-6 col-lg-3">
                                    <label className="field">
                                      <span
                                        className="field__label"
                                        htmlFor="country"
                                      >
                                        COUNTRY *
                                      </span>
                                      <select
                                        className="form-control "
                                        id="country"
                                        onChange={(e) =>
                                          setShippingAddress({
                                            ...shippingAddress,
                                            country: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="india">INDIA</option>
                                      </select>
                                    </label>
                                  </div>
                                  <div className="col-sm-12 col-md-6 col-lg-3">
                                    <label className="field">
                                      <span
                                        className="field__label"
                                        htmlFor="zipcode"
                                      >
                                        STATE *
                                      </span>
                                      <input
                                        className="form-control "
                                        type="text"
                                        id="state"
                                        name="state"
                                        onChange={(e) =>
                                          setShippingAddress({
                                            ...shippingAddress,
                                            state: e.target.value,
                                          })
                                        }
                                      />
                                    </label>
                                  </div>
                                  <div className="col-sm-12 col-md-6 col-lg-3">
                                    <label className="field">
                                      <span
                                        className="field__label"
                                        htmlFor="zipcode"
                                      >
                                        CITY *
                                      </span>
                                      <input
                                        className="form-control "
                                        type="text"
                                        id="city"
                                        name="city"
                                        onChange={(e) =>
                                          setShippingAddress({
                                            ...shippingAddress,
                                            city: e.target.value,
                                          })
                                        }
                                      />
                                    </label>
                                  </div>
                                  <div className="col-sm-12 col-md-6 col-lg-3">
                                    <label className="field">
                                      <span
                                        className="field__label"
                                        htmlFor="zipcode"
                                      >
                                        PIN CODE *
                                      </span>
                                      <input
                                        className="form-control "
                                        type="number"
                                        name="pincode"
                                        onChange={(e) =>
                                          setShippingAddress({
                                            ...shippingAddress,
                                            pincode: e.target.value,
                                          })
                                        }
                                        id="pincode"
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="fields ">
                                      <label className="field">
                                        <span
                                          className="field__label"
                                          htmlFor="firstname"
                                        >
                                          MOBILE NO. *
                                        </span>
                                        <div className="input-group flex-nowrap">
                                          <span
                                            className="input-group-text"
                                            id="addon-wrapping"
                                          >
                                            +91
                                          </span>
                                          <input
                                            className="form-control "
                                            type="number"
                                            id="phno"
                                            name="phno"
                                            onChange={(e) =>
                                              setShippingAddress({
                                                ...shippingAddress,
                                                mobileno: e.target.value,
                                              })
                                            }
                                            aria-label="Username"
                                            aria-describedby="addon-wrapping"
                                          />
                                        </div>
                                        {/* <!--<input className="form-control " type="number" id="phno" name="phno" onchange="sab(event)" />--> */}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-sm-12 col-md-6 col-lg-6">
                                    <label className="field">
                                      <span
                                        className="field__label"
                                        htmlFor="lastname"
                                      >
                                        EMAIL *
                                      </span>
                                      <input
                                        className="form-control "
                                        type="email"
                                        id="email"
                                        name="email"
                                        onChange={(e) =>
                                          setShippingAddress({
                                            ...shippingAddress,
                                            email: e.target.value,
                                          })
                                        }
                                      />
                                    </label>
                                  </div>
                                </div>

                                <hr className="mb-4" />
                              </div>
                              <div className="container">
                                <span
                                  style={{
                                    fontSize: '40px',
                                    fontWeight: '100',
                                  }}
                                >
                                  Billing Address
                                </span>
                                <p>Please enter your billing details.</p>
                                <input
                                  type="checkbox"
                                  className="me-2"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setBillingAddress({ ...shippingAddress });
                                    }
                                  }}
                                />
                                <label>Same as Shipping Address</label>
                                <hr />
                                <div className="fields fields--2">
                                  <label className="field">
                                    <span
                                      className="field__label"
                                      htmlFor="firstname"
                                    >
                                      FIRST NAME
                                    </span>
                                    <input
                                      className="form-control "
                                      type="text"
                                      id="firstname2"
                                      name="fname2"
                                      value={billingAddress.firstname}
                                      onChange={(e) =>
                                        setBillingAddress({
                                          ...billingAddress,
                                          firstname: e.target.value,
                                        })
                                      }
                                    />
                                  </label>
                                  <label className="field">
                                    <span
                                      className="field__label"
                                      htmlFor="lastname"
                                    >
                                      LAST NAME
                                    </span>
                                    <input
                                      className="form-control"
                                      type="text"
                                      id="lastname2"
                                      name="lname2"
                                      value={billingAddress.lastname}
                                      onChange={(e) =>
                                        setBillingAddress({
                                          ...billingAddress,
                                          lastname: e.target.value,
                                        })
                                      }
                                    />
                                  </label>
                                </div>
                                <label className="field">
                                  <span
                                    className="field__label"
                                    htmlFor="address"
                                  >
                                    ADDRESS
                                  </span>
                                  <input
                                    className="form-control mb-4 mt-2 "
                                    type="text"
                                    id="address2"
                                    name="address2"
                                    value={billingAddress.address}
                                    onChange={(e) =>
                                      setBillingAddress({
                                        ...billingAddress,
                                        address: e.target.value,
                                      })
                                    }
                                    placeholder="Enter your full address"
                                  />
                                </label>

                                <div className="row">
                                  <div className="col-sm-12 col-md-6 col-lg-3">
                                    <label className="field">
                                      <span
                                        className="field__label"
                                        htmlFor="country"
                                      >
                                        COUNTRY
                                      </span>
                                      <select
                                        className="form-control "
                                        id="country"
                                        value={billingAddress.country}
                                        onChange={(e) =>
                                          setBillingAddress({
                                            ...billingAddress,
                                            country: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="india">INDIA</option>
                                      </select>
                                    </label>
                                  </div>
                                  <div className="col-sm-12 col-md-6 col-lg-3">
                                    <label className="field">
                                      <span
                                        className="field__label"
                                        htmlFor="zipcode"
                                      >
                                        STATE
                                      </span>
                                      <input
                                        className="form-control "
                                        type="text"
                                        id="state2"
                                        name="state2"
                                        value={billingAddress.state}
                                        onChange={(e) =>
                                          setBillingAddress({
                                            ...billingAddress,
                                            state: e.target.value,
                                          })
                                        }
                                      />
                                    </label>
                                  </div>
                                  <div className="col-sm-12 col-md-6 col-lg-3">
                                    <label className="field">
                                      <span
                                        className="field__label"
                                        htmlFor="zipcode"
                                      >
                                        CITY
                                      </span>
                                      <input
                                        className="form-control"
                                        type="text"
                                        id="city2"
                                        name="city2"
                                        value={billingAddress.city}
                                        onChange={(e) =>
                                          setBillingAddress({
                                            ...billingAddress,
                                            city: e.target.value,
                                          })
                                        }
                                      />
                                    </label>
                                  </div>
                                  <div className="col-sm-12 col-md-6 col-lg-3">
                                    <label className="field">
                                      <span
                                        className="field__label"
                                        htmlFor="zipcode"
                                      >
                                        PIN CODE
                                      </span>
                                      <input
                                        className="form-control "
                                        type="number"
                                        id="pincode2"
                                        name="pincode2"
                                        value={billingAddress.pincode}
                                        onChange={(e) =>
                                          setBillingAddress({
                                            ...billingAddress,
                                            pincode: e.target.value,
                                          })
                                        }
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12">
                                    <label className="field">
                                      <span
                                        className="field__label"
                                        htmlFor="gst"
                                      >
                                        GSTIN
                                      </span>
                                      <input
                                        className="form-control "
                                        type="text"
                                        id="gst"
                                        name="gst"
                                        placeholder="for business customers only"
                                        value={billingAddress.Gst}
                                        onChange={(e) =>
                                          setBillingAddress({
                                            ...billingAddress,
                                            Gst: e.target.value,
                                          })
                                        }
                                      />
                                    </label>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                      <div className="fields">
                                        <label className="field">
                                          <span
                                            className="field__label"
                                            htmlFor="firstname"
                                          >
                                            MOBILE NO.
                                          </span>
                                          <div className="input-group flex-nowrap">
                                            <span
                                              className="input-group-text"
                                              id="addon-wrapping"
                                            >
                                              +91
                                            </span>
                                            <input
                                              className="form-control "
                                              type="number"
                                              id="phno2"
                                              name="phno2"
                                              aria-label="Username"
                                              aria-describedby="addon-wrapping"
                                              value={billingAddress.mobileno}
                                              onChange={(e) =>
                                                setBillingAddress({
                                                  ...billingAddress,
                                                  mobileno: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                      <label className="field">
                                        <span
                                          className="field__label"
                                          htmlFor="lastname"
                                        >
                                          EMAIL
                                        </span>
                                        <input
                                          className="form-control "
                                          type="email"
                                          id="email2"
                                          name="email2"
                                          value={billingAddress.email}
                                          onChange={(e) =>
                                            setBillingAddress({
                                              ...billingAddress,
                                              email: e.target.value,
                                            })
                                          }
                                        />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="container">
                                <hr />
                                <p className="text-danger">{err}</p>
                                {valstatus ? (
                                  <button
                                    className="btn w-100 btn-outline-dark "
                                    type="button"
                                    id="buttonhid"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#coupone"
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                                    //   onClick="sender(event)"
                                    onClick={val}
                                  >
                                    Continue
                                  </button>
                                ) : (
                                  <button
                                    className="btn w-100 btn-outline-dark "
                                    type="button"
                                    //   onClick="sender(event)"
                                    onClick={val}
                                  >
                                    Continue{' '}
                                    <FaCaretDown size={25} className="pb-2" />
                                  </button>
                                )}
                              </div>
                              {/* collapse rename */}
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div
        className={`${!valstatus ? 'd-none' : ''} container card-shadow mt-5`}
        id="coupone"
      >
        <div className="card card-body" style={{ border: 'none' }}>
          <div className=" bg-grey">
            <div className="px-1 py-5">
              <span
                className="fw-bold mb-5 mt-2 pt-1"
                style={{
                  fontSize: '30px',
                  fontWeight: '100',
                }}
              >
                Summary
              </span>
              <hr className="my-4" />

              <div className="d-flex justify-content-between mb-4">
                <span
                  id="notitem"
                  className="text-uppercase"
                  style={{
                    fontWeight: '100',
                    fontSize: '20px',
                  }}
                >
                  {/* {Items?.length > 1
                    ? `Items ${Items.length}`
                    : `Item ${Items.length}`} */}
                  {/* Items&nbsp;{Items.length} */}
                  {Items?.length > 1
                    ? `${Items.length} Items`
                    : `${Items.length} Item`}
                </span>
                <h5 id="a1"> </h5>
              </div>

              <span
                className="text-uppercase mb-3"
                style={{
                  fontWeight: '100',
                  fontSize: '20px',
                }}
              >
                Apply code
              </span>

              <div className="mb-5 pt-3">
                <div className="form-outline">
                  <div className="row">
                    <div className="col">
                      <input
                        autoComplete="off"
                        type="text"
                        id="coupon"
                        className="form-control"
                        style={{ textTransform: 'capitalize' }}
                        ref={CouponRef}
                        onChange={(e) => {
                          e.target.value = e.target.value.toUpperCase();
                          setCoupenStatus({ ...coupenStatus, text: '' });
                        }}
                      />
                    </div>
                    <div className="col-auto">
                      <button
                        className="btn btn-vio"
                        onClick={(e) => applyCodefun()}
                      >
                        <FaRegCheckSquare size={25} className="pb-1 " />
                      </button>
                    </div>
                  </div>
                  <p
                    className="form-label text-danger"
                    id="error"
                    htmlFor="form3Examplea2"
                  ></p>

                  <p className="form-label" id="mess" htmlFor="form3Examplea2">
                    {applyCode.length
                      ? coupenStatus.text && (
                          <p
                            className={`${
                              coupenStatus.status
                                ? 'text-success'
                                : 'text-danger'
                            }`}
                          >
                            {coupenStatus.text}
                          </p>
                        )
                      : 'Enter the coupon code you have'}
                  </p>
                </div>
              </div>

              <hr className="my-4" />

              <div className="d-flex justify-content-between mb-0">
                <span
                  className="text-uppercase"
                  style={{
                    fontWeight: '100',
                    fontSize: '20px',
                  }}
                >
                  Total price
                </span>
                <h5 id="a2">₹ {Total + ' /-'}</h5>
              </div>
              <div className="d-flex justify-content-between mb-0">
                <span
                  className="text-uppercase text-success"
                  style={{
                    fontWeight: '100',
                    fontSize: '20px',
                  }}
                >
                  Discount
                </span>
                <h5 id="a2 text-success">₹ {Discount + ' /-'}</h5>
              </div>
              <div className="d-flex justify-content-between mb-0">
                <span
                  className=""
                  style={{
                    fontWeight: '100',
                    fontSize: '20px',
                    textTransform: 'none',
                  }}
                >
                  AMOUNT TO BE PAID
                  <br />
                  (includes free shipping)
                </span>
                <h5 id="a2">₹ {Total - Discount + ' /-'}</h5>
              </div>
              <div className="d-flex justify-content-end mb-5">
                <h5 className="text-success" id="a3">
                  {' '}
                </h5>
              </div>

              <br />

              <Payment
                value={{
                  gift,
                  shippingAddress,
                  billingAddress,
                  coupenStatus,
                  applyCode,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contentcart;
