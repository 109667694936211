import React, { useState } from 'react';
import { Connectnavbar } from './ConnectNavbar';
import { Footer } from '../Footer/Footer';
import { NavbarVipar } from '../NavbarVipar/NavbarVipar';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';

export const Content = () => {
  return (
    <>
      <div>
        <NavbarVipar />
      </div>
      <div>
        <Connectnavbar />
      </div>

      <div id="fooo">
        <section className="bgvipar d-none d-md-block">
          <div className="container w-100">
            <div className="row  d-flex justify-content-center align-items-center py-2 py-md-3 px-2">
              <p
                style={{
                  fontSize: '15px',
                  fontFamily: 'maiandra',
                  textAlign: 'left',
                }}
                className="m-0 pt-5 text-light  "
              >
                The information technology wing of{' '}
                <span
                  className="vipar "
                  style={{
                    fontSize: '22px',
                    color: '#99d050',
                  }}
                >
                  Vipar&nbsp;
                </span>{' '}
                <sup>®</sup>.
              </p>
              <p
                style={{
                  fontSize: '15px',
                  fontFamily: 'maiandra',
                  textAlign: 'left',
                }}
                className="m-0 pb-5 pt-3 text-light  "
              >
                Professional-Networking, Informative, Educative and Awareness
                Creation online platforms which are Accessible to users
                Globally.
              </p>
              <br />
            </div>
          </div>
        </section>
        <div className="py-5 d-none d-md-block">
          <div className="container  d-none d-md-block ">
            <div>
              <span
                className="vipar"
                style={{
                  fontSize: '22px',
                  color: '#99d050',
                }}
              >
                {' '}
                Vipar&nbsp;
              </span>{' '}
              <span className="verticals">CONNECT&nbsp;</span>develops
              subscription-based web and mobile apps available on Windows,
              Android and iOS.
            </div>
            {/* <div className="pt-3">
              50% of the revenue generated from the subscriptions to these apps
              is used for funding our humane and social welfare initiatives at
              <span
                className="vipar"
                style={{ fontSize: '22px', color: '#99d050' }}
              >
                {' '}
                Vipar
              </span>
              &nbsp;&nbsp;
              <span className="verticals">CARE</span>.
            </div> */}
            <div className="pt-3">
              Our first and newly launched app is{' '}
              <span className="prochure">PROchure</span>.
            </div>
            <hr
              className="pt-2 "
              style={{
                color: 'darkgray',
                borderTop: '2px solid',
              }}
            />
            <div className="">
              Please click on the link below to apply for marketing jobs.
            </div>
            <div className="pt-3">
              <a
                style={{ textDecoration: 'none' }}
                href="https://www.vipar-connect.in"
                target="_blank"
              >
                www.vipar-connect.in
              </a>
            </div>
          </div>
        </div>
        <section className="bgvipar d-block d-md-none">
          <div className="container w-100">
            <div className=" py-2 py-md-3 px-2">
              <p
                style={{
                  fontSize: '15px',
                  fontFamily: 'maiandra',
                  textAlign: 'left',
                }}
                className="m-0 pt-5 text-light  "
              >
                The information technology wing of{' '}
                <span
                  className="vipar "
                  style={{
                    fontSize: '22px',
                    color: '#99d050',
                  }}
                >
                  Vipar&nbsp;
                </span>{' '}
                <sup>®</sup>.
              </p>

              <p
                className="text-light mb-4 mt-3"
                style={{
                  fontFamily: 'maiandra',

                  textAlign: 'left',
                }}
              >
                Professional-Networking, Informative, Educative and Awareness
                Creation online platforms which are Accessible to users
                Globally.
              </p>
            </div>
          </div>
        </section>
        <div style={{ overflow: 'hidden' }} className="py-5  d-block d-md-none">
          <div className="container ps-3">
            <div className="">
              <span
                className="vipar"
                style={{
                  fontSize: '22px',
                  color: '#99d050',
                }}
              >
                {' '}
                Vipar&nbsp;
              </span>{' '}
              <span className="verticals">CONNECT&nbsp;</span>develops
              subscription-based web and mobile apps available on Windows,
              Android and iOS.
            </div>

            {/* <div className="pt-3">
              50% of the revenue generated from the subscription to these apps
              is used for funding our humane and social welfare initiatives at
              <span
                className="vipar"
                style={{ fontSize: '22px', color: '#99d050' }}
              >
                {' '}
                Vipar
              </span>
              &nbsp;&nbsp;
              <span className="verticals">CARE</span>.
            </div> */}
            <div className="pt-3">
              Our first and newly launched app is{' '}
              <span className="prochure">PROchure</span>.
            </div>
            <hr
              className="pt-2 "
              style={{
                color: 'darkgray',
                borderTop: '2px solid',
              }}
            />

            <div className="">
              Please click on the link below to apply for marketing jobs.
            </div>
            <div className="pt-3">
              <a
                style={{ textDecoration: 'none' }}
                href="https://www.vipar-connect.in"
                target="_blank"
              >
                www.vipar-connect.in
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};
