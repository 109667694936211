import React from 'react';

export function Footer() {
  return (
    <div
      id="footer"
      class="w-100  py-2"
      style={{
        backgroundColor: '#1d242d',
        position: 'relative',
        bottom: '0',
        zIndex: 1000,
      }}
    >
      <div class="container d-flex justify-content-center">
        <div class="row pt-2">
          <center>
            <div class="col-12">
              <p class="text-left text-light mb-1">
                &copy;{' '}
                <span>
                  {' '}
                  <span class="viparfooter monot">Vipar&nbsp;</span>
                  <sup class="">&reg;</sup>
                </span>
                &nbsp; |&nbsp; All Rights Reserved
              </p>
              <p class="text-left text-light mb-1">
                Designed & Powered by{' '}
                <span
                  className="vipar"
                  style={{ fontSize: '22px', color: '#99d050' }}
                >
                  {' '}
                  Vipar
                </span>
                &nbsp;&nbsp;
                <span className="verticals">CONNECT</span>
              </p>
            </div>
          </center>
        </div>
      </div>
    </div>
  );
}
