import React, { useEffect, useState } from 'react';
import { NewNavViparc } from './Vipar-chic/NewNavViparc/NewNavViparc';
import { Footer } from './Footer/Footer';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import axios from 'axios';
import { Link } from 'react-router-dom';

export const Invoicenew = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [state1, setstate1] = useState([]);
  const [tax, settax] = useState([]);

  useEffect(() => {
    axios
      .post('/public/invoicenew')
      .then((res) => {
        console.log(res.data);
        setstate1(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .post('/public/finalinvoice')
      .then((res) => {
        console.log(res.data);
        settax(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div>
        <NewNavViparc />
        <hr
          className="mt-0"
          style={{
            color: 'rgb(1, 32, 96)',
            opacity: '1',
            borderTop: '3px solid',
          }}
        />
      </div>
      <div className="bv" style={{ backgroundColor: '#e9eef5' }}>
        <div className="pt-5">
          {!tax?.length == 0 ? (
            <></>
          ) : (
            <>
              <div
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ flexShrink: 0 }}>
                    PROVISIONAL INVOICES
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <table className="table ">
                    <tbody className="nm">
                      {state1?.map((elem) => {
                        console.log(elem);
                        console.log(elem.Order_Id);

                        return (
                          <tr>
                            <td>{elem.Order_date}</td>
                            <td>{elem.Order_Id}</td>
                            <td>
                              <Link
                                className="btn btn-primary"
                                to={`/invoicedrop?order=${elem.Order_Id}`}
                              >
                                view
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </AccordionDetails>
              </div>
            </>
          )}

          <Accordion
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography sx={{ flexShrink: 0 }}>FINAL TAX INVOICES</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <table className="table ">
                <tbody className="nm">
                  {tax?.map((elem) => {
                    console.log(elem);
                    console.log(elem.Order_Id);

                    return (
                      <tr>
                        <td>{elem.Order_date}</td>
                        <td>{elem.Order_Id}</td>
                        <td>
                          <Link
                            className="btn btn-primary"
                            to={`/invoicedrop?order=${elem.Order_Id}`}
                          >
                            view
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {tax.length == 0 && (
                <span className="text-center">
                  FINAL TAX INVOICES WILL BE AVAILABLE ONLY AFTER ONE WEEK POST
                  ORDER DELIVERY
                </span>
              )}
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      <Footer />
    </>
  );
};
