import axios from 'axios';
import React, { useReducer } from 'react';
import { useContext } from 'react';
import { createContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { notify } from '../../App';
import { Linkbroken } from './Linkbroken';
import { Reviewmodal } from './Reviewmodal';
import './User.css';
import Userloading from './Userloading';

export const loading = createContext();

export const User = () => {
  const [params, setParams] = useSearchParams();
  let order = params.get('order');
  let varient = params.get('varient');
  let product = params.get('product');
  const reducer = (state, action) => {
    switch (action.type) {
      case 'loading':
        return { component: <Userloading />, type: action.type };
      case 'linkbroken':
        return { component: <Linkbroken />, type: action.type };
      case 'contentviewer':
        return { component: <Contentview />, type: action.type };
      case 'feedback':
        return { component: <Reviewmodal />, type: action.type };
      default:
        return { component: <Linkbroken />, type: action.type };
        <></>;
    }
  };
  const [state, dispatch] = useReducer(reducer, {
    component: <Userloading />,
    type: 'loading',
  });
  useEffect(() => {
    axios
      .post('/public/customer_review', { order, varient, product })
      .then((res) => {
        dispatch({ type: 'contentviewer' });
      })
      .catch((err) => {
        console.log(err);
        notify('something went wrong');
        dispatch({ type: 'linkbroken' });
      });
    if (order?.length === 0) {
      return dispatch({ type: 'linkbroken' });
    }
  }, []);

  return (
    <>
      <loading.Provider value={{ dispatch }}>
        {state.component}
      </loading.Provider>
    </>
  );
};

export const Contentview = () => {
  const first = useContext(loading);
  const [params, setParams] = useSearchParams();
  let order = params.get('order');
  let varient = params.get('varient');
  let product = params.get('product');

  const [feed, setFeed] = useState({
    comment: '',
    rating: 0,
    Order_Id: order,
    varientId: varient,
    productId: product,
  });
  //  console.log(http://localhost:3000/customer_review?order=order_L60S3m1neVIOOd&&varient=63aea1b585be28d2b5f0d16c&&product=63abfaab51626cde3b9d7c6f)
  useEffect(() => {
    axios
      .post('/public/customer_review', { order, varient, product })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const submit = () => {
    axios
      .post('/public/postreview', { feed, order, varient, product })
      .then((res) => {
        first.dispatch({ type: 'feedback' });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="MainSubmitDiv">
        <div
          className=" d-flex justify-content-center align-items-center text-white m-0 p-0"
          style={{ height: '100vh' }}
        >
          <div className="sabaricardforall">
            <div className="text-start">
              <span
                className="name mb-5 fw-500"
                style={{
                  fontWeight: '500',
                  fontSize: '25px',
                  fontFamily: 'Cursive',
                }}
              >
                Give us feedback
              </span>
              <textarea
                className="form-control mt-3"
                id="exampleFormControlTextarea1"
                rows="5"
                onChange={(e) => setFeed({ ...feed, comment: e.target.value })}
              ></textarea>

              <div className="py-3 mt-3">
                <span
                  className="mb-0 mt-4 "
                  style={{
                    fontWeight: '400',
                    fontSize: '25px',
                    fontFamily: 'Cursive',
                  }}
                >
                  please rate your experience
                </span>

                <div className="rating pt-4">
                  <StarRatings
                    rating={feed.rating}
                    starRatedColor="gold"
                    starHoverColor="gold"
                    changeRating={(e) => setFeed({ ...feed, rating: e })}
                    numberOfStars={5}
                    name="rating"
                  />
                </div>
                <div className="pt-5 d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn newSubmitButton btn-rounded px-4"
                    onClick={(e) => submit()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </>
  );
};
