import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { hookselector } from '../../../redux/viparChic/cartSlice';

export const Editmodal = ({ value, id, cart }) => {
  console.log(id);
  console.log(value);
  let dispatch = useDispatch();
  console.log(value);
  const [first, setfirst] = useState();
  let setAdd = (value) => {
    setfirst(value);
    dispatch(hookselector(value, id, cart));
  };
  const [modal, setModal] = useState(false);
  let handleClose = () => {
    setModal(false);
  };
  let handleshow = (e) => {
    setModal(true);
  };

  const [edit, setedit] = useState([]);

  useEffect(() => {
    axios
      .get('/public/getproducts?id=' + id)
      .then((res) => {
        setedit(res.data[0]);
        console.log(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Button variant="primary" onClick={(e) => handleshow(e)}>
        Edit
      </Button>

      <Modal show={modal} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="" id="korg">
            <center>
              <img
                src="/img/dubhookkorg.jpg"
                height="300px"
                width="300px"
                alt=""
              />
            </center>
            <center>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="a"
                  checked={first === 'a'}
                  onChange={(e) => setAdd(e.target.value)}
                />
                <label
                  style={{ fontSize: '17px' }}
                  className="form-check-label"
                  htmlFor="inlineRadio1"
                >
                  A
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value="b"
                  checked={first === 'b'}
                  onChange={(e) => setAdd(e.target.value)}
                />
                <label
                  style={{ fontSize: '17px' }}
                  className="form-check-label"
                  htmlFor="inlineRadio2"
                >
                  B
                </label>
              </div>
            </center>

            <div>
              {edit?.variant?.map((elem) => {
                return <p>{elem.varentName} </p>;
              })}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
