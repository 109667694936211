import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import moment from 'moment';
import { notify } from '../../App';
import { Link } from 'react-router-dom';

export const Verticalsvaccanies = () => {
  const [uservalue, setUservalue] = useState({
    CurrentCTS: '',
    SalaryExpectation: '',
  });
  const [Submit2, setSubmit2] = useState(false);

  const [check, setcheck] = useState(true);
  const [check2, setcheck2] = useState(true);

  const anees = useRef();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [shows, setShows] = useState(false);

  const [index, setindex] = useState();

  const handleCloses = () => setShows(false);
  const handleShows = () => setShows(true);
  const [drop, setdrop] = useState(true);
  const [opper, setopper] = useState();

  useEffect(() => {
    axios
      .post('/vipar/reg')
      .then((res) => {
        console.log(res.data);
        setopper(res.data);
      })
      .catch((err) => {});
  }, []);
  useEffect(() => {
    console.log(opper);
  }, [opper]);

  const [verticals, setVerticals] = useState([]);

  useEffect(() => {
    axios
      .post('/vipar/vertview')
      .then((res) => {
        console.log(res.data);
        setVerticals(res.data);
      })
      .catch((err) => {});
  }, []);

  const [img, setimg] = useState([]);

  useEffect(() => {
    axios
      .post('/vipar/regupdate')
      .then((res) => {})
      .catch((err) => {});
  }, []);

  const [first, setfirst] = useState({});

  useEffect(() => {
    axios
      .post('/vipar/regupdate', { first })
      .then((res) => {
        console.log('SADds');
      })
      .catch((err) => {
        console.log(err);
      });
  }, [first]);

  const submit = (e) => {
    console.log('ASdsasd');
    console.log(e);
    e.preventDefault();
    handleClose();

    var formData = new FormData();
    console.log(img);
    {
      img?.map((elem) => {
        formData.append('file', elem);
      });
    }

    var dataSend = JSON.stringify({ uservalue, first });
    formData.append('data', dataSend);

    axios
      .post('/vipar/regupdate', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        notify('Vacancy Applied Succesffuly', true);
        handleClose2();
        setimg([]);
        setSubmit2(false);
      })
      .catch((err) => {
        notify('Something went wrong', false);
        setSubmit2(false);
        console.log(err);
      });
  };
  const [jobview, setJobview] = useState({});
  useEffect(
    (vert) => {
      console.log(jobview);
    },
    [jobview]
  );

  const [Noval, setNoval] = useState('');
  // const sortedOpper = opper.sort((a, b) => a.opper.localeCompare(b.opper));
  return (
    <>
      <div className="row mt-4 fntvv">
        <div
          className="col-6 "
          style={{
            textAlignLast: 'Start',
          }}
        >
          <span className="invisible">.</span>VERTICALS
        </div>
        <div
          className="col-6"
          style={{
            textAlignLast: 'end',
            paddingLeft: '68px',
          }}
        >
          VACANCIES<span className="invisible">.</span>
        </div>
      </div>
      {verticals?.map((vert, ind) => {
        console.log(vert);
        return (
          <>
            <div className="card mt-3">
              <div
                className="card-body ps-0"
                style={{
                  paddingTop: '10px',
                  paddingBottom: '10px',
                }}
              >
                {vert?.jobStatus === true ? (
                  <div
                    className="row "
                    onClick={(e) => {
                      setJobview(vert);
                      handleShow();
                      setNoval(vert?.verticals);
                    }}
                  >
                    <div className="col-6  ps-4 verticals">
                      {vert?.verticals}
                    </div>
                    <div
                      className="col-6 text-success pe-4"
                      style={{ textAlignLast: 'end' }}
                    >
                      <button
                        style={{
                          border: 'none',
                          backgroundColor: 'white',
                          color: 'green',
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    className="row "
                    onClick={(e) => {
                      handleShows();
                      setNoval(vert?.verticals);
                    }}
                  >
                    <div className="col-6  ps-4 verticals">
                      {vert?.verticals}
                    </div>
                    <div
                      className="col-6 text-success pe-4"
                      style={{ textAlignLast: 'end' }}
                    >
                      <button
                        style={{
                          border: 'none',
                          backgroundColor: 'white',
                          color: 'red',
                        }}
                      >
                        No
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        );
      })}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <span
              className="vipar"
              style={{
                fontSize: '25px',
                color: '#99d050',
              }}
            >
              {' '}
              Vipar&nbsp;&nbsp;
            </span>
            {Noval}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {' '}
          {opper?.map((elem, ind) => {
            console.log(elem);
            return jobview?.verticals === elem?.verticals &&
              elem?.Status === true ? (
              <>
                {opper[ind - 1]?.JobSection == elem?.JobSection ? (
                  <></>
                ) : (
                  <>
                    {ind != 0 && <hr />}
                    <div
                      className="hfont pb-3"
                      style={{ textTransform: 'uppercase' }}
                    >
                      {elem?.JobSection}
                    </div>
                  </>
                )}
                {opper[ind - 1]?.JobSubSection == elem?.JobSubSection ? (
                  <></>
                ) : (
                  <>
                    <div>{elem?.JobSubSection}</div>
                  </>
                )}
                <Accordion defaultActiveKey="" className="py-3">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>{elem.JobTitle}</Accordion.Header>

                    <Accordion.Body>
                      <div className="pt-3">
                        <label htmlhtmlFor="inputname">Vacancies</label>
                        <div className="card mt-2">
                          <div
                            className="card-body ps-3 "
                            style={{
                              paddingTop: '10px',
                              paddingBottom: '10px',
                            }}
                          >
                            <span className="">{elem?.Vacancies}</span>
                          </div>
                        </div>
                      </div>
                      <div className="py-4">
                        <label htmlhtmlFor="inputname">Job Title</label>
                        <div className="card mt-2">
                          <div
                            className="card-body ps-3 "
                            style={{
                              paddingTop: '10px',
                              paddingBottom: '10px',
                            }}
                          >
                            <span className="">{elem?.JobTitle}</span>
                          </div>
                        </div>
                      </div>{' '}
                      <div className="py-4">
                        <label htmlhtmlFor="inputname">Job Description</label>
                        <div className="card mt-2">
                          <div
                            className="card-body ps-3  "
                            style={{
                              paddingTop: '10px',
                              paddingBottom: '10px',
                            }}
                          >
                            <span className="">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: elem?.JobDescription,
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label htmlhtmlFor="inputname">Qualification</label>
                        <div className="card mt-2">
                          <div
                            className="card-body ps-0 "
                            style={{
                              paddingTop: '10px',
                              paddingBottom: '10px',
                            }}
                          >
                            <span className="ps-3">{elem?.Qualfication}</span>
                          </div>
                        </div>
                      </div>
                      <div className="pt-4">
                        <label htmlhtmlFor="inputname">Skills</label>
                        <div className="card mt-2">
                          <div
                            className="card-body ps-3 "
                            style={{
                              paddingTop: '10px',
                              paddingBottom: '10px',
                            }}
                          >
                            <span className="">{elem?.Skills}</span>
                          </div>
                        </div>
                      </div>
                      <div className="py-4">
                        <label htmlhtmlFor="inputname">Experience</label>
                        <div className="card mt-2">
                          <div
                            className="card-body ps-3"
                            style={{
                              paddingTop: '10px',
                              paddingBottom: '10px',
                            }}
                          >
                            <span className="">{elem?.Experience}</span>
                          </div>
                        </div>
                      </div>
                      <div className="pb-4">
                        <label htmlhtmlFor="inputname">
                          Work / Job Location
                        </label>
                        <div className="card mt-2">
                          <div
                            className="card-body ps-3"
                            style={{
                              paddingTop: '10px',
                              paddingBottom: '10px',
                            }}
                          >
                            <span className="">{elem?.WorkJoblocation}</span>
                          </div>
                        </div>
                      </div>
                      <div className="pb-4">
                        <label htmlhtmlFor="inputname">Joining Date</label>
                        <div className="card mt-2">
                          <div
                            className="card-body ps-3 "
                            style={{
                              paddingTop: '10px',
                              paddingBottom: '10px',
                            }}
                          >
                            <span className="">
                              {moment(elem?.JoiningDate).format('Do MMMM YYYY')}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        Salary / Remuneration :{' '}
                        <a
                          style={{ textDecoration: 'none' }}
                          target="blank"
                          // href="/one_work_one_wage"
                          href="/one_work_one_wage?remuneration=remuneration"
                        >
                          Read&nbsp;REMUNERATION
                        </a>
                      </div>
                      <div className="pt-4">
                        <div>
                          <button
                            className="btn btn-primary mt-3 mx-2"
                            style={{ backgroundColor: 'rgb(1 32 96)' }}
                            variant="primary"
                            onClick={(e) => {
                              // setSubmit2(true);
                              handleShow2();
                              setindex(ind);
                              handleClose();
                            }}
                          >
                            APPLY
                          </button>
                        </div>
                      </div>
                    </Accordion.Body>
                    {/* </form> */}
                  </Accordion.Item>
                </Accordion>
              </>
            ) : (
              <></>
            );
          })}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal show={shows} onHide={handleCloses} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h5 className="">
              Currently there is no vacancy in{' '}
              <span
                className="vipar"
                style={{
                  fontSize: '25px',
                  color: '#99d050',
                }}
              >
                {' '}
                Vipar&nbsp;&nbsp;
              </span>
              {Noval}
            </h5>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show2} onHide={handleClose2} centered>
        <Modal.Header closeButton></Modal.Header>
        <form
          id="myForm"
          onSubmit={(e) => submit(e)}
          novalidate
          className={Submit2 ? 'was-validated' : ''}
        >
          <Modal.Body>
            {opper?.map((elem, ind) => {
              if (index === ind) {
                if (elem?.Experience != 'Fresher') {
                  return (
                    <>
                      <div className="">
                        <label>What is your Current CTC ?</label>
                        <input
                          className="form-control pt-2"
                          type="text"
                          onChange={(e) => {
                            setUservalue({
                              ...uservalue,
                              CurrentCTS: e.target.value,
                            });
                          }}
                        />
                        <div class="invalid-feedback">
                          Please enter your Current CTC
                        </div>
                      </div>
                      <div className="py-4">
                        <label>What is your Salary Expectation ?</label>
                        <input
                          className="form-control pt-2"
                          type="text"
                          onChange={(e) => {
                            setUservalue({
                              ...uservalue,
                              SalaryExpectation: e.target.value,
                            });
                          }}
                        />
                        <div class="invalid-feedback">
                          Please enter your Salary Expectation
                        </div>
                      </div>

                      <div className="">
                        <label>Are you ready to Relocate ?</label>

                        <div className="pt-2">
                          <input
                            type="checkbox"
                            defaultChecked
                            className="form-check-input "
                            id="exampleCheck1"
                            value={check2}
                            onChange={(e) => setcheck2(false)}
                          />
                          <label
                            class="form-check-label ps-2"
                            for="exampleCheck1"
                          >
                            Yes I am ready to Relocate.
                          </label>
                        </div>
                      </div>
                      <div className="pt-4">
                        <div>
                          <div class="form-check form-check-inline">
                            <input
                              required
                              class="form-check-input"
                              type="checkbox"
                              id="inlineCheckbox1"
                              value={check}
                              onChange={(e) => setcheck(false)}
                            />
                            <label
                              class="form-check-label"
                              for="inlineCheckbox1"
                            >
                              I have read, understood and agree with the
                              Concept, Remuneration and Employment Policies of
                              <span
                                className="vipar"
                                style={{
                                  fontSize: '23px',
                                  color: '#99d050',
                                }}
                              >
                                {' '}
                                Vipar
                              </span>
                              .
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="pt-3">
                        <div>
                          <input
                            required
                            hidden
                            ref={anees}
                            type="file"
                            className="btn btn-primary mt-3 mx-2"
                            style={{ backgroundColor: 'rgb(1 32 96)' }}
                            onChange={(e) =>
                              setimg([...img, ...e.target.files])
                            }
                          />
                          <button
                            type="button"
                            id="anees"
                            className="btn btn-outline-primary mt-3 mx-2"
                            style={{ backgroundColor: 'rgb(1 32 96)' }}
                            onClick={(e) => anees.current.click()}
                          >
                            UPLOAD CV
                          </button>
                          <div class="invalid-feedback">PLEASE UPLOAD CV</div>
                        </div>
                      </div>
                      <div className="py-4">
                        <div>
                          <button
                            className="btn btn-primary mt-3 mx-2"
                            style={{ backgroundColor: 'rgb(1 32 96)' }}
                            variant="primary"
                            onClick={(e) => {
                              setSubmit2(true);
                              setfirst(elem);
                            }}
                          >
                            SENT
                          </button>
                        </div>
                      </div>
                    </>
                  );
                }
              }
              console.log(elem);
            })}
          </Modal.Body>
        </form>
      </Modal>
    </>
  );
};
