import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Footer } from '../../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import './Connect.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { notify } from '../../../App';
import { useNavigate } from 'react-router-dom';
const Connect = () => {
  const [submitt, setSubmitt] = useState(false);

  let formref = useRef();
  let navigate = useNavigate();
  const [err, setErr] = useState();
  const validation = (data) => {
    if (data.Name.length === 0) {
      return setErr('Enter your name');
    }
    if (data.Contact.length === 0) {
      return setErr('Enter your mobile number');
    }
    if (data.Contact.length !== 10) {
      return setErr('Enter a valid mobile number');
    }
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.Email)) {
      setErr('');
    } else {
      return setErr('Enter your email');
    }
    if (data.Subject.length === 0) {
      return setErr('Select a Subject');
    }
    if (data.Message.length === 0) {
      return setErr('Type your message');
    }
    return true;
  };

  const submit = (e) => {
    e.preventDefault();
    setSubmitt(true);
    let elements = e.target.elements;

    let data = {
      Name: elements.name.value,
      Contact: elements.mob.value,
      Email: elements.email.value,
      Subject: elements.subject.value,
      Message: elements.message.value,
    };
    if (validation(data) === true) {
      axios
        .post('/public/connect', data)
        .then((res) => {
          e.target.reset();
          notify('Successfull', true);
        })
        .catch((err) => {
          console.log(err);
          notify('Something went wrong !', false);
        });
    }
  };

  const [grv, setgrv] = useState();

  useEffect(() => {
    axios
      .post('/public/grev')
      .then((res) => {
        console.log(res.data);
        setgrv(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <hr
        className="mt-0"
        style={{
          color: 'rgb(1, 32, 96)',
          opacity: '1',
          borderTop: '3px solid',
        }}
      />
      <div className="container-fluid  p-0 pb-2 pt-5">
        {grv ? (
          <center>
            <span className="" style={{ fontSize: '20px', fontWeight: '100' }}>
              For Purchase-related Complaints,
              <br />
              please click on{' '}
              <button
                type="submit"
                className="btn btn-outline-dark btn-lg px-3 btnn"
                onClick={(e) => navigate('/vipar-chic/grievances')}
              >
                GRIEVANCES
              </button>
              <br />
              <br />
              For Bulk Purchasing, Corporate Gifting or Partnerships, please
              fill in&nbsp;the&nbsp;form&nbsp;below
              <br />
            </span>
          </center>
        ) : (
          ''
        )}

        <div className="  my-2 d-flex justify-content-center ">
          <div className="container row ">
            <form
              ref={formref}
              className="col-md-9 m-auto "
              onSubmit={(e) => submit(e)}
              onChange={(e) => {
                if (submitt) {
                  let elements = formref.current.elements;

                  let data = {
                    Name: elements?.name?.value ? elements.name.value : '',
                    Contact: elements.mob.value,
                    Email: elements.email.value,
                    Subject: elements.subject.value,
                    Message: elements.message.value,
                  };

                  validation(data);
                }
              }}
            >
              <div className="mb-3">
                <label htmlhtmlFor="inputsubject">Subject *</label>
                {/* <!-- <input type="text" className="form-control mt-1" id="subject" name="subject" placeholder="Subject"> --> */}
                <select name="subject" className="form-select mt-1">
                  <option value=""></option>
                  {/* <option>Product Review</option>
                  <option>Product Enquiry</option> */}
                  <option>Bulk Orders</option>
                  <option>Corporate Gifting</option>
                  <option>Partnerships</option>
                  {/* <option>Purchase Grievances</option> */}
                </select>
              </div>
              <div className="mb-3">
                <label htmlhtmlFor="inputmessage">Requirement *</label>
                <textarea
                  className="form-control mt-1"
                  id="message"
                  name="message"
                  rows="8"
                ></textarea>
              </div>
              <div className="">
                <div className="row ">
                  <div className="form-group col-md-12 mb-3">
                    <label htmlhtmlFor="inputname">Name *</label>
                    <input
                      type="text"
                      className="form-control mt-1"
                      id="name"
                      name="name"
                    />
                  </div>
                  <div className="form-group col-md-12 mb-3">
                    <label htmlhtmlFor="inputemail">Contact Number *</label>
                    <input
                      type="number"
                      className="form-control mt-1"
                      id="mob"
                      name="mob"
                    />
                  </div>
                  <div className="form-group col-md-12 mb-3">
                    <label htmlhtmlFor="inputemail">Email *</label>
                    <input
                      type="email"
                      className="form-control mt-1"
                      id="email"
                      name="email"
                    />
                  </div>
                </div>
              </div>

              <div className="">
                <div>
                  <p className="text-danger">{err}</p>
                </div>
                <div className="col text-end mt-2">
                  <button
                    type="submit"
                    className="btn btn-outline-dark btn-lg px-3 btnn"
                  >
                    Shoot
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <ToastContainer />
        <div></div>
      </div>
    </>
  );
};

export default Connect;
