import React from 'react';
import { Viparcarenav } from './Viparcarenav';
import { Link, useLocation } from 'react-router-dom';
import { Logocenter } from '../Logocenter';
import { Footer } from '../Footer/Footer';

export const Philosophy = () => {
  let location = useLocation();

  return (
    <>
      <nav
        className="navbar navbar-expand navbar-light shadow"
        id="viparcare"
        style={{ fontFamily: 'FootlightMTLight', display: 'block' }}
      >
        <div
          className="  px-4  w-100"
          style={{
            display: 'block',
            height: '122px',
            backgroundColor: 'rgb(1,32,96,1)',
          }}
        >
          <div className="">
            <div className="d-flex justify-content-between">
              <div style={{ marginTop: '20px' }}>
                <Link
                  class="navbar-brand text-success logo align-self-center"
                  to="/vipar-care"
                >
                  {/* <img
                    className=""
                    src="/img/carecirclee.png"
                    alt=""
                    height="80px"
                    width="80px"
                  /> */}
                  <div style={{ width: '80px', height: '80px' }}>
                    <Logocenter link={'/img/carecirclee.png'} />
                  </div>
                </Link>
              </div>

              <div className="xn">
                {/* <span className="invisible">
                  Innovative&nbsp;Utilitarian&nbsp;Products
                </span> */}
                <div className="pt-2">
                  <div className="mt-2  ">
                    <span
                      className="vipar transform"
                      style={{
                        fontSize: '22px',
                        color: '#99d050',
                        paddingLeft: '39px',
                      }}
                    >
                      Vipar
                    </span>
                    <span
                      className="ps-1 text-light transform"
                      style={{
                        fontSize: '20px',
                        fontFamily: 'maiandra',
                      }}
                    >
                      &nbsp;CARE
                    </span>
                  </div>
                  <div className=" " style={{ height: '13px' }}></div>
                  <div className="mt-3">
                    {' '}
                    <span
                      className="  text-light   btnsss maiandra "
                      style={{
                        fontSize: '16px',
                        textTransform: 'none',
                      }}
                    >
                      Compassionate. Empathetic.
                    </span>
                  </div>
                </div>
              </div>
              <div className="invisible">
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container-fluid mt-2 w-100"
          style={{ display: 'block' }}
        >
          <div className=" d-flex justify-content-center d-md-block ">
            <div className="r" id="templatemo_main_nav">
              <div className="flex-fill">
                <div className="d-block d-md-none">
                  <ul
                    className=" navbar-nav d-flex   justify-content-center mx-auto ms-0"
                    style={{ border: 'none' }}
                  >
                    <li className="nav-item pr-0">
                      {/* <Link
                        to="/vipar-care"
                        className={`viparc nav-link cardtextt  aa ${
                          location.pathname === '/vipar-care'
                            ? ' active-link-vipar'
                            : ''
                        }`}
                      >
                        What&nbsp;is&nbsp;
                        <span style={{ fontFamily: 'monotype-Corsiva' }}>
                          Vipar
                        </span>
                        &nbsp;&nbsp;CARE&nbsp;?
                      </Link> */}
                    </li>
                    {/* {just hidden needed fo future} */}
                    {/* <li className="nav-item pr-0">
                      <Link
                        to="/vipar-care/connect"
                        className={`ssviparc nav-link cardtextt  aa ${
                          location.pathname === '/vipar-care/connect'
                            ? ' active-link-vipar'
                            : ''
                        }`}
                      >
                        GET&nbsp;IN&nbsp;TOUCH
                      </Link>
                    </li> */}
                    {/* {just hidden needed fo future} */}
                  </ul>
                  <ul
                    className=" navbar-nav d-flex  justify-content-center mx-auto ms-0 asdddd"
                    style={{ border: 'none' }}
                  >
                    <li className="nav-item ">
                      <Link
                        to="/vipar-care/philosophy"
                        className={`nav-link cardtextt aa asdddd ${
                          location.pathname === '/vipar-care/philosophy'
                            ? ' active-link-vipar'
                            : ''
                        }`}
                      >
                        PHILOSOPHY
                      </Link>
                    </li>

                    <li className="nav-item ">
                      <Link
                        to="/vipar-care/humane"
                        className={`nav-link cardtextt aa asdddd ${
                          location.pathname === '/vipar-care/humane'
                            ? ' active-link-vipar'
                            : ''
                        }`}
                      >
                        HUMANE INITIATIVES
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="d-none d-md-block ">
                  <div id="nsknfkjdsnfksjn">
                    <ul
                      className="  d-flex ps-0 pt-2  justify-content-center mx-auto ms-0"
                      style={{ border: 'none' }}
                    >
                      <li className="nav-item ">
                        {/* <Link
                          to="/vipar-care"
                          className={`nav-link cardtextt cdd 
                            ${
                              location.pathname === '/vipar-care'
                                ? ' active-link-vipar'
                                : ''
                            }`}
                        >
                          What&nbsp;is&nbsp;
                          <span style={{ fontFamily: 'monotype-Corsiva' }}>
                            Vipar
                          </span>
                          &nbsp;&nbsp;CARE&nbsp;?
                        </Link> */}
                      </li>
                      {/* {just hidden needed fo future} */}
                      {/* <li className="nav-item ">
                        <Link
                          to="/vipar-care/connect"
                          className={`nav-link cardtextt cdd 
                            ${
                              location.pathname === '/vipar-care/connect'
                                ? ' active-link-vipar'
                                : ''
                            }`}
                        >
                          GET&nbsp;IN&nbsp;TOUCH
                        </Link>
                      </li> */}
                      {/* {just hidden needed fo future} */}
                      <li className="nav-item ">
                        <Link
                          to="/vipar-care/philosophy"
                          className={`nav-link cardtextt aa asdddd ${
                            location.pathname === '/vipar-care/philosophy'
                              ? ' active-link-vipar'
                              : ''
                          }`}
                        >
                          PHILOSOPHY
                        </Link>
                      </li>

                      <li className="nav-item ">
                        <Link
                          to="/vipar-care/humane"
                          className={`nav-link cardtextt aa asdddd ${
                            location.pathname === '/vipar-care/humane'
                              ? ' active-link-vipar'
                              : ''
                          }`}
                        >
                          HUMANE INITIATIVES
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <section className="bgvipar">
        <div className="container pt-5 w-100 h-100">
          <div className="  d-flex justify-content-center align-items-center py-5 py-md-3 px-2">
            <div className="card cnb ">
              <div className="car-body cnb vb">
                <div className="vb_n">
                  <div className="vb_n-triangle"></div>
                </div>

                <div className="pt-3 ps-3">
                  {/* <span className="">My PERSPECTIVE on Life :</span>s */}
                </div>
                <div className=" px-3">
                  <span className="">
                    “We must make a conscious effort to reach out to all those
                    who are in dire need of financial support and security. And
                    the ideal way to help them achieve this is not by merely
                    lending them money, instead to make an attempt in bridging
                    the gap between the immense financial disparities in our
                    society so that everyone gets an equal and august
                    opportunity to livelihood in order to live a life with
                    self-respect.
                  </span>
                </div>
                <div className="pt-3 pb-4 px-3">
                  <span className="">
                    It is also Imperative for each one of us to do something for
                    the hapless animals and the lower sections of the society,
                    which makes our very existence on this planet worthwhile and
                    purposeful.”
                  </span>
                  <br />
                </div>
                <div className="pt-1 pb-2 px-3">
                  <span>- Ar. Vinod Venugopal</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5 bgvipar">
          <span className="mt-5 invisible">
            It is also Imperative for each one of us to do something for the
            hapless animals and the lower sections of the society, which makes
            our very existence on this planet worthwhile and purposeful.” It is
            also Imperative for each one of us to do something for the hapless
            animals and the lower sections of the society, which makes our very
            our very existence on this planet worthwhile and purposeful.” It is
            also Imperative for each one of us to do something for the hapless
            animals and the lower sections of the society, which makes our very
            existence on this planet worthwhile and purposeful.”
          </span>
          <Footer />
        </div>
      </section>
    </>
  );
};
