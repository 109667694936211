import { modalClasses } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import "./About.css";
import { NewNavViparc } from "../NewNavViparc/NewNavViparc";
import { NavbarVipar } from "../../NavbarVipar/NavbarVipar";
import { Footer } from "../../Footer/Footer";
import { Link } from "react-router-dom";
function Viparchicmain() {
  let modal = useSelector((state) => {
    return state.modal.value;
  });
  console.log(modal);
  return (
    <>
      <section className="bgvipar py-2 py-md-3   ">
        <div className="container w-100">
          <div className=" align-items-center py-2 py-md-3 px-2">
            <p
              style={{ fontSize: "15px", fontFamily: "maiandra" }}
              className="m-0 mt-3 text-light "
            >
              {/* <div
                className="fdd"
                style={{ fontSize: '30px', fontFamily: 'maiandra' }}
              >
                <span className="fdd">
                  {' '}
                  <span style={{ fontSize: '30px', fontFamily: 'maiandra' }}>
                    C
                  </span>
                  ollection of
                </span>
                <span className="fdd">
                  {' '}
                  <span style={{ fontSize: '30px', fontFamily: 'maiandra' }}>
                    H
                  </span>
                  ome décor and
                </span>
                <br></br>
                <span className="fdd">
                  {' '}
                  <span className="fdd" style={{ fontSize: '30px' }}>
                    I
                  </span>
                  nnovative
                </span>
                <span className="fdd">
                  {' '}
                  <span className="fdd" style={{ fontSize: '30px' }}>
                    C
                  </span>
                  ommodities
                </span>
              </div> */}
              <div className="fdd">
                The retail wing of{" "}
                <span
                  className="vipar "
                  style={{
                    fontSize: "22px",
                    color: "#99d050",
                  }}
                >
                  Vipar&nbsp;
                </span>{" "}
                <sup>®</sup>.
              </div>
              <div className=" pt-1 fdd">
                <span className=" fdd" style={{ fontSize: "30px" }}>
                  C
                </span>
                ollection of{" "}
                <span className="fdd" style={{ fontSize: "30px" }}>
                  H
                </span>
                andy{" "}
                <span className="fdd" style={{ fontSize: "30px" }}>
                  I
                </span>
                nnovative{" "}
                <span className="fdd" style={{ fontSize: "30px" }}>
                  C
                </span>
                ommodities
                <br />
                <div className="pt-2 fdd">
                  An online retail store of unique and innovative articles and
                  appliances which are exclusive products of{" "}
                  <span 
                    className="vipar"
                    style={{ fontSize: "22px", color: "#99d050" }}
                  >
                    Vipar&nbsp;
                  </span>
                  .
                </div>
              </div>
            </p>
          </div>
        </div>
      </section>
      <section className="pb-5 ps-2">
        <div className="container">
          <div className="pt-5">
            <p
              className="text-dark mt-2"
              style={{ fontFamily: "FootlightMTLight" }}
            >
              A{" "}
              <span
                className="vipar"
                style={{ fontSize: "22px", color: "#99d050" }}
              >
                Vipar&nbsp;{" "}
              </span>
              exclusive product is Conceptualized, Designed, Manufactured,
              Marketed and Sold by{" "}
              <span
                className="vipar"
                style={{ fontSize: "22px", color: "#99d050" }}
              >
                Vipar&nbsp;{" "}
              </span>
              and available for purchase only at <Link to="/" style={{textDecoration:"none",color:"black"}}>www.vipar.in.</Link>
            </p>
          </div>
        </div>
      </section>
      {/* <section className="bgvipar d-none d-md-block">
        <div className="container d.none w-100">
          <div className="row align-items-center py-2 py-md-3">
            <div className="col-md-4 d-flex justify-content-center">
              <div className="row">
                <div className="col-12">
                  <center>
                    <img className="imagesize" src="/img/realicon.png" alt="" />
                  </center>
                </div>
                <div className="col-12">
                  <center>
                    <h3>
                      <span className="vipiargrrencolforabout monot">
                        Vipar&nbsp;
                      </span>
                      <span className="abouttextcon">-&nbsp;chic</span>
                      <br />
                      <span className="abouttextname2">
                        &nbsp;so chic.&nbsp;so unique.
                      </span>{' '}
                    </h3>
                  </center>
                </div>
              </div>
            </div>
            <div className="col-md-8 ">
              <p className="toperrrr">
                <span className="blueicon2">c</span>
                <span className="abouttextname">ollection&nbsp;of</span>
                <span className="blueicon2"> h</span>
                <span className="abouttextname">ome décor&nbsp;and</span>
                <span className="blueicon2"> i</span>
                <span className="abouttextname">nnovative</span>&nbsp;&nbsp;
                <span className="blueicon2">c</span>
                <span className="abouttextname">ommodities</span> <br />
              </p>
            </div>
          </div>
        </div>
        <div>
          <center>
            <span className="pfont abouttextname">
              <span className="vipar monot">Vipar&nbsp;</span>
              <span className="chicc">
                -&nbsp;chic is an online retail store of{' '}
              </span>
              <span className="vipar monot">Vipar&nbsp;</span>
              <sup className="reg">&reg;</sup>
            </span>
            <br />
          </center>
        </div>
      </section> */}
      {/* <!-- Close Banner -->

    <!-- Start Section --> */}
      {/* <section className="container py-2 py-md-3 invisible">
        <div className=" text-center pb-3">
          <div className="col-lg-6 m-auto">
            <h1 className="h1" style={{ fontFamily: "FootlightMTLight" }}></h1>
          </div>
        </div>
        <div className="container">
          <div className="row g-3 g-md-5">
            <div
              className="col-6 col-lg-3"
              onClick={(e) => {
                modal.toumodal();
              }}
            >
              <div
                className="h-100 py-2 py-md-3 services-icon-wap shadow"
                style={{ backgroundColor: "rgb(255 255 255)" }}
              >
                <div className="h1 text-vio text-center">
                  <i className="fa fa-address-book"></i>
                </div>
                <p className=" mt-4 text-center fs">Terms of use</p>
              </div>
            </div>
            <div className="col-6 col-lg-3" onClick={modal.ppmodal}>
              <div
                className="h-100 py-2 py-md-3 services-icon-wap shadow"
                style={{ backgroundColor: "rgb(255 255 255)" }}
              >
                <div className="h1 text-vio text-center">
                  <i
                    className="far fa-eye-slash"
                    style={{ fontSize: "35px" }}
                  ></i>
                </div>
                <p className=" mt-4 text-center fs">Privacy Policy</p>
              </div>
            </div>

            <div className="col-6 col-lg-3 " onClick={modal.dpmodal}>
              <div
                className="h-100 py-2 py-md-3 services-icon-wap shadow"
                style={{ backgroundColor: "rgb(255 255 255)" }}
              >
                <div className="h1 text-vio text-center">
                  <i className="fa fa-truck fa-lg"></i>
                </div>
                <p className="fs mt-4 text-center">Delivery Policy</p>
              </div>
            </div>

            <div className="col-6 col-lg-3" onClick={modal.epmodal}>
              <div
                className="h-100 py-2 py-md-3 services-icon-wap shadow"
                style={{ backgroundColor: "rgb(255 255 255)" }}
              >
                <div className="h1 text-vio text-center">
                  <i
                    className="fas fa-exchange-alt"
                    style={{ fontSize: "35px" }}
                  ></i>
                </div>
                <p className="fs mt-4 text-center">Exchange Policy</p>
              </div>
            </div>
          </div>
        </div>

        <hr />
        <span style={{ fontSize: "20px" }}>
          POLICIES of every online shopping site are to be read clearly and
          understood before placing an order. PLEASE do so to avoid unnecessary
          confusion for either parties.
        </span>
        <br />
      </section> */}

      {/* <!-- End Section -->

    <!-- Start Brands -->
    
    <!--End Brands--> */}
    </>
  );
}

export default Viparchicmain;
