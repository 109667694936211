import React from 'react';
import { Link } from 'react-router-dom';

function Emptycart() {
  return (
    <>
      {/* cart empty */}
      <hr
        className="mt-0"
        style={{
          color: 'rgb(1, 32, 96)',
          opacity: '1',
          borderTop: '3px solid',
        }}
      />
      <section>
        <div className="container-fluid mt-100">
          <div className="row">
            <div className="col-md-12">
              <div className="card emptycartheight">
                <div className="card-body ">
                  <div className="col-sm-12 empty-cart-cls text-center ruigwuitgosr">
                    {' '}
                    <img
                      // src="/img/empty.png"
                      src="/img/shoppingbagg.png"
                      style={{
                        width: '130px',
                        height: '100px',
                        transform: 'scale(-1)',
                      }}
                      className="img-fluid mb-4 mr-3"
                      alt=""
                    />
                    <h3 className="text-dark">
                      <strong>Your Shopping Bag is Empty !</strong>
                    </h3>
                    {/* <div className='col-12'> */}
                    <p className="text-center text-dark">
                      <span className="" style={{ fontSize: '23px' }}>
                        A small percentage of the profit margin from the sale of
                        each product goes to{' '}
                        <span
                          className="vipar"
                          style={{ fontSize: '25px', color: '#99d050' }}
                        >
                          &nbsp;Vipar&nbsp;&nbsp;
                        </span>
                        &nbsp;CARE. So buy something !!
                      </span>{' '}
                    </p>
                    {/* </div> */}
                    <a
                      data-bs-toggle="collapse"
                      href="#collapseExample"
                      className="btn btn-outline-dark cart-btn-transform"
                      data-abc="true"
                    >
                      <span style={{ textTransform: 'none', fontSize: '15px' }}>
                        Start Shopping
                      </span>
                    </a>
                  </div>
                  <div className="collapse" id="collapseExample">
                    <div className="card card-body border-0 emptycartheight">
                      <center>
                        <Link
                          to="/vipar-chic/accessories"
                          className="btn btn-outline-dark cart-btn-transform m-3"
                        >
                          Go to Accessories
                        </Link>
                        <Link
                          to="/vipar-chic/articles"
                          className="btn btn-outline-dark cart-btn-transform m-3"
                        >
                          Go to Articles
                        </Link>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Emptycart;
